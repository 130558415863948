import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { asyncState, reducerUtils } from 'utils/asyncUtils';
import { call, put, takeLatest, takeEvery } from "@redux-saga/core/effects";
import * as NoticeApi from 'api/notice';
import { formatDateTime } from 'utils/dateUtils';
import { AxiosError } from 'axios';

type ApiResponse = {
    success: boolean;
    message: string;
}

type NoticeState = {
    noticeResponse: asyncState;
    createDialogOpen: boolean;
    editDialogOpen: boolean;
    deleteDialogOpen:boolean;
    registerResponse: asyncState;
    detailNoticeResponse: asyncState;
    modifyResponse: asyncState;
    selectedNotice: NoticeApi.NoticeDetail | null;
};

const initialState: NoticeState =  {
    noticeResponse: reducerUtils.initial(),
    createDialogOpen: false,
    editDialogOpen: false,
    deleteDialogOpen: false,
    registerResponse: reducerUtils.initial(),
    detailNoticeResponse: reducerUtils.initial(),
    modifyResponse: reducerUtils.initial(),
    selectedNotice: null
};

const noticeSlice = createSlice({
    name: 'notice',
    initialState: initialState,
    reducers: {
        getNoticeList(state) {
            state.noticeResponse = reducerUtils.loading();
        },
        getNoticeListSuccess(state, action: PayloadAction<NoticeApi.Notice[]>) {
            state.noticeResponse = reducerUtils.success(action.payload);
        },
        getNoticeListError(state, action: PayloadAction<AxiosError>) {
            state.noticeResponse = reducerUtils.error(action.payload);
        },
        setSeletedNotice(state, action: PayloadAction<NoticeApi.NoticeDetail | null>) {
            state.selectedNotice = action.payload;
        },
        openCreateDialog(state) {
            state.createDialogOpen = true;
        },
        closeCreateDialog(state) {
            state.createDialogOpen = false;
        },
        openEditDialog(state) {
            state.editDialogOpen = true;
        },
        closeEditDialog(state) {
            state.editDialogOpen = false;
            state.modifyResponse = reducerUtils.initial();
        },
        openDeleteDialog(state) {
            state.deleteDialogOpen = true;
        },
        closeDeleteDialog(state) {
            state.deleteDialogOpen = false;
        },
        registerNotice(state, action: PayloadAction<NoticeApi.NoticeCreateInput>) {
            state.registerResponse = reducerUtils.loading();
        },
        registerNoticeSuccess(state, action: PayloadAction<ApiResponse>) {
            state.registerResponse = reducerUtils.success(action.payload);
        },
        registerNoticeError(state, action: PayloadAction<AxiosError>) {
            state.registerResponse = reducerUtils.error(action.payload);
        },
        modifyNotice(state, action: PayloadAction<NoticeApi.NoticeModifyInput>) {
            state.modifyResponse = reducerUtils.loading();
        },
        modifyNoticeSuccess(state, action: PayloadAction<ApiResponse>) {
            state.modifyResponse = reducerUtils.success(action.payload);
            //state.detailResponse = reducerUtils.initial();
        },
        modifyNoticeError(state, action: PayloadAction<AxiosError>) {
            state.modifyResponse = reducerUtils.error(action.payload);
        },
        getNoticeDetail(state, action: PayloadAction<number>) {
            state.detailNoticeResponse = reducerUtils.loading();
        },
        getNoticeDetailSuccess(state, action: PayloadAction<NoticeApi.NoticeDetail>) {
            state.detailNoticeResponse = reducerUtils.success(action.payload);
        },
        getNoticeDetailError(state, action: PayloadAction<AxiosError>) {
            state.detailNoticeResponse = reducerUtils.error(action.payload);
        },
    },
});

export const { 
    getNoticeList, 
    getNoticeListSuccess, 
    getNoticeListError, 
    openCreateDialog, 
    closeCreateDialog,
    openEditDialog,
    closeEditDialog,
    openDeleteDialog,
    closeDeleteDialog,
    registerNotice,
    registerNoticeError,
    registerNoticeSuccess,
    modifyNotice,
    modifyNoticeSuccess,
    modifyNoticeError,
    getNoticeDetail,
    getNoticeDetailSuccess,
    getNoticeDetailError,
    setSeletedNotice
} = noticeSlice.actions;
export default noticeSlice.reducer;

/* saga */

function* getNoticeListSaga() {
    try {
        const noticeList: NoticeApi.Notice[] = yield call(NoticeApi.getNoticeList);
        const newNoticeList: NoticeApi.Notice[] = noticeList.map((notice:NoticeApi.Notice, index: number) => {
            const regDate = new Date(notice.reg_date);
            const formattedDate = formatDateTime(regDate);
            notice.reg_date = formattedDate;
            return notice;
        })
        yield put(getNoticeListSuccess(newNoticeList));
    } catch (error) {
        yield put(getNoticeListError(error));
    }
}

function* getNoticeDetailSaga(action: PayloadAction<number>) {
    try {
        const noticeDetail: NoticeApi.NoticeDetail = yield call(NoticeApi.getNoticeDetail, action.payload);
        yield put(getNoticeDetailSuccess(noticeDetail));
    } catch (error) {
        yield put(getNoticeDetailError(error));
    }
}

function* registerNoticeSaga(action: PayloadAction<any>) {
    try {
        const response: ApiResponse = yield call(NoticeApi.registerNotice, action.payload);
        yield put(registerNoticeSuccess(response));
    } catch (error) {
        yield put(registerNoticeError(error));
    }
}

function* modifyNoticeSaga(action: PayloadAction<NoticeApi.NoticeModifyInput>) {
    try {
        const response: ApiResponse = yield call(NoticeApi.modifyNotice, action.payload);
        yield put(modifyNoticeSuccess(response));
    } catch (error) {
        yield put(modifyNoticeError(error));
    }
}


export function* noticeSaga() {
    yield takeLatest(getNoticeList, getNoticeListSaga);
    yield takeLatest(getNoticeDetail, getNoticeDetailSaga);
    yield takeLatest(registerNotice, registerNoticeSaga);
    yield takeLatest(modifyNotice, modifyNoticeSaga);
}