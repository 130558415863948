import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useAlarm } from 'hooks/useAlarm';

import Layout from "layout";
import { Title } from 'pages';
import { PanelBox } from "components/styles/PanelBox";
import WholeNotify from './WholeNotify';
import NotConfirmedNotify from './NotConfirmedNotify';

const NotifyWrap = styled.div`
  .panel_notify {
    border-bottom-color: #c5cad0;
    min-height: 800px;
  }
`;
const NotifyList = styled.div`
  position: relative;
  padding: 30px;
  &::before {
    content: '';
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 36px;
    border-left: 3px solid #e0e2e5
  }
  ul {
    position: relative;
    padding-left: 14px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -1px;
      width: 16px;
      height: 16px;
      border: 8px double #e0e2e5;
      border-radius: 50%;
      background: #fff;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      width: 16px;
      height: 16px;
      border: 8px double #e0e2e5;
      border-radius: 50%;
      background: #fff;
    }
  }
  li {
    position: relative;
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    margin: -35px 0;
    &:first-child  {
      margin-top: 0
    }
    &:last-child  {
      margin-bottom: 0
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      border-top: 2px dotted #e0e2e5;
      margin-top: -1px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -14px;
      width: 14px;
      height: 14px;
      border: 3px solid #e0e2e5;
      border-radius: 50%;
      background: #fff;
      transform: translateY(-50%);
    }
    .box {
      position: relative;
      width: calc(50% - 5px);
      border: 2px solid #e0e2e5;
      border-radius: 5px;
      padding: 16px 20px;
      background: linear-gradient(#fdfdfd,#f6f6f9);
      box-shadow: inset 0 0 1px #fff;
      line-height: 1.5em;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -8px;
        width: 14px;
        height: 14px;
        border: 3px solid #e0e2e5;
        border-radius: 50%;
        background: #fff;
        transform: translateY(-50%);
      }
    }    
    &:nth-child(even) {
      justify-content: flex-end;
      &::before {
        width: 60%
      }
    }
    &:nth-child(odd) {
      &::before {
        width: 24px;
      }
    }
    &.new {
      &::before, &::after,
      .box, .box::after {
        border-color: #ff5d4d
      }
    }
    .time {
      color: #6a7484;
      margin-bottom: 6px
    }
    .cont {
      font-size: 13px;
      color: #071e3f;
    }
  }
`;
const Notify = () => {
    const currentPage = Title.Notify;

    const { callGetNotConfirmedAlarmCount, notConfirmedResponse } = useAlarm();
    const [showNotConfirmed, setShowNotConfirmed] = useState<boolean>(false);
    const [notConfirmedCount, setNotConfirmedCount] = useState<number>(0);

    useEffect(() => {
      callGetNotConfirmedAlarmCount();
    }, [showNotConfirmed]);

    useEffect(() => {
      if (!notConfirmedResponse.loading && (notConfirmedResponse.data !== null)) {
        setNotConfirmedCount(notConfirmedResponse.data);
      }
    }, [notConfirmedResponse]);

    const handleWholeAlarmClick = () => {
      //setShowNotConfirmed((prev: boolean) => (!prev));
      setShowNotConfirmed(false);
    };

    const handleNotComfirmedAlarmClick = () => {
      setShowNotConfirmed(true);
    };

    return (
        <Layout currentPage={currentPage}>
            <div className="headerOption">
                <div className="vmInfo" style={{cursor: "pointer"}}>
                    <span onClick={handleWholeAlarmClick}>
                      전체 알림 확인
                    </span>
                    <span onClick={handleNotComfirmedAlarmClick}>미확인 알림
                        <strong className="c3">{notConfirmedCount}</strong>
                    </span>
                </div>
            </div>
            <NotifyWrap>
                <PanelBox className="panel_notify">
                    <NotifyList>
                        {
                          (showNotConfirmed)
                          ? <NotConfirmedNotify />
                          : <WholeNotify />
                        }
                    </NotifyList>
                </PanelBox>
            </NotifyWrap>
        </Layout>
    );
}
export default Notify;
