import axios from 'axios';
import { handleError } from './axiosUtils';

//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("access-token");

export type userSimpleInfo = {
    id: string;
    name: string;
    col_nm: string;
    dep_nm: string;
    role_cd: string;
    job_cd: string;
    saida_stat_cd: number;
};


export const userLogin = async (userId: string, userPwd: string, userRole: string) => {
    console.log("loginUserApi userLogin");
    try {
        const requestUrl = "/user/login/auth";
        const data = {
            id: userId,
            pwd: userPwd,
            role: userRole,
        };

        const res = await axios.post(requestUrl, data);
        //const res = await axios.create(requestUrl, data);
        console.log(res);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const userLogOut = async () => {

    try {
        const requestUrl = "/user/logout";

        const res = await axios.post(requestUrl);
        return (res.data.data);
    } catch (error)  {
        handleError(error);
    }

}

export const idInqury = async (userName: string, userEmail: string) => {
    try {
        const requestUrl = "/user/idinqury";
        const data = {
            name: userName,
            email: userEmail
        };

        const res = await axios.post(requestUrl, data);
        console.log(res);
        return res;
    } catch(error) {
        //console.log(error);
        //throw new Error(error.message);
        handleError(error);
    }
};

export const getUserSimpleInfo = async () => {
    try {
        const requestUrl = "/user/myinfo-simple";
        
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error)  {
        handleError(error);
    }
};

export const changePassword = async (newPwd: string) => {
    try {
        const requestUrl = "/user/pwinqury/modify";
        const data = {
            pwd: newPwd
        };
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
}
