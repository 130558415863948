import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

type CustomCheckboxProps = {
    checked: boolean;
    setChecked: (value: boolean) => void;
    text: string;
}

function CustomCheckbox({
    checked,
    setChecked,
    text
}: CustomCheckboxProps) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <div>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <span>{text}</span>
        </div>
    );
}

export default CustomCheckbox;