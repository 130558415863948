import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest, getContext } from "@redux-saga/core/effects";
import * as LabApi from "api/lab";
import { reducerUtils, asyncState } from "utils/asyncUtils";
import { History } from "history";
import { AxiosError } from "axios";
import { spliteDate } from "utils/dateUtils";

type signInfo = {
  // sseq: number, //강의코드
  // stat_cd : number,
  res_cd: string;
  pwd: string;
  tseq: number;
  signup_msg: string;
};

type searchInfo = {
  pageNumber: number;
  stat_cd: string;
  start_day: string;
  end_day: string;
};

type labState = {
  sseq: number; //강의코드
  stat_cd: number;
  res_cd: string;
  pwd: string;
  tseq: number;
  signup_msg: string;
  signResponse: asyncState;
  booksResponse: asyncState;
  templateResponse: asyncState;
};

const initialState: labState = {
  sseq: 0, //강의코드
  stat_cd: 0,
  res_cd: "",
  pwd: "",
  tseq: 0,
  signup_msg: "",
  signResponse: reducerUtils.initial(),
  booksResponse: reducerUtils.initial(),
  templateResponse: reducerUtils.initial(),
};

const labSlice = createSlice({
  name: "lab",
  initialState: initialState,
  reducers: {
    labSignup(state, action: PayloadAction<signInfo>) {
      state.signResponse = reducerUtils.loading();
    },
    labSignupSuccess(state, action: PayloadAction<signInfo>) {
      state.signResponse = reducerUtils.success(action.payload);
    },
    labSignupError(state, action: PayloadAction<AxiosError>) {
      state.signResponse = reducerUtils.error(action.payload);
    },
    getBooksList(state, action: PayloadAction<searchInfo>) {
      state.booksResponse = reducerUtils.loading();
    },
    getBooksListSuccess(state, action: PayloadAction<LabApi.BookList[]>) {
      state.booksResponse = reducerUtils.success(action.payload);
    },
    getBooksListError(state, action: PayloadAction<AxiosError>) {
      state.booksResponse = reducerUtils.error(action.payload);
    },
    getBooksSearchList(state, action: PayloadAction<searchInfo>) {
      state.booksResponse = reducerUtils.loading();
    },
    getBooksSearchListSuccess(state, action: PayloadAction<LabApi.BookList[]>) {
      state.booksResponse = reducerUtils.success(action.payload);
    },
    getBooksSearchListError(state, action: PayloadAction<AxiosError>) {
      state.booksResponse = reducerUtils.error(action.payload);
    },
    getTemplateList(state) {
      state.templateResponse = reducerUtils.loading();
    },
    getTemplateListSuccess(state, action: PayloadAction<LabApi.TemplateInstance[]>) {
      state.templateResponse = reducerUtils.success(action.payload);
    },
    getTemplateListError(state, action: PayloadAction<AxiosError>) {
      state.templateResponse = reducerUtils.error(action.payload);
    },
  },
});

export const {
  labSignup,
  labSignupSuccess,
  labSignupError,
  getBooksList,
  getBooksListSuccess,
  getBooksListError,
  getBooksSearchList,
  getBooksSearchListSuccess,
  getBooksSearchListError,
  getTemplateList,
  getTemplateListSuccess,
  getTemplateListError
} = labSlice.actions;
export default labSlice.reducer;

/* saga */

function* signSaga(action: PayloadAction<signInfo>) {
  try {
    const ap = action.payload;
    yield call(LabApi.bookSignup, ap.res_cd, ap.pwd, ap.tseq, ap.signup_msg);
    // ap.sseq, ap.stat_cd,
    const history: History = yield getContext("history");
    history.push("/Portal/lab/reservationlist");
  } catch (error) {
    yield put(labSignupError(error));
  }
}

function* getBooksListSaga(action: any) {
  try {
    const booksResult: any[] = yield call(
      LabApi.getBookList,
      action.payload.pageNumber,
      action.payload.stat_cd,
      action.payload.start_day,
      action.payload.end_day
    );
    const result = Object.entries(booksResult);
    const booksList = result[0][1];
    const pageList = result[1][1];
    const total: number = pageList.rowCount;
    const newTemplateResult: LabApi.BookList[] = booksList.map(
      (books: LabApi.BookList, index: number) => {
        const regDate = books.reg_date;
        const formattedDate = spliteDate(regDate);
        books.reg_date = formattedDate;
        books.total = total;
        return books;
      }
    );
    yield put(getBooksListSuccess(newTemplateResult));
  } catch (error) {
    yield put(getBooksListError(error));
  }
}

function* getTemplateListSaga() {
    try {
      const templateResult: LabApi.TemplateInstance[] = yield call(
        LabApi.getTemplateList,
      );
      console.log(templateResult)
      console.log("~~~~??")

      yield put(getTemplateListSuccess(templateResult));
    } catch (error) {
      yield put(getTemplateListError(error));
    }
  }


export function* labSaga() {
  yield takeLatest(labSignup, signSaga);
  yield takeLatest(getBooksList, getBooksListSaga);
  yield takeLatest(getTemplateList, getTemplateListSaga);
}
