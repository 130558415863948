function callApi(url, data, method) {
    let dataString = "?";
    let option = {};
    console.log(data);
    if (method === "GET") {
        // if method is "GET", concat data
        let i = 0;
        for (let d in data) {
            if (i === 0) {
                dataString += (d + "=" + data[d]);
            }
            else {
                dataString += "&" + (d + "=" + data[d]);
            }

            i++;
        }
        url = url + "" + dataString;
        console.log(url);

        // append access-token if it exists in localStorage.
        if (localStorage.getItem("access-token")) {
            option = {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("access-token"),
                },
            };
        }
        else {
            option = {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                },
            };
        }
        
    }
    else {
        // if method is not "GET", execute bellow.
        console.log("method is not GET");
        // append access-token if it exists in localStorage.
        if (localStorage.getItem("access-token")) {
            option = {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("access-token"),
                },
                body: JSON.stringify(data)
            };
        }
        else {
            option = {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            };
        }
    }

    const call = new Promise((resolve, reject) => {
        fetch(url, option)
        .then(function (res) {
            console.log(res);
            if(res.status === 500) {
                throw new Error(500);
            }
            else if (res.status === 403) {
                throw new Error(403);
            }
            else {
                return res;
            }
        })
        .then(function (res) {
            if (res.status === 0) {
                reject(res);
            }
            else {
                resolve(res.json());
            }
        })
        .catch(function (res, b) {
            
        })
    })

    return call;
}

export { callApi };