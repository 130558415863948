import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { getExpectedList, getOnGoingList, getCompletedList, setSelectedReservation, setCompletedList } from 'store/reservationSlice';
import { Reservation, permitReservation } from 'api/reservation';
import { ApiResponse } from 'api/axiosUtils';

export function useReservation() {
    const { expectedResponse, ongoingResponse, completedResponse, selectedReservation } = useSelector((state: RootState) => state.reservation);
    const dispatch = useDispatch();

    const callGetReservationList = (timing: string) => {
        if (timing === 'EXPECT') {
            dispatch(getExpectedList(timing));
        }
        else if (timing === 'ING') {
            dispatch(getOnGoingList(timing));
        }
        else {
            dispatch(getCompletedList(timing));
        }
    };

    const callSetSelectedReservation = (reservation: Reservation | null) => {
        dispatch(setSelectedReservation(reservation));
    };

    const callPermitReservation = (nseq: number, stat_cd: number, timing: string, reason_msg?: string) => {
        permitReservation(nseq, stat_cd, reason_msg).then(function (res) {
            callGetReservationList(timing);
            callSetSelectedReservation(null);
        });
    };

    const updateRow = () => {
        
    }

    return {
        expectedResponse,
        ongoingResponse,
        completedResponse,
        selectedReservation,
        callGetReservationList,
        callSetSelectedReservation,
        callPermitReservation,
    };
}