import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
function GnLocal({match}:RouteComponentProps) {

    return (
        <React.Fragment>
            <div className="layout_inner">
                <div className="equipment_tit_wrap">
                    <h3 className="equipment_tit">경남 지역</h3>
                    <p>경남 지역의 연구/실습 장비 목록입니다.</p>
                </div>

                <h3 className="board_tit">장비 목록</h3>
                <div className="equipment_list_wrap type_top type_bottom">
                    <img src="/lib/image/equipment/gn_local_01.png" alt=""/>
                    <div className="text_wrap">
                        <h4 className="equipment_list_tit">경남 지역 보유 장비</h4>
                        <p>
                            그 외 경남지역 소재의 대학, 기업, 연구소 및 관련 기관의<br/>
                            보유 장비 목록을 확인하실 수 있습니다.
                        </p>
                        <button className="btn_view_more" onClick={()=>{window.open('https://gnjangbi.com/eqList')}}>보유 장비 보러가기</button>
                    </div>
                </div>

                <h3 className="board_tit">인적 자원</h3>
                <div className="equipment_human_list">
                <ul>
                    <li>
                        <div className="equip_human_box human_box_04">
                            <h5>경남 지역 기관별<br/>전문가 안내</h5>
                            <p>
                                경남연구 장비 지원 시스템<br/>
                                참여기관의 기관별 전문가를<br/>
                                확인하실 수 있습니다.
                            </p>
                            <a href="https://gnjangbi.com/inst/list" target="_blank">자세히 보기</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        </React.Fragment>
    );
}

export default GnLocal;