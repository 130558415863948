import React, { useState, useEffect } from 'react';

import { PanelBox } from "components/styles/PanelBox";
import styled from 'styled-components';
import { numberWithCommas } from 'utils/common-utils';
import { PieChart, Pie, Cell } from "recharts";


type DashboardCorechartProps = {
  core : any;
}


const DashboardCorechart = ({core}: DashboardCorechartProps) => {
  const labelFormatter = (value:any) => {
    return value + '%';
};

    const data = [
      { name: "사용", value: core.used },
      { name: "잔여", value: core.total-core.used },
    ];

    const renderCustomizedLabel = (()=>{
      return(
        <text fill="red">{}</text>
      );
    })

    const COLORS = ["#8884D8", "#82CA9D"];

    return(
        <PanelBox className="panel_graph">
        <div className="panelTitBar">
            <div className="tit">VCPUs</div>
        </div>
       
        <ChartWarrap>
          <PieChart width={320} height={310} style={{left:'50%', marginLeft:'-120px',top:'50%', marginTop:'-50px', marginBottom:'50px'}}>
                <Pie
                  data={data}
                  cx={130}
                  cy={200}
                  startAngle={90}
                  endAngle={-270}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label = {({          
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    value,
                    index})=>{
                      const RADIAN = Math.PI / 180;
                      const radius = 25 + innerRadius + (outerRadius - innerRadius);
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);
                    return (
                    <text x={x} y={y} fill="#8884d8" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">{data[index].name}: {numberWithCommas(value)}</text>
                    )
                  }}
                >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
          </PieChart>
        </ChartWarrap>

        <DashboardList>
                    <li className="vm_01">
                        <span className="label">총 VCPUs </span>
                        <span className="value">{ numberWithCommas(core.total) }<span className="ea">Core</span></span>
                    </li>
                    <li className="vm_03">
                        <span className="label">사용중인 VCPUs</span>
                        <span className="value">{ numberWithCommas(core.used) }<span className="ea">Core</span></span>
                    </li>
         </DashboardList>
    </PanelBox>
    )
}

export default DashboardCorechart;

const DashboardList = styled.ul`
  flex-grow: 1;
  margin-left: 13px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 45px;
    padding: 10px 10px 10px 20px;
    &:first-child ~ li {
      border-top: 1px dotted #c5cad0
    }
    .label {
      position: relative;
      padding-left: 12px;
      color: #071e3f;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #071e3f;
        transform: translateY(-50%);
      }
    }
    .value {
      font-size: 22px;
      font-weight: bold
    }
  }
`;

  const ChartWarrap = styled.div`
  position:relative
`;