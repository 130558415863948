import React from 'react';
import { practicerStateClass } from 'utils/state';

export default function SAIDACellRenderer (props: any) {
    if (props.data.saida_stat_cd === 1) {
        return `<span class="state_ico ${practicerStateClass.APPROVED}">승인 및 사용중</span>`;
    }
    else {
        return `<span></span>`;
    }
}