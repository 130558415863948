import React, { useState, useEffect } from 'react';
import { userLogin } from 'api/loginUser';

type loginInput = {
    id: string;
    pwd: string;
    role: string;
};

export function useApproveVm() {
    const [loginInput, setLoginInput] = useState<loginInput>({
        id: '',
        pwd: '',
        role: 'ADMIN',
    });

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.name;
        const value = event.target.value;

        setLoginInput((prev: loginInput) => ({
            ...prev,
            [key]: value,
        }));
    };

    return {
        loginInput,
        onChangeInput,
    }
}