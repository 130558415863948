import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import * as CommunityApi from "../api/community";
import { reducerUtils, asyncState } from "../utils/asyncUtils";
import { formatDateTime, spliteDateTime } from "../utils/dateUtils";
import { AxiosError } from "axios";

type searchInfo = {
  pageNumber: number;
  keyword: string;
};

type communityState = {
  noticeResponse: asyncState;
  noticeContentResponse: asyncState;
  noticeImportantResponse: asyncState;
};

const initialState: communityState = {
  noticeResponse: reducerUtils.initial(),
  noticeContentResponse: reducerUtils.initial(),
  noticeImportantResponse: reducerUtils.initial(),
};

const communitySlice = createSlice({
  name: "community",
  initialState: initialState,
  reducers: {
    getNoticeList(state, action: PayloadAction<searchInfo>) {
      state.noticeResponse = reducerUtils.loading();
    },
    getNoticeListSuccess(
      state,
      action: PayloadAction<CommunityApi.noticeList[]>
    ) {
      state.noticeResponse = reducerUtils.success(action.payload);
    },
    getNoticeListError(state, action: PayloadAction<AxiosError>) {
      state.noticeResponse = reducerUtils.error(action.payload);
    },
    getNoticeContent(state, action: PayloadAction<any>) {
      state.noticeContentResponse = reducerUtils.loading();
    },
    getNoticeContentSuccess(
      state,
      action: PayloadAction<CommunityApi.noticeContent[]>
    ) {
      state.noticeContentResponse = reducerUtils.success(action.payload);
    },
    getNoticeContentError(state, action: PayloadAction<AxiosError>) {
      state.noticeContentResponse = reducerUtils.error(action.payload);
    },
    getImportantNoticeList(state) {
      state.noticeImportantResponse = reducerUtils.loading();
    },
    getImportantNoticeListSuccess(state, action: PayloadAction<any[]>) {
      state.noticeImportantResponse = reducerUtils.success(action.payload);
    },
    getImportantNoticeListError(state, action: PayloadAction<AxiosError>) {
      state.noticeImportantResponse = reducerUtils.error(action.payload);
    },
  },
});

export const {
  getNoticeList,
  getNoticeListSuccess,
  getNoticeListError,
  getNoticeContent,
  getNoticeContentSuccess,
  getNoticeContentError,
  getImportantNoticeList,
  getImportantNoticeListSuccess,
  getImportantNoticeListError
} = communitySlice.actions;
export default communitySlice.reducer;

/* saga */
function* getNoticeListSaga(action: any) {
  try {
    const noticeResult: any[] = yield call(
      CommunityApi.getNoticeList,
      action.payload.pageNumber,
      action.payload.keyword
    );
    const result = Object.entries(noticeResult);
    const noticeList = result[0][1];
    const pageList = result[1][1];
    const total: number = pageList.rowCount;
    const newNoticeResult: CommunityApi.noticeList[] = noticeList.map(
      (notice: CommunityApi.noticeList, index: number) => {
        const regDate = new Date(notice.reg_date);
        const formattedDate = formatDateTime(regDate);
        const real = spliteDateTime(formattedDate);

        notice.reg_date = real;
        notice.total = total;
        return notice;
      }
    );
    yield put(getNoticeListSuccess(newNoticeResult));
  } catch (error) {
    yield put(getNoticeListError(error));
  }
}

function* getNoticeContentSaga(action: any) {
  try {
    const noticeResult: CommunityApi.noticeContent[] = yield call(
      CommunityApi.getNoticeContent,
      action.payload
    );

    yield put(getNoticeContentSuccess(noticeResult));
  } catch (error) {
    yield put(getNoticeContentError(error));
  }
}

function* getImportantNoticeListSaga() {
        try {
          const noticeImportantResult: any[] = yield call(
            CommunityApi.getImportantNoticeList
          );
         
          console.log(noticeImportantResult);
       
          yield put(getImportantNoticeListSuccess(noticeImportantResult));
        } catch (error) {
          yield put(getImportantNoticeListError(error));
        }
      }

export function* communitySaga() {
  yield takeLatest(getNoticeList, getNoticeListSaga);
  yield takeLatest(getNoticeContent, getNoticeContentSaga);
  yield takeLatest(getImportantNoticeList, getImportantNoticeListSaga)
}
