import React from 'react';
import styled from 'styled-components';
import { usePortalDataset } from 'hooks/usePortalDataset';
import { useDatasetDetail } from 'hooks/useDatasetDetail';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CIconButton } from "components/buttons";

import DatasetEditDialog from './DatasetEditDialog';

const TitleWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .notice-important {
        color: #F72828;
    }
`;


const AttachedFile = styled.div`
    display: flex;
    align-items: flex-start;

    p {
        padding: 0px 5px;
    }
`;



type DatasetDetailProps = {
    editDialogOpen: boolean;
    handleEditDialogOpen: () => void;
    handleEditDialogClose: () => void;
}

function DatasetDetail({
    editDialogOpen,
    handleEditDialogOpen,
    handleEditDialogClose
}: DatasetDetailProps) {
    const { detail, handleDelete } = useDatasetDetail();

    const { handleDownloadDataset } = usePortalDataset();


    const handleDownload = (dsseq: string | number | undefined) => {
        dsseq && handleDownloadDataset(dsseq);
    }
    return (
        (detail) &&
        <PanelBox className="panel_detail">
                <div className="panelTitBar">
                    <div className="tit"><strong>{detail.title}</strong>의 상세보기</div>
                </div>
                <CommActionBar>
                    <div className="iconBtnGrope">
                        <CIconButton
                            icon="edit"
                            tooltip="수정"
                            onClick={handleEditDialogOpen}
                        />
                        <CIconButton
                            icon="delete2"
                            tooltip="삭제"
                            onClick={handleDelete}
                        />
                    </div>
                    <DatasetEditDialog
                        open={editDialogOpen}
                        onClose={handleEditDialogClose}
                    />
                </CommActionBar>
                <div className="panelCont">
                    <table className="tb_data">
                        <tbody>
                        <tr>
                            <th>제목</th>
                            <td>
                                <TitleWrap>
                                    <div>{detail.title}</div>
                                    {/*(detail.statCd === 0) ? <div className="notice-important"><span className="state_ico state_04">중요</span></div> : <div></div>*/}
                                </TitleWrap>
                            </td>
                        </tr>
                        <tr>
                            <th>파일명</th>
                            {
                                (detail.file_nm !== null)             
                                ? 
                                <AttachedFile onClick={(e) => handleDownload(detail.dsseq)}>
                                    <img src="/lib/image/common/ico_file.png" alt=""/>
                                    <p>{detail?.file_nm}</p>
                                    <img src="/lib/image/common/ico_download.png" alt=""/>
                                </AttachedFile>

                                : ""
                            }
                        </tr>
                        <tr>
                            <th>제공기관</th>
                            <td><div>{detail.provider}</div></td>
                        </tr>
                        <tr>
                            <th>분류체계</th>
                            <td><div>{detail.category_name}</div></td>
                        </tr>
                        <tr>
                            <th>파일확장자</th>
                            <td><div>{detail.extension_cd}</div></td>
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td><pre style={{height:'110px', overflowY: 'scroll'}}>{detail.content}</pre></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </PanelBox>
    );
}

export default DatasetDetail;