import axios from 'axios';
import { handleError } from './axiosUtils';

export type Alarm = {
    useq: number;
    rseq: number;
    msg: string;
    grade: number;
    class_alarm: string;
    reg_date: string;
    cnf_date: string | null;
    is_cnf: boolean;
};

export type PushAlarm = {
    id: string; // 사용자 아이디
    useq: number; // 사용자 시퀀스
    rseq: number; // 알람 시퀀스
    msg: string; // 알람 메시지
    grade: string; // 알람 등급
    class: string; // 알람 유형
    detail: any; // payload
};

export type VmCreatePushDetail = {
    ratio: string;
    isFinished: boolean;
    count: number;
    total: number;
}

export const getAlarmList = async (page: number, notConfirm: boolean = false) => {
    try {
        const requestUrl = "/log/alarm/list";

        const res = await axios.get(requestUrl, {
            params: {
                notConfirm: notConfirm,
                page: page,
                displayCnt: 20
            }
        });
        return (res.data.data.alarmLogList);
    } catch (error) {
        handleError(error);
    }
};

export const confirmAlarm = async (rseq: number) => {
    try {
        const requestUrl = "/log/alarm/confirm";
        const res = await axios.get(requestUrl, {
            params: {
                rseq: rseq
            }
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getNotConfirmedAlarmCont = async () => {
    try {
        const requestUrl = "/log/alarm/not-confirm";
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch(error) {
        handleError(error);
    }
};