import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncState, createAsyncReducer, createAsyncSaga, reducerUtils } from 'utils/asyncUtils';
import { takeEvery } from '@redux-saga/core/effects';
import { approveAuth } from 'api/mail';

type mailState = {
    authApprovalResponse: asyncState;
}

const initialState: mailState = {
    authApprovalResponse: reducerUtils.initial(),
}

const mailSlice = createSlice({
    name: 'mail',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        ...createAsyncReducer('mail/authApproval', 'authApprovalResponse'),
    }
});

export default mailSlice.reducer;

/* saga */

const authApprovalSaga = createAsyncSaga('mail/authApproval', approveAuth);

export function* mailSaga() {
    yield takeEvery('mail/authApproval', authApprovalSaga);
}