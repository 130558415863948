import React from 'react';
import { Link } from 'react-router-dom';

type LocationProps = {
    location: string;
    rootLocation?: string;
};

function Location({ 
    location, 
    rootLocation 
}: LocationProps) {

    return (
        <section id="location">
            <div className="header_location">
                <h2 className="layout_screen-out">Site Location</h2>
                <div className="layout_inner layout_clearfix js_location">
                    <Link to="/main">{rootLocation}</Link>
                    <span>{location}</span>
                </div>
            </div>
        </section>
    );
}

export default Location;