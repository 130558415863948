import React, { useEffect } from 'react';
import "styles/portal.scss";
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import Home from './pages/Home/home';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import LoginRouter from './router/loginRouter';
import JoinRouter from './router/joinRouter';
import FindIdRouter from './router/findIdRouter';
import FindPasswordRouter from './router/findPasswordRouter';
import MyPageRouter from './router/myPageRouter';
import InfoRouter from './router/infoRouter';
import LabRouter from './router/labRouter';
import EquipmentRouter from './router/equipmentRouter';
import ContestRouter from './router/contestRouter';
import CommunityRouter from './router/communityRouter';
import Privacy from './pages/Home/privacy';

function PortalMain({ match }: RouteComponentProps) {

    return (
        <React.Fragment>
            <Header />
            <Switch>
                <Route exact path={`${match.url}`} component={Home} />
                <Route path={`${match.url}/main`} component={Home} />
                <Route path={`${match.url}/privacy`} component={Privacy} />
                <Route path={`${match.url}/login`} component={LoginRouter} />
                <Route path={`${match.url}/join`} component={JoinRouter} />
                <Route path={`${match.url}/find_id`} component={FindIdRouter} />
                <Route path={`${match.url}/find_password`} component={FindPasswordRouter} />
                <Route path={`${match.url}/mypage`} component={MyPageRouter} />
                <Route path={`${match.url}/about`} component={InfoRouter} />
                <Route path={`${match.url}/lab`} component={LabRouter} />
                <Route path={`${match.url}/equipment`} component={EquipmentRouter} />
                <Route path={`${match.url}/contest`} component={ContestRouter} />
                <Route path={`${match.url}/community`} component={CommunityRouter} />
            </Switch>
            <Footer match={match} />
        </React.Fragment>
    );
}

export default PortalMain;