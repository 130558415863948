import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { usePracticer } from 'hooks/usePracticer';

import { CDialog } from "components/dialogs";
import { swalConfirm } from "utils/swal-utils";
import { PanelBox } from "components/styles/PanelBox";

const CodeWrap = styled.div`
  width: 100%;
  height: 380px;
  background: #272822;
  color: #f8f8f2;
  padding: 20px;
  font-family: Consolas,"courier new",serif;
  font-size: 105%;
  overflow: auto;
`;

type StateLogDialogProps = {
    open: boolean;
    onClose: () => void;
};

function StateLogDialog ({
    open,
    onClose
}: StateLogDialogProps) {
    const { logDetailResponse } = usePracticer();
    const [logDetail, setLogDetail] = useState<string>("");
    const handleClose = () => {
        setLogDetail("");
        onClose && onClose();
    };

    useEffect(() => {
        if(!logDetailResponse.loading && logDetailResponse.data) {
            setLogDetail(logDetailResponse.data);
        }
    }, [logDetailResponse]);

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="sm"
            title={`상세 LOG 정보`}
            onClose={handleClose}
            modules={['confirm']}
        >
            <CodeWrap>
                {logDetail}
            </CodeWrap>
        </CDialog>
    );
}
export default StateLogDialog;
