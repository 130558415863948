import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncState, createAsyncReducer, createAsyncSaga, reducerUtils } from 'utils/asyncUtils';
import { getDatasetList, getDatasetCategories, getFileExtensions, addDataset, getDatasetDetail, modifyDataset, deleteDataset } from 'api/dataset';
import { takeLatest, takeEvery, put, call } from '@redux-saga/core/effects';
import { swalAlert } from 'utils/swal-utils';

type datasetState = {
    datasetResponse: asyncState;
    categoryResponse: asyncState;
    fileExtensionResponse: asyncState;
    registerResponse: asyncState;
    detailResponse: asyncState;
    modifyResponse: asyncState;
    deleteResponse: asyncState;
};

const initialState: datasetState = {
    datasetResponse: reducerUtils.initial(),
    categoryResponse: reducerUtils.initial(),
    fileExtensionResponse: reducerUtils.initial(),
    registerResponse: reducerUtils.initial(),
    detailResponse: reducerUtils.initial(),
    modifyResponse: reducerUtils.initial(),
    deleteResponse: reducerUtils.initial(),
};

const datasetSlice = createSlice({
    name: 'dataset',
    initialState: initialState,
    reducers: {
        resetRegisterResponse(state: datasetState) {
            state.registerResponse = reducerUtils.initial();
        },
        resetModifyResponse(state: datasetState) {
            state.modifyResponse = reducerUtils.initial();
        },
        resetDeleteResponse(state: datasetState) {
            state.deleteResponse = reducerUtils.initial();
        },
        resetDetailResponse(state: datasetState) {
            state.detailResponse = reducerUtils.initial();
        }
    },
    extraReducers: {
        ...createAsyncReducer('dataset/getDatasetList', 'datasetResponse'),
        ...createAsyncReducer('dataset/getCategoryList', 'categoryResponse'),
        ...createAsyncReducer('dataset/getFileExtensionList', 'fileExtensionResponse'),
        ...createAsyncReducer('dataset/registerDataset', 'registerResponse'),
        ...createAsyncReducer('dataset/getDatasetDetail', 'detailResponse'),
        ...createAsyncReducer('dataset/modifyDataset', 'modifyResponse'),
        ...createAsyncReducer('dataset/deleteDataset', 'deleteResponse'),
    }
});

export const {
    resetRegisterResponse,
    resetModifyResponse,
    resetDeleteResponse,
    resetDetailResponse
} = datasetSlice.actions;
export default datasetSlice.reducer;

/* saga */

const getDatasetListSaga = createAsyncSaga('dataset/getDatasetList', getDatasetList);
const getCategoryListSaga = createAsyncSaga('dataset/getCategoryList', getDatasetCategories);
const getFileExtensionListSaga = createAsyncSaga('dataset/getFileExtensionList', getFileExtensions);
const registerDatasetSaga = createAsyncSaga('dataset/registerDataset', addDataset);
const getDatasetDetailSaga = createAsyncSaga('dataset/getDatasetDetail', getDatasetDetail);
const modifyDatasetSaga = createAsyncSaga('dataset/modifyDataset', modifyDataset);
const deleteDatasetSaga = createAsyncSaga('dataset/deleteDataset', deleteDataset);

function* registerSuccessSaga(action: any) {
    yield put({
        type: 'dataset/getDatasetList'
    });

    yield put(resetRegisterResponse());
}

function* registerErrorSaga(action: any) {
    yield call(() => swalAlert("데이터셋 등록에 실패하였습니다."));
    yield put(resetRegisterResponse());
}

function* modifySuccessSaga(action: any) {
    yield put({
        type: 'dataset/getDatasetDetail',
        payload: action.payload.dsseq
    });

    yield put({
        type: 'dataset/getDatasetList'
    });

    yield put(resetModifyResponse());
}

function* modifyErrorSaga(action: any) {
    yield call(() => swalAlert("데이터셋 수정에 실패하였습니다."));
    yield put(resetModifyResponse());
}

function* deleteSuccessSaga(action: any) {
    yield call(() => swalAlert("데이터셋을 삭제하였습니다."));
    yield put({
        type: 'dataset/getDatasetList'
    });
    yield put(resetDetailResponse());
}

export function* datasetSaga() {
    yield takeLatest('dataset/getDatasetList', getDatasetListSaga);
    yield takeLatest('dataset/getCategoryList', getCategoryListSaga);
    yield takeLatest('dataset/getFileExtensionList', getFileExtensionListSaga);
    yield takeEvery('dataset/registerDataset', registerDatasetSaga);
    yield takeEvery('dataset/registerDatasetSuccess', registerSuccessSaga);
    yield takeEvery('dataset/registerDatasetError', registerErrorSaga);
    yield takeLatest('dataset/getDatasetDetail', getDatasetDetailSaga);
    yield takeEvery('dataset/modifyDataset', modifyDatasetSaga);
    yield takeEvery('dataset/modifyDatasetSuccess', modifySuccessSaga);
    yield takeLatest('dataset/deleteDataset', deleteDatasetSaga);
    yield takeEvery('dataset/modifyDatasetError', modifyErrorSaga);
    yield takeEvery('dataset/deleteDatasetSuccess', deleteSuccessSaga);
}