import React, { useState, useEffect } from 'react';
import { useNotice } from 'hooks/useNotice';
import styled from 'styled-components';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CIconButton } from "components/buttons";
import NoticeEditDialog from './NoticeEditDialog';
import NoticeDeleteDialog from './NoticeDeleteDialog';

const TitleWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .notice-important {
        color: #F72828;
    }
`;

function NoticeDetails(){
    const { selectedNotice,  detailNoticeResponse, editDialogOpen, deleteDialogOpen, callOpenEditDialog, callCloseEditDialog, callOpenDeleteDialog, callCloseDeleteDialog} = useNotice();
    const [ detail, setDetail ] = useState({
        cont : '',
        fileNm: '',
        cseq: 0,
        title: '',
        statCd: 0
    })

    const hostUrl = window.location.protocol+'//'+window.location.host;

    useEffect(() => {
        if(!detailNoticeResponse.loading && !detailNoticeResponse.error && detailNoticeResponse.data){
            const cont = detailNoticeResponse.data.data.cont;
            const fileNm = detailNoticeResponse.data.data.file_nm;
            const cseq = detailNoticeResponse.data.data.cseq;
            const title = detailNoticeResponse.data.data.title;
            const statCd = detailNoticeResponse.data.data.stat_cd;
            setDetail({
                cont: cont,
                fileNm: fileNm,
                cseq: cseq,
                title: title,
                statCd: statCd,
            });
        }
    }, [detailNoticeResponse]);

    
    const handleEdit = () => {
        callOpenEditDialog();
        console.log(hostUrl);
    }
    const handleEditClose = () => {
        callCloseEditDialog();
    }


    const handleDelete = () => {
        callOpenDeleteDialog();
    }
    const handleDeleteClose = () => {
        callCloseDeleteDialog();
    }


    if (selectedNotice === null) {
        return (
            <PanelBox>

            </PanelBox>
        );
    }
 
        return (
            <PanelBox className="panel_detail">
                <div className="panelTitBar">
                    <div className="tit"><strong>{selectedNotice.title}</strong>의 상세보기</div>
                </div>
                <CommActionBar>
                    <div className="iconBtnGrope">
                        <CIconButton
                            icon="edit"
                            tooltip="수정"
                            onClick={handleEdit}
                        />
                        <CIconButton
                            icon="delete2"
                            tooltip="삭제"
                            onClick={handleDelete}
                        />
                    </div>
                    <NoticeEditDialog
                        open={editDialogOpen}
                        onClose={handleEditClose}
                        detail={detail}
                        hostUrl={hostUrl}
                    />
                    <NoticeDeleteDialog
                        open={deleteDialogOpen}
                        onClose={handleDeleteClose}
                        detail={detail}
                        hostUrl={hostUrl}
                    />
                </CommActionBar>
                <div className="panelCont">
                    <table className="tb_data">
                        <tbody>
                        <tr>
                            <th>제목</th>
                            <td>
                                <TitleWrap>
                                    <div>{selectedNotice.title}</div>
                                    {(detail.statCd === 0) ? <div className="notice-important"><span className="state_ico state_04">중요</span></div> : <div></div>}
                                </TitleWrap>
                            </td>
                        </tr>
                        <tr>
                            <th>파일명</th>
                            {
                                (detail.fileNm !== null)
                                ? <a href={`${hostUrl}/Portal/community/notice/download-file?cseq=${detail.cseq}`}><td>{detail.fileNm}</td></a>
                                : ""
                            }
                        </tr>
                        <tr>
                            <th>내용</th>
                            <td><pre style={{height:'110px', overflowY: 'scroll'}}>{detail.cont}</pre></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </PanelBox>
        );
}


export default NoticeDetails;