/* config */

export const websocketConnectUrl = 'https://myplatform.kr/websocket';
//export const websocketConnectUrl = 'http://182.252.131.61:9008/websocket';
export const saidaUrl = "http://bigdata.myplatform.kr:8902"
//export const saidaUrl = "http://118.39.27.165:8902";
//export const serverUrl = "http://182.252.131.61:9006";
export const serverUrl = "https://myplatform.kr";
//export const serverUrl = "http://localhost:8080"
export const userGuideUrl = "https://joahanen.cafe24.com"
export const coreList = ['CPU', 'GPU']
export const gpu_prefix = "CUSTOM_GPU"
export const gpuFlavorList = 
[
    {
        name : 'mdev_nvidia-468',
        vcpus : 6,
        ram : 4096,
        disk : 64,
        type_id : 'mdev_nvidia-468'
    },
    {
        name : 'mdev_nvidia-470',
        vcpus : 12,
        ram : 8192,
        disk : 160,
        type_id : 'mdev_nvidia-470'
    },
    {
        name : 'mdev_nvidia-471',
        vcpus : 24,
        ram : 10240,
        disk : 240,
        type_id : 'mdev_nvidia-471'
    },
    {
        name : 'mdev_nvidia-473',
        vcpus : 96,
        ram : 40960,
        disk : 320,
        type_id : 'mdev_nvidia-473'
    }
]