import Swal from "sweetalert2";

export const swal = (text) => {
    return Swal.fire({
        text: text,
        width: 270,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
    });
};

export const swalConfirm = (text) => {
    return Swal.fire({
        text: text,
        width: 270,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
    });
};

export const swalAlert = (text) => {
    return Swal.fire({
        text: text,
        width: 270,
        showCancelButton: false,
        confirmButtonText: '확인'
    });
}

export const openSwal = (
    text,
    onClickConfirm = () => {console.log("useSwal test");}
) => {
    return Swal.fire({
        text: text,
        width: 270,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
    }).then(function(res) {
        if (res.isConfirmed) {
            onClickConfirm();
        }

        return res;
    });
};
