import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { useLab } from "hooks/useLab";
import TextField from "@material-ui/core/TextField";
import { materialDate } from 'utils/dateUtils';

import { saidaUrl } from "config";
import { BookList, SignupDay } from "api/lab";
import // formatDate,
// toStringDate,
// spliteDate,
// dayInKorean,
// formatTime,
"utils/dateUtils";
import * as Common from "../../utils/common.js";
import ReservationListTable from "./reservationListTable";
import LabPagination from "./LabPagiation";

function ReservationList({ match }: RouteComponentProps) {
  const { booksResponse, saidaUseStat, callGetBooksList } = useLab();
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState(0);
  const [searchStatus, setSearchStatus] = useState("");
  const defaultNow = materialDate(new Date());
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultNow);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultNow);
  const [msg, setMsg] = useState("");
  //const [showPaging, setShowPaging] = React.useState(true);
  // const [ startDefault, setStartDefault] = React.useState(true);
  // const [ endDefault, setEndDefault] = React.useState(true);
  

  useEffect(() => {
    if (!booksResponse.loading && !booksResponse.error && booksResponse.data) {
      const tmpBooksList = booksResponse.data.map((books: BookList) => {
        let tmpBooks = { ...books };

        // const regDay: string = spliteDate(books.reg_date);
        // const startDay: string = formatDate(books.start_day);
        // const endDay: string = formatDate(books.end_day);
        const regDay: string = books.reg_date;
        const startDay: string = books.start_day;
        const endDay: string = books.end_day;
        const lectureDay = startDay + " ~ " + endDay;

        tmpBooks.start_day = startDay;
        tmpBooks.end_day = endDay;
        tmpBooks.reg_date = regDay;
        tmpBooks.lecture_day = lectureDay;

        // 강의 시간
        let lectureTime: string = "";
        books.dayList.forEach((day: SignupDay, index: number) => {
          // const dayString = dayInKorean(day.week);
          // const startTime = formatTime(day.start_time);
          // const endTime = formatTime(day.end_time);
          const dayString = day.week;
          const startTime = day.start_time;
          const endTime = day.end_time;
          if (index === 0) {
            lectureTime =
              lectureTime + (dayString + "  " + startTime + " ~ " + endTime);
          } else {
            lectureTime =
              lectureTime +
              " / " +
              (dayString + "  " + startTime + " ~ " + endTime);
          }
        });
        tmpBooks.lecture_time = lectureTime;

        if (tmpBooks.total !== undefined) {
          setTotal(tmpBooks.total);
        } else if (tmpBooks.total === 0) {
          setTotal(0);
          setMsg("예약 현황이 없습니다.");
        }
        return tmpBooks;
      });
      setRowData(tmpBooksList);
    } else if (!booksResponse.loading || booksResponse.error) {
      setTotal(0);
      setMsg("예약 현황이 없습니다.");
    }
  }, [booksResponse]);

  const updateLabList = (newList?: any) => {
    let List: any = {};
    if (searchStatus === "" || newList === undefined || searchStatus==="전체") {
      List = {
        pageNumber: pageNumber,
      };
      callGetBooksList(List);

      //setShowPaging(true);

    } else {
      callGetBooksList(newList);
      //setShowPaging(false);
    }
  };

  useEffect(() => {
    const newList = {
      pageNumber: pageNumber,
      stat_cd: searchStatus,
      start_day: selectedStartDate,
      end_day: selectedEndDate,
    };

    updateLabList(newList);
  }, [pageNumber]);

  const handleAscSort = () => {
    console.log("asc sort");
    let _rowData = [...rowData];
    _rowData.sort((a: any, b: any) => a.start_day.localeCompare(b.start_day));
    setRowData(_rowData);
  };

  const handleDescSort = () => {
    console.log("desc sort");
    let _rowData = [...rowData];
    let ascDate = _rowData
      .sort((a: any, b: any) => a.start_day.localeCompare(b.stat_str))
      .reverse();
    let descDate = ascDate.reverse();
    setRowData(descDate);
  };

  const handleSearchData = (e: any, type: string) => {
    const value = e.target.value;
    if (type === "status") {
      setSearchStatus(value);
    } else if (type === "start") {
      setSelectedStartDate(value);
    } else if (type === "end") {
      setSelectedEndDate(value);
    }
  };

  const getSearchData = useCallback(() => {
    const status = searchStatus;
    const startDate = selectedStartDate;
    const endDate = selectedEndDate;

    // const FullList = {
    //   pageNumber: pageNumber,
    // };

    const newList = {
      pageNumber: 1,
      stat_cd: status,
      start_day: startDate,
      end_day: endDate,
    };

    updateLabList(newList);

    // if(status === ""){
    //   updateLabList(FullList);
    //   setShowPaging(true);
    // }else if(status === "전체"){
    //   updateLabList(FullList);
    //   setShowPaging(true);

    // }else{
    // updateLabList(newList);
    //}
    
  }, [searchStatus, selectedStartDate, selectedEndDate, callGetBooksList]);

  const onChangeServicePage = (page: number) => {
    setPageNumber(page);
  };

  const handleSaidaClick = () => {
    window.open(saidaUrl, "_blank");
  };

  return (
    <React.Fragment>
      <div className="layout_inner">
        <h3 className="board_tit">예약 현황 검색</h3>
        <div className="search_box">
          <ul>
            {/* <input type="text" className="rsv_input_01" placeholder="종료일자" style={{width:"320px"}}/> */}
            <li style={{ margin: "0px 20px" }}>
              <span style={{ marginRight: "20px", verticalAlign: "middle" }}>
                신청일자
              </span>
              <TextField
                id="date"
                type="date"
                // defaultValue={materialDate(new Date())}
                defaultValue={selectedStartDate}
                onChange={(e) => {
                  handleSearchData(e, "start");
                }}
              />
              <span
                style={{
                  color: "#a4aab0",
                  margin: "0px 20px",
                  textAlign: "center",
                }}
              >
                {" "}
                ~{" "}
              </span>
              <TextField
                id="date"
                type="date"
                defaultValue={selectedEndDate}
                onChange={(e) => {
                  handleSearchData(e, "end");
                }}
              />
            </li>
            <li style={{ margin: "0px 20px" }}>
              <span style={{ marginRight: "20px", verticalAlign: "middle" }}>
                예약 상태
              </span>
              <div
                className="select_wrap select_status select_wrap_full"
                style={{ width: "200px", margin: "0px 3px" }}
              >
                <select
                  name="_status"
                  id="_status"
                  className="reservationOption"
                  defaultValue="전체"
                  onChange={(e) => {
                    handleSearchData(e, "status");
                  }}
                >
                  <option value="전체">전체</option>
                  <option value="1">승인</option>
                  <option value="3">취소</option>
                  {/* <option value="반려2">반려</option>
                                        <option value="">예약 상태</option>
                                        <option value="3">보류</option>
                                        <option value="4">취소</option>
                                        <option value="5">정지</option> */}
                </select>
              </div>
            </li>
            <li>
              <button
                type="button"
                className="contest_button"
                style={{ marginLeft: "10px" }}
                onClick={getSearchData}
              >
                검색
              </button>
            </li>
          </ul>
        </div>

        <h3 className="board_tit">
          예약 현황 목록
          <Link to="/Portal/lab/reservationWrite">
            <button type="button" className="btn_request">
              신청하기
            </button>
          </Link>
          {
            (saidaUseStat === 1) &&
            <button type="button" className="btn_request" onClick={handleSaidaClick} style={{ marginRight: "10px" }}>
              SAi-Da
            </button>
          }
        </h3>
        <div className="vm_use_list">
          <table className="tb_style_03">
            <thead style={{ borderBottom: "1px solid #0a2348" }}>
              <tr>
                {/* <th className="tb_num">NO</th> */}
                <th className="tb_req"> 신청일자</th>
                <th className="tb_name" colSpan={2}>
                  {" "}
                  실습실 용도
                </th>
                <th className="tb_req">
                  <div className="sort_wrap">
                    <p>실습실</p>시작일
                    <div className="sort_btns">
                      <button
                        type="button"
                        className="btn_sort asc js_btn_sort"
                        onClick={handleAscSort}
                      >
                        Asc
                      </button>
                      <button
                        type="button"
                        className="btn_sort desc js_btn_sort on"
                        onClick={handleDescSort}
                      >
                        Desc
                      </button>
                    </div>
                  </div>
                </th>
                <th className="tb_req">
                  <p>실습실</p>종료일
                </th>
                <th className="tb_req">남은 기간</th>
                <th className="tb_status">
                  예약 상태
                </th>
                <th className="tb_status">
                  실습실 상태
                </th>
                <th>예약 신청서</th>
                <th>터미널</th>
                <th>가상 실습실</th>
                {/* <th className="tb_rsv">예약 취소</th> */}
                <th className="tb_rsv">취소 및 종료</th>
              </tr>
            </thead>
            <tr
              style={{
                borderBottom: "1px solid #0a2348",
                margin: "0px",
                padding: "0px",
                zIndex: 1000,
              }}
            />
            <ReservationListTable
              rowData={rowData}
              total={total}
              msg={msg}
              updateLabList={updateLabList}
            />
          </table>
          <div className="pagenation_wrap" style={{ marginTop: "0" }}>
            <div className="pagenation_inner">
                  <LabPagination
                    totalCount={total}
                    itemPerPage={5}
                    onChangePage={onChangeServicePage}
                  />
                </div>

            {/*showPaging && (
              <div className="pagenation_inner">
                <LabPagination
                  totalCount={total}
                  itemPerPage={5}
                  onChangePage={onChangeServicePage}
                />
              </div>
            )*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReservationList;
