import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const loginToken = localStorage.getItem('access-token');

export function useMaintainLogin() {
    //const [autoLogin, setAutoLogin] = useState<boolean>(verifyYn);
    const { userId } = useSelector((state: RootState) => state.loginUser);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (loginToken && (userId === '')) {
            dispatch({ type: 'loginUser/autoLogin' });
            //setAutoLogin(true);
        }
        else {
            //history.push("/Portal/main");
            //setAutoLogin(false);
        }
    }, []);
}