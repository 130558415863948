import React from 'react';
import Layout from "layout";
import { Title } from 'pages';
import TemplateList from './TemplateList';
import TemplateDetail from './TemplateDetail';

const Template = () => {
    const currentPage = Title.Template;
    
    return (
        <Layout currentPage={currentPage}>
            <TemplateList />
            <TemplateDetail />
        </Layout>
    );
}
export default Template;
