import React, { useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";

import newIcon from "images/portal/board/ico_new.png";

import NotificationsIcon from '@material-ui/icons/Notifications';

const importantStyle = {
  fontWeight: 700,
};

const importantTrStyle = {
  backgroundColor: '#F7F7F7',
};

const importantFontStyle = {fontSize:"11px", backgroundColor:"#F37121", color:"white"}

type NoticeListTableProps = {
  total: number;
  rowData: any;
  msg: string;
  listNumber: number;
};

function NoticeListTable({
  total,
  rowData,
  msg,
  listNumber,
}: NoticeListTableProps) {
  if (total === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={10}>{msg}</td>
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody>
        {rowData.map((notice: any, idx: number) => {
          // 등록된지 며칠 지났는지 계산
          const regDate = new Date(notice.reg_date);
          const today = new Date();

          const timeBetween = today.getTime() - regDate.getTime();
          const dayBetween = timeBetween / (1000 * 60 * 60 * 24);

          return (
            listNumber > 0 && (
              <>
                <tr key={idx}  style={notice.stat_cd === 0 ? importantTrStyle : {}}>
                  {/* <td className="td_num">{listNumber--}</td> */}
                  <td className="td_num">
                    {
                      (notice.stat_cd === 0) ?<span style={importantFontStyle}>ㅤ공지ㅤ</span> : listNumber--
                    }
              
                  </td>
                  <td
                    className="td_subject"
                    style={notice.stat_cd === 0 ? importantStyle : {}}
                  >
                    <Link
                      to={`/Portal/community/notice_view?no=${notice.cseq}`}
                      style={{ display: "inline" }}
                    >
                      {notice.title}
                    </Link>
                    {dayBetween < 10 || dayBetween === 10 ? (
                      <span>
                        <img src={newIcon} alt="new notice" />
                      </span>
                    ) : (
                      <span />
                    )}
                  </td>
                  <td className="td_date" style={{ width: "130px" }}>
                    {notice.name}
                  </td>
                  <td className="td_date" style={{ width: "130px" }}>
                    {notice.reg_date}
                  </td>
                  <td className="td_date" style={{ width: "130px" }}>
                    {notice.views}
                  </td>
                </tr>
              </>
            )
          );
        })}
      </tbody>
    );
  }
}

export default NoticeListTable;
