import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { useInputs } from 'hooks/useInputs';

const FilterStyle = {
    boxShadow: "0 1px 4px 1px rgb(186 191 199 / 40%)",
    borderRadius: "3px",
    background: "#ffffff",
    minWidth: "145px",
    boxSizing: "border-box",
};

const InputStyle = {
    border: "none",
    width: "100%",
    height: "100%",
    padding: "6px 6px"
};


export default forwardRef((props, ref) => {
    const [active, setActive] = useState(false);
    const { inputs, setInputs, onChangeInput } = useInputs({searchText: ""});

    useEffect(() => {
        props.filterChangedCallback(inputs.searchText);
    }, [inputs]);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params) {
                return true;
            },

            isFilterActive() {
                return active && inputs.searchText !== "";
                //return inputs.searchText !== "";
            },

            // this example isn't using getModel() and setModel(),
            // so safe to just leave these empty. don't do this in your code!!!
            getModel() {
            },

            setModel(model) {
            },
        }
    });

    const onChangeSearchText = (event) => {
        onChangeInput(event);
        setActive(true);
    };

    const onBlurInput = (event) => {
        //setInputs((prev) => ({...prev, searchText: ""}));
        setActive(false);
    };

    return (
        <div style={FilterStyle}>
            <input name="searchText" type="text" onChange={onChangeSearchText} onBlur={onBlurInput} value={inputs.searchText} style={InputStyle} placeholder="Fliter..." />
        </div>
    )
});