import React from 'react';
import { Link } from 'react-router-dom';

import Location from '../../components/pageLocation/location';

function EmailPassword() {
    const informText = "실습자 회원가입시 등록한 이메일 주소로 비밀번호 재설정 링크를 발송해드렸습니다.\n메일 확인 후 비밀번호를 재설정 하시길 바랍니다.";
    
    return (
        <React.Fragment>
            <section id="contetns">
                <div className="layout_inner">
                    <div className="join_wrap">
                        <div className="join_tit">
                            실습자 비밀번호 찾기
                        </div>
                        <div className="join_box_wrap">
                            <div className="check_finish">
                                <h3>등록한 이메일로 메일이 <span>발송</span>되었습니다.</h3>
                                <p>
                                        {
                                            informText.split('\n').map(line => {
                                                return (<span>{line}<br/></span>)
                                            })
                                        }
                                </p>
                                <Link to="/Portal/main">
                                    <button className="btn_move">
                                        메인 페이지로 이동
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default EmailPassword;