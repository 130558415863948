import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ match }) {

    function handleChange(value) {
        const getUrl = value
        window.location.href=getUrl
    }


    return (
        <footer id="footer_wrap">
            <div className="layout_inner">
                <div className="box-links">
                    <ul>
                        <li><Link to={`/Portal/privacy`}><strong>개인정보처리방침</strong></Link></li>
                        <li><a href="#" target="_blank">이메일 무단 수집 거부</a></li>
                        <li><a href="#" target="_blank">유관기관배너목록</a></li>
                    </ul>
                </div>

                <div className="box-links">
                    <ul>
                        <li>경남 창원시 의창구 창원대로 18번길 22(팔용동)</li>
                        <li>TEL : 1688-3360</li>
                        <li>FAX : 055. 262. 9669</li>
                        <li><a href="mailto:myplatform@gntp.or.kr" target="_blank">E MAIL : myplatform@gntp.or.kr</a></li>
                    </ul>
                </div>

                <div className="copyright">
                    Copyright ⓒ 2014 재단법인 경남테크노파크 all rights reserved.
                </div>

                <div className="select_box_wrap">
                    <div className="select_wrap footerSelWrap">
                        <select name="" id="" className="footerSelOption" style={{padding:"0px"}} onChange={event => handleChange(event.target.value)}>
                            <option value="">전국 TP 사이트</option>
                            <option value="http://www.technopark.kr">전국테크노파크</option>
                            <option value="https://seoultp.or.kr/home/homeIndex.do">서울테크노파크</option>
                            <option value="http://gwtp.or.kr/">강원테크노파크</option>
                            <option value="https://www.gtp.or.kr/antp/index/default.jsp">경기테크노파크</option>
                            <option value="http://gdtp.or.kr/">경기대진테크노파크</option>
                            <option value="https://www.gntp.or.kr/">경남테크노파크</option>
                            <option value="http://gbtp.or.kr/">경북테크노파크</option>
                            <option value="https://www.gjtp.or.kr/home/main.cs">광주테크노파크</option>
                            <option value="https://ttp.org/">대구테크노파크</option>
                            <option value="https://www.djtp.or.kr/">대전테크노파크</option>
                            <option value="https://www.btp.or.kr/">부산테크노파크</option>
                            <option value="https://utp.or.kr/">울산테크노파크</option>
                            <option value="https://www.itp.or.kr/">인천테크노파크</option>
                            <option value="http://www.jntp.or.kr/home/main.do">전남테크노파크:</option>
                            <option value="https://www.jbtp.or.kr/">전북테크노파크</option>
                            <option value="http://jejutp.or.kr/">제주테크노파크</option>
                            <option value="https://ctp.or.kr/">충남테크노파크</option>
                            <option value="http://www.cbtp.or.kr/">충북테크노파크</option>
                            <option value="https://pohangtp.org/main/index.do">포항테크노파크</option>
                            <option value="https://sjtp.or.kr/">세종테크노파크</option>
                        </select>
                    </div>

                    <div className="select_wrap footerSelWrap">
                        <select name="" id="" className="footerSelOption"  onChange={event => handleChange(event.target.value)}>
                            <option value="">패밀리 사이트</option>
                            <option value="http://gn-platform.or.kr/main.do">울산-경남 지역혁신플랫폼 총괄운영센터</option>
                            <option value="https://www.changwon.ac.kr/platform/main.do">스마트제조엔지니어링 사이트</option>
                            <option value="https://platform.kyungnam.ac.kr/kr/">스마트제조ICT사업단</option>
                            <option value="http://www.usg.ac.kr/">USG 공유대학 사이트</option>
                            <option value="https://bigdata.gyeongnam.go.kr/index.gn">경상남도 빅데이터 허브포털</option>
                        </select>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;