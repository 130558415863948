import React from 'react';
import { Link } from 'react-router-dom';

import AllMenu from './allMenu';

function DropDownNav({ showMenu, onClickNavMenu, onShowMenu, onHideMenu, showMypage }) {
    return (
        <nav className="header_gnb_wrap" onMouseEnter={onShowMenu} onMouseLeave={onHideMenu} >
            <div className="header_gnb">
                <div className="layout_inner">
                    <ul className="header_gnb_list">
                        <li>
                            <Link to="/Portal/about"><span>센터 소개</span></Link>
                        </li>
                        <li>
                            <Link to="/Portal/lab"><span>가상 실습실</span></Link>
                        </li>
                        <li>
                            <Link to="/Portal/equipment"><span>연구 &middot; 실습 장비 공유</span></Link>
                        </li>
                        {/* <li>
                            <Link to="/Portal/contest"><span>공모전 &middot; 경진대회</span></Link>
                        </li> */}
                        <li>
                            <Link to="/Portal/community"><span>커뮤니티</span></Link>
                        </li>
                        {
                            showMypage && 
                            <li onClick={onClickNavMenu}>
                                <Link to="/Portal/mypage"><span>마이페이지</span></Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
            <AllMenu onClickNavMenu={onClickNavMenu} showMenu={showMenu} showMypage={showMypage} />
        </nav>
    );
}

export default DropDownNav;