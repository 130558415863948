import React, { useState } from 'react';

export function useInputs<T>(initialValue: T) {
    const [inputs, setInputs] = useState<T>(initialValue);

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.name;
        const type = event.target.type;

        if ((type === 'text') || (type === 'number') || (type === 'select')) {
            const value = event.target.value;
            setInputs((prev: T) => ({
                ...prev,
                [key]: value,
            }));
        }
        else if (type === 'checkbox') {
            const value = event.target.checked;
            setInputs((prev: T) => ({
                ...prev,
                [key]: value,
            }));
        }
        else if (type === 'file') {
            const value = event.target.files;
            setInputs((prev: T) => ({
                ...prev,
                [key]: value,
            }))
        }
    };

    const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const key = event.target.name;
        const value = event.target.value;

        setInputs((prev: T) => ({
            ...prev,
            [key]: value,
        }));
    };

    const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const key = event.target.name;
        const value = event.target.value;

        setInputs((prev: T) => ({
            ...prev,
            [key]: value,
        }));
    };

    return {
        inputs,
        setInputs,
        onChangeInput,
        onChangeSelect,
        onChangeTextarea,
    }
}