import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
);

type CustomPaginationProps = {
    totalCount: number;
    itemPerPage: number;
    onChangePage: (page: number) => void;
};

function CustomPagination({
    totalCount,
    itemPerPage,
    onChangePage
}: CustomPaginationProps) {
    const classes = useStyles();
    const [page, setPage] = useState<number>(1);

    let count = Math.floor(totalCount / itemPerPage);
    if ((totalCount % itemPerPage) > 0) {
        count += 1;
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(prev => value);
        onChangePage(value);
    };

    return (
        <div className={classes.root}>
            <Pagination count={count} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
        </div>
    );
}

export default CustomPagination;