import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { authApproveData } from 'api/mail';

export function useApproveAuth(data: any) {
    const { authApprovalResponse } = useSelector((state: RootState) => (state.mail));
    const dispatch = useDispatch();

    useEffect(() => {
        callApproveAuth();
    }, [dispatch]);

    const callApproveAuth = () => {
        dispatch({
            type: 'mail/authApproval',
            payload: data
        });
    };

    return {
        authApprovalResponse
    };
}