import React from 'react';
import reset from 'styled-reset';
//import theme from 'styles/theme';
import { createGlobalStyle } from "styled-components";
import NotoR2 from 'fonts/NotoSansKR/NotoSans-Regular.woff2'
import NotoR from 'fonts/NotoSansKR/NotoSans-Regular.woff'
import NotoROtf from 'fonts/NotoSansKR/NotoSans-Regular.otf'

import NotoL2 from 'fonts/NotoSansKR/NotoSans-Light.woff2'
import NotoL from 'fonts/NotoSansKR/NotoSans-Light.woff'
import NotoLOtf from 'fonts/NotoSansKR/NotoSans-Light.otf'

import NotoM2 from 'fonts/NotoSansKR/NotoSans-Medium.woff2'
import NotoM from 'fonts/NotoSansKR/NotoSans-Medium.woff'
import NotoMOtf from 'fonts/NotoSansKR/NotoSans-Medium.otf'

import NotoB2 from 'fonts/NotoSansKR/NotoSans-Bold.woff2'
import NotoB from 'fonts/NotoSansKR/NotoSans-Bold.woff'
import NotoBOtf from 'fonts/NotoSansKR/NotoSans-Bold.otf'

import selectArr from 'images/bullet/select_arr.png';
import toastClose from 'images/bullet/toast_close.png';
import state01_ing from 'images/state/ico_state01_ing.png';
import state02_ing from 'images/state/ico_state02_ing.png';
import state03_ing from 'images/state/ico_state03_ing.png';
import state05_ing from 'images/state/ico_state05_ing.png';
import state06_ing from 'images/state/ico_state06_ing.png';

const globalStyles = createGlobalStyle`
  ${reset};

  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url(${NotoR2}) format('woff'),
    url(${NotoR}) format('woff'),
    url(${NotoROtf}) format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    src: url(${NotoL2}) format('woff'),
    url(${NotoL}) format('woff'),
    url(${NotoLOtf}) format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    src: url(${NotoM2}) format('woff'),
    url(${NotoM}) format('woff'),
    url(${NotoMOtf}) format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    src: url(${NotoB2}) format('woff'),
    url(${NotoB}) format('woff'),
    url(${NotoBOtf}) format('opentype');
  }
  
  body, input, button, select, textarea, td {
    font-family: "Noto Sans", sans-serif;
    color: #626b7a;
    font-size: 12px;
    font-weight: 400
  }  
  *, *::before, *::after {
    box-sizing: border-box
  }
  button {
    cursor: pointer;
    outline: transparent;
    padding: 0;
  }
  input::-webkit-input-placeholder {opacity:0.4}
  input::-moz-placeholder {opacity:0.4}
  input:-ms-input-placeholder {opacity:0.4}  
  
  html, body {
    height: 100%
  }
  strong {
    font-weight: 500
  }

  select {
    height: 36px;
    border: 1px solid #0f213e;
    color: #2f394a;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 30px; 
    padding-left: 10px;
    background: #fff url(${selectArr}) no-repeat right center;
  }
  select::-ms-expand {
    display: none;
  }
  textarea {
    resize: vertical;
  }
  a {
    //color: #0082ff
    color: ${({ theme }) => theme.colors.primary};
  }
  ul, li {
    list-style: none;
  }
  
  .wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    min-width: 1680px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 12px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  
  .headerOption {
    position: absolute;
    top: 0;
    right: 10px;
    height: 64px;
    display: flex;
    align-items: center;
    color: #677183;
    font-size: 13px;
    .vmInfo {
      span {
        position: relative;
        display: inline-flex;
        line-height: 22px;
        padding-left: 24px;
        margin-left: 15px;
        &:first-child ~ span {
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            height: 10px;
            border-left: 1px solid #c5cad0;
            transform: translateY(-50%);
          }
        }
      }
      strong {
        display: inline-flex;
        padding: 0 10px;
        margin-top: -1px;
        font-size: 22px;
        font-weight: 700;
        &.c1 { color: ${({ theme }) =>theme.colors.primaryDark} }
        &.c2 { color: #000 }
        &.c3 { color: #ff5d4d }
      }
    }
    select {
      min-width: 170px
    }
  }
  .btn_comm {
    min-width: 24px;
    min-height: 24px;
    border: 1px solid #bec3ca;
    border-radius: 3px;
    &:not(select) {
      background: linear-gradient(#fdfdfd,#f6f6f9);
      box-shadow: inset 0 0 1px #fff;
    }
  }
  .btnLabel_icon {
    ${({ theme }) => theme.mixins.ir_btn};
    background-position: center center;
    background-repeat: no-repeat;
    &.hover {
      background-position-y: top;
      &:hover,&:focus {
        background-position-y: -20px
      }
    }
  }
  
  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      background-color: #0a2348 !important;
      font-family: inherit !important;
      font-size: 10px;
      font-weight: 300;
      padding: 4px 12px;
      margin: 6px 0
    }
    .MuiTooltip-arrow {
      color: #0a2348 !important;
    }
  }

  .state_ico {
    position: relative;
    padding-left: 17px;
    color: #007aff;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 1px;
      width: 10px;
      height: 10px;
      transform: translateY(-50%);
      border-radius: 50%;
      border: 3px solid #007aff;
    }
    &.ing {
      background: no-repeat left center;
      &::before { display: none }
    }
  }

  .state_01 {
    color: #007aff;
    &.state_ico::before {
      border-color: #007aff;
    }
    &.stateBar {
      background-color: #5ba9ff
    }
    &.ing {
      background-image: url(${state01_ing});
    }
  }
  .state_02 {
    color: #14bd19;
    &.state_ico::before {
      border-color: #14bd19;
    }
    &.ing {
      background-image: url(${state02_ing});
    }
    &.stateBar {
      background-color: #14bd19
    }
  }
  .state_03 {
    color: #cc00ff;
    &.state_ico::before {
      border-color: #cc00ff;
    }
    &.ing {
      background-image: url(${state03_ing});
    }
    &.stateBar {
      background-color: #cc00ff
    }
  }
  .state_04 {
    color: #f72828;
    &.state_ico::before {
      border-color: #f72828;
    }
    &.stateBar {
      background-color: #f16a6a
    }
  }
  .state_05 {
    color: #ff7e00;
    &.state_ico::before {
      border-color: #ff7e00;
    }
    &.ing {
      background-image: url(${state05_ing});
    }
    &.stateBar {
      background-color: #ff7e00
    }
  }
  .state_06 {
    color: #7e8289;
    &.state_ico::before {
      border-color: #7e8289;
    }
    &.ing {
      background-image: url(${state06_ing});
    }
    &.stateBar {
      background-color: #7e8289
    }
  }
    
  .stateBar {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 14px;
    border-radius: 5px;
    background-color: #5ba9ff;
    & + * {
      margin-top: 9px;
    }
    .state_ico {
      color: #fff;
      &::before {
        border-color: #fff
      }
    }
  }
  
  /* table */
  .panel_detail {
    .tb_data {
      border: 1px double #c5cad0;
    }
  }
  .tb_data {
    width: 100%;
    border-top: 1px double ${({ theme }) => theme.colors.defaultDark};
    border-bottom: 1px double #c5cad0;
    border-left: 0 hidden;
    border-right: 0 hidden;
    border-collapse: collapse;
    tbody {
      th, td {
        height: 33px;
        padding: 8px 10px 8px 15px;
        vertical-align: middle;
        line-height: 1.3em;
      }
      th {
        width: 15%;
        border: 1px solid #eff1f3;
        background: #fafbfc;
        text-align: left;
        color: ${({ theme }) => theme.colors.defaultDark}
      }
      td {
        border: 1px solid #f6f7f9;
        line-height: 1.4em;
      }
    }
    textarea {
      width: 100%;
      border: 1px solid #c5cad0;
      border-radius: 2px;
      padding-left: 10px;
      &:hover {
        border-color: #000
      }
    }
  }
  .tb_write {
    tbody td {
      padding: 2px
    }
  }
  .added {
    color: #949ca9 !important;
  }
  table td {
    .td-txt {
      display: inline-block;
      padding: 6px 12px;
    }
    .link_file {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      padding-left: 25px;
      background: url(../images/bullet/ico_file.png) no-repeat left center;
      color: #626b7a;
      border: 0;
      &:hover {
        color: ${({ theme }) => theme.colors.primaryDark};
      }
    }
    .td-board {
      min-height: 300px;
      padding: 6px 12px;
      img { max-width: 100% }
    }
  }

  .Toastify {
    .Toastify__toast {
      padding: 0 50px 5px 10px;
      border-radius: 3px;
      box-shadow: none;
      font-family: inherit;
      color: #fff;
      font-size: 13px;
      font-weight: 300;
      word-break: break-all;
      background: #ff7800;
      min-height: 41px
    }
    .Toastify__toast--info {
      background: #ff7800;
    }
    .Toastify__toast--error {
      background: #E53A40;
    }
    .Toastify__toast--warning {
      background: #EFDC05;
    }
    .Toastify__toast--success {
      background: #56A902;
    }
    .Toastify__close-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      width: 30px;
      height: 30px;
      color: #fff;
      opacity: 1;
      background: url(${toastClose}) no-repeat center center;
      svg { display: none }
    }
    .Toastify__progress-bar {
      height: 3px;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .swal2-container {
    z-index: 99999 !important;
    .swal2-popup {
      padding: 0;
      border: 2px solid #0090ff;
      border-radius: 5px;
      background: #fff
    }
    .swal2-html-container {
      padding: 35px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.defaultDark};
    }
    .swal2-actions {
      margin: 0;
      padding: 10px;
      background-color: #f5f6f9;
      border-top: 1px solid #ebecef;
      .swal2-styled {
        min-width: 92px;
        height: 32px;
        border-radius: 3px;
        padding: 0;
        margin: 0 1px;
        border: 1px solid ${({ theme }) => theme.colors.defaultDark};
        color: ${({ theme }) => theme.colors.defaultDark};
        background-color: #fff !important;
        font-size: 12px;
        transition: 0.2s;
        &:hover {
          border-color: ${({ theme }) => theme.colors.primaryDark};
          color: ${({ theme }) => theme.colors.primaryDark};
        }
      }
    }
  }
  .form_fullWidth { width: 100%; }
  .MuiFormControl-root {
    select {
      border: 1px solid #c5cad0;
      border-radius: 2px;
      height: 32px;
      background-image: url(../images/bullet/select_arr_g.png);
      color: #626b7a;
      &:hover {
        border-color: #000
      }
    }
  }
    
    // .MuiSelect-selectMenu {
    //   min-height: 32px
    // }
    // .MuiOutlinedInput-input {
    //   padding: 0
    // }
    // .MuiOutlinedInput-notchedOutline {
    //   border: 1px solid #c5cad0;
    //   border-radius: 2px;
    // }
    // .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    //   border-width: 1px;
    //   border-color: ${({ theme }) => theme.colors.defaultDark};
    //   background-color: #fff      
    // }
    // .MuiInputBase-root {
    //   font: inherit;
    //  
    // }
    // .MuiInputBase-input {
    //   display: flex;
    //   align-items: center;
    //   color: #626b7a !important;
    //   padding-left: 12px
    // }
  
  .inputWrap_dividers {
    display: flex;
    justify-content: space-between;
    .mdd {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      color: #a3a9af
    }
    .inputArea {
      display: flex;
      flex-grow: 1;
      width: calc(50% - 10px);
      margin: 0 -1px;
      > * {
        flex-grow: 1;
        margin: 0 1px;
      }
    }
  }
  .inputWrap_btns {
    display: flex;
    justify-content: space-between;
    .input_text {
      height: 32px;
      border: 1px solid #c5cad0;
      border-radius: 2px;
      flex-grow: 1;
      padding-left: 11px;
    }
    button {
      height: 32px;
      margin-left: 2px;
      font-size: 12px
    }
  }
`;

export default globalStyles;
