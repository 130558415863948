import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Location from '../../components/pageLocation/location';

function Privacy() {
    const [open, setOpen] = useState(false);

    const onClickRead = () => {
        setOpen((prev) => (!prev));
    };

    return (
        <React.Fragment>
            <Location location="개인정보 처리방침" />
            <section id="contetns">
                <div className="layout_inner">
                    <div className="login_wrap">
                        <div className="login_tit">
                            개인정보 처리방침
                        </div>
                        <div className="privacy_wrap">
                            <strong>경남대학교 개인정보 처리방침 (개정13차, 2020.12.07.)</strong>
                            <div>경남대학교 개인정보 처리방침 (개정13차, 2020.12.07.)</div>
                            <div>경남대학교는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</div>
                            <strong>제1조(개인정보의 처리 목적)</strong>
                            <div>1. 경남대학교는 다음의 목적을 위해 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용하지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행합니다.</div>
                            <span className="clause_indent">01 입시 및 학사업무 처리</span>
                            <span className="clause_indent">&nbsp;&nbsp;입시전형 업무, 신입생 선발, 학사관리 및 학교행정 업무, 국제교류생관리, 수강생 학사관리(평생교육원, 과학영재교육원, 스포츠지도사연수원) 등</span>
                            <span className="clause_indent">02 서비스 제공 및 관리</span>
                            <span className="clause_indent">&nbsp;&nbsp;공지사항, 증명서 발급, 입시상담, 도서이용자 관리, 취업정보, 취업지원 관리, 생활관생 관리, 학자금 대출 등</span>
                            <span className="clause_indent">03 발전기금 관리</span>
                            <span className="clause_indent">&nbsp;&nbsp;발전기금 기탁자 관리 및 기부금 영수증 발급 등</span>
                            <span className="clause_indent">04 민원사무 처리</span>
                            <span className="clause_indent">&nbsp;&nbsp;개인정보 열람, 개인정보 정정·삭제, 개인정보 처리정지 요구, 개인정보 유출사고 신고, 개인정보 침해 사실 신고 접수·처리 등</span>

                            <div>2. 경남대학교는 개인정보 보호법 제32조에 따라 등록·공개하는 개인정보파일은 행정자치부 개인정보보호 종합포털(www.privacy.go.kr) → 민원마당 → 개인정보 열람 등 요구 → 개인정보파일 목록검색 → 기관명에 ‘경남대학교’ 입력 후 조회하실 수 있습니다.</div>
                            <strong>제2조(개인정보의 처리 및 보유기간)</strong>
                            <div>경남대학교는 법령에 따른 개인정보 보유.이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유.이용기간 내에서 개인정보를 처리.보유합니다.</div>
                            <strong>제3조(개인정보의 제3자 제공)</strong>
                            <div>경남대학교는 수집.보유하고 있는 개인정보는 이용자의 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않으며 다음 각 호의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.</div>
                            <span className="clause_indent">01 정보주체로부터 별도의 동의를 받은 경우</span>
                            <span className="clause_indent">02 다른 법률에 특별한 규정이 있는 경우</span>
                            <span className="clause_indent">03 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</span>
                            <span className="clause_indent">04 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우</span>
                            <span className="clause_indent">05 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 개인정보위원회의 심의의결을 거친 경우</span>
                            <span className="clause_indent">06 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우</span>
                            <span className="clause_indent">07 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</span>
                            <span className="clause_indent">08 법원의 재판업무 수행을 위하여 필요한 경우</span>
                            <span className="clause_indent">09 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</span>
                            <div>우리대학교는 다음과 같은 기관에게 개인정보를 제공하고 있으며 제공목적 및 범위는 다음과 같다.</div>
                            <table className="tb_style_01">
                                <tr>
                                    <th>제3자 제공한 기관명</th>
                                    <th>제3자 제공 파일명</th>
                                    <th>제공파일의 항목</th>
                                    <th>법적근거</th>
                                    <th>관련부서</th>
                                </tr>
                                <tr>
                                    <td>부산울산경남권역이러닝센터(GeLC)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>서울디지털대학교(SDU)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>한국대학가상교육연합(KCU)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>한국지역대학연합(RUCK)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>육군본부</td>
                                    <td>입시관리(군사학과)</td>
                                    <td>주민등록번호,이름,수험번호,성별,출신고교,졸업년도,고교성적</td>
                                    <td>육군본부와의 군사학 발전 협력 합의서</td>
                                    <td>입학관리팀</td>
                                </tr>
                                <tr>
                                    <td>한국대학교육협의회</td>
                                    <td>입시관리</td>
                                    <td>모집시기구분,이름,주민등록번호,수험번호,제출대학코드(표준분류 기준),접수구분,합격/예치금납부 구분,접수일시</td>
                                    <td>고등교육법 시행령 제42조(입학지원방법등) 제42조의2(입학지원방법 위반의 처리)</td>
                                    <td>입학관리팀</td>
                                </tr>
                                <tr>
                                    <td>각종교외장학재단</td>
                                    <td>교외장학대상자명단</td>
                                    <td>성명,학과,학번,학년,주민등록번호,성적,학적상태,장학금액</td>
                                    <td>개인정보법17조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>보훈청</td>
                                    <td>국가보훈자녀 수업료 국고보조금청구대상자</td>
                                    <td>보훈번호,주민등록번호,성명,학과,학번,학년,성적,신청학점,입학금,수업료,등록금액</td>
                                    <td>[보훈법령] 국가유공자예우 및 지원에 관한 법률 제42조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>한국TOEIC위원회</td>
                                    <td>토익,토익스피킹,JPT 성적조회대상자 명단</td>
                                    <td>성명,생년월일,수험번호,응시일,성적</td>
                                    <td>개인정보법17조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>한국산업인력공단</td>
                                    <td>정보처리기사,사무자동화산업기사등 자격증취득자명단</td>
                                    <td>성명,생년월일,수험번호,응시일,성적</td>
                                    <td>개인정보법17조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>한국장학재단</td>
                                    <td>학사정보원장,수납정보원장</td>
                                    <td>주민등록번호,성명,학과,학번,학년,성적,신청학점,입학금,수업료,등록금액,장학금액,대학수납가상계좌,학적상태</td>
                                    <td>한국장학재단설립등에 관한법률제50조의2(자료제출의 요청)</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>경남은행</td>
                                    <td>수입/지출 관련 명단 (급여포함)</td>
                                    <td>성명,은행명,계좌번호,예금주,지급금액</td>
                                    <td>개인정보보호법 제17조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>퇴직소득명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,입사일,퇴사일,근속월수,근속연수,퇴직급여액,퇴직소득공제,산출세액</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td></td>
                                    <td>사업자등록번호,주민등록번호,기부일자,기부금액</td>
                                    <td>상속증여세법제50조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>출연받은 재산의 공익목적 사용현황(출연재산사용명세)</td>
                                    <td>사업자등록번호,주민등록번호,기부일자,사용일자,사용금액</td>
                                    <td>상속증여세법제50조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 간소화 파일(대학 교육비)</td>
                                    <td>사업자등록번호,성명,주민등록번호,납입금액,교육비구분,공제대상 교육비 부담액</td>
                                    <td>소득세법제165조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 간소화 파일(기부금)</td>
                                    <td>사업자등록번호,성명,주민등록번호,기부일자,기부금액</td>
                                    <td>소득세법제165조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 근로소득 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,주소,근무처,근무기간,급여,결정세액,기납부세액,차감징수세액,공제대상자 주민등록번호,그외 공제내역 등</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 기부금 명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,근무지,주소,전화번호,기부내용,기부처,기부일자,기부금액,공제금액,소멸금액,이월금액</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 의료비 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,근무지,주소,전화번호,기부내용,기부처,기부일자,기부금액,공제금액,소멸금액,이월금액</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>일용근로소득 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,지급월,근무월,근무일수,총지급액(과세소득),비과세소득,소득세,지방소득세</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>거주자 기타소득 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,지급연월일,귀속연월일,지급총액,필요경비,소득금액,세율,소득세,지방소득세</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>부가가치세신고납부자료</td>
                                    <td>사업자등록번호,성명,주민등록번호,의료비공제대상자 주민등록번호,건수,금액</td>
                                    <td>부가가치세법제48조,제49조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>골드스타</td>
                                    <td>경남조선과 3개교 체육대회 참가 교직원 및 학생 명단</td>
                                    <td>성명,주민등록번호,연락처</td>
                                    <td>개인정보보호법</td>
                                    <td>조선해양시스템공학과</td>
                                </tr>
                                <tr>
                                    <td>국민건강보험공단</td>
                                    <td>건강보험 직장가입자 자격취득 및 상실</td>
                                    <td>성명,주민등록번호,보수월액,취득일,상실일,보수월액,근무월수</td>
                                    <td>국민건강보험법 시행령 제81조</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>국민연금공단</td>
                                    <td>국밈연금 취득 및 상실</td>
                                    <td>성명,주민등록번호,취득일,소득월액,상실일,상실부호</td>
                                    <td>국민연금법 시령 113조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>근로복지공단</td>
                                    <td>고용산재보험 취득 및 상실</td>
                                    <td>성명,주민등록번호,취득일,월평균보수월액,근로시간,계약종료일</td>
                                    <td>고용보험 시행령 제145조의 2,산업재해보상보험 시행령 제127조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>동부화재</td>
                                    <td>경남대학교 교직원단체보험 가입현황</td>
                                    <td>성명,주민등록번호,남여구분</td>
                                    <td>신용정보의 이용 및 보호에 관한 법률 시행령 제37조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>사립학교교직원연금공단</td>
                                    <td>교직원 임용신고서 봉급지급 내역서,신분변동신고</td>
                                    <td>성명,주민번호,성별,직급,직위,임용일자,근무월수,소득총액</td>
                                    <td>사학연금법 시행령 제89조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>한국교육개발원</td>
                                    <td>학생명단</td>
                                    <td>학생기본정보,입학정보,재적정보,학적변동정보,졸업정보,외국인 학생정보</td>
                                    <td>'교육통계조사에 관한 훈령‘ (교육부 훈령 제50호)/’통계청 지정 통계 승인번호 제33401호‘</td>
                                    <td>평가감사팀</td>
                                </tr>
                                <tr>
                                    <td>한국교육개발원</td>
                                    <td>교원명단</td>
                                    <td>교원기본정보,학위정보,임용정보,경력정보,본직정보,동태정보,강의정보</td>
                                    <td>'교육통계조사에 관한 훈령‘ (교육부 훈령 제50호)/’통계청 지정 통계 승인번호 제33401호‘</td>
                                    <td>평가감사팀</td>
                                </tr>
                                <tr>
                                    <td>한국교육개발원</td>
                                    <td>직원·조교 명단</td>
                                    <td>기본정보 및 채용정보,연수정보</td>
                                    <td>'교육통계조사에 관한 훈령‘ (교육부 훈령 제50호)/’통계청 지정 통계 승인번호 제33401호‘</td>
                                    <td>평가감사팀</td>
                                </tr>
                                <tr>
                                    <td>각 지방병무청</td>
                                    <td>학적보유자 변동사항 통보</td>
                                    <td>학번,성명,주민등록번호,주소,학과,학년,학적변동 연월일,학적변동 사유</td>
                                    <td>병역법 시행규칙 제85조</td>
                                    <td>학사관리팀</td>
                                </tr>
                                <tr>
                                    <td>경남지방병무청</td>
                                    <td>학적보유자 명부</td>
                                    <td>성명,주민등록번호,학번,학년,입학년도,졸업년도,편입여부,재외국민여부,전자우편주소,휴대전화번호</td>
                                    <td>
                                        <span style={{display: "block"}}>•병역법 시행령 제125조(재학생 입영 등 연기)</span>
                                        <span style={{display: "block"}}>•병무청 훈령 제1132호『입영연기 관리규정』제5조(학적보유자 명부의 송부 및 관리)</span>
                                    </td>
                                    <td>학사관리팀</td>
                                </tr>
                                <tr>
                                    <td>경남지방병무청</td>
                                    <td>졸업자 명부</td>
                                    <td>주민등록번호,성명,학적보유사항,최종 학적보유 또는 변동일</td>
                                    <td>병역법 제80 조</td>
                                    <td>학사관리팀</td>
                                </tr>
                                <tr>
                                    <td>경남지방병무청</td>
                                    <td>학적상태 통보</td>
                                    <td>전공학과,학년,학적상태,변동일자,휴학사유</td>
                                    <td>
                                        <span style={{display: "block"}}>•병역법 시행령 제127조 학적변동자의 처리</span>
                                        <span style={{display: "block"}}>•입영연기 관리규정 제16조 각급학교 병무담당 교육</span>
                                    </td>
                                    <td>학사관리팀</td>
                                </tr>
                            </table>
                            <strong>제4조(개인정보처리의 위탁)</strong>
                            <div>경남대학교는 원칙적으로 이용자의 동의 없이 해당 개인 정보의 처리를 타인에게 위탁하지 않습니다. 단, 경남대학교가 제3자에게 개인정보의 처리 업무를 위탁하는 경우에는 「개인정보보호법」제26조(업무위탁에 따른 개인정보의 처리 제한)에 따라 위탁하며 다음 각 호의 내용이 포함된 문서에 의하여 위탁 업무의 내용과 수탁자를 홈페이지에 게시합니다.</div>
                            <div>01 위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항</div>
                            <div>02 개인정보의 기술적.관리적 보호조치에 관한 사항</div>
                            <div>03 그 밖에 개인정보의 안전한 관리를 위하여 다음과 같이 대통령령으로 정한 사항</div>
                            <span className="clause_indent">가. 위탁업무의 목적 및 범위</span>
                            <span className="clause_indent">나. 재위탁 제한에 관한 사항</span>
                            <span className="clause_indent">다. 개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항</span>
                            <span className="clause_indent">라. 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항</span>
                            <span className="clause_indent">마. 법 제26조제2항에 따른 수탁자(이하 "수탁자"라 한다)가 준수하여야 할 의무를 위반한 경우의 손해배상 등 책임에 관한 사항</span>
                            <div>우리 대학교는 다음과 같은 기관에게 개인정보를 위탁하고 있으며 위탁업무내용 및 수탁기관은 다음과 같습니다.</div>
                            <table className="tb_style_01">
                                <colgroup>
                                    <col style={{width: "55%"}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>계약서 명</th>
                                        <th>수탁업체</th>
                                        <th>관련부서</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>출납업무(일괄입금,급여입금 등)와 관련한 개인정보 수집 및 처리</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>재무회계팀</td>
                                    </tr>
                                    <tr>
                                        <td>대학 및 대학원(신입 편입생 포함)등록금 수납 관련 개인정보처리</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>재무회계팀</td>
                                    </tr>
                                    <tr>
                                        <td>등록금 및 제 납부금의 수납 업무와 관련한 개인정보 수집 및 처리</td>
                                        <td>NH농협은행 신마산지점</td>
                                        <td>재무회계팀</td>
                                    </tr>
                                    <tr>
                                        <td>시스템경비 및 시설 경비 위탁운영 관리</td>
                                        <td>㈜에스원</td>
                                        <td>총무인사팀</td>
                                    </tr>
                                    <tr>
                                        <td>주차장 및 주차시설의 위탁운영 관련</td>
                                        <td>㈜아마노코리아</td>
                                        <td>총무인사팀</td>
                                    </tr>
                                    <tr>
                                        <td>보안감시 및 도난 예방, 시설물관리 및 화재예방</td>
                                        <td>㈜에스티에스시스템</td>
                                        <td>총무인사팀</td>
                                    </tr>
                                    <tr>
                                        <td>생활관 관생 관리비 수납</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>생활관 관생 관리비 수납</td>
                                        <td>NH농협은행 신마산지점</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>단체결핵검사</td>
                                        <td>대한결핵협회울산경남지부회</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>식사신청 확인</td>
                                        <td>㈜동원홈푸드</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>항공권발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜경남투어렌트카</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>여행자보험 가입에 필요한 정보 수집</td>
                                        <td>㈜DB손해보험</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>여행자보험 가입에 필요한 정보 수집</td>
                                        <td>골드스타보험대리점</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜웰빙투어</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜투어이천</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜대상에듀</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜비에스하이투어</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>도서관 개인정보DB 유지관리업무</td>
                                        <td>㈜퓨쳐누리</td>
                                        <td>학술문헌팀</td>
                                    </tr>
                                    <tr>
                                        <td>월영소식 홈페이지 유지보수 (전산관리 위탁)</td>
                                        <td>엔디소프트㈜</td>
                                        <td>홍보실</td>
                                    </tr>
                                    <tr>
                                        <td>2020학년도 대학일자리센터 온라인 취업지원플랫폼 [성취감솔루션]</td>
                                        <td>주식회사잡담</td>
                                        <td>대학일자리센터</td>
                                    </tr>
                                    <tr>
                                        <td>종합정보시스템 재개편 용역</td>
                                        <td>주식회사 TIS</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>중앙전산기기실 서버시스템 유지관리 용역</td>
                                        <td>㈜우리아이티</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>정보시스템 통합유지보수</td>
                                        <td>㈜우리아이티</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>컴퓨터 및 프린터 외 유지보수</td>
                                        <td>㈜우리아이티</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>경남타임즈 홈페이지 유지보수</td>
                                        <td>엔디소프트㈜</td>
                                        <td>언론출판원 행정지원팀</td>
                                    </tr>
                                    <tr>
                                        <td>교육방송국 홈페이지 유지보수</td>
                                        <td>엔디소프트㈜</td>
                                        <td>언론출판원 행정지원팀</td>
                                    </tr>
                                    <tr>
                                        <td>IPP일반학습병행사업 온라인 관리시스템의 유지보수</td>
                                        <td>㈜이데링크</td>
                                        <td>KU-IPP사업단행정팀</td>
                                    </tr>
                                    <tr>
                                        <td>경남대학교 LINC+ 사업단 홈페이지 유지보수</td>
                                        <td>바롬넷</td>
                                        <td>UOK LINC+사업단</td>
                                    </tr>
                                    <tr>
                                        <td>경남대학교 LINC+ 4차 산업혁명 혁신선도대학사업 학생역량관리 프로그램 운영</td>
                                        <td>㈜이데링크</td>
                                        <td>UOK LINC+사업단</td>
                                    </tr>
                                    <tr>
                                        <td>경남대학교 산학협력단 연구비종합관리시스템 운영</td>
                                        <td>중소기업은행</td>
                                        <td>산학협력단</td>
                                    </tr>
                                    <tr>
                                        <td>학생경력개발시스템 홈페이지 유지보수</td>
                                        <td>㈜헬로잡</td>
                                        <td>취업지원팀</td>
                                    </tr>
                                    <tr>
                                        <td>2021학년도 대입 인터넷 원서접수 대행</td>
                                        <td>㈜진학어플라이</td>
                                        <td>입학관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>2021학년도 합격자통합관리시스템</td>
                                        <td>㈜진학어플라이</td>
                                        <td>입학관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>재학생의 국제학생증 발급</td>
                                        <td>한국국제학생교류회</td>
                                        <td>학사관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>증명발급시스템 운영</td>
                                        <td>㈜디지털존</td>
                                        <td>학사관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>신입생,편입생 학생증 발급</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>학사관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>LMS강좌운영 유지보수</td>
                                        <td>㈜아이맥스소프트</td>
                                        <td>교육혁신본부</td>
                                    </tr>
                                    <tr>
                                        <td>콘텐츠제작 시스템 유지보수</td>
                                        <td>㈜자이닉스</td>
                                        <td>교육혁신본부</td>
                                    </tr>
                                    <tr>
                                        <td>연구활동종사자상해보험 가입에 필요한 정보 수집</td>
                                        <td>㈜DB손해보험</td>
                                        <td>시설관리팀</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <strong>제5조(정보주체의 권리.의무 및 그 행사방법)</strong>
                            <div>정보주체는 다음과 같은 권리를 행사할 수 있으며, 만14세 미만 아동의 법정대리인은 그 아동의 개인정보에 대한 열람, 정정.삭제, 처리정지를 요구할 수 있습니다.</div>
                            <div>01 개인정보 열람 요구 : 경남대학교에서 보유하고 있는 개인정보파일은 「개인정보보호법」제35조(개인정보의 열람)에 따라 열람을 요구할 수 있으며 개인정보 열람 요구 시 법 제35조 5항에 의하여 열람을 제한할 수 있습니다.</div>
                            <span className="clause_indent">가. 법률에 따라 열람이 금지되거나 제한되는 경우</span>
                            <span className="clause_indent">나. 다른 사람의 생명.신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</span>
                            <span className="clause_indent">다. 공공기관이 다음의 경우 어느 하나에 해당하는 업무를 수항할 때 중대한 지장을 초래하는 경우</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 조세의 부과.징수 또는 환급에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 「초.중등교육법」 및 「고등교육법」에 따른 각급 학교, 「평생교육법」에 따른 평생교육시설, 그 밖의 다른 법률에 따라 설치된 고등교육기관에서의 성적 평가 또는 입학자 선발에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 학력.기능 및 채용에 관한 시험, 자격 심사에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 보상금.급부금 산정 등에 대하여 진행 중인 평가 또는 판단에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 다른 법률에 따라 진행 중인 감사 및 조사에 관한 업무</span>
                            <div>02 개인정보 정정.삭제 요구 : 경남대학교에서 보유하고 있는 개인정보파일에 대해서는 「개인정보보호법」 제36조(개인정보의 정정.삭제)에 따라 경남대학교에 개인정보의 정정.삭제를 요구할 수 있습니다. 단, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</div>
                            <div>03 개인정보 처리정지 요구 : 경남대학교에서 보유하고 있는 개인정보파일은 「개인정보 보호법」 제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수 있습니다. 단, 개인정보 처리정지 요구 시 법 제37조제2항에 의하여 처리정지 요구가 거절될 수 있습니다.</div>
                            <span className="clause_indent">가. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</span>
                            <span className="clause_indent">나. 다른 사람의 생명.신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</span>
                            <span className="clause_indent">다. 공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우</span>
                            <span className="clause_indent">라. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우</span>
                            <div>04 권리행사의 방법 및 절차 : 개인정보보호법 제38조(권리행사의 방법 및 절차)에 의해 경남대학교는 정보주체가 열람 등 요구에 대한 거절 등 조치에 대하여 불복이 있는 경우 이의를 제기할 수 있도록 필요한 절차를 다음과 같이 안내합니다.</div>
                            <div>[불복사유]</div>
                            <div>가. 이의신청 : 경남대학교로부터 열람여부의 결정통지를 받은 날 또는 열람거절의 결정이 있는 것으로 보는 날부터 "30일"이내 제기</div>
                            <div>나. 행정심판 : 처분이 있음을 안 날부터 "90일" 이내 제기(처분이 있은 날로부터 180일을 경과하면 제기 불가)</div>
                            <div>다. 행정소송 : 처분 등이 있음을 안 날부터 "90일" 이내(처분 등이 있는 날로부터 1년을 경과하면 제기 불가)</div>
                            <div>청구방법 : 서면, 유선, 이메일</div>
                            <div>청구장소 : 경남대학교 정보전산팀 개인정보보호담당자</div>
                            <div>- 전화 : 055-249-2852, fax : 0505-999-2111</div>
                            <div>- 이메일 : privacy@kyungnam.ac.kr</div>
                            
                            <strong>제6조(처리하는 개인정보 항목)</strong>
                            <div>경남대학교는 제1조 2항의 목록에서 검색되는 “개인정보파일에 기록되는 개인정보의 항목”을 처리하고 있습니다.</div>
                            
                            <strong>제7조(개인정보의 파기)</strong>
                            <div>경남대학교는 원칙적으로 개인정보의 보유기간이 경과했거나 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 단, 다른 법률에 따라 보존하여야하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</div>
                            <div>01 파기 절차</div>
                            <div>개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및 기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</div>
                            <div>02 파기 기한 및 파기방법</div>
                            <div>보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등 그 개인정보가 불필요하게 되었을 때에는 지체없이 파기합니다. 전자적 파일형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</div>
                            
                            <strong>제8조(개인정보 보호책임자)</strong>
                            <div>경남대학교 개인정보보호책임자 : 교학부총장 박 재 윤</div>
                            <div>개인정보보호담당자 : 손 영 화</div>
                            <div>연락처 : 055)249-2852, privacy@kyungnam.ac.kr</div>

                            <strong>제9조(시행령 제30조제1항에 따른 개인정보의 안전성 확보조치)</strong>
                            <div>경남대학교는 「개인 정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 관리적, 기술적, 물리적 조치를 하고 있습니다.</div>
                            <div>개인정보의 안전성 확보를 위해 다음과 같은 관리적, 기술적 및 물리적 조치를 취하고 있습니다.</div>
                            <div>01 관리적 조치 : 개인정보보호 추진계획 수립.시행, 정기적인 교육 등</div>
                            <div>02 기술적 조치 : 개인정보 유출 및 훼손을 막기 위한 보안프로그램 설치, 고유식별정보 등의 암호화, 외부로부터의 무단접근 통제, 실시간 보안관제 모니터링 및 이벤트 분석.대응 등</div>
                            <div>03 물리적 조치 : 개인정보처리시스템의 보관장소에 대한 출입통제 등</div>

                            <strong>제10조(개인정보파일의 열람 및 정정 청구)</strong>
                            <div>정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 경남대학교는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</div>
                            <a className="persnal-btn" onClick={onClickRead}>개인정보열람 접수처리 부서 보기(열람을 원하면 클릭)</a>
                            <div className="persnal-dep" style={{display: open? '' : 'none'}}>
                                <table className="tb_style_01">
                                    <thead>
                                        <tr>
                                            <th>개인정보파일명</th>
                                            <th>열람장소</th>
                                            <th>연락처</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> 과학영재교육원 원생현황 </td>
                                            <td> 과학영재교육원 행정지원팀 </td>
                                            <td>055-249-2796 </td>
                                        </tr>
                                        <tr>
                                            <td> 사이버강좌수강생명단 </td>
                                            <td> 교수학습센터 </td>
                                            <td>055-249-2816</td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={3}> 교육대학원 교학행정실 </td>
                                            <td rowSpan={3}>055-249-2810</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 서약서제출자명부 </td>
                                            <td rowSpan={6}> 극동문제연구소 </td>
                                            <td rowSpan={6}>02-3700-0702</td>
                                        </tr>
                                        <tr>
                                            <td> 유료회원관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 통일경제아카데미 </td>
                                        </tr>
                                        <tr>
                                            <td> 특수자료 디지털 콘텐츠 열람자관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 홈페이지 회원관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 특수자료열람/대출대장 </td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={5}> 대학원 교학행정실 </td>
                                            <td rowSpan={5}>055-249-2808</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 정부보증학자금융자 </td>
                                        </tr>
                                        <tr>
                                            <td> 취업통계관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 교수연수원관리 </td>
                                            <td> 사범대부설 교육연수원 </td>
                                            <td>055-249-2302</td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={3}> 산업경영대학원 교학행정실 </td>
                                            <td rowSpan={3}>055-249-2585</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 연구과제관리 </td>
                                            <td> 산학협력단 산학협력팀 </td>
                                            <td> 055-249-2298 </td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={2}> 입학관리팀 </td>
                                            <td rowSpan={2}>055-249-2031</td>
                                        </tr>
                                        <tr>
                                            <td> 입시홈페이지 회원관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 외부장학 기부금 발급자 관리 </td>
                                            <td rowSpan={3}> 장학복지팀 </td>
                                            <td rowSpan={3}>055-249-2033</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 정부보증학자금융자 및 농어촌출신대학생학자금융자, 국가장학금 </td>
                                        </tr>
                                        <tr>
                                            <td> 기부금 등 출연재산관리 </td>
                                            <td> 재무회계팀 </td>
                                            <td>055-249-6388</td>
                                        </tr>
                                        <tr>
                                            <td> 경남대학교홈페이지 회원관리 </td>
                                            <td> 정보전산팀 </td>
                                            <td>055-249-2858</td>
                                        </tr>
                                        <tr>
                                            <td> 체육영재사업육성 지원자 명부 </td>
                                            <td> 체육교육과 </td>
                                            <td>055-249-2366</td>
                                        </tr>
                                        <tr>
                                            <td> 취업통계관리 </td>
                                            <td> 취창업지원팀 </td>
                                            <td>055-249-2288</td>
                                        </tr>
                                        <tr>
                                            <td> 여성지도자 수료생 명부 </td>
                                            <td rowSpan={3}> 평생교육원 행정지원팀 </td>
                                            <td rowSpan={3}>055-249-2717</td>
                                        </tr>
                                        <tr>
                                            <td> 학점은행제 교육생 명부 </td>
                                        </tr>
                                        <tr>
                                            <td> 평생학습과정 교육생 명부 </td>
                                        </tr>
                                        <tr>
                                            <td> 졸업관리 </td>
                                            <td rowSpan={2}> 학사관리팀 </td>
                                            <td rowSpan={2}>055-249-2491</td>
                                        </tr>
                                        <tr>
                                            <td> 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 장애학생 현황 </td>
                                            <td rowSpan={2}> 학생지원팀 </td>
                                            <td rowSpan={2}>055-249-2950</td>
                                        </tr>
                                        <tr>
                                            <td> 발전기금 기탁자관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 어학연수생 관리 </td>
                                            <td rowSpan={2}> 학술교류팀 </td>
                                            <td rowSpan={2}>055-249-2221</td>
                                        </tr>
                                        <tr>
                                            <td> 외국인 학생 현황 </td>
                                        </tr>
                                        <tr>
                                            <td> 중앙도서관 이용자 </td>
                                            <td> 학술문헌팀 </td>
                                            <td>055-249-2844</td>
                                        </tr>
                                        <tr>
                                            <td> 생활관 관생 현황관리 </td>
                                            <td> 한마생활관 행정지원팀 </td>
                                            <td>055-249-2260</td>
                                        </tr>
                                        <tr>
                                            <td> 입학생관리 </td>
                                            <td rowSpan={3} style={{borderBottom: "1px solid #3a4147"}}> 행정대학원 교학행정실 </td>
                                            <td rowSpan={3} style={{borderBottom: "1px solid #3a4147"}}>055-249-2594</td>
                                        </tr>
                                        <tr>
                                            <td> 장학생관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div><strong>제11조(정보주체의 권익침해에 대한 구제방법)</strong></div>
                            <div>1. 정보주체는 개인정보를 침해당했을 시에는 경남대학교에 아래와 같이 민원접수를 할 수 있습니다.</div>
                            <div>개인정보 침해관련 민원 접수·처리부서</div>
                            <span className="clause_indent">- 부서명 : 경남대학교 정보전산원 정보전산팀</span>
                            <span className="clause_indent">- 담당자 : 손영화</span>
                            <span className="clause_indent">- 연락처 : 055-249-2852, privacy@kyungnam.ac.kr, fax) 0505-999-2111</span>
                            <div>2. 정보주체는 경남대학교의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 아래의 기관에 대해 문의하실 수 있습니다.</div>
                            <div>경남대학교 상위 감독기관(교육부)</div>
                            <span className="clause_indent">- 소관업무 : 개인정보 침해사실 신고, 상담 신청</span>
                            <span className="clause_indent">- 홈페이지 : www.moe.go.kr</span>
                            <span className="clause_indent">- 전화 : (국번없이) 110</span>
                            <span className="clause_indent">- 주소 : (30119) 세종특별자치시 갈매로 408, 14동(어진동)</span>
                            <div>개인정보 침해신고센터 (한국인터넷진흥원 운영)</div>
                            <span className="clause_indent">- 소관업무 : 개인정보 침해사실 신고, 상담 신청</span>
                            <span className="clause_indent">- 홈페이지 : privacy.kisa.or.kr</span>
                            <span className="clause_indent">- 전화 : (국번없이) 118</span>
                            <span className="clause_indent">- 주소 : 전라남도 나주시 진흥길 9 한국인터넷진흥원</span>
                            <div>개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</div>
                            <span className="clause_indent">- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</span>
                            <span className="clause_indent">- 홈페이지 : www.kopico.go.kr</span>
                            <span className="clause_indent">- 전화 : 1833-6972</span>
                            <span className="clause_indent">- 주소 : 서울특별시 종로구 세종대로 209 정부서울청사 4층</span>
                            <span className="clause_indent">- 대검찰청 사이버범죄수사단 : (국번없이) 1301, privacy@spo.go.kr(www.spo.go.kr)</span>
                            <span className="clause_indent">- 경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)</span>
                            <div>3. 정보주체의 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 정보주체는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</div>
                            <div>※ 행정심판에 대해 자세한 사항은 행정심판위원회 (http://www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</div>
                            
                            <strong>제12조(영상정보처리기기 설치.운영)</strong>
                            <div>경남대학교는 아래와 같이 영상정보처리기기를 설치.운영하고 있습니다.</div>
                            <Link to="https://www.kyungnam.ac.kr/sites/ko/download/cctv06.pdf" download="" target="_blank" title="다운로드">경남대학교 CCTV 설치 및 운영 지침 보기(열람을 원하면 클릭)</Link>
                            <div>01 영상정보처리기기 설치근거 및 목적 : 시설안전, 화재예방</div>
                            <div>02 설치 대수, 설치 위치 및 촬영범위</div>
                            <span className="clause_indent">- 설치대수 : 624</span>
                            <span className="clause_indent">- 설치위치 : 학내 각 건물(설치 건물 안내판 부착)</span>
                            <span className="clause_indent">- 촬영범위 : 건물 내 외부</span>
                            <div>03 관리책임자, 담당부서, 연락처 : 전지만, 총무인사팀, 055-249-2053</div>
                            <div>04 영상정보 촬영시간, 보관기간, 보관장소 및 처리방법</div>
                            <span className="clause_indent">- 촬영시간 : 24시간</span>
                            <span className="clause_indent">- 보관기간 : 촬영일로부터 1개월</span>
                            <span className="clause_indent">- 보관장소 및 처리방법 : 총무인사팀 상황실</span>
                            <div>05 개인영상정보 확인 및 장소 : 경남대학교 총무인사팀</div>
                            <div>06 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보에 관하여 열람 또는 존재확인.삭제를 원하는 경우 언제든지 영상정보처리기기 운영자에게 요구하실 수 있습니다. 단, 귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체, 재산의 이익을 위하여 필요한 개인영상정보에 한정됩니다.</div>
                            <div>07 영상정보 안정성 확보 조치 : 관리적 보안대책으로는 통제구역 설정으로 비인가자의 출입 제한하며 녹화서버 및 중계장비 등은 별도의 분리된 공간에 잠금장치를 하여 운영하고 있으며, 기술적 보안대책으로는 CCTV시스템 망을 인터넷망과 분리된 별도의 단독망으로 구성하였고 원격지 건물은 VPN장비를 이용 암호화를 통한 자료의 송수신이 가능토록 하여 운영하고 있습니다.</div>

                            <strong>제13조(개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)</strong>
                            <div>우리대학교 홈페이지는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.</div>

                            <strong>제14조(개인정보 처리방침의 변경)</strong>
                            <div>1. 이 개인정보 처리방침은 시행일로부터 적용됩니다.</div>
                            <span className="clause_indent">시행일자 : 2020년 12월 07일</span>
                            <div>2. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.</div>
                            <ul className="con-list">
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/8738/subview.do">개인정보 처리방침 보기 (개정13차, 2020.12.07.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7760/subview.do">개인정보 처리방침 보기 (개정12차, 2020.03.23.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7761/subview.do">개인정보 처리방침 보기 (개정11차, 2019.11.14.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7762/subview.do">개인정보 처리방침 보기 (개정10차, 2019.03.19.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7763/subview.do">개인정보 처리방침 보기 (개정9차, 2018.04.27.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7764/subview.do">개인정보 처리방침 보기 (개정8차, 2017.03.15.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7765/subview.do">개인정보 처리방침 보기 (개정7차, 2017.01.18.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7766/subview.do">개인정보 처리방침 보기 (개정6차, 2016.07.22.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7767/subview.do">개인정보 처리방침 보기 (개정5차, 2016.06.01.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7768/subview.do">개인정보 처리방침 보기 (개정4차, 2013.07.19.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7769/subview.do">개인정보 처리방침 보기 (개정3차, 2012.05.15.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7773/subview.do">개인정보 처리방침 보기 (개정2차, 2011.08.03.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7774/subview.do">개인정보 처리방침 보기 (개정1차, 2009.10.13.)</a></li>
                                <li><a href="https://www.kyungnam.ac.kr//ko/7771/subview.do">개인정보 처리방침 보기 (제정, 2008.11.14.)</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Privacy;