import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import { reducerUtils, asyncState, createAsyncSaga } from "utils/asyncUtils";
import { AxiosError } from "axios";
import { getAdminReservationList, Reservation } from 'api/reservation';

type ReservationState = {
    expectedResponse: asyncState;
    ongoingResponse: asyncState;
    completedResponse: asyncState;
    selectedReservation: Reservation | null;
};

const initalState: ReservationState = {
    expectedResponse: reducerUtils.initial(),
    ongoingResponse: reducerUtils.initial(),
    completedResponse: reducerUtils.initial(),
    selectedReservation: null,
};

const reservationSlice = createSlice({
    name: 'reservation',
    initialState: initalState,
    reducers: {
        getExpectedList(state, action: PayloadAction<string>) {
            state.expectedResponse = reducerUtils.loading();
        },
        getExpectedListSuccess(state, action: PayloadAction<Reservation[]>) {
            state.expectedResponse = reducerUtils.success(action.payload);
        },
        getExpectedListError(state, action: PayloadAction<AxiosError>) {
            state.expectedResponse = reducerUtils.error(action.payload);
        },
        getOnGoingList(state, action: PayloadAction<string>) {
            state.ongoingResponse = reducerUtils.loading();
        },
        getOnGoingListSuccess(state, action: PayloadAction<Reservation[]>) {
            state.ongoingResponse = reducerUtils.success(action.payload);
        },
        getOnGoingError(state, action: PayloadAction<AxiosError>) {
            state.ongoingResponse = reducerUtils.error(action.payload);
        },
        getCompletedList(state, action: PayloadAction<string>) {
            state.completedResponse = reducerUtils.loading();
        },
        getCompletedListSuccess(state, action: PayloadAction<Reservation[]>) {
            state.completedResponse = reducerUtils.success(action.payload);
        },
        getCompletedError(state, action: PayloadAction<AxiosError>) {
            state.completedResponse = reducerUtils.error(action.payload);
        },
        setSelectedReservation(state, action: PayloadAction<Reservation | null>) {
            state.selectedReservation = action.payload;
        },
        setCompletedList(state, action: PayloadAction<Reservation[]>) {
            state.completedResponse.data = action.payload;
        },
    },
});

export const {
    getExpectedList,
    getExpectedListError,
    getExpectedListSuccess,
    getOnGoingList,
    getOnGoingError,
    getOnGoingListSuccess,
    getCompletedList,
    getCompletedError,
    getCompletedListSuccess,
    setSelectedReservation,
    setCompletedList,
} = reservationSlice.actions;

export default reservationSlice.reducer;

/* saga */

const getExpectedListSaga = createAsyncSaga<Reservation[]>('reservation/getExpectedList', getAdminReservationList);
const getOnGoingListSaga = createAsyncSaga<Reservation[]>('reservation/getOnGoingList', getAdminReservationList);
const getCompletedListSaga = createAsyncSaga<Reservation[]>('reservation/getCompletedList', getAdminReservationList);

export function* reservationSaga() {
    yield takeLatest(getExpectedList, getExpectedListSaga);
    yield takeLatest(getOnGoingList, getOnGoingListSaga);
    yield takeLatest(getCompletedList, getCompletedListSaga);
}