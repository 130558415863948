import React, {  useState, useRef, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { useLoginUser } from "hooks/useLoginUser";
import { useLab } from "hooks/useLab";
import * as Common from "../../utils/common.js";
import CustomAlert from "../../components/alert/customAlert";
import { LectureDetail, TemplateInstance } from "api/lab";
import { swalConfirm, swal } from "utils/swal-utils";
import { useHistory } from 'react-router-dom';

// import React, { ChangeEvent, useState, useRef, useEffect, useCallback, } from "react";
// import * as Fetch from "../../utils/fetchFunction.js";
// import Button from "@material-ui/core/Button";
// import LabSeachDialog from "./LabSeachDialog";
// import SearchIcon from "@material-ui/icons/Search";

function ReservationWrite({ match }: RouteComponentProps) {
  const history = useHistory();
  
  const { signResponse, templateResponse, callLabSignup, getLectureDetail, callGetTemplateList } = useLab();

  const { userName, userId, userJob, userRole} = useLoginUser();


  const [alertText, setAlertText] = useState("");
  const [alertPopOpen, setAlertPopOpen] = useState(false);
  const [bookData, setBookData] = useState({
    res_cd: "",
    pwd: "",
    tseq: 0,
  });
  const [templates, setTemplates] =  useState<TemplateInstance[] | null>(
    []
  );

  const [lectureDetail, setLectureDetail] = useState<LectureDetail | null>(
    null
  );

  const textRef = useRef<HTMLTextAreaElement>(null);
  const selRef = useRef<HTMLSelectElement>(null);
  const textCurrent: any = textRef.current;
  const selCurrent: any = selRef.current;

  useEffect(()=>{
    callGetTemplateList();
  },[])

  useEffect(()=>{
    if(templateResponse.data && !templateResponse.error && !templateResponse.loading){
      setTemplates(templateResponse.data);
    }
  },[templateResponse]);


  const handleFocus = () => {
    textCurrent.disabled = false;
    textCurrent.focus();
  };

  const handleUnFocus = () => {
    textCurrent.disabled = true;
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    setBookData({
      ...bookData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMSGChange = (e: React.ChangeEvent<any>) => {
    if( selCurrent !== null ){
      if (selCurrent?.value === "ETC") {
        handleFocus();
      } else {
        textCurrent.value = "";
        handleUnFocus();
      }
    }
  };

  const onClickSubmitBtn = () => {
    const selMsg = selCurrent?.value;
    const areaMsg = textCurrent?.value;

    const bookDataObject = {
      // sseq: sbjCode, //강의코드
      // stat_cd: 0,
      res_cd: selMsg,
      pwd: bookData.pwd,
      tseq: bookData.tseq
    };

    console.log(bookDataObject);
    console.log(areaMsg);

    if (Common.checkValidity(bookDataObject) && bookData.tseq > 0) {
       swalConfirm("예약 신청 하시겠습니까?").then(function (res) {
         if (res.isConfirmed) {
           callLabSignup(selMsg, bookData.pwd, Number(bookData.tseq), areaMsg);
           history.push("/Portal/lab/reservationlist")
         }
       });
     } else {
       swal("정보를 모두 입력해주세요.");
     }
  };

      // if (Common.checkValidity(bookDataObject) && bookData.vm_cnt > 0) {
    //   swalConfirm("예약 신청 하시겠습니까?").then(function (res) {
    //     if (res.isConfirmed) {
    //       callLabSignup(selMsg, bookData.pwd, Number(bookData.vm_cnt), areaMsg);
    //     }
    //   });
    // } else {
    //   swal("정보를 모두 입력해주세요.");
    // }


  const onConfirmAlert = () => {
    setAlertText("");
    setAlertPopOpen(false);
  };

  const goBack = ()=>{
    history.goBack();
  }

  {
    /* 
    const [sbjList, setSbjList] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState<number>();
    const [lectureDetail, setLectureDetail] = useState<LectureDetail | null>(
      null
    );
    const [colList, setColList] = useState([]);
    const [deptList, setDeptList] = useState([]);
    const [open, setOpen] = useState(false);
    const [sbjName, setSbjName] = useState("");
    const [sbjCode, setSbjCode] = useState(0);

    const openAlert = (message: string) => {
    setAlertText(message);
    setAlertPopOpen(true);
    };


    // 강의 조회를 위해 학교, 과 정보 호출 
    useEffect(() => {
      Fetch.callApi("/user/depcol-info", {}, "GET").then((res) => {
        console.log(res);
        if (res.success) {
          setColList(res.data.col);
          setDeptList(res.data.dep);
        } else {
          console.log("조회 실패");
        }
      });
    }, []);

    
    const onChangeSbj = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setSbjName(event.target.value);
      },
      [onResult]
    );

   // 다이얼로그에서 강의 선택 후 넘겨준 콜백함수
    const onResult = useCallback((value: any) => {
      setSbjName(value.nm);
      setSbjCode(value.code);
      setOpen(false);
    }, []);

  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    // call API to get sbj_list
    useEffect(() => {
      Fetch.callApi("/subject/list-simple", {}, "GET").then((res) => {
        if (res.success) {
          setSbjList(res.data);
          return res.data.sseq;
        } else {
          console.log("조회 실패");
        }
      });
      if (signResponse.error) {
        openAlert(signResponse.error.message);
      }
    }, [signResponse]);
  
    // call API to get sbj detail
    const [selectLectureProps, setSelectLectureProps] = useState({
      onSelect: async (sseq: number) => {
        setSelectedLecture(sseq);
        const detail = await getLectureDetail(sseq);
        setLectureDetail(detail);
        return detail;
      },
    });
  */
  }



  return (
    <React.Fragment>
      <CustomAlert alertOpen={alertPopOpen} onConfirm={onConfirmAlert}>
        <div>{alertText}</div>
      </CustomAlert>
      <div className="layout_inner">
        <h3 className="board_tit">예약 신청서 작성</h3>
        <div className="join_wrap mypage_wrap">
          <div className="join_box_wrap">
            <table className="tb_style_02">
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "35%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "35%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>아이디</th>
                  <td>
                    <span className="mypage_info">{userId}</span>
                  </td>
                  <th>이름</th>
                  <td>
                    <span className="mypage_info">{userName}</span>
                  </td>
                </tr>
                <tr>
                <th>템플릿</th>
                  <td>
                      <select
                        name="tseq"
                        onChange={handleChange}
                      >
                        <option value={0}>템플릿을 선택하세요.</option>
                        {
                          templates?.map((data:TemplateInstance) => {
                            return <option key={data.tseq} value={data.tseq}>{data.tp_nm}</option>;
                          })
                        }
                      </select>
                  </td>
                  <th>비밀번호</th>
                  <td>
                    <div>
                      <input
                        type="password"
                        name="pwd"
                        className="input_style_02"
                        placeholder="비밀번호를 입력해주세요."
                        onChange={handleChange}
                      />
                    </div>
                  </td>
                </tr>
                {/* <tr>
                  <th>템플릿</th>
                  <td>
                      <select
                        name="tseq"
                        onChange={handleChange}
                      >
                        <option>템플릿을 선택하세요.</option>
                        {
                          templates?.map((data:TemplateInstance) => {
                            return <option key={data.tseq} value={data.tseq}>{data.tp_nm}</option>;
                          })
                        }
                      </select>
                  </td>
                  <td colSpan={2}></td>
                </tr> */}

                <tr>
                  <th rowSpan={2} style={{ borderBottom: "1px solid #0a2348" }}>
                    신청 사유
                  </th>
                  <td colSpan={3}>
                    <div className="">
                      <select
                        name="res_cd"
                        id=""
                        className=""
                        onChange={handleMSGChange}
                        ref={selRef}
                        disabled={false}
                      >
                        <option value="">신청 사유 선택</option>
                        <option value="TRAIN">수업 실습용</option>
                        <option value="RESEARCH">연구용</option>
                        <option value="ETC">기타</option>
                        {/* <option value="STUDY">자습용</option> */}
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <textarea
                      name=""
                      id=""
                      ref={textRef}
                      className="textarea"
                      disabled
                      placeholder="기타 사유를 500자 내외로 입력해주세요."
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="btn_wrap">
              <Link to="/Portal/lab/reservationlist">
                <button
                  className="btn_cancel reserve_btn"
                  style={{ marginRight: "4px" }}
                >
                  취소
                </button>
              </Link>
              <button className="btn_summit" onClick={onClickSubmitBtn}>
                완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReservationWrite;
