/* Util functions for handling date and time */

export function formatDateTime(date: Date): string {
    // format date
    const year = date.getFullYear();
    const month = (1 + date.getMonth());
    const day = date.getDate();

    const monthString = (month >= 10) ? month : ('0' + month);
    const dayString = (day >= 10) ? day : ('0' + day);

    const resultDate = year + '.' + monthString + '.' + dayString;

    //format time
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const hourString = (hour >= 10) ? hour : ('0' + hour);
    const minuteString = (minute >= 10) ? minute : ('0' + minute);
    const secondString = (second >= 10) ? second : ('0' + second);

    const resultTime = hourString + ':' + minuteString + ':' + secondString;

    return resultDate + '  ' + resultTime;
}

// dateString : YYYYMMDD 형식의 날짜 
export function formatDate(dateString: string): string {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const date = dateString.substring(6);

    return (year + "-" + month + "-" + date);
}

// timeString : HHMM 형식의 시간
export function formatTime(timeString: string): string {
    const hour = timeString.substring(0, 2);
    const minute = timeString.substring(2);

    return (hour + ":" + minute);
}

// timeString: YYYYMMDD HHMM 형식
export function formatDateTimeString(timeString: string) {
    const dateTime = timeString.split(" ");
    const date = formatDate(dateTime[0]);
    const time = formatTime(dateTime[1]);

    return (date + " " + time);
}

export function dayInKorean(dayString: string): string {
    switch(dayString) {
        case "MON":
            return "월";
        case "TUE":
            return "화";
        case "WED":
            return "수";
        case "THU":
            return "목";
        case "FRI":
            return "금";
        case "SAT":
            return "토";
        case "SUN":
            return "일";
        default: 
            return " ";
    }
}

export function toStringDate (d: Date | null) {
    let str = d?.toString();
    let strArray:string[] | undefined = str?.split('-');
    let strDate ='';
    
    if(strArray !==null && strArray !==undefined){
    let strDate = strArray[0]+strArray[1]+strArray[2];
      return strDate
    }
    return strDate;
}


export function spliteDate (str:string) {
    let strDate = str.substring(0,10)
    return strDate;
}


export function spliteDateTime (str:string) {
    let strDate = str.substring(0,17)
    return strDate;
}

export function spliteDateTimeSeconds (str:string) {
    let strDate = str.substring(0,19)
    return strDate;
}

export function materialDate(dateNow :Date){
    let year = dateNow.getFullYear(); 
    let monthWithOffset = dateNow.getUTCMonth() + 1; 
    let month = 
    monthWithOffset.toString().length < 2 
        ? `0${monthWithOffset}`
        : monthWithOffset;
    let date =
    dateNow.getUTCDate().toString().length < 2 
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();

    const materialDateInput = `${year}-${month}-${date}`; 
    
    return materialDateInput;
}
