import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import theme from 'styles/theme';
import { Menu, MenuItem } from "@material-ui/core";
import { useLoginUser } from 'hooks/useLoginUser';

import sideUserImg from 'images/layout/sideUser_avatar.png';
import sideUserArrImg from 'images/layout/sideUser_arr.png';

const useStyles = makeStyles(() =>
    createStyles({
        popover: {
            '& .MuiPopover-paper': {
                border: '1px solid #0a2348',
                backgroundColor: '#fff',
                boxShadow: 'none',
                borderRadius: '0',
                minWidth: 182,
                margin: '5px 0 0 -1px',
            },
            '& .MuiList-root': {
                padding: 0,
                '& .MuiListItem-root': {
                    font: 'inherit',
                    color: `${theme.colors.defaultDark}`,
                    borderTop: '1px solid #e9eef0',
                    padding: '10px 12px',
                    position: 'relative',
                    '&:hover': {
                        backgroundColor: '#f5f6f9',
                        borderColor: '#ebecef',
                    },
                    '&:hover + .MuiListItem-root ': {
                        borderTopColor: '#ebecef',
                    },
                    '&:first-child': {
                        borderTopWidth: 0
                    },
                    '& a': {
                        color: `${theme.colors.defaultDark}`,
                        textDecoration: 'none',
                    }
                },
            },
        },
    }),
);

const UserArea = styled.div`
  display: flex;
  align-items: center;
  height: 132px;
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  &.hasNotify {
    button .avatar::after {
      content: '';
      position: absolute;
      top: 1px;
      right: -1px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid #3c45d5;
      background: #ff5d4d
    }
  }
`;
const BtnArea = styled.div`
  width: 100%;

  button {
    position: relative;
    width: 100%;
    height: 32px;
    padding-left: 40px;
    border: 0;
    color: #fff;
    background: transparent;
    .avatar {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      border: 1px solid #3c45d5;
      border-radius: 50%;
      padding: 2px;
      background: #fff url(${sideUserImg}) no-repeat center center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      display: block;
      text-align: left;
      background: url(${sideUserArrImg}) no-repeat right center
    }
  }
`;

const SideUser = () => {
    const { userName, userRole, callUserLogout } = useLoginUser();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleUserOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleUserClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = () => {
        handleUserClose();
    };

    const handleLogout = () => {
        callUserLogout();
        handleItemClick();
    };

    return (
        <UserArea className="">
            <BtnArea>
                <button
                    type="button"
                    onClick={handleUserOpen}
                >
                    <span className="avatar"></span>
                    <span className="name">{userName}</span>
                </button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleUserClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    className={classes.popover}
                >
                    {
                        (userRole === "ADMIN")
                        && <MenuItem
                        onClick={handleItemClick}
                        >
                            <Link to="/Notify">알림 보기</Link>
                        </MenuItem>
                    }
                    <MenuItem
                        onClick={handleItemClick}
                    >
                        <Link to="/Portal/mypage">사용자 정보</Link>
                    </MenuItem>
                    <MenuItem
                        onClick={handleLogout}
                    >
                        로그아웃
                    </MenuItem>
                </Menu>
            </BtnArea>
        </UserArea>
    )
};

export default SideUser;
