import React from 'react';
import { practicerStateClass } from 'utils/state';

export default function AuthorityStatusCellRenderer (params: any) {
    switch(params.data.stat_cd) {
        case (0):
            return `<span class="state_ico ${practicerStateClass.APPROVE_REQUEST}">등록 요청</span>`;
        case (1):
            return `<span class="state_ico ${practicerStateClass.APPROVED}">승인 및 사용중</span>`;
        case (2):
            return `<span class="state_ico ${practicerStateClass.DENIED}">승인 거부</span>`;
        case (3):
            return `<span class="state_ico ${practicerStateClass.STOP}">실습 정지</span>`;
        case (4):
            return `<span class="state_ico ${practicerStateClass.WITHDRAW_REQUEST}">탈퇴 요청</span>`;
        case (5):
            return `<span class="state_ico ${practicerStateClass.WITHDRAW}">탈퇴</span>`;
        default: 
            return `<span></span>`;
    }
}