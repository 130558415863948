import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { CDialog } from "components/dialogs";
import { swalConfirm, swal } from "utils/swal-utils";
import { CTextField } from "components/textfields";
import CustomCheckbox from 'components/checkbox/CustomCheckbox';

import { useNotice } from 'hooks/useNotice';
import { modifyNotice } from 'api/notice';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { StringifyOptions } from 'query-string';

const FileRowWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

type NoticeEditDialogProps = {
    open: boolean;
    onClose: () => void;
    detail:any;
    hostUrl: string;
};

const NoticeEditDialog = ({open,detail,onClose, hostUrl}:NoticeEditDialogProps) => {

    const { detailNoticeResponse, modifyResponse, selectedNotice, callGetNoticeList, callGetNoticeDetail, callSetSelectedNotice } = useNotice();
    const [ editTitle, setEditTitle] = useState(detail.title);
    const [ editCont, setEditCont] = useState(detail.cont);
    const [ delFile, setDelFile ] =  useState(false);
    const [importantChecked, setImportantchecked] = useState<boolean>((detail.statCd === 0) ? true : false);
    
    const form = new FormData();

    useEffect(() => {
        if (!modifyResponse.loading && modifyResponse.error) {
            swal(modifyResponse.error.message);
            handleClose();
        }
        
        if (!modifyResponse.loading && modifyResponse.data) {
            swal(modifyResponse.data.message);
            handleClose();
        }
    }, [modifyResponse]);

    useEffect(() => {
        setEditTitle(detail.title);
        setEditCont(detail.cont);
    }, [open]);


    const onChangeTitle = (event: any) => {
        setEditTitle(event.target.value);
    };

    const onChangeCont = (event: any) => {
        setEditCont(event.target.value);
    };

    const handleUpdateAlert = () => {
        swalConfirm("수정하시겠습니까??").then(function(res) {
            if (res.isConfirmed) {
                const statCd = (importantChecked) ? '0' : '1';

                form.append('cseq', detail.cseq);
                form.append('title', editTitle);
                form.append('cont', editCont);
                form.append('file_del', JSON.stringify(delFile));
                form.append('stat_cd', statCd);
                
                modifyNotice(form).then(function(res) {
                    swal(res.message).then(function(res) {
                        handleClose();
                        callGetNoticeList();
                        callGetNoticeDetail(detail.cseq);
                    });
            });}
        });
    }

    const addFile = (event: any): void => {
        event.preventDefault();
        for(let key of Object.keys(event.target.files)) {
            if (key !== 'length') {
                form.append('file', event.target.files[key]);
                form.append('file_del', JSON.stringify(false));
                console.log(event.target.files[key])
            }
        }
    }

    const onChangeFile = (event: any): void => {
        event.preventDefault();

        for(let key of Object.keys(event.target.files)) {
            if (key !== 'length') {
                form.append('file', event.target.files[key]);
            }
        }
    }

    const handleClose = () => {
        onClose && onClose();
        setDelFile(false);
        callGetNoticeList();
    };
    
    const handleDel = () =>{
        setDelFile(true);
    }


    if (detailNoticeResponse.loading && detailNoticeResponse.data) {
        // 로딩중
    }
    if (detailNoticeResponse.error) {
        // 에러
    }
    if (!detailNoticeResponse.data) {
        return null;
    }

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="md"
            title={`공지사항 수정하기`}
            onClose={handleClose}
            onUpdate={handleUpdateAlert}
            modules={['update']}
            topBtn={true}
            // topModules={['update','delete']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>제목</th>
                    <td colSpan={3}>
                    <CTextField
                            id="dataset-title"
                            type="text"
                            className="form_fullWidth"
                            // defaultValue={selectedNotice?.title}
                            value={selectedNotice?.title}
                            onChange={onChangeTitle}
                        />
                    </td>
                </tr>
                <tr>
                    <th>내용</th>
                    <td colSpan={3}>
                        <textarea
                            rows={20}
                            name='cont'
                            onChange={onChangeCont}
                            defaultValue={detail.cont}
                        ></textarea>
                    </td>
                </tr>
                <tr>
                    <th>첨부파일</th>
                    <td>
                        <FileRowWrap>
                            <div>
                                {
                                    (delFile)
                                    ? <input type='file' id='fileUpload' onChange={addFile}/>
                                    :(
                                        (detail.fileNm !== null)
                                        ? <td><a  style={{color:'black', marginRight:'5px'}}href={`${hostUrl}/Portal/community/notice/download-file?cseq=${detail.cseq}`}>{detail.fileNm}</a> <button onClick={handleDel}>&#x2004;X&#x2004;</button></td>
                                        : <input type='file' id='fileUpload' onChange={addFile}/>
                                    )
                                }
                            </div>
                            <CustomCheckbox checked={importantChecked} setChecked={setImportantchecked} text="중요" />
                        </FileRowWrap>
                    {/* <input type='file' id='fileUpload' onChange={addFile}/>
                    <td>{detail.fileNm} <button onClick={handleDel}> 삭제 </button></td> */}
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default NoticeEditDialog;
