import React, { useState, useEffect } from 'react';
import Layout from "layout";
import { Title } from 'pages';
import { CTabs, CTab, CTabPanel } from 'components/tabs';
import AcceptTab from './AcceptTab';
import RegistrationTab from './RegistrationTab';

const Contest = () => {
    const currentPage = Title.Contest;
    const [tabvalue, setTabvalue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabvalue(newValue);
    };

    return (
        <Layout currentPage={currentPage}>
            <CTabs
                type="tab1"
                value={tabvalue}
                onChange={handleTabChange}
            >
                <CTab label="공모전 관리" />
                <CTab label="공모전 접수 관리" />
            </CTabs>
            <CTabPanel
                value={tabvalue}
                index={0}
            >
                <RegistrationTab/>
            </CTabPanel>
            <CTabPanel
                value={tabvalue}
                index={1}
            >
                <AcceptTab/>
            </CTabPanel>
        </Layout>
    );
}
export default Contest;
