import React, { useState } from 'react';
import * as Fetch from '../../utils/fetchFunction.js';

import withdrawImg from 'images/portal/mypage/end_img.png';

function Withdrawal({ match, history }) {
    const [withdrawalValue, setWithdrawalValue] = useState({
        pwd: "",
        reason: "졸업 (퇴사)",
        reason_msg: "",
    })
    const [msgOpen, setMsgOpen] = useState(false);

    const handleReasonChange = (event) => {
        const value = event.target.value;
        setWithdrawalValue((prev) => ({
            ...prev,
            reason: value
        }));

        if (value === '기타') {
            setMsgOpen(true);
        }
        else {
            setMsgOpen(false);
        }
    };

    const handleChange = (event) => {
        const key = event.target.name;
        const value = event.target.value;

        setWithdrawalValue((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleWithdrawal = () => {
        Fetch.callApi("/user/withdrawal", withdrawalValue, "POST")
        .then((res) => {
            console.log(res);
            if (res.success) {
                localStorage.removeItem('access-token');
                history.push(`${match.url}/withdraw/finish`);
            }
            else {
                window.alert(res.message);
            }
        });
    };

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <React.Fragment>
            <h3 className="board_tit">회원탈퇴 안내</h3>
            <div className="end_wrap">
                <img src={withdrawImg} alt="withdrawImg" />
            </div>
            <h3 className="board_tit">탈퇴 사유 및 비밀번호 재확인</h3>
            <div className="join_box_wrap">
                <table className="tb_style_02">
                    <colgroup>
                        <col style={{width: "20%"}} />
                        <col />
                    </colgroup>
                    <tr>
                        <th>비밀번호 재확인</th>
                        <td>
                            <input type="password" name="pwd" value={withdrawalValue.password} onChange={handleChange} className="input_style_02" placeholder="현재 비밀번호를 입력해주세요." />
                        </td>
                    </tr>
                    <tr>
                    <th rowSpan="2">탈퇴 사유</th>
                        <td>
                            <div className="select_wrap">
                                <select value={withdrawalValue.reason} onChange={handleReasonChange}>
                                    <option value="졸업 (퇴사)">졸업 (퇴사)</option>
                                    <option value="기타">기타</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    {
                        msgOpen && 
                        <tr>
                            <td style={{borderBottom: "1px solid #e4e8ec"}}>
                                <textarea name="reason_msg" value={withdrawalValue.reason_msg} onChange={handleChange} className="textarea" placeholder="탈퇴 사유를 500자 내외로 입력해주세요."></textarea>
                            </td>
                        </tr>
                    }
                </table>
                <div className="btn_wrap">
                    <button className="btn_cancel" style={{marginRight: "0.3rem"}} onClick={handleCancel}>취소</button>
                    <button className="btn_summit" onClick={handleWithdrawal}>탈퇴</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Withdrawal;