import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useCommunity } from 'hooks/useCommunity';
import RollingNotice from './RollingNotice';


import main_visual_txt from 'images/portal/main/main_visual_txt.png';

function Home() {
    const banner01Header = "가상실습실을 이용하고\n싶으신가요?";
    const banner01Text = "가상 실습실\n예약 신청서 작성하기";
    const banner02Header = "내가 예약한\n실습실이 궁금한가요?";
    const banner02Text = "나의 가상 실습실\n예약 신청 현황 확인하기";
    const aboutText = "경남 빅데이터 쉐어 플랫폼의\n소개와 목적, 조직도를\n알려드립니다.";
    const equipText = "USG 공유대학과 경남지역의\n공유가능한 연구/실습장비를 검색\n하실 수 있습니다.";

    // const { noticeResponse, callGetNoticeList} = useCommunity();

    const { noticeImportantResponse, callGetImportantNoticeList} = useCommunity();
    const [ rowData, setRowData ] = useState([]);

    // useEffect(() => {
    //     let List = {
    //         'pageNumber': 1,
    //     }
    //     callGetNoticeList(List);
    // }, []);

    // useEffect(() => {
    //     if (!noticeResponse.loading && !noticeResponse.error && noticeResponse.data){
    //         setRowData(noticeResponse.data);
    //     }
    // },[noticeResponse])

    useEffect(() => {
        callGetImportantNoticeList();
    }, []);

    useEffect(() => {
        if (!noticeImportantResponse.loading && !noticeImportantResponse.error && noticeImportantResponse.data){
            console.log(noticeImportantResponse)
            setRowData(noticeImportantResponse.data);
        }
    },[noticeImportantResponse])

    return (
        <React.Fragment>
            <section id="visual">
                <div className="layout_inner visual_txt">
                    <img src={main_visual_txt} alt="main_visual_txt" />
                </div>
            </section>
            <section id="main_notice_wrap">
                <div className="layout_inner">
                    <div className="main_notice">
                        <h3>공지사항</h3>
                            <RollingNotice rowData={rowData}/>
                            <div className="main_notice_btn_wrap" style={{verticalAlign:"flex-end"}}><Link to="/Portal/community">더 보기</Link> </div>
                    </div>

                </div>
            </section>
            <section id="banner_wrap">
                <div className="layout_inner">
                    <ul>
                        <li>
                            <div className="banner_01">
                                <h4>
                                    {
                                        banner01Header.split('\n').map((line, i) => {
                                            return (<span key={i}>{line}<br/></span>)
                                        })
                                    }
                                </h4>
                                <p>
                                    {
                                        banner01Text.split('\n').map((line, i) => {
                                            return (<span key={i}>{line}<br/></span>)
                                        })
                                    }
                                </p>
                                <Link to="/Portal/lab/reservationWrite"><button type="button" className="btn_banner"></button></Link>
                            </div>
                        </li>

                        <li>
                            <div className="banner_02">
                                <h4>
                                    {
                                        banner02Header.split('\n').map((line, i) => {
                                            return (<span key={i}>{line}<br/></span>)
                                        })
                                    }
                                </h4>
                                <p>
                                    {
                                        banner02Text.split('\n').map((line, i) => {
                                            return (<span key={i}>{line}<br/></span>)
                                        })
                                    }
                                </p>
                                <Link to="/Portal/lab/reservationlist"><button type="button" className="btn_banner"></button></Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="main_leaflet">
                <div className="layout_inner">
                    <ul>
                        <li className="about">
                            <h4>센터 소개</h4>
                            <p>
                                {
                                    aboutText.split('\n').map((line, i) => {
                                        return (<span key={i}>{line}<br/></span>)
                                    })
                                }
                            </p>
                            <Link to="/Portal/about">바로가기</Link>
                        </li>
                        <li className="equip">
                            <h4>연구&middot;실습 장비 공유</h4>
                            <p>
                                {
                                    equipText.split('\n').map((line, i) => {
                                        return (<span key={i}>{line}<br/></span>)
                                    })
                                }
                            </p>
                            <Link to="/Portal/equipment/usg">바로가기</Link>
                        </li>
                        {/*<li className="contest">
                            <h4>공모전&middot;경진대회 <button className="btn_contest_plus"></button></h4>
                            <div className="contest_list"><a>2021 전기안전 콘텐츠 공모전 <span className="blue">D-38</span></a></div>
                            <div className="contest_list"><a>2021년도 신용보증기금 홍보영상 시나리오 공모전 <span className="orange">D-9</span></a></div>
                            <div className="contest_list"><a>2021 전기안전 콘텐츠 공모전 <span className="blue">D-40</span></a></div>
                        </li>*/}
                    </ul>

                </div>
            </section>
        </React.Fragment>
    );
}

export default Home;