import React from 'react';
import { Link } from 'react-router-dom';

function WithdrawalFinish() {
    const withdrawText = "그 동안 빅데이터 셰어 플랫폼을 이용해 주셔서 감사합니다.\n보다 나은 서비스로 다시 찾아 뵙겠습니다.";

    return (
        <div className="join_wrap">
            <div className="join_tit">
                회원 탈퇴
            </div>
            <div className="join_box_wrap">
                <div className="withdrawal_finish">
                    <h3>회원탈퇴가 <span>완료</span>되었습니다.</h3>
                    <p>
                        {
                            withdrawText.split('\n').map(line => {
                                return (<span>{line}<br/></span>)
                            })
                        }
                    </p>
                    <Link to="/Portal/main">
                        <button className="btn_move">메인 페이지로 이동</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default WithdrawalFinish;