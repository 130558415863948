import React, { useEffect, useState } from 'react';
import { Alarm } from 'api/alarm';
import { useAlarm } from 'hooks/useAlarm';
import { formatDateTime } from 'utils/dateUtils';

type NotifyContentProps = {
    alarm: Alarm;
};

function NotifyContent({
    alarm,
}: NotifyContentProps) {
    const { callConfirmAlarm, callGetNotConfirmedAlarmCount } = useAlarm();
    const [alarmStyle, setAlarmStyle] = useState<string>("");

    useEffect(() => {
        if (alarm.is_cnf) {
            setAlarmStyle("");
        }
        else {
            setAlarmStyle("new");
        }
    }, [alarm]);

    const handleAlarmClick = () => {
        if (!alarm.is_cnf) {
            callConfirmAlarm(alarm.rseq).then(function (res) {
                if (res.success) {
                    setAlarmStyle("");
                    callGetNotConfirmedAlarmCount();
                }
            });
        }
    };

    return (
        <li className={alarmStyle}>
            <div className="box" onClick={handleAlarmClick}>
                <div className="time">{formatDateTime(new Date(alarm.reg_date))}</div>
                <div className="cont">
                    {alarm.msg}
                </div>
            </div>
        </li>
    );
}

export default NotifyContent;