import React, { useState, useEffect } from 'react';
import Layout from "layout";
import { Title } from 'pages';
import { useDashboard } from 'hooks/useDashboard';

import styled from 'styled-components';
import DashboardInstancechart from './DashboardInstancechart';
import DashboardCorechart from './DashboardCorechart';
import DashboardRAMchart from './DashboardRAMchart';
import DashboardUserchart from './DashboardUserChart';


const Dashboard = () => {
    const currentPage = Title.Dashboard;
    const {callDashboardItem, dashboardItemResponse} = useDashboard();
    const [localGb, setLocalGb] = useState({total:0, used:0});
    const [core, setCore] = useState({total:0, used:0});
    const [RAM, setRAM] = useState({total:0, used:0});
    const [Vms, setVms] = useState({total:0, used:0});
    const [userState, setUserState] = useState({runningUser:0, waitRegister:0, waitSubjectSignUp:0});
    
    useEffect(()=>{
      callDashboardItem();
    },[])
    

  useEffect(() => {
    if (!dashboardItemResponse.loading && !dashboardItemResponse.error && dashboardItemResponse.data){
      // console.log(dashboardItemResponse.data)
      if(dashboardItemResponse.data.localGb < dashboardItemResponse.data.localGbUsed){
        setLocalGb({
          total : dashboardItemResponse.data.localGb,
          used : dashboardItemResponse.data.localGb
        });
      }else{
        setLocalGb({
          total : dashboardItemResponse.data.localGb,
          used : dashboardItemResponse.data.localGbUsed
        });
      }

      if(dashboardItemResponse.data.vcpus < dashboardItemResponse.data.vcpusUsed){
        setCore({
          total : dashboardItemResponse.data.vcpus,
          used : dashboardItemResponse.data.vcpus
        });
      }else{
        setCore({
          total : dashboardItemResponse.data.vcpus,
          used : dashboardItemResponse.data.vcpusUsed
        });
      }

      if(dashboardItemResponse.data.memoryMb < dashboardItemResponse.data.memoryMbUsed){
        setRAM({
        total : dashboardItemResponse.data.memoryMb,
        used : dashboardItemResponse.data.memoryMb
      });
      }else{
        setRAM({
          total : dashboardItemResponse.data.memoryMb,
          used : dashboardItemResponse.data.memoryMbUsed
        });
      }

      if(dashboardItemResponse.data.memoryMb < dashboardItemResponse.data.memoryMbUsed){
        setVms({
        total : dashboardItemResponse.data.totalVms,
        used : dashboardItemResponse.data.totalVms
      });
      }else{
        setVms({
          total : dashboardItemResponse.data.totalVms,
          used : dashboardItemResponse.data.runningVms
        });
      }
      
      setUserState({
        runningUser : dashboardItemResponse.data.runningUser,
        waitRegister : dashboardItemResponse.data.wait_registerUser,
        waitSubjectSignUp: dashboardItemResponse.data.wait_subjectSignUpUser

      });
    }
},[dashboardItemResponse])


    return (
        <Layout currentPage={currentPage}>
            <DashboardWrap>
                    <DashboardInstancechart localGb={localGb}/>
                    <DashboardCorechart core={core}/>
                    <DashboardRAMchart RAM={RAM}/>
            </DashboardWrap>
            <DashboardUserchart Vms={Vms} userState={userState} />
        </Layout>
    );
}
export default Dashboard;

const DashboardWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 12px;
  .panel_graph {
    margin-left: 12px;
    flex-grow: 1;
    panel_graph;
  }
  .panel_instancegraph {
    flex-grow: 1;
    panel_graph;
  }
  .panel_dashboard {
    width: 500px;
    margin-left: 12px;
    .panelCont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 60px;
    }
  }
  .slash {
    position: relative;
    display: inline-block;
    width: 20px;
    padding: 0 5px;
    font-weight: 300;
    font-size: 10px;
    color: transparent;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -4px;
      width: 29px;
      border-top: 1px solid #b7b7b7;
      transform: rotate(-70deg);
    }
  }
  .vm_01 {
    color: #1d90ff
  }
  .vm_02 {
    color: #00b91a
  }
  .vm_03 {
    color: #ff5a00
  }
`;
