import React from 'react';
import { Link } from 'react-router-dom';

import trainee from 'images/portal/mypage/join_ico_trainee.png';
import admin from 'images/portal/mypage/join_ico_admin.png';

function JoinFinish({ match, userType }) {
    if (userType === 't') {
        return (
            <div className="join_wrap">
                <div className="join_tit">
                    <img src={trainee} alt="userImg" /> 실습자 회원가입
                </div>
                <div className="join_box_wrap">
                    <div className="join_finish">
                        <h3>실습자 가입신청이 <span>완료</span>되었습니다.</h3>
                        <p>
                            관리자가 가입 승인을 하면 빅데이터 셰어 플랫폼을 이용하실 수 있습니다.
                            승인 완료시 등록하신 이메일 주소로 안내 메일이 발송됩니다.
                        </p>
                        <Link to="/Portal/main">
                            <button className="btn_move">메인 페이지로 이동</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    else if (userType === 'a') {
        return (
            <div className="join_wrap">
                <div className="join_tit">
                    <img src={admin} alt="userImg" /> 관리자 회원가입
                </div>
                <div className="join_box_wrap">
                    <div className="join_finish">
                        <h3>관리자 가입신청이 <span>완료</span>되었습니다.</h3>
                        <p>
                            관리자가 가입 승인을 하면 빅데이터 셰어 플랫폼을 이용하실 수 있습니다.
                            승인 완료시 등록하신 이메일 주소로 안내 메일이 발송됩니다.
                        </p>
                        <Link to="/Portal/main">
                            <button className="btn_move">메인 페이지로 이동</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div>
                wrong user type.
            </div>
        );
    }
}

export default JoinFinish;