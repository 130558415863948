import axios from 'axios';
import React from 'react';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type Notice = {
    title: string;
    reg_date: string;
    cseq: number;
};

export type NoticeCreateInput = {
    cseq?: number;
    title: string;
    cont: string;
    file_nm?: string,
    file_binary? : string
};

export type NoticeModifyInput = {
    cseq: number;
    title: string;
    cont: string;
    file_nm?: string,
    file_binary? : string
};

export type NoticeDetail = {
    title: string;
    reg_date: string;
    cseq: number;
    cont: string,
    file_nm?: string | null,
    file_binary?: string | null,
};


export const getNoticeList = async () => {
    try {
        const requestUrl = "/notice/admin/list";
    
        const res = await axios.get(requestUrl);
        console.log(res)
        return (res.data.data)
    } catch (error) {
        handleError(error);
    }
};

export const getNoticeDetail = async (cseq : number) => {
    try {
        const requestUrl = "/notice/content";
        const res = await axios.get(requestUrl, {
            params: {
                cseq : cseq,
            } 
        });
        const result = res.data;
        return result
    } catch (error) {
        handleError(error);
    }
};


export const deleteNotice = async (cseq: number) => {

    try {
        const requestUrl = "/notice/admin/delete";

        const res = await axios.delete(requestUrl, {
            data: {
                cseq: cseq,
            }
        });

        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const registerNotice = async (form: any) => {
    try {
        const requestUrl = "/notice/admin/register";
        const res = await axios.post(requestUrl, form,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        
        return (res.data);
    } catch (error) {
        handleError(error);
    }
}


export const modifyNotice = async (form: any) => {
    try {
        const requestUrl = "/notice/admin/modify";
        const res = await axios.post(requestUrl, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};
