import React from 'react';

export { default as Dashboard } from './Dashboard/Dashboard';
export { default as Reservation } from './Reservation/Reservation';
export { default as Environment } from './Environment/Environment';
export { default as Lecture } from './Lecture/Lecture';
export { default as Template } from './Template/Template';
export { default as Practicer } from './Practicer/Practicer';
export { default as Dataset } from './Dataset/Dataset';
export { default as Contest } from './Contest/Contest';
// export { default as Board } from './Board/Board';
export { default as Notice } from './Notice/Notice';
export { default as Notify } from './User/Notify';
export { default as PortalMain } from './PortalMain/PortalMain';
export { default as Admin } from './Admin/Admin';
export { default as EmailApprove } from './Mail/EmailApprove';
export { default as VmApprove } from './Mail/VmApprove';

const Title = {
    Dashboard: '대시보드',
    Reservation: '가상 실습실 예약 관리',
    Environment: '가상 환경 관리',
    Lecture: '강의 관리',
    Template: '템플릿 관리',
    Practicer: '실습자 관리',
    Dataset: '데이터셋 관리',
    Contest: '공모전 관리',
    Board: '공지사항 관리',
    Notify: '알림',
    Admin: '관리자 관리',
};

export { Title }
