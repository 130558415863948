import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { getDashboardItem } from "store/dashboardSlice";

export function useDashboard() {
    const {dashboardItemResponse } =  useSelector((state: RootState) => (state.dashboard));
    
    const dispatch = useDispatch();
    
    const callDashboardItem = () => {
        dispatch(getDashboardItem());
    };

    return {
        dashboardItemResponse,
        callDashboardItem
    };
};