import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { usePortalDataset } from "hooks/usePortalDataset";
import { datasetList } from "api/portalDataset";
import DatasetListTable from "./datasetListTable";
import DatasetPagination from "./datasetPagination";
import { Categories } from "api/portalDataset";

function DatasetList({ match }: RouteComponentProps) {
  const {
    datasetResponse,
    categoriesResponse,
    callGetDatasetList,
    callGetCategoriesList,
  } = usePortalDataset();
  const [rowData, setRowData] = useState([]);
  const [listNum, setListNum] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [msg, setMsg] = useState("");
  const [categories, setCategories] = useState<Categories[] | null>([]);

  useEffect(() => {
    callGetCategoriesList();
  }, []);

  useEffect(() => {
    if (
      categoriesResponse.data &&
      !categoriesResponse.error &&
      !categoriesResponse.loading
    ) {
      setCategories(categoriesResponse.data.data);
    }
  }, [categoriesResponse]);

  useEffect(() => {
    if (
      !datasetResponse.loading &&
      !datasetResponse.error &&
      datasetResponse.data
    ) {
      if (datasetResponse.data.length === 0) {
        setTotal(0);
        setMsg("데이터셋 내역이 없습니다.");
      } else {
        const tmpDatasetList = datasetResponse.data.map(
          (dataset: datasetList) => {
            let tmpDataset = { ...dataset };
            if (tmpDataset.total !== undefined) {
              setTotal(tmpDataset.total);
              setListNum(tmpDataset.dsseq)
            } else if (tmpDataset.total === 0) {
              setTotal(0);
              setMsg("데이터셋 내역이 없습니다.");
            }
            return tmpDataset;
          }
        );
        setRowData(tmpDatasetList);
      }
    }
  }, [datasetResponse]);

  const updateDatasetList = (newList?: any) => {
    let List: any = {};
    if (searchKeyword === "" && newList === undefined) {
      List = {
        pageNumber: pageNumber,
      };
      callGetDatasetList(List);
    } else {
      callGetDatasetList(newList);
    }
  };

  const listNumber = total - (pageNumber - 1) * 10;

  useEffect(() => {
    const newList = {
      pageNumber: pageNumber,
      keyword: searchKeyword,
      category: searchCategory
    };

    updateDatasetList(newList);
  }, [pageNumber]);

  const handleSearchData = (e: any) => {
    const value = e.target.value;
    setSearchKeyword(value);
  };

  const handleSearchCategory = (e: any) => {
    const value = e.target.value;
    setSearchCategory(value);
  };


  const getSearchData = useCallback(() => {

    const newList = {
      pageNumber: pageNumber,
      keyword: searchKeyword,
      category: searchCategory
    };

    if (searchCategory !== "" || searchKeyword !== "") {
      updateDatasetList(newList);
    } 
    else if ( searchCategory === ""){
        updateDatasetList();
    }
    else {
      alert("검색어를 입력해주세요.");
    }
  }, [searchKeyword, callGetDatasetList]);

  const onChangeDatasetPage = (page: number) => {
    setPageNumber(page);
  };

  return (
    <React.Fragment>
      <div className="layout_inner">
        <div className="board_wrap">
          <h3 className="board_tit">데이터셋 목록 검색</h3>
          <div className="search_box">
            <ul>
              <li>
                <div className="select_wrap contest_status">
                  <select name="fcseq" className="reservationOption" onClick={(e)=>{handleSearchCategory(e)}}>
                    <option value="">카테고리 전체</option>
                    {categories?.map((data: Categories) => {
                      return (
                        <option value={data.category_cd} key={data.category_cd}>
                          {data.category_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </li>
              <li>
                <input
                  type="text"
                  placeholder="검색어를 입력해주세요."
                  className="contest_input"
                  onChange={(e) => {
                    handleSearchData(e);
                  }}
                />
              </li>
              <li>
                <button
                  type="button"
                  className="contest_button"
                  onClick={getSearchData}
                >
                  검색
                </button>
              </li>
            </ul>
          </div>

          <h3 className="board_tit">데이터셋 목록</h3>
          <table className="tb_style_03">
            <tbody>
              <tr>
                <th className="tb_num">NO</th>
                <th className="tb_req">분류</th>
                <th>제목</th>
                <th className="tb_status">신청 현황</th>
              </tr>
              <DatasetListTable
                listNumber={listNumber}
                rowData={rowData}
                total={total}
                msg={msg}
              />
            </tbody>
          </table>

          <div className="pagenation_wrap" style={{ marginTop: "0" }}>
            <div className="pagenation_inner">
              <DatasetPagination
                totalCount={total}
                itemPerPage={10}
                onChangePage={onChangeDatasetPage}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DatasetList;
