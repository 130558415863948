import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { spliteDate } from 'utils/dateUtils';

import { makeStyles } from '@material-ui/core/styles';
import FiberNewIcon from "@material-ui/icons/FiberNew";

import newIcon from 'images/portal/board/ico_new.png';


function RollingNotice({ rowData }) {
    const [num, setNum] = useState(1);

    const classes = useStyles();

    const sliderRef = useRef();

    const handleNext = () => {
        sliderRef.current.slickNext();
        setNum(num + 1)
    }

    const handlePrev = () => {
        sliderRef.current.slickPrev();
        setNum(num - 1)
    }

    const settings = {
        className: "rollingPost",
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: false,
        vertical: true,
    };

    return (
        <>
            {/* <div className="main_notice_btn_wrap" >
                <button type="button" className="main_prev" onClick={handlePrev}>Prev</button>
                <button type="button" className="main_next" onClick={handleNext}>Next</button>
                {
                    (num !== 1) && <button type="button" className="main_prev" onClick={handlePrev}>Prev</button>
                }
                {
                    (num !== 10) &&  <button type="button" className="main_next" onClick={handleNext}>Next</button>
                }
            </div> */}
            <ul>
                <Slider {...settings} ref={sliderRef}>
                    {
                        rowData?.map((notice, i) => {
                            const regDate = new Date(notice.reg_date);
                            const today = new Date();

                            const timeBetween = today.getTime() - regDate.getTime();
                            const dayBetween = timeBetween / (1000 * 60 * 60 * 24);

                            return (<li key={i}>
                                <Link to={`/Portal/community/notice_view?no=${notice.cseq}`}>
                                {notice.title}
                                {
                                    ((dayBetween < 10) || (dayBetween === 10)) &&
                                    <span className="newBadge"> <img src={newIcon} alt="new notice" style={{ display: "inline" }} /></span>
                                }
                                <span className="main-notice-date">{spliteDate(notice.reg_date)}</span></Link></li>)
                        })
                    }
                </Slider>
            </ul>
        </>
    );
}

export default RollingNotice;

const useStyles = makeStyles({
    New: {
        fontSize: '21px',
        color: 'red',
        verticalAlign: 'sub',
    },
});
