import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

function Introduce({match}:RouteComponentProps) {

    return (
        <React.Fragment>
                <div className="layout_inner">
                    <p className="common_subtit"><span>BIGDATA</span> share platform</p>
                    <h3 className="common_tit">경남 빅데이터 공유 플랫폼</h3>

                    <div className="introduce_wrap">
                    <div className="introduce_main_text">
                        빅데이터 분석 기법을 학습하여 세상과 소통하고,<br/>
                        제조 ICT 분야를 선도하는<br/>
                        혁신 인재가 되기를 응원하겠습니다.
                    </div>

                    <div className="introduce_sub_text">
                        <p>
                        4차 산업 혁명 시대에 들어선 모든 영역에서 AI·Big Data에 대한 수요가<br/>
                        늘어났습니다. 이에 따라 경남에서는 이를 체계적으로 지원할 조직이나<br/>
                        인프라를 확보하여 AI·Big Data 전문 기업 및 기관 등 협력관계를 구축하여<br/>
                        <strong>모든 자원을 공유 형태로 제공하기 위한 빅데이터 인프라를 구축</strong>하고,<br/>
                        USG 참여 학생들에게 안정적인 AI·Big Data 자원을 공유하여<br/>
                        <strong>AI·Big Data 분석 학습 환경을 제공</strong>하고자 합니다.
                        </p>
                        <p>
                        따라서, 학기 초 한 번의 템플릿 생성을 통해 원클릭으로 가상 서버를 할당하여<br/>
                        학생들은 <strong>클라우드를 통해 제공된 최적의 가상실습 환경</strong>에서<br/>
                        즉시 수강이 가능하고, 자원환경 자동 체크로 백업·복구 및 오토스케일링을 통해<br/>
                        <strong>편리한 자원 관리 기능을 지원</strong>할 것입니다.
                        </p>
                        <p>
                        빅데이터 공유 플랫폼과 함께 데이터 경제 시대에 자신의 가치를 올리고,<br/>
                        빅데이터 분석 기법을 학습하여 세상과 소통하고, 제조 ICT 분야를 선도하는<br/>
                        혁신 인재가 되기를 응원하겠습니다.
                        </p>
                    </div>
                    
                    <div className="introduce_img">
                       <img src="/lib/image/about/introduce_img.jpg" alt="인사말 이미지"/>
                    </div>

                    <div className="animation_circle_01"></div>
                    </div>
                </div>
        </React.Fragment>
    );
}

export default Introduce;