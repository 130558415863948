import React from "react";
import { RootState } from "../store";
import { useSelector, useDispatch } from "react-redux";
import {
  getDatasetList,
  getDatasetContent,
  getCategoriesList,
} from "../store/portalDatasetSlice";
import { downloadDataset } from "api/portalDataset";
import { swalAlert } from "utils/swal-utils";


export function usePortalDataset() {
  const { datasetResponse, datasetContentResponse, categoriesResponse } =
    useSelector((state: RootState) => state.portalDataset);
  const dispatch = useDispatch();

  const callGetDatasetList = (pagesearchInfo: any) => {
    dispatch(getDatasetList(pagesearchInfo));
  };

  const callGetDatasetContent = (cseq: any) => {
    dispatch(getDatasetContent(cseq));
  };

  const callGetCategoriesList = () => {
    dispatch(getCategoriesList());
  };

  const handleDownloadDataset = (dsseq: number | string) => {
    const result = downloadDataset(dsseq);
    console.log(result);

    result.then((res) => {
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        const fileName = res.headers['content-disposition'].split("filename=")[1];

        link.href = url;
        link.setAttribute("download", fileName);
        link.style.cssText = "display: none";
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    }).catch((error) => {
      console.log(">>> handleDownloadDataset catch");
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        console.log(reader.result);
        const result: any = JSON.parse(String(reader.result));
        //alert(result.message);
        swalAlert(result.message);
      });
      reader.readAsText(error.response.data);
    })
  };

  return {
    datasetResponse,
    callGetDatasetList,
    datasetContentResponse,
    callGetDatasetContent,
    categoriesResponse,
    callGetCategoriesList,
    handleDownloadDataset,
  };
}
