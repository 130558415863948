import React, { useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";

type NoticeListTableProps = {
  total: number;
  rowData: any;
  msg: string;
  listNumber: number;
};

function DatasetListTable({
  total,
  rowData,
  msg,
  listNumber,
}: NoticeListTableProps) {
  if (total === 0) {
    return (
      <>
        <tr>
          <td colSpan={3}>{msg}</td>
        </tr>
      </>
    );
  } else {
    return (
      <>
        {rowData.map((dataset: any, idx: number) => {
          return (
            listNumber > 0 && (
              <>
                <tr key={dataset.dsseq}>
                  <td className="num">{listNumber--}</td>
                  <td className="sort">{dataset.category_name}</td>
                  <td className="txt_left">
                    {" "}
                    <Link to={`/Portal/lab/datasetView?no=${dataset.dsseq}`}>
                      {dataset.title}
                    </Link>
                  </td>
                  <td className="date">{dataset.reg_date}</td>
                </tr>
              </>
            )
          );
        })}
      </>
    );
  }
}

export default DatasetListTable;
