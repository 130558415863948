import React, { useState, useEffect } from 'react';
import { agDateColumnFilter } from "utils/common-utils";
import { useTemplate } from 'hooks/useTemplate';
import { Template } from 'api/template';

import { SelectableAgGrid } from 'components/datagrids';
import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CCreateButton } from "components/buttons";
import TemplateCreateDialog from './TemplateCreateDialog';

function TemplateList() {
    const { templateResponse, callGetTemplateList, callSetSelectedTemplate } = useTemplate();
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(true);
    const [rowData, setRowData] = useState<Template[]>([]);

    useEffect(() => {
        callGetTemplateList();
    }, [open]);

    useEffect(() => {
        if (!templateResponse.loading && !templateResponse.error && templateResponse.data) {
            setRowData(templateResponse.data);
        }
    }, [templateResponse]);

    
    const [columnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: '템플릿명',
            field: 'tp_nm',
            filter: true,
        },
        {
            headerName: 'VM 환경',
            headerClass: 'vm inviornment',
            children: [
                {
                    field: 'network_nm',
                    headerName: '네트워크명',
                },
                {
                    field: 'image_nm',
                    headerName: '이미지',
                },
            ],
        },
        {
            headerName: '관리자 ID',
            field: 'id',
            filter: true,
        },
        {
            headerName: '생성일시',
            field: 'reg_date',
            filter: false,
        },
    ]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onTemplateSelected = (data: any) => {
        callSetSelectedTemplate(data[0]);
    };

    return (
        <PanelBox>
            <CommActionBar isSearch={true}>
                <CCreateButton onClick={handleOpen}>
                    템플릿 등록
                </CCreateButton>
                <TemplateCreateDialog
                    open={open}
                    onClose={handleClose}
                />
            </CommActionBar>
            <div className="grid-height_10">
                <SelectableAgGrid 
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={onTemplateSelected}
                    showPagination={true}
                />
            </div>
        </PanelBox>
    );
}

export default TemplateList;