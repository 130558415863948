import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import { openSwal } from "utils/swal-utils";
import { useLecture } from 'hooks/useLecture';
import { formatDate, formatTime } from 'utils/dateUtils';

type LectureEditDialogProps = {
    open: boolean;
    onClose: () => void;
};

function LectureDeleteDialog({
    open,
    onClose
}: LectureEditDialogProps) {
    const { detailResponse, selectedLecture, callDeleteLecture } = useLecture();

    const handleClose = () => {
        onClose && onClose();
    };

    if (detailResponse.loading && detailResponse.data) {
        // 로딩중
    }
    if (detailResponse.error) {
        // 에러
    }
    if (!detailResponse.data) {
        return null;
    }

    if (selectedLecture === null) {
        return (
            <React.Fragment />
        );
    }

    const handleDeleteAlert = () => {
        openSwal("삭제하시겠습니까?", () => {
            callDeleteLecture(selectedLecture.sseq);
        });
    };

    return (
        <CDialog
            id="myEditDialog"
            open={open}
            maxWidth="sm"
            title={`강의 삭제`}
            onClose={handleClose}
            onDelete={handleDeleteAlert}
            modules={['delete']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>강의명</th>
                    <td>
                        <span className="td-txt">{selectedLecture.sbj_nm}</span>
                    </td>
                </tr>
                <tr>
                    <th>환경선택</th>
                    <td>
                        <span className="td-txt">{detailResponse.data.tp_nm}</span>
                    </td>
                </tr>
                <tr>
                <th>강의기간</th>
                    <td>
                        <span className="td-txt">{formatDate(selectedLecture.start_day)}</span>
                        <span className="mdd">~</span>
                        <span className="td-txt">{formatDate(selectedLecture.end_day)}</span>
                    </td>
                </tr>
                <tr>
                    <th>강의시간</th>
                    <td>
                        <span className="td-txt">
                            {selectedLecture.lecture_time}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>강의차수</th>
                    <td>
                        <span className="td-txt">
                            {selectedLecture.sbj_seq}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>개설인원</th>
                    <td>
                        <span className="td-txt">
                            {selectedLecture.capacity}
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default LectureDeleteDialog;
