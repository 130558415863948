import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule, GridApi } from "@ag-grid-community/all-modules";

import DateRangeFilter from './DateRangeFilter';
import DateRangeFilter2 from './DateRangeFilter2';
import CustomSearchFilter from './CustomTextFilter';
import CustomSelectFilter from './CustemSelectFilter';
import GridPagination from './GridPagination';
import CustomGridPagination from './CustomGridPagination';

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import "styles/ag-custom.scss";

ModuleRegistry.register(ClientSideRowModelModule);

type SelectableAgGridProps = {
    rowData: any[];
    columnDefs: any[];
    onRowSelected: (data: any) => void;
    showPagination?: boolean;
    customPagination?: boolean;
    getNextPage?: () => void;
    getPrevPage?: () => void;
    itemPerPage?: number;
    totalCount?: number;
};

function SelectableAgGrid({ 
    rowData,
    columnDefs,
    onRowSelected,
    showPagination = false,
    customPagination = false,
    getNextPage = () => {},
    getPrevPage = () => {},
    itemPerPage = 10,
    totalCount = 0,
}: SelectableAgGridProps) {
    const [gridApi, setGridApi] = useState<GridApi>();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowPerPage, setRowPerPage] = useState<number>(10);

    useEffect(() => {
        if (customPagination) {
            setRowPerPage(itemPerPage);
        }
    }, [customPagination]);

    useEffect(() => {
        if (showPagination) {
            setRowPerPage(itemPerPage);
        }
    }, [showPagination]);

    const gridRef = useRef<HTMLDivElement>(null);

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onFirstDataRendered = (params: any) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = (params: any) => {
        const selectedNodes = params.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node: any) => node.data);
        onRowSelected(selectedData);
    };

    const onGridSizeChanged = (params: any) => {
        //const gridWidth = document.getElementById('my-grid').offsetWidth;
        if (!gridRef.current) {
            return;
        }
        const gridWidth = gridRef.current.offsetWidth;
        const columnsToShow = [];
        const columnsToHide = [];
        let totalColsWidth = 0;
        const allColumns = params.columnApi.getAllColumns();
        for (let i = 0; i < allColumns.length; i++) {
            const column = allColumns[i];
            totalColsWidth += column.getMinWidth();
            if (totalColsWidth > gridWidth) {
                columnsToHide.push(column.colId);
            } else {
                columnsToShow.push(column.colId);
            }
        }
        params.columnApi.setColumnsVisible(columnsToShow, true);
        params.columnApi.setColumnsVisible(columnsToHide, false);
        params.api.sizeColumnsToFit();
    };

    const onClickPrev = () => {
        if (gridApi) {
            gridApi.paginationGoToPreviousPage();
        }
    };

    const onClickNext = () => {
        if (gridApi) {
            gridApi.paginationGoToNextPage();
        }
    };

    const onChangeRowPerPage = (value: number) => {
        if (gridApi) {
            setRowPerPage(value);
            gridApi.paginationSetPageSize(value);
        }
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        autoHeight: true,
    }

    return (
        <div id="my-grid" className="grid-wrapper ag-theme-alpine" ref={gridRef}>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                applyColumnDefOrder={true}
                pagination={true}
                paginationPageSize={rowPerPage}
                cacheBlockSize={10}
                suppressPaginationPanel={true}
                onRowClicked={onRowClicked}
                rowSelection="single"
                frameworkComponents={{
                    //lineBreakRenderer: LineBreakRenderer,
                    dateRangeFilter: DateRangeFilter,
                    dateRangeFilter2: DateRangeFilter2,
                    customSearchFilter: CustomSearchFilter,
                    customSelectFilter: CustomSelectFilter,
                }}
            />
            {
                showPagination
                ? <GridPagination 
                    totalCount={rowData.length}
                    itemPerPage={rowPerPage}
                    onClickPrev={onClickPrev}
                    onClickNext={onClickNext}
                    onChangeItemPerPage={onChangeRowPerPage}
                />
                : <div></div>
            }
            {
                customPagination
                ? <CustomGridPagination 
                    totalCount={totalCount}
                    itemPerPage={rowPerPage}
                    onClickPrev={getPrevPage}
                    onClickNext={getNextPage}
                    onChangeItemPerPage={onChangeRowPerPage}
                />
                : <div></div>
            }
        </div>
    );
}

export { SelectableAgGrid };