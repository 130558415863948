import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { getTemplateList, setSeletedTemplate } from 'store/templateSlice';
import { Template, deleteTemplate } from 'api/template';
import { swal } from 'utils/swal-utils';

export function useTemplate() {
    const { templateResponse, selectedTemplate, networkList, imageList, flavorList } = useSelector((state: RootState) => state.template);
    const dispatch = useDispatch();

    const callGetTemplateList = () => {
        dispatch(getTemplateList());
    };

    const callSetSelectedTemplate = (template: Template | null) => {
        dispatch(setSeletedTemplate(template));
    };

    const callDeleteTemplate = async (tseq: number) => {
        try {
            const result = await deleteTemplate(tseq);
            swal(result.message);
            callGetTemplateList();
            callSetSelectedTemplate(null);
        } catch (error) {
            swal(error.message);
        }
    }

    return { 
        templateResponse, 
        selectedTemplate,
        networkList,
        imageList,
        flavorList,
        callGetTemplateList, 
        callSetSelectedTemplate,
        callDeleteTemplate
    };
};
