import React from 'react';
import { Route, Switch, Link, RouteComponentProps } from 'react-router-dom';

function ContestList({match}:RouteComponentProps) {
    return (
        <React.Fragment>
              <div className="layout_inner">
                <h3 className="board_tit">공모전 &middot; 경진대회 검색</h3>
                <div className="search_box">
                <ul>
                    <li style={{width: '100%'}}>
                    <input type="text" className="contest_input_01" placeholder="검색어를 입력하세요."/>
                    </li>
                    <li>
                    <button type="button" className="contest_button">검색</button>
                    </li>
                </ul>
                </div>

                <h3 className="board_tit">공모전 &middot; 경진대회 목록</h3>

                <div className="contest_list">
                <ul>
                    <li>
                    <img src="/lib/image/contest/contest_img_01.png" alt=""/>
                    <Link to={`${match.url}/contest_view`}>
                    <button type="button" className="btn_contest_view_result">결과 보기</button>
                    </Link>
                    </li>

                    <li>
                    <img src="/lib/image/contest/contest_img_02.png" alt=""/>
                    <Link to={`${match.url}/contest_view`}>
                    <button type="button" className="btn_contest_view">자세히 보기</button>
                    </Link>
                    </li>

                    <li>
                    <img src="/lib/image/contest/contest_img_03.png" alt=""/>
                    <button type="button" className="btn_contest_view">자세히 보기</button>
                    </li>

                    <li>
                    <img src="/lib/image/contest/contest_img_04.png" alt=""/>
                    <button type="button" className="btn_contest_view">자세히 보기</button>
                    </li>

                    <li>
                    <img src="/lib/image/contest/contest_img_05.png" alt=""/>
                    <button type="button" className="btn_contest_view">자세히 보기</button>
                    </li>
                </ul>
                </div>

                <div className="pagenation_wrap" style={{marginTop: '0'}}>
                <div className="pagenation_inner">
                    <a href="#" className="pagenation_btn first"><span>First</span></a>
                    <a href="#" className="pagenation_btn prev"><span>Prev</span></a>
                    <div className="pagenation_current">
                    <span className="current">1- 5</span>
                    <span>of 40</span>
                    </div>
                    <a href="#" className="pagenation_btn next"><span>Next</span></a>
                    <a href="#" className="pagenation_btn last"><span>Last</span></a>
                </div>
                </div>

                {/* 공모전 경진대회가 없을경우 */}

                {/* <div className="no_page">
                <img src="/lib/image/common/no_page.png" alt=""/>
                <p>공모전&middot;경진대회가 없습니다.</p>
                </div> 
                 */}
            </div>
        </React.Fragment>
    );
}

export default ContestList;