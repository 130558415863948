import React, { useState, useEffect } from 'react';
import { agDateColumnFilter } from "utils/common-utils";
import { usePracticer } from 'hooks/usePracticer';
import { Practicer } from 'api/practicer';
import { practicerStateClass } from 'utils/state';
import { serverUrl } from 'config';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CIconButton } from 'components/buttons';
import { SelectableAgGrid } from 'components/datagrids';
import SAIDACellRenderer from './SAIDACellRenderer';
import AuthorityStatusCellRenderer from './AuthorityStatusCellRenderer';

type AuthorityListProps = {
    cellRenderer: any
};

function AuthorityList({
    cellRenderer
}: AuthorityListProps) {
    const { practicerResponse, callGetPracticerList, callSetSelectedPracticer } = usePracticer();
    const [update, setUpdate] = useState(false);
    const [rowData, setRowData] = useState<Practicer[]>([]);

    useEffect(() => {
        callGetPracticerList();
    }, [update]);

    useEffect(() => {
        if (!practicerResponse.loading && practicerResponse.data) {
            setRowData(practicerResponse.data);
        }
    }, [practicerResponse])

    
    const [columnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: 'ID',
            field: 'id',
            filter: true,
        },
        {
            headerName: '이름',
            field: 'name',
            filter: true,
        },

        {
            headerName: '권한',
            field: 'role_str',
            filter: true,
        },
        {
            headerName: '학교/소속',
            field: 'col_nm',
            filter: true,
        },
        {
            headerName: '학과/부서',
            field: 'dep_nm',
            filter: true,
        },
        {
            headerName: '이메일',
            field: 'email',
            filter: true,
        },
        /*{
            headerName: '생년월일',
            field: 'birth_day',
            minWidth: 150,
            maxWidth: 200,
            filter: false,
            cellRenderer: function (params: any) {
                const birthday = params.data.birth_day;
                const result = cellRenderer.renderBirthday(birthday);
                return result;
            }
        },*/
        {
            headerName: 'vm 개수',
            field: 'vm_count',
            filter: false,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            headerName: 'SAi-Da',
            field: 'saida_stat_cd',
            filter: false,
            cellRenderer: SAIDACellRenderer
        },
        {
            headerName: '상태',
            field: 'stat_str',
            filter: true,
            minWidth: 150,
            maxWidth: 200,
            cellRenderer: AuthorityStatusCellRenderer
        },
    ]);

    const handleRowSelect = (practicer: Practicer[]) => {
        callSetSelectedPracticer(practicer[0]);
    };

    return (
        <PanelBox>
            <CommActionBar isSearch={true} style={{flexDirection: "row-reverse"}}>
                <a href={`${serverUrl}/Practicer/user-list/download-file`}>
                    <CIconButton
                        icon="download"
                        tooltip="다운로드"
                    />
                </a>
            </CommActionBar>
            <div className="grid-height_10">
                <SelectableAgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={handleRowSelect}
                    showPagination={true}
                />
            </div>
        </PanelBox>
    );
}

export default AuthorityList;