import React from 'react';
import styled from 'styled-components';

const TabPanel = styled.div`
  margin-top: -2px
`;

const CTabPanel = (props) => {
    const {
        children,
        value,
        index,
        ...other
    } = props;

    return (
        <TabPanel
            role="tabpanel"
            hidden={value !== index}
            id={`ctabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </TabPanel>
    );
};

export { CTabPanel };
