import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import ControlledAccordions from './FaqAccordions';

function FAQView({match}:RouteComponentProps) {
    return (
        <React.Fragment>
            <ControlledAccordions/>
        </React.Fragment>
    );
}

export default FAQView;