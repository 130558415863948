import React, { useState, useEffect } from 'react';
import { agDateColumnFilter } from "utils/common-utils";
import { useEnvironment } from 'hooks/useEnvironment';
import { asyncState } from 'utils/asyncUtils';
import { getSubjectState, SubjectState } from 'api/environment';
import { LectureDetail } from 'api/lecture';
import { vmStateClass, vmStateName } from 'utils/state';
import { serverUrl } from 'config';

import { SelectableAgGrid } from 'components/datagrids';
import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CCreateButton, CIconButton, CSelectButton } from "components/buttons";
import EnvironmentCreateVMDialog from "./EnvironmentCreateVMDialog";
import EnvironmentDeleteVMDialog from './EnvironmentDeleteVmDialog';
import SelectLectureDialog from "./SelectLectureDialog";
import { formatTime } from 'utils/dateUtils';
import { swal } from 'utils/swal-utils';

import EnvironmentList from './EnvironmentList';
import EnvironmentPagingList from './EnvironmentPagingList';
import AllEnvironmentList from './AllEnvironmentList';

type EnvironmentTabProps = {
    timing: string;
    listResponse: asyncState;
    fetchList: any;
    vmPurpose: string; // G이면 수업 실습용, R이면 연구용
};

function EnvironmentTab({
    timing,
    listResponse,
    fetchList,
    vmPurpose,
}: EnvironmentTabProps) {
    const { callGetSimpleLectureList, getLectureDetail, callCreateVmInstance, callSetSelectedVm } = useEnvironment();

    const [open, setOpen] = useState(false);
    //const [update] = useState(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [selectOpen, setSelectOpen] = useState<boolean>(false);
    const [sbjState, setSbjState] = useState<SubjectState | null>(null);
    /*const [selectLectureProps, setSelectLectureProps] = useState({
        lectureList: [],
        onSelect: async (sseq: number) => {
            setSelectedLecture(sseq);
            const detail = await getLectureDetail(sseq);
            const sbj_state = await getSubjectState(sseq);
            setSbjState(sbj_state);
            setLectureDetail(detail);
            return detail;
        },
        onConfirm: () => {
            setOpen(true);
        }
    });*/
    const [selectedLecture, setSelectedLecture] = useState<number>();
    const [lectureDetail, setLectureDetail] = useState<LectureDetail | null>(null);

    /*const handleLectureSelectOpen = async () => {
        try {
            setSelectedLecture(-1);
            const lectureList = await callGetSimpleLectureList();
            console.log(lectureList);
            setSelectLectureProps((prev: any) => ({
                ...prev,
                lectureList: lectureList
            }));
            setSelectOpen(true);
        } catch (error) {
            swal("강의 목록을 가져오지 못하였습니다.");
        }
    }

    const handleLectureSelectClose = () => {
        setSelectOpen(false);
    }*/

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteOpen = () => {
        setOpenDelete(true);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    /*const handleRowSelection = (instance: any) => {
        callSetSelectedVm(instance[0]);
    };*/

    //const handleVmCreation = (vmCont: number, sseq: number, tseq: number, dseq: number) => {
    const handleVmCreation = (vmCont: number, usgType: string) => {
        //createVmInstance(vmCont, sseq, tseq);
        //callCreateVmInstance(vmCont, sseq, tseq, dseq);
        callCreateVmInstance(vmCont, usgType);
    };

    return (
        <PanelBox>
            <CommActionBar isSearch={false}>
                {
                    (timing !== 'ALL') &&
                    <CCreateButton onClick={handleOpen}>
                        VM생성
                    </CCreateButton>
                }
                {
                    (timing !== 'ALL') &&
                    <a href={`${serverUrl}/Environment/user-list/downalod-file/${(timing === 'PRACTICE') ? 'G' : 'R'}`}>
                        <CIconButton
                            icon="download"
                            tooltip="다운로드"
                            style={{position: "absolute", right: "8px", top: "23px", transform: "translateY(-10px)"}}
                        />
                    </a>
                }
                {/*
                    (timing === 'EXPECT' || timing === 'ALL' ) &&
                    //<CCreateButton onClick={handleLectureSelectOpen}>
                    <CCreateButton onClick={handleOpen}>
                        VM생성
                    </CCreateButton>
                */}
                {/*<CCreateButton onClick={handleDeleteOpen}>
                    VM일괄 삭제
                </CCreateButton>*/}
                {/*<SelectLectureDialog 
                    open={selectOpen}
                    onClose={handleLectureSelectClose}
                    lectureList={selectLectureProps.lectureList}
                    onSelect={selectLectureProps.onSelect}
                    onConfirm={selectLectureProps.onConfirm}
                />*/}
                <EnvironmentCreateVMDialog
                    open={open}
                    onClose={handleClose}
                    //lectureDetail={lectureDetail}
                    //lectureState={sbjState}
                    onConfirm={handleVmCreation}
                    subjectType={vmPurpose}
                />
                <EnvironmentDeleteVMDialog 
                    open={openDelete}
                    onClose={handleDeleteClose}
                />
            </CommActionBar>
            {
                (timing === 'ALL')
                ? <AllEnvironmentList listResponse={listResponse} fetchList={fetchList} />
                : <EnvironmentPagingList listResponse={listResponse} fetchList={fetchList} />
            }
            
            {/*<div className="grid-height_10">
                <SelectableAgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={handleRowSelection}
                    showPagination={true}
                />
            </div>*/}
        </PanelBox>
    );
}

export default EnvironmentTab;