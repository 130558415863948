import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import { CButton, CIconButton } from "components/buttons";
import theme from "styles/theme";
import { swalConfirm } from "utils/swal-utils";
import { LectureDetail } from 'api/lecture';
import { useSwal } from 'hooks/useSwal';
import { SubjectState, getSubjectState } from 'api/environment';

const EnvCont = styled.div`
  padding: 30px;
  .titArea {
    text-align: center;
    font-size: 14px;
    .tit {
      font-size: 32px;
      font-weight: 300;
      line-height: 1.5em;
      margin-bottom: 10px
    }
  }
  .vmArea {
    display: flex;
    justify-content: space-between;
    width: 390px;
    margin: 25px auto 10px;
    padding: 30px 5px 0;
    border-top: 1px dotted #dbe5ee;
    .btn_common, .btn_icon {
      height: 43px;
      min-width: 43px;
      & + button {
        margin-left: 2px
      }
    }
    .value {
      display: flex;
      align-items: center;
      font-size: 28px;
      color: #afb8c4;
      font-weight: 300;
      .current, .total {
        padding: 0 8px;
        font-weight: 700;
        font-size: 34px;
      }
      .current {
        color: ${theme.colors.primaryDark};
      }
      .total {
        color: ${theme.colors.defaultDark};
      }
    }
  }
`;

type EnvironmentCreateVMDialogProps = {
  open: boolean;
  onClose: () => void;
  //lectureDetail: LectureDetail | null;
  //lectureState: SubjectState | null;
  //onConfirm: (vmCount: number, sseq: number, tseq: number, dseq: number) => void;
  onConfirm: (vmCount: number, usgType: string) => void;
  subjectType: string;
};

function EnvironmentCreateVMDialog({
  open,
  onClose,
  //lectureDetail,
  //lectureState,
  onConfirm,
  subjectType
}: EnvironmentCreateVMDialogProps) {
    const { openSwal } = useSwal();

    const [count, setCount] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);

    /*useEffect(() => {
      if (lectureState !== null) {
        setCount(lectureState.signUpUserCnt);
      }
    }, [lectureState]);*/

    useEffect(() => {
      getSubjectState(subjectType).then((res) => {
        setTotalCount(res.signUpNotActiveCnt);
        setCount(0);
      })
      .catch((error) => {
        setTotalCount(0);
        setCount(0);
      });
      
      return () => {
        setTotalCount(0);
        setCount(0);
      }
    }, [open]);

    const handleClose = () => {
        onClose && onClose();
    };
    const handleCreateAlert = () => {
        //swalConfirm("생성하시겠습니까?");
        /*if (lectureDetail !== null)
          openSwal({ text: "생성하시겠습니까?", onClickConfirm: () => {
            onConfirm(count, lectureDetail.sseq, lectureDetail.tseq, lectureDetail.subject_days[0].dseq);
            handleClose();
          } });*/
        openSwal({ text: "생성하시겠습니까?", onClickConfirm: () => {
          onConfirm(count, subjectType);
          handleClose();
        } });
    }

    const onClickPlus = () => {
      setCount((prev: number) => {
        /*if (lectureState !== null) {
          if (prev < lectureState.signUpUserCnt) {
            return (prev + 1);
          }
        }
        return prev;*/
        if (prev < totalCount) {
          return (prev + 1);
        }
        else {
          return prev;
        }
      });
    };

    const onClickMinus = () => {
      setCount((prev: number) => {
        if (prev > 1) {
          return (prev - 1);
        }
        else {
          return prev;
        }
      })
    };

    const onClickEvery = () => {
      /*if (lectureState !== null) {
        setCount(lectureState.signUpUserCnt);
      }*/
      setCount(totalCount);
    }

    /*if (lectureDetail === null) {
      return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="sm"
            title={`VM 생성`}
            onClose={handleClose}
            onCreate={handleCreateAlert}
            modules={['create']}
        ></CDialog>
      );
    }*/

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="sm"
            title={`VM 생성`}
            onClose={handleClose}
            onCreate={handleCreateAlert}
            modules={['create']}
        >
            <EnvCont>
                <div className="titArea">
                    {/*<div className="tit">{lectureDetail.sbj_nm}의</div><div className="tit">가상환경을 생성하시겠습니까?</div>*/}
                    <div className="tit">가상환경을 생성하시겠습니까?</div>
                    <div>생성하실 VM의 갯수를 정해주세요.</div>
                </div>
                <div className="vmArea">
                    <CButton type="btn3" onClick={onClickEvery}>
                        모두 선택
                    </CButton>
                    <div className="value">
                        <span className="current">{count}</span>
                        /
                        {/*<span className="total">{lectureState?.signUpUserCnt}</span>*/}
                        <span className="total">{totalCount}</span>
                    </div>
                    <div>
                        <CIconButton
                            type="outlined"
                            icon="plus_l"
                            onClick={onClickPlus}
                        />
                        <CIconButton
                            type="outlined"
                            icon="minus_l"
                            onClick={onClickMinus}
                        />
                    </div>
                </div>
            </EnvCont>
        </CDialog>
    );
}
export default EnvironmentCreateVMDialog;
