import React, {useState} from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';


function Business({match}:RouteComponentProps) {
    return (
        <React.Fragment>
            <div className="layout_inner">
                <div className="cont_common_text-group">
                    <h3 className="common_tit">센터 소개</h3>
                    <div className="cont_common_text-group_block intro">
                        <p className="cont_common_text-group_tit">목적</p>
                        <ul>
                        <li>경남형 빅데이터 &#38; 플랫폼 비즈니스 기반을 마련하여 다양한 분야에 빅데이터 공급체계를 구축함으로써 지역 내 산업혁신 및 지역 혁신 토대 마련</li>
                        <li>지역 내 대학들에게 수도권 대학과의 디지털 인프라 격차를 일부 해소할 수 있는 기반 구축으로 지역 내 대학들의 연구역량 확보</li>
                        <br/>
                        </ul>

                        <p className="cont_common_text-group_tit">필요성</p>
                        <ul>
                            <li>정부는 D.N.A (Data, Network, AI)를 기반으로 다양한 신산업 육성을 위한 사업들을 추진하고 있으며, 경남에서도 전 분야에서 관련 사업을
                                추진하고 있으나 이를 체계적으로 조정하고 관리할 수 있는 컨트롤 센터 부재</li>
                            <li>경남 빅데이터 공유 플랫폼 구축을 통하여 지역 내 수집이 가능한 모든 데이터를 수집, 정제하여 보관한 후에 필요한 전 영역에 관련 빅데이터
                                를 공급하는 시스템 구축 필요</li>
                            <li>제조업 이외에도 전반적으로 AI·Big Data기술을 결합하여, 스마트시티, 대민서비스, 지역 및 사회 문제 해결 등을 추진하고, 이를 기반으로
                                다양한 스타트업 기업들이 출현하고 있기 때문에 이를 위한 지원 및 육성 체계 마련 필요</li>
                        </ul>
                    </div>
                </div>
                <div className="cont_common_text-group">
                    <h3 className="common_tit">플랫폼 소개</h3>
                    <div className="cont_common_text-group_block">
                        <ul>
                           <li>경남 빅데이터 공유 플랫폼은 클라우드 가상 실습 환경을 지원합니다.</li>
                           <li>클라우드 기반 빅데이터를 수집·분석할 수 있는 학습 환경을 제공하고, 제공된 가상 실습 환경에서 학생들이 즉시 수업이 가능합니다.</li>
                        </ul>
                    </div>
                    <div className="image_box">
                        <img src="/lib/image/about/business_img_01.png" alt="플랫폼 소개 이미지"/>
                    </div>
                </div>

                <div className="cont_common_text-group">
                    <h3 className="common_tit">플랫폼 활용</h3>
                    <div className="cont_common_text-group_block">
                        <ul>
                        <li>다양한 분야에 빅데이터 공급체계를 구축하고 확보된 데이터를 활용하여 지속 가능한 데이터 공급체계 구축</li>
                        <li>디지털 인프라 기반 구축으로 지역 내 대학들의 연구역량 확보</li>
                        <li>제조 산업 분야와 연관된 데이터를 수집하여 산업군 내 기초 분석 자료로 활용</li>
                        <li>지역 중소기업에게도 제공할 수 있는 데이터를 공급하여 기술 개발 기반 구축</li>
                        </ul>
                    </div>
                    <div className="image_box">
                        <img src="/lib/image/about/business_img_02.png" alt="플랫폼 활용 이미지"/>
                    </div>
                </div>
                <div className="cont_common_text-group">
                    <h3 className="common_tit">플랫폼 구성</h3>
                    <div className="cont_common_text-group_block"></div>
                    <div className="image_box">
                        <img src="/lib/image/about/business_img_03.png" alt="플랫폼 구성 이미지 #1"/>
                    </div>
                    <div className="image_box mt40">
                        <img src="/lib/image/about/business_img_04.png" alt="플랫폼 구성 이미지 #2"/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Business;