import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { setSelectedVm } from "store/environmentSlice";
import { getSubjetDetail, getTodaySubjectList } from "api/lecture";
import { VmInstance, getConsoleUrl, getJupyterInfo, deleteInstance, getBatchDeleteList, sendActionToVm, createIndividualVmInstance } from "api/environment";
import { ApiResponse } from "api/axiosUtils";
import { swal } from "utils/swal-utils";

export function useEnvironment() {
    const { expectedResponse, ongoingResponse, completedResponse, wholeResponse, practiecPurposeResponse, researchPurposeResponse, createVmResponse, selectedVm, vmActionResponse } =  useSelector((state: RootState) => (state.environment));
    const dispatch = useDispatch();

    const callGetExpectedEnvironmentList = () => {
        dispatch({
            type: 'environment/getExpectedList',
            payload: 'EXPECT'
        });
    };

    const callGetOngoingEnvironmentList = () => {
        dispatch({
            type: 'environment/getOngoingList',
            payload: 'ING'
        });
    };

    const callGetCompletedEnvironmentList = () => {
        dispatch({
            type: 'environment/getCompletedList',
            payload: 'END'
        });
    };

    const callGetWholeEnvironmentList = (page: number, category?: string, keyword?: string) => {
        dispatch({
            type: 'environment/getWholeList',
            payload: {
                page: page,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
    };

    const callPracticePurposeList = (page: number, category?: string, keyword?: string) => {
        dispatch({
            type: 'environment/getPracticePurposeList',
            payload: {
                timing: 'G',
                page: page,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
    };

    const callResearchPurposeList = (page: number, category?: string, keyword?: string) => {
        dispatch({
            type: 'environment/getResearchPurposeList',
            payload: {
                timing: 'R',
                page: page,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
    };

    const callSuPracticePurposeList = (page: number, category?: string, keyword?: string) => {
        dispatch({
            type: 'environment/getSuPracticePurposeList',
            payload: {
                timing: 'G',
                page: page,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
    };

    const callSuResearchPurposeList = (page: number, category?: string, keyword?: string) => {
        dispatch({
            type: 'environment/getSuResearchPurposeList',
            payload: {
                timing: 'R',
                page: page,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
    };

    const callGetSimpleLectureList = () => {
        try {
            const result = getTodaySubjectList();
            console.log(result);
            return result;
        } catch (error) {
            return [];
        }
    };

    const getLectureDetail = (lectureSeq: number) => {
        try {
            const result = getSubjetDetail(lectureSeq);
            return result;
        } catch (error) {
            return null;
        }
    };

    //const callCreateVmInstance = (vmCount: number, sseq: number, tseq: number, dseq: number) => {
    const callCreateVmInstance = (vmCount: number, usgType: string) => {
        dispatch({
            type: 'environment/createVmInstance',
            payload: {
                cnt: vmCount,
                usg_type: usgType
                //sseq: sseq,
                //tseq: tseq,
                //dseq: dseq,
            }
        })
    };

    const callSetSelectedVm = (instance: VmInstance) => {
        dispatch(setSelectedVm(instance));
    };

    const callVmActionRequest = async (instanceId: string, action: string) => {
        /*dispatch({
            type: 'environment/requestVmAction',
            payload: {
                id: instanceId,
                action: action
            }
        });*/
        try {
            if (!instanceId) {
                return swal('인스턴스가 없습니다.');
            }
            const result: ApiResponse = await sendActionToVm({id: instanceId, action: action});
            if (result.success) {
                if (action === 'START') {
                    swal('가상환경 실행 요청 완료');
                }
                else if (action === 'STOP') {
                    swal('가상환경 중지 요청 완료');
                }
                else {
                    swal(result.message);
                }
            }
            else {
                swal('가상환경 변경 요청에 실패하였습니다.');
            }
        } catch (error: any) {
            if (error.success) {
                swal(error.message);
            }
            else {
                swal(error.message);
            }
        }
    }

    const callGetConsoleUrl = async (nseq: number) => {
        try {
            const result: ApiResponse = await getConsoleUrl(nseq);
            console.log(result);
            if (result.success) {
                //swal(result.data);
                window.open(result.data);
            }
            else {
                swal(result.message);
            }
        } catch (error: any) {
            if (error.success) {
                swal(error.data);
            }
            else {
                //swal(error.message);
                swal("정보를 가져오지 못했습니다.");
            }
        }
    };

    const callGetJupyterInfo = async (nseq: number) => {
        try {
            const result: ApiResponse = await getJupyterInfo(nseq);
            if (result.success) {
                //swal(result.data);
                const popUrl = 'http://' + result.data.addr;
                //const popOption = 'top=100, left=300, width=735, height=435, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=yes';
                window.open(popUrl);
            }
            else {
                swal(result.message);
            }
        } catch (error: any) {
            if (error.success) {
                swal(error.data);
            }
            else {
                //swal(error.message);
                swal("정보를 가져오지 못했습니다.");
            }
        }
    };

    const callDeleteInstance = async (id: string) => {
        try {
            if (!id) {
                return swal('인스턴스가 없습니다.');
            }
            const result: ApiResponse = await deleteInstance(id);
            if (result.success) {
                swal(result.message);
            }
            else {
                swal(result.message);
            }
        } catch (error: any) {
            if (error.success) {
                swal(error.message);
            }
            else {
                swal(error.message);
            }
        }
    };

    const callGetBatchDeleteList = async () => {
        try {
            const result = await getBatchDeleteList();
            return result;
        } catch (error) {
            return [];
        }
    };

    const callCreateIndividualVm = async (nseq: number) => {
        try {
            const result = await createIndividualVmInstance(nseq);
            if (result.success) {
                swal(result.message);
            }
            else {
                swal(result.message);
            }
        } catch (error: any) {
            if (error.success) {
                swal(error.data);
            }
            else {
                //swal(error.message);
                swal("생성 요청에 실패하였습니다.");
            }
        }
    };

    // functions for su-admin user
    const callSuAdminCompleteList = () => {
        try {
            //const result = await 
        } catch (error) {

        }
    };

    return {
        expectedResponse,
        ongoingResponse,
        completedResponse,
        wholeResponse,
        createVmResponse,
        selectedVm,
        vmActionResponse,
        practiecPurposeResponse,
        researchPurposeResponse,
        callGetExpectedEnvironmentList,
        callGetOngoingEnvironmentList,
        callGetCompletedEnvironmentList,
        callGetWholeEnvironmentList,
        callGetSimpleLectureList,
        getLectureDetail,
        callCreateVmInstance,
        callSetSelectedVm,
        callVmActionRequest,
        callGetConsoleUrl,
        callGetJupyterInfo,
        callDeleteInstance,
        callGetBatchDeleteList,
        callPracticePurposeList,
        callResearchPurposeList,
        callSuPracticePurposeList,
        callSuResearchPurposeList,
        callCreateIndividualVm,
    };
}