import React, { useState, useEffect } from 'react';
import { useAdmin } from 'hooks/useAdmin';
import { serverUrl } from 'config';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CIconButton } from 'components/buttons';
import { SelectableAgGrid } from 'components/datagrids'
import { practicerStateClass } from 'utils/state';

function AuthorityList() {
    const { callGetAdminList, adminResponse, callSetSelectedAdmin } = useAdmin();
    const [update] = useState(false);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        callGetAdminList();
    }, [update]);

    useEffect(() => {
        if (!adminResponse.loading && adminResponse.data) {
            setRowData(adminResponse.data);
        }
    }, [adminResponse]);

    const [columnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: 'ID',
            field: 'id',
            filter: true,
        },
        {
            headerName: '이름',
            field: 'name',
            filter: true,
        },
        {
            headerName: '권한',
            field: 'role_str',
            filter: true,
        },
        {
            headerName: '학교/소속',
            field: 'col_nm',
            filter: true,
        },
        {
            headerName: '학과/부서',
            field: 'dep_nm',
            filter: true,
        },
        {
            headerName: '이메일',
            field: 'email',
            filter: true,
        },
        {
            headerName: '상태',
            field: 'stat_str',
            filter: true,
            minWidth: 150,
            maxWidth: 200,
            cellRenderer: function (params: any) {
                switch(params.data.stat_cd) {
                    case ('0'):
                        return `<span class="state_ico ${practicerStateClass.APPROVE_REQUEST}">${params.data.stat_str}</span>`;
                    case ('1'):
                        return `<span class="state_ico ${practicerStateClass.APPROVED}">${params.data.stat_str}</span>`;
                    case ('2'):
                        return `<span class="state_ico ${practicerStateClass.DENIED}">${params.data.stat_str}</span>`;
                    case ('3'):
                        return `<span class="state_ico ${practicerStateClass.STOP}">${params.data.stat_str}</span>`;
                    case ('4'):
                        return `<span class="state_ico ${practicerStateClass.WITHDRAW_REQUEST}">${params.data.stat_str}</span>`;
                    case ('5'):
                        return `<span class="state_ico ${practicerStateClass.WITHDRAW}">${params.data.stat_str}</span>`;
                    default: 
                        return `<span>${params.data.stat_str}</span>`;
                }
            }
            /*cellRenderer: function (params: any) {
                switch(params.data.stat_cd) {
                    case (0):
                        return `<span class="state_ico state_02">등록 요청</span>`;
                    case (1):
                        return `<span class="state_ico state_02">승인 및 사용중</span>`;
                    case (2):
                        return `<span class="state_ico state_02">승인 거부</span>`;
                    case (3):
                        return `<span class="state_ico state_02">실습 정지</span>`;
                    case (4):
                        return `<span class="state_ico state_03">탈퇴 요청</span>`;
                    case (5):
                        return `<span class="state_ico state_04">탈퇴</span>`;
                    default: 
                        return `<span>${params.data.stat_str}</span>`;
                }
            }*/
        },
    ]);

    const handleRowSelect = (admin: any) => {
        callSetSelectedAdmin(admin[0]);
    };

    return (
        <PanelBox>
            <CommActionBar isSearch={true} style={{flexDirection: "row-reverse"}}>
                <a href={`${serverUrl}/Admin/admin-list/download-file`}>
                        <CIconButton
                            icon="download"
                            tooltip="다운로드"
                        />
                </a>
            </CommActionBar>
            <div className="grid-height_10">
                <SelectableAgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={handleRowSelect}
                    showPagination={true}
                />
            </div>
        </PanelBox>
    );
}

export default AuthorityList;