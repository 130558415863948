import React, { useState, useEffect } from 'react';
import { useDashboard } from 'hooks/useDashboard';
import { PanelBox } from "components/styles/PanelBox";
import { AgGrid } from "components/datagrids";
import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { numberWithCommas } from 'utils/common-utils';


type DashboardUserchartProps = {
  Vms : any,
  userState : any;
}


const DashboardUserchart = ({Vms, userState}:DashboardUserchartProps) => {
   const classes = useStyles();

    return(
        <PanelBox className="panel_dashboard">
        <div className="panelTitBar">
            <div className="tit">현황</div>
            {/* <div className="date">{dateInfo}</div> */}
        </div>
        <div className="panelCont">
            <DashboardCont>
                <DashboardCircle>
                    <div className="value">
                        <span className="current">{numberWithCommas(Vms.used)}</span>
                        <span className="slash">/</span>
                        <span className="total">{numberWithCommas(Vms.total)}</span>
                    </div>
                    <div className="label">VM 사용 현황</div>
                </DashboardCircle>
                <DashboardList>
                <li className="vm_01">
                        <span className="label">총 VM</span>
                        <span className="value">{numberWithCommas(Vms.total)}개</span>
                    </li>
                    <li className="vm_01">
                        <span className="label">할당한 VM</span>
                        <span className="value">{numberWithCommas(Vms.used)}개</span>
                    </li>
                    <li className="vm_03">
                        <span className="label">할당 가능한 VM</span>
                        <span className="value ">{numberWithCommas(Vms.total-Vms.used)}개</span>
                    </li>
                </DashboardList>
                <Updown/>
                <DashboardIcon>
                  {
                    (userState.waitRegister || userState.waitSubjectSignUp !== 0)
                      ? <NotificationsActiveIcon className={classes.activeNotice}/>
                      : <NotificationsIcon className={classes.Notice}/>
                  }
               </DashboardIcon>
                <DashboardList>
                    <li className="vm_01">
                        <span className="label">가입 신청 대기 중</span>
                        <span className="value">{numberWithCommas(userState.waitRegister)}명</span>
                    </li>
                    <li className="vm_01">
                        <span className="label">실습실 이용 대기 중</span>
                        <span className="value">{numberWithCommas(userState.waitSubjectSignUp)}명</span>
                    </li>
                </DashboardList>
            </DashboardCont>
        </div>
    </PanelBox>
    )
}

export default DashboardUserchart;

const useStyles = makeStyles({
  activeNotice: {
    fontSize: '180px',
    color: '#FFC658',
  },
  Notice: {
    fontSize: '180px',
    color: '#e6e8e6',
  },
});

const DashboardCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
`;

const DashboardIcon = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: 180px;
height: 180px;
color: #fff;
font-size:'';
`

const DashboardCircle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #0088ff;
  color: #fff;
  &::before {
    content: '';
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
  }
  .value {
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 300;
    .current, .total {
      font-size: 40px;
      font-weight: bold;
      color: #fff;
    }
    .slash::after {
      border-color: #84c2ff
    }
  }
  .label {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 300
  }
`;

const DashboardList = styled.ul`
  flex-grow: 1;
  margin: 20px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 45px;
    padding: 10px 10px 10px 20px;
    &:first-child ~ li {
      border-top: 1px dotted #c5cad0
    }
    .label {
      position: relative;
      padding-left: 12px;
      color: #071e3f;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #071e3f;
        transform: translateY(-50%);
      }
    }
    .value {
      font-size: 22px;
      font-weight: bold
    }
  }
`;

const Updown = styled.div`
border: 1px solid #e2e4e7;
height:  200px;
margin-left: 30px;
margin-right: 30px;
`