import React from 'react';
import CustomPagination from '../../components/pagination/customPagination'

type datasetPaginationProps = {
    totalCount: number;
    itemPerPage: number;
    onChangePage: (page: number) => void;
};

function datasetPagination({
    totalCount,
    itemPerPage,
    onChangePage
}: datasetPaginationProps) {
    return (
        <CustomPagination totalCount={totalCount} itemPerPage={itemPerPage} onChangePage={onChangePage} />
    );
}

export default datasetPagination;