import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Location from '../components/pageLocation/location';
import ContestList from '../pages/Contest/contestList'
import ContestView from '../pages/Contest/contestView'

function ContestRouter({ match }) {
    return (
        <React.Fragment>
            <Location location="공모전·경진대회" />
            <section id="contetns">
                <div className="layout_inner">
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <ContestList match={match} />} />
                        <Route path={`${match.url}/contest`} render={() => <ContestList match={match}  />} />
                        <Route path={`${match.url}/contest_view`} render={() => <ContestView match={match}  />} />
                    </Switch>
                </div>
            </section>
        </React.Fragment>   
    );
}

export default ContestRouter;
