import React, { useState, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';

export function useDataset() {
    const { datasetResponse, categoryResponse } = useSelector((state: RootState) => (state.dataset));
    const dispatch = useDispatch();

    const [datasetList, setDatasetList] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState<any>(null);
    const [createDialogOpen, setCreateDialogOpen] = useState<Boolean>(false);
    const [editDialogOpen, setEditDialogOpen] = useState<Boolean>(false);

    useEffect(() => {
        dispatch({
            type: 'dataset/getDatasetList'
        });
    }, []);

    useEffect(() => {
        if (!datasetResponse.loading && datasetResponse.data) {
            console.log(datasetResponse.data);
            setDatasetList(datasetResponse.data);
        }
    }, [datasetResponse]);

    useEffect(() => {
        if (!categoryResponse.loading && categoryResponse.data) {

        }
    }, [categoryResponse]);

    const handleCreateDialogOpen = () => {
        setCreateDialogOpen(true);
    }

    const handleCreateDialogClose = () => {
        setCreateDialogOpen(false);
    };

    const handleEditDialogOpen = () => {
        if (selectedDataset) {
            dispatch({
                type: 'dataset/getDatasetDetail',
                payload: selectedDataset[0].dsseq 
            });
            setEditDialogOpen(true);
        }
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const handleDatasetSelect = (dataset: any) => {
        setSelectedDataset(dataset);
        dispatch({
            type: 'dataset/getDatasetDetail',
            payload: dataset[0].dsseq 
        });
    };

    return {
        datasetList,
        createDialogOpen,
        handleCreateDialogOpen,
        handleCreateDialogClose,
        editDialogOpen,
        handleEditDialogOpen,
        handleEditDialogClose,
        handleDatasetSelect,
    }
}