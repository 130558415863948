import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { userGuideUrl } from 'config';
import $ from 'jquery';
window.jQuery = $;

const menuHideStyle = {
    opacity: '0',
    visibility: 'hidden',
    'transition-duration': '0.5s',
};

const menuShowStyle = {
    opacity: '1',
    visibility: 'visible',
    'transition-duration': '0.5s',
};

function AllMenu({ onClickNavMenu, showMenu, showMypage }) {
    const [menuStyle, setMenuStyle] = useState(menuHideStyle)

    useEffect(() => {
        /*$('.header_gnb_list').mouseenter(function(){
            $('.header_gnb_list > li > ul').addClass('on');
            $('.header_gnb').stop().animate({height: "230px"}).addClass('line').addClass('on');
        })
        
        
        $('.header_gnb').mouseleave(function(){
            $('.header_gnb_list > li > ul').removeClass('on');
            $('.header_gnb').stop().animate({height: "60px"}, function (){
                $('.header_gnb').removeClass('line').removeClass('on');
            })
        })*/

        /*$('.header_gnb_list').hover(function(){
            $('.all_menu_wrap').stop().animate();
        })

        $('.header_gnb').mouseleave(function(){
            
        })*/

        /*$('.btn_all_menu').click(function (){
            $('.all_menu_wrap').fadeIn();
        })
        
        $('.btn_all_close').click(function (){
            $('.all_menu_wrap').fadeOut();
        })*/
        if (showMenu) {
            //$('.all_menu_wrap').fadeIn();
            setMenuStyle(menuShowStyle);
        }
        else {
            //$('.all_menu_wrap').fadeOut();
            setMenuStyle(menuHideStyle);
        }
    }, [showMenu]);

    return (
        <div class="all_menu_wrap" style={menuStyle}>
            <div class="all_menu">
                <div class="layout_inner">
                    <ul class="all_menu_list">
                        <li onClick={onClickNavMenu}>
                            <Link to="/Portal/about"><span>센터 소개</span></Link>
                            <ul class="depth2">
                                <li><Link to="/Portal/about/introduce">인사말</Link></li>
                                <li><Link to="/Portal/about/business">플랫폼 소개</Link></li>
                                <li><Link to="/Portal/about/organization">조직도</Link></li>
                            </ul>
                        </li>
                        <li onClick={onClickNavMenu}>
                            <Link to="/Portal/lab"><span>가상 실습실</span></Link>
                            <ul class="depth2">
                                <li><Link to="/Portal/lab/dataset">데이터셋</Link></li>
                                <li><Link to="/Portal/lab/datasetlist">데이터셋 목록</Link></li>
                                { 
                                showMypage && 
                                    <>
                                    <li><Link to="/Portal/lab/reservationlist">예약 신청 현황</Link></li>
                                    <li><Link to="/Portal/lab/reservationWrite">예약 신청서 작성</Link></li>
                                    </> 
                                }
                            </ul>
                        </li>
                        <li onClick={onClickNavMenu}>
                            <Link to="/Portal/equipment"><span>연구 &middot; 실습 장비 공유</span></Link>
                            <ul class="depth2">
                                <li><Link to="/Portal/equipment/usg">USG</Link></li>
                                <li><Link to="/Portal/equipment/gntp">경남 테크노파크</Link></li>
                                <li><Link to="/Portal/equipment/gn_local">경남 지역</Link></li>
                            </ul>
                        </li>
                        {/* <li onClick={onClickNavMenu}>
                            <Link to="/Portal/contest"><span>공모전 &middot; 경진대회</span></Link>
                            <ul class="depth2">
                                <li><Link to="/Portal/contest" style={{marginRight: 0}}>공모전 &middot; 경진대회 안내</Link></li>
                            </ul>
                        </li> */}
                        <li onClick={onClickNavMenu}>
                            <Link to="/Portal/community"><span>커뮤니티</span></Link>
                            <ul class="depth2">
                                <li><Link to="/Portal/community/notice">공지사항</Link></li>
                                {
                                     showMypage && 
                                     <li onClick={()=>{window.open(`${userGuideUrl}`,"_blank")}}><a>사용자 가이드</a></li>
                                }
                                {/* <li><Link to="/Portal/community/faq">FAQ</Link></li> */}
                            </ul>
                        </li>
                        {
                            showMypage &&
                            <li onClick={onClickNavMenu}>
                                <Link to="/Portal/mypage"><span>마이페이지</span></Link>
                                <ul class="depth2">
                                    <li><Link to="/Portal/mypage/user_info">내 정보</Link></li>
                                    <li><Link to="/Portal/mypage/change_password">비밀번호 변경</Link></li>
                                    <li><Link to="/Portal/mypage/withdraw">회원탈퇴</Link></li>
                                    {/* <li><Link to="/Portal/mypage/contest_state">공모전 신청현황</Link></li> */}
                                </ul>
                            </li>
                        }
                    </ul>
                    {/*<button type="button" class="btn_all_close">ALL MENU</button>*/}
                </div>
            </div>
        </div>
    );
}

export default AllMenu;