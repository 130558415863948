import React, { useState, useEffect } from 'react';
import { Route, Switch, Link, useLocation} from 'react-router-dom';
import $ from 'jquery';

import Location from '../components/pageLocation/location';
import USG from '../pages/Equipment/usg';
import GNTP from '../pages/Equipment/gntp';
import GnLocal from '../pages/Equipment/gnLocal';


function EquipmentRouter({ match }) {
    const location = useLocation();
    
    const [locationText, setLocationText] = useState("USG");

    // use jquery functions
    useEffect(() => {
        $('.js_tab > *').on('click',function(){
            addClassEvent(this);
        });
    }, []);

    useEffect(() => {
        const path = location.pathname;
        if(path === '/Portal/equipment/gn_local'){
            let val = $('.js_tab')[0].children[2]
            addClassEvent(val);
            onClickTab('경남 지역');
        }else if(path === '/Portal/equipment/gntp'){
            let val = $('.js_tab')[0].children[1]
            addClassEvent(val);
            onClickTab('경남 테크노파크');
        }else{
            let val = $('.js_tab')[0].children[0]
            addClassEvent(val);
            onClickTab('USG');
        }
      }, [location]);

    const addClassEvent = (val) => {
        $(val).addClass('on');
        $(val).siblings().removeClass('on');
    }

    const onClickTab = (text) => {
        setLocationText(text);
    };

    return (
        <React.Fragment>
            <Location location={locationText} rootLocation="연구·실습 장비 공유" />
            <section id="contetns">
                <div className="layout_inner">
                    <div className="top_tab js_tab">
                        <Link to={`${match.url}/usg`} className="on" onClick={() => onClickTab("USG")}><span>USG</span></Link>
                        <Link to={`${match.url}/gntp`} onClick={() => onClickTab("경남 테크노파크")}><span>경남 테크노파크</span></Link>
                        <Link to={`${match.url}/gn_local`} onClick={() => onClickTab("경남 지역")}><span>경남 지역</span></Link>
                    </div>
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <USG match={match} />} />
                        <Route path={`${match.url}/usg`} render={() => <USG match={match} />} />
                        <Route path={`${match.url}/gntp`} render={() => <GNTP match={match} />} />
                        <Route exact path={`${match.url}/gn_local`} render={() => <GnLocal match={match} />} />
                    </Switch>
                </div>
            </section>
        </React.Fragment>
    );
}

export default EquipmentRouter;