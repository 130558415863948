import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LectureDayCreateInput, LectureDayModifyInput } from 'api/lecture';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { CTextField } from 'components/textfields';
import { CIconButton, CButton } from 'components/buttons';

import PlusIcon from 'images/btn/ico_plus_l.png';

const DayAddBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
`;

export type lectureDay = {
    index: number;
    //week: string;
    //start_time: string;
    //end_time: string;
    day: LectureDayCreateInput | LectureDayModifyInput;
}

type LectureTimeInputProps = {
    dayList: lectureDay[];
    onAddDay: () => void;
    onDeleteDay: (index: number) => void;
    onUpdateDay: (newDay: lectureDay) => void;
};

function LectureTimeInput({
    dayList,
    onAddDay,
    onDeleteDay,
    onUpdateDay
}: LectureTimeInputProps) {
    const handleUpdateDay = (newDay: lectureDay) => {
        onUpdateDay(newDay);
    };
    return (
        <>
            {
                dayList.map((lectureDay: lectureDay) => {
                    const {index, day} = lectureDay;
                    return (
                        <InputForm index={index} week={day.week} startTime={day.start_time} endTime={day.end_time} onDelete={onDeleteDay} onUpdate={onUpdateDay} key={index} />
                    );
                }) 
            }
            <DayAddBtnWrap>
                <CIconButton icon="plus_l" type="outlined" onClick={onAddDay} />
                {/*<CButton type="btn2">
                    <span
                        className="ico"
                        style={{
                            //backgroundImage: `url(images/btn/ico_${icon}.png)`,
                            backgroundImage: `url(${PlusIcon})`,
                        }}
                    />
                    </CButton>*/}
            </DayAddBtnWrap>
        </>
    );
}

type InputFormProps = {
    index: number;
    week: string;
    startTime: string;
    endTime: string;
    onDelete: (index: number) => void;
    onUpdate: (newDay: lectureDay) => void;
};

function InputForm({
    index,
    week,
    startTime,
    endTime,
    onDelete,
    onUpdate,
}: InputFormProps) {
    const [inputValue, setInputValue] = useState<LectureDayCreateInput>({
        week: week,
        start_time: startTime,
        end_time: endTime,
    });

    useEffect(() => {
        //handleUpdate();
        onUpdate({
            index: index,
            day: {
                week: inputValue.week,
                start_time: inputValue.start_time.replace(":", ""),
                end_time: inputValue.end_time.replace(":", "")
            }
        });
    }, [inputValue]);

    const handleUpdate = () => {
        console.log('handleUpdate');
        
    };

    const handleChange= (event: any) => {
        const key = event.target.name;
        const value = event.target.value;

        console.log(`${key} : ${value}`);
        setInputValue((prev: LectureDayCreateInput) => ({
            ...prev,
            //[key]: value.replace(":", "")
            [key]: value
        }));
    };

    return (
        <div className="inputWrap_dividers">
            <div className="inputArea">
                <FormControl className="">
                    <select name="week" value={inputValue.week} onChange={handleChange}>
                        <option value="">요일</option>
                        <option value="MON">월</option>
                        <option value="TUE">화</option>
                        <option value="WED">수</option>
                        <option value="THU">목</option>
                        <option value="FRI">금</option>
                        <option value="SAT">토</option>
                        <option value="SUN">일</option>
                    </select>
                </FormControl>
            </div>
            <div className="inputArea">
                <FormControl className="">
                    <CTextField type="time" name="start_time" value={inputValue.start_time} onChange={handleChange} />
                </FormControl>
            </div>
            <span className="mdd">~</span>
            <div className="inputArea">
                <FormControl className="">
                    <CTextField type="time" name="end_time" value={inputValue.end_time} onChange={handleChange} />
                </FormControl>
            </div>
            <CIconButton icon="minus_l" type="outlined" onClick={() => onDelete(index)}  />
        </div>
    );
}

export default LectureTimeInput;