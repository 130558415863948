import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { asyncState, createAsyncReducer, createAsyncSaga, reducerUtils } from 'utils/asyncUtils';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { getTraineeList, Practicer, getUserActiveLogList, ActiveLog, getUserActiveLogDetail, updateSaidaUseStatus, updateInitUserPasswd } from 'api/practicer';

type practicerState = {
    practicerResponse: asyncState;
    selectedPracticer: Practicer | null;
    activeLogResponse: asyncState;
    logDetailResponse: asyncState;
    saidaUseUpdateResponse: asyncState;
};

const initialState: practicerState = {
    practicerResponse: reducerUtils.initial(),
    selectedPracticer: null,
    activeLogResponse: reducerUtils.initial(),
    logDetailResponse: reducerUtils.initial(),
    saidaUseUpdateResponse: reducerUtils.initial(),
};

const practicerSlice = createSlice({
    name: 'practicer',
    initialState: initialState,
    reducers: {
        setSelectedPracticer(state, action: PayloadAction<Practicer | null>) {
            state.selectedPracticer = action.payload;
        },
    },
    extraReducers: {
        ...createAsyncReducer('practicer/getPracticerList', 'practicerResponse'),
        ...createAsyncReducer('practicer/getActiveLogList', 'activeLogResponse'),
        ...createAsyncReducer('practicer/getActiveLogDetail', 'logDetailResponse'),
        ...createAsyncReducer('practicer/updateSaidaUseStatus', 'saidaUseUpdateResponse'),
        ...createAsyncReducer('practicer/updateInitUserPasswd', 'updateInitUserPasswdResponse'),
    }
});

export const {
    setSelectedPracticer
} = practicerSlice.actions;
export default practicerSlice.reducer;

/* saga */

const getPracticerListSaga = createAsyncSaga<Practicer[]>('practicer/getPracticerList', getTraineeList);
const getActiveLogListSaga = createAsyncSaga<ActiveLog[]>('practicer/getActiveLogList', getUserActiveLogList);
const getActiveLogDetailSaga = createAsyncSaga('practicer/getActiveLogDetail', getUserActiveLogDetail);
const updateSaidaUseStatusSaga = createAsyncSaga('practicer/updateSaidaUseStatus', updateSaidaUseStatus);
const updateInitUserPasswdSaga = createAsyncSaga('practicer/updateInitUserPasswd', updateInitUserPasswd);


export function* practicerSaga() {
    yield takeLatest('practicer/getPracticerList', getPracticerListSaga);
    yield takeLatest('practicer/getActiveLogList', getActiveLogListSaga);
    yield takeLatest('practicer/getActiveLogDetail', getActiveLogDetailSaga);
    yield takeLatest('practicer/updateSaidaUseStatus', updateSaidaUseStatusSaga);
    yield takeLatest('practicer/updateInitUserPasswd', updateInitUserPasswdSaga);
}