/* axios utils */
import axios from 'axios';

export type ApiResponse = {
    success: boolean,
    message: string,
    data?: any
};

export const handleError = (error: any) => {
    console.log(error);
    if (error.response) {
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        console.log("error.response");
        if (error.response.status < 500)  {
            console.log("error status < 500");
            console.log(error.toJSON());
            console.log(error.response);
            throw new Error (error.response.data.message);
        }
        else if (error.response.status === 401) {
            // authorization 오류 (토큰 만료....?)
            console.log("error status 401");
            // 토큰 만료 시 로그아웃
            localStorage.removeItem('access-token');
        }
        else {
            console.log("error status > 500");
            throw new Error("요청이 실패하였습니다.");
        }
    }
    else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log("error.request");
        throw new Error("요청이 실패하였습니다.");
    }
    else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        console.log("else");
        throw new Error("요청이 실패하였습니다.");
    }
};

// request interceptor
axios.interceptors.request.use(
    function (config) {
    // set authorization
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');
    return config;
    }
);

export default axios;
// response interceptor
//axios.interceptors.response.use(function (res))