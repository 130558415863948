import React, { useState, useEffect } from 'react';
import { openSwal } from "utils/swal-utils";
import { useLecture } from 'hooks/useLecture';
import { deleteSubject } from 'api/lecture';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import LectureEditDialog from './LectureEditDialog';
import LectureDeleteDialog from './LectureDeleteDialog';
import { CIconButton } from "components/buttons";

function LectureDetail() {
    const { selectedLecture, detailResponse, editDialogOpen, callOpenEditDialog, callCloseEditDialog, callDeleteLecture } = useLecture();
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    if (selectedLecture === null) {
        return (
            <PanelBox>

            </PanelBox>
        );
    }

    const handleEdit = () => {
        //setEditOpen(true);
        callOpenEditDialog();
    }
    const handleEditClose = () => {
        //setEditOpen(false);
        callCloseEditDialog();
    }

    const handleDelete = () => {
        /*openSwal("삭제하시겠습니까?", () => {
            callDeleteLecture(selectedLecture.sseq);
        });*/
        setDeleteOpen(true);
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    if (!detailResponse.loading && detailResponse.data && !detailResponse.error) {
        return (
            <PanelBox className="panel_detail">
                <div className="panelTitBar">
                    <div className="tit"><strong>{selectedLecture.sbj_nm}</strong>의 상세보기</div>
                </div>
                <CommActionBar>
                    <div className="iconBtnGrope">
                        <CIconButton
                            icon="edit"
                            tooltip="수정"
                            onClick={handleEdit}
                        />
                        <CIconButton
                            icon="delete2"
                            tooltip="삭제"
                            onClick={handleDelete}
                        />
                    </div>
                    <LectureEditDialog
                        open={editDialogOpen}
                        onClose={handleEditClose}
                    />
                    <LectureDeleteDialog
                        open={deleteOpen}
                        onClose={handleDeleteClose}
                    />
                </CommActionBar>
                <div className="panelCont">
                    <LectureState state={selectedLecture.stat_cd_subject} text={selectedLecture.stat_cd_str_subject} />
                    <table className="tb_data">
                        <tbody>
                        <tr>
                            <th>강의명</th>
                            <td>{selectedLecture.sbj_nm}</td>
                            <th>담당자</th>
                            <td>{selectedLecture.name}</td>
                        </tr>
                        <tr>
                            <th>학교</th>
                            <td>{selectedLecture.col_nm}</td>
                            <th>학과</th>
                            <td>{selectedLecture.dep_nm}</td>
                        </tr>
                        <tr>
                            <th>강의 기간</th>
                            <td>{selectedLecture.lecture_day}</td>
                            <th>강의 시간</th>
                            <td>{selectedLecture.lecture_time}</td>
                        </tr>
                        <tr>
                            <th>강의 차수</th>
                            <td>{selectedLecture.sbj_seq}차</td>
                            <th>개설인원</th>
                            <td>{selectedLecture.capacity}명</td>
                        </tr>
                        <tr>
                            <th>환경</th>
                            <td>{detailResponse.data.tp_nm}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </PanelBox>
        );
    }

    return (
        <PanelBox></PanelBox>
    );
}

type LectureStateProps = {
    state: number;
    text: string;
};

function LectureState({
    state,
    text
}: LectureStateProps) {
    //if (state === "강의 중") {
    if (state === 1) {
        return (
            <div className="stateBar state_02">
                <span className="state_ico">{text}</span>
            </div>
        );
    }
    else if (state === 0) {
        return (
            <div className="stateBar state_01">
                <span className="state_ico">{text}</span>
            </div>
        );
    }
    else if (state === 2) {
        return (
            <div className="stateBar state_03">
                <span className="state_ico">{text}</span>
            </div>
        );
    }
    else if (state === 3) {
        return (
            <div className="stateBar state_04">
                <span className="state_ico">{text}</span>
            </div>
        );
    }
    else {
        return (
            <div className="stateBar">
                <span className="state_ico">상태 없음</span>
            </div>
        );
    }
}

export default LectureDetail;