import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useApproveVm } from 'hooks/useApproveVm';
import { openSwal } from 'utils/swal-utils';

import Loading from 'components/spinner/Loading';

import HeaderImg from 'images/portal/main/main_visual.png';
import HeaderTextImg from 'images/portal/main/main_visual_txt.png';
import CheckIconImg from 'images/portal/mypage/check_ico_finish.png';
import AdminImg from 'images/portal/mypage/join_ico_admin.png';
import TraineeImg from 'images/portal/mypage/join_ico_trainee.png';

const ContentArea = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f6f8fa;
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ContentWrap = styled.div`
    width: 900px;
    height: 100%;
    border-radius: 10px;
    background-color: #ffffff;

    header {
        background-image: url(${HeaderImg});
        background-size: cover;
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 300px;
        margin-bottom: 15px;
        #email-visual-text {
            width: 400px;
            position: relative;
            margin: 30px;
        }
    }

    .email_join_finish {
        padding: 80px 100px 80px 315px;
        border-top: 1px solid #3a4147;
        background: url(${CheckIconImg}) no-repeat 100px center;
    }

    #vm_approve_content {
        width: 100%;
        height: calc(100% - 300px - 83px);
    }
`;

const LoginForm = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login_input {
        margin: 10px 0px;

        input {
            margin-bottom: 5px;
        }
    }

    button {
        margin: 10px 0px;
    }
`;

type MailProps = {
    info: string;
};

function VmApprove({ match }: RouteComponentProps<MailProps>) {
    const { info } = match.params;
    const data = queryString.parse(info);
    const { loginInput, onChangeInput } = useApproveVm();

    useEffect(() => {
        
    }, []);

    const onClickLogin = () => {

        openSwal('가상환경 삭제를 승인 하시겠습니까?', () => {
            console.log('api 호출');
        })
    };

    return (
        <ContentArea>
            <ContentWrap>
                <header>
                    <img src={HeaderTextImg} id="email-visual-text" alt="visual text" />
                </header>
                <div className="join_wrap" id="vm_approve_content">
                    <div className="join_tit">
                        <img src={AdminImg} alt="admin" /> 관리자 인증
                    </div>
                    <LoginForm>
                        <div>
                            <div className="login_input">
                                <input type="text" name="id" className="input_style_01" placeholder="User ID" value={loginInput.id} onChange={onChangeInput} />
                                <input type="password" name="password" className="input_style_01" placeholder="Password" value={loginInput.pwd} onChange={onChangeInput} />
                            </div>
                            <button type="button" className="login_btn" onClick={onClickLogin}>LOGIN</button>
                        </div>
                    </LoginForm>
                </div>
            </ContentWrap>
        </ContentArea>
    );
}

export default VmApprove;