import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import Location from '../components/pageLocation/location';
import LoginChoose from '../pages/Login/loginChoose';
import TraineeLogin from '../pages/Login/traineeLogin';
import AdminLogin from '../pages/Login/adminLogin';

function LoginRouter({ match, history }: RouteComponentProps) {
    return (
        <React.Fragment>
            <Location location="로그인" />
            <section id="contetns">
                <div className="layout_inner">
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <LoginChoose match={match} />} />
                        <Route path={`${match.url}/trainee_login`} render={() => <TraineeLogin match={match} history={history} />} />
                        <Route path={`${match.url}/admin_login`} render={() => <AdminLogin match={match} history={history} />} />
                    </Switch>
                </div>
            </section>
        </React.Fragment>   
    );
}

export default LoginRouter;