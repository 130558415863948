import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { swal } from "utils/swal-utils";

type ApiResponse = {
    success: boolean;
    data: any;
    message: string;
};

const downloadAxios: AxiosInstance = axios.create();

downloadAxios.interceptors.response.use(function (response: AxiosResponse<ApiResponse>) {
    // status가 2xx의 범위일 때
    return response;
}, function (error) {
    // status가 2xx 이외의 범위일 때
    //swal("요청이 실패하였습니다.");
    //throw new Error("요청이 실패하였습니다.");
    //return error;
    console.log(">>> fileDownloadAxiosUtils : caught error");
    console.log(error.toJSON());
    //throw new Error(error);
    return Promise.reject(error);
});

// request interceptor
downloadAxios.interceptors.request.use(
    function (config) {
    // set authorization
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');
    return config;
    }
);

export default downloadAxios;