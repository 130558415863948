import React, { useState, useEffect } from 'react';
import { Route, Switch, Link, useLocation } from 'react-router-dom';
import $ from 'jquery';

import Location from '../components/pageLocation/location';
import Introduce from '../pages/About/introduce';
import Organization from '../pages/About/organization';
import Business from '../pages/About/business';

function InfoRouter({ match }) {
    const location = useLocation();
    
    const [locationText, setLocationText] = useState('인사말');

    // use jquery functions
    useEffect(() => {
        $('.js_tab > *').on('click',function(){
            addClassEvent(this);
        });
    }, []);

    useEffect(() => {
        const path = location.pathname;
        if(path === '/Portal/about/organization'){
            let val = $('.js_tab')[0].children[2]
            addClassEvent(val);
            onClickTab('조직도');
        }else if(path === '/Portal/about/business'){
            let val = $('.js_tab')[0].children[1]
            addClassEvent(val);
            onClickTab('플랫폼 소개');
        }else{
            let val = $('.js_tab')[0].children[0]
            addClassEvent(val);
            onClickTab('인사말');
        }
      }, [location]);

    const addClassEvent = (val) => {
        $(val).addClass('on');
        $(val).siblings().removeClass('on');
    }

    const onClickTab = (text) => {
        setLocationText(text);
    };

    return (
        <React.Fragment>
            <Location location={locationText} rootLocation="센터 소개" />
            <section id="contetns">
                <div className="layout_inner">
                    <div className="top_tab js_tab">
                        <Link to={`${match.url}/introduce`} className="on" onClick={() => onClickTab("인사말")} value="인사말"><span>인사말</span></Link>
                        <Link to={`${match.url}/business`}   onClick={() => onClickTab("플랫폼 소개")} value="플랫폼 소개"><span>플랫폼 소개</span></Link>
                        <Link to={`${match.url}/organization`} onClick={() => onClickTab("조직도")} value="조직도"><span>조직도</span></Link>
                    </div>
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <Introduce match={match} />} />
                        <Route path={`${match.url}/introduce`} render={() => <Introduce match={match}/>}/>  
                        <Route path={`${match.url}/business`} render={() => <Business match={match}/>}/> 
                        <Route exact path={`${match.url}/organization`} render={() => <Organization match={match}/>} /> 
                    </Switch>
                </div>
            </section>
        </React.Fragment>
    );
}

export default InfoRouter;