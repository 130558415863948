import React, { useState, useEffect } from 'react';
import Layout from "layout";
import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { Title } from 'pages';
import { AgGrid, SelectableAgGrid } from 'components/datagrids'
import { agDateColumnFilter } from "utils/common-utils";
import { CCreateButton } from "components/buttons";
import { useDataset } from 'hooks/useDataset';

import DatasetCreateDialog from './DatasetCreateDialog';
import DatasetDialog from './DatasetDialog';
import DatasetEditDialog from './DatasetEditDialog';
import DatasetDetail from './DatasetDetail';

const Dataset = () => {
    const { 
        datasetList, 
        createDialogOpen, 
        handleCreateDialogOpen,
        handleCreateDialogClose,
        editDialogOpen,
        handleEditDialogOpen,
        handleEditDialogClose,
        handleDatasetSelect,
    } = useDataset();
    const currentPage = Title.Dataset;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params) => (params.node.rowIndex + 1)
        },
        {
            headerName: '카테고리',
            field: 'category_name',
            minWidth: 150,
            maxWidth: 200,
            filter: true,
        },
        {
            headerName: '제목',
            field: 'title',
            filter: true,
        },
        {
            headerName: '등록일',
            field: 'reg_date',
            filter: 'agDateColumnFilter',
            filterParams: agDateColumnFilter(),
            minWidth: 150,
            maxWidth: 200,
        },
    ]);

    useEffect(() => {
        setRowData(datasetList);
    }, [datasetList]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleRowSelect = (dataset) => {
        alert(dataset);
    };

    return (
        <Layout currentPage={currentPage}>
            <PanelBox>
                <CommActionBar isSearch={true}>
                    <CCreateButton  onClick={handleCreateDialogOpen}>
                        등록
                    </CCreateButton>
                    {/*<CCreateButton  onClick={handleEditDialogOpen}>
                        수정
                    </CCreateButton>
                    <CCreateButton  onClick={handleOpen}>
                        상세보기
                    </CCreateButton>
                    */}
                    <DatasetCreateDialog
                        //open={open2}
                        open={createDialogOpen}
                        //
                        onClose={handleCreateDialogClose}
                    />
                </CommActionBar>
                <div className="grid-height_10">
                    <SelectableAgGrid
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onRowSelected={handleDatasetSelect}
                        showPagination={true}
                        itemPerPage={10}
                    />
                </div>
                <DatasetDialog
                    open={open}
                    onClose={handleClose}
                />
                {/*<DatasetEditDialog
                    open={editDialogOpen}
                    onClose={handleEditDialogClose}
                    editable={editable}
                />*/}
            </PanelBox>
            <DatasetDetail editDialogOpen={editDialogOpen} handleEditDialogOpen={handleEditDialogOpen} handleEditDialogClose={handleEditDialogClose} />
        </Layout>
    );
}
export default Dataset;
