/* functions that can be used in reducers  */
import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "@redux-saga/core/effects";

export interface asyncState {
    loading: boolean;
    data: any | null;
    error: any | null;
};

// create states
export const reducerUtils = {
    initial: (initialData = null): asyncState => ({
        loading: false,
        data: initialData,
        error: null
    }),
    loading: (prevState = null): asyncState => ({
        loading: true,
        data: prevState,
        error: null
    }),
    success: (payload: any): asyncState => ({
        loading: false,
        data: payload,
        error: null
    }),
    error: (error: any): asyncState => ({
        loading: false,
        data: null,
        error: error
    }),
};

// create async reducer.
export const createAsyncReducer = (type: string, key: string) => {
    const [SUCCESS, ERROR] = [`${type}Success`, `${type}Error`];
    
    return {
        [`${type}`](state: any) {
            return ({
                ...state,
                [key]: reducerUtils.loading()
            });
        },
        [SUCCESS](state: any, action: PayloadAction) {
            return ({
                ...state,
                [key]: reducerUtils.success(action.payload)
            });
        },
        [ERROR](state: any, action: PayloadAction) {
            return ({
                ...state,
                [key]: reducerUtils.error(action.payload)
            });
        },
    }
}

// create async saga
export function createAsyncSaga<T>(type: string, apiCaller: any) {
    const [SUCCESS, ERROR] = [`${type}Success`, `${type}Error`];

    return function* (action: any) {
        try {
            const response: T = yield call(apiCaller, action.payload);
            //yield console.log(">>> async saga : " + response);
            yield put({
                type: SUCCESS,
                payload: response
            });
        } catch (error) {
            yield put({
                type: ERROR,
                payload: error
            });
        }
    }
};