import React from 'react';
import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { userLogin, userLogout } from 'store/loginUserSlice';
import { changePassword } from 'api/loginUser';
import { swal } from 'utils/swal-utils';

export function useLoginUser() {
    const { userName, userRole, loginResponse, userId, accessToken, isUserLogout, userJob, isWsConnected, changePwdResponse } = useSelector((state: RootState) => state.loginUser);
    const dispatch = useDispatch();

    const callUserLogin = (userId: string, userPwd: string, userRole: string) => {
        dispatch(userLogin({userId: userId, userPwd: userPwd, userRole: userRole}));
    };

    const callUserLogout = () => {
        dispatch(userLogout());
    };

    const checkAccessToken = (): boolean => {
        const token = localStorage.getItem('access-token');
        if (token === null) {
            return false;
        }
        else {
            return true;
        }
    };

    const callChangePassword = async (newPwd: string) => {
        /*dispatch({
            type: 'loginUser/changePassword',
            payload: newPwd
        })*/
        try {
            const result = await changePassword(newPwd);
            swal(result.message);
        } catch (error: any) {
            swal(error.message);
        }
    }

    return { userId, userName, userRole, loginResponse, accessToken, isUserLogout, isWsConnected, userJob, changePwdResponse, callUserLogin, callUserLogout, checkAccessToken, callChangePassword };
}