import React from 'react';

import { useLoginUser } from 'hooks/useLoginUser';
import { reasonForKorean } from '../../utils/commonUtil';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';


type AlertDialogProps = {
  book:any;
};

export default function AlertDialog({book}:AlertDialogProps) {
  const {userName, userId} = useLoginUser();

  const classes = useStyles();
  
  const [dopen, setDOpen] = React.useState(false);

  const handlDialogClickOpen = () => {
    setDOpen(true);
  };

  const handleClose = () => {
    setDOpen(false);
  };


  const maskingPwd = (pwd:any)=>{ 
    let originStr = pwd; 
    let maskingStr; 
    let strLength; 

    strLength = originStr.length; 
    if(strLength < 3){ 
      maskingStr = originStr.replace(/(?<=.{1})./gi, "*"); 
    }else { 
      maskingStr = originStr.replace(/(?<=.{2})./gi, "*"); } 
      return maskingStr; 
    }
  
    const pwd = maskingPwd(book.pwd);


  return (
    <div>
      <Button  className="btn_rsv btn_info" onClick={handlDialogClickOpen}>
        조회
      </Button>
      <Dialog
        open={dopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={
          classes.dialog
      }
      >
        <DialogTitle id="alert-dialog-title">예약 신청서</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <table className="tb_pop_data">
              <tbody><tr>
              <th>아이디</th>
                <td>{userId}</td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{userName}</td>
              </tr>
              <tr>
                <th>사용 기간</th>
                <td>{book.lecture_day}</td>
              </tr>
              {/* <tr>
                <th>학교, 학과</th>
                <td>{book.col_nm}, {book.dep_nm}</td>
              </tr>
              <tr>
                <th>강의명</th>
                <td>{book.sbj_nm}</td>
              </tr>
              <tr>
                <th>강의 시간</th>
                <td>{book.lecture_time}</td>
              </tr> */}
              <tr>
                <th>VM 수량</th>
                <td>{book.vm_cnt}</td>
              </tr>
              <tr>
                  <th>템플릿 이름</th>
                  <td>{book.tp_nm}</td>
              </tr>
              <tr>
                <th>비밀번호</th>
                <td>{pwd}</td>
              </tr>
              <tr>
                <th rowSpan={3}>신청사유</th>
                <td>{reasonForKorean(book.res_cd)}</td>
              </tr>
              <tr>
                <td>{book.signup_msg}</td>
              </tr>
            </tbody></table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" className="btn_summit" color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



const useStyles = makeStyles(() =>
    createStyles({
        dialog: {
            '& .MuiDialog-paper': {
                backgroundColor: '#7367f0',
                paddingTop: 0,
                '& .MuiDialogTitle-root': {
                    height: 37,
                    backgroundColor: '#7367f0',
                    margin: '0 -2px',
                },
                '& .MuiDialogActions-root.dialog-topBtn': {
                    display: 'block',
                    padding: '2px 0',
                    border: 0,
                    borderTop: '1px solid #0085eb',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    '& .btn_common': {
                        backgroundColor: '#52a5ff',
                        border: '1px solid #0085eb',
                        '&:not(:first-child)': {
                            marginLeft: 1
                        }
                    }
                }
            }
        },
        '@global': {
            '.MuiDialog-paper': {
                padding: 2,
                borderRadius: 3,
                backgroundColor: '#0090ff',
                boxShadow: 'none',
                '& .MuiDialogTitle-root': {
                    display: 'flex',
                    alignItems: 'center',
                    height: 35,
                    padding: '0 15px 2px',
                    '& .MuiTypography-h6': {
                        font: 'inherit',
                        fontSize: '13px',
                        color: '#fff',
                        fontWeight: '300'
                    }
                },
                '& .MuiDialogContent-root': {
                    padding: 14,
                    border: '1px solid #0085eb',
                    borderBottom: '0',
                    backgroundColor: '#fff'
                },
                '& .MuiDialogActions-root': {
                    padding: '11px 10px 10px',
                    border: '1px solid #0085eb',
                    borderTop: '0',
                    boxShadow: 'inset 0 1px 0 #ebecef',
                    backgroundColor: '#f5f6f9',
                    justifyContent: 'center',
                },
                '& .dialog-topBtn': {
                    display: 'none',
                }
            }
        },
    }),
);
