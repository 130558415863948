import React, { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import * as Common from '../../utils/common.js';
import { useLoginUser } from 'hooks/useLoginUser';

//import { HomeDispatchContext, SET_LOGIN_USER_TYPE, SET_LOGIN_USER_NAME } from 'main/store/context';

const menuHideStyle = {
    opacity: '0',
    visibility: 'hidden',
    'transition-duration': '0.5s',
};

const menuShowStyle = {
    opacity: '1',
    visibility: 'visible',
    'transition-duration': '0.5s',
};

function DropDownMenu({ menuList, open, onCloseMenu }) {
    //const {dispatch} = useContext(HomeDispatchContext);
    const { callUserLogout } = useLoginUser();
    const [menuStyle, setMenuStyle] = useState(menuHideStyle);

    useEffect(() => {
        if (open) {
            setMenuStyle(menuShowStyle);
        }
        else {
            setMenuStyle(menuHideStyle);
        }
    }, [open]);

    const onClickLogout = () => {
        //Common.logOut();
        //dispatch({ type: SET_LOGIN_USER_TYPE, userType: "" });
        //dispatch({ type: SET_LOGIN_USER_NAME, userName: "" });
        callUserLogout();
        setMenuStyle(menuHideStyle);
        onCloseMenu();
    };

    const onClickMenu = () => {
        setMenuStyle(menuHideStyle);
        onCloseMenu();
    };

    return (
        <div className="sidebar_user_menu" style={menuStyle}>
            <ul>
                {
                    menuList.map((menu, i) => (<li onClick={onClickMenu} key={i}><Link to={menu.link}>{menu.name}</Link></li>))
                }
            </ul>
            <button type="button" onClick={onClickLogout}>
                <Link to="/Portal/main">Logout</Link>
            </button>
        </div>
    );
}

export default memo(DropDownMenu);