import React, { useState, useEffect, useCallback } from 'react';
import { agDateColumnFilter } from "utils/common-utils";
import { useLecture } from 'hooks/useLecture';
import { dayInKorean, formatDate, formatTime } from 'utils/dateUtils';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CCreateButton } from "components/buttons";
import LectureCreateDialog from './LectureCreateDialog';
import { SelectableAgGrid } from 'components/datagrids';
import { Lecture, LectureDay } from 'api/lecture';

function LectureList() {
    const { lectureResponse, createDialogOpen, editDialogOpen, callGetLectureList, callSetSelectedLecture, callOpenCreateDialog, callCloseCreateDialog, callGetDetail } = useLecture();
    const [open, setOpen] = useState(createDialogOpen);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        callGetLectureList();
    }, [createDialogOpen, editDialogOpen]);

    useEffect(() => {
        if (!lectureResponse.loading && !lectureResponse.error && lectureResponse.data){
            const tmpLectureList = lectureResponse.data.map((lecture: Lecture) => {
                let tmpLecture = {...lecture};
                // 강의 기간
                const startDay: string = formatDate(lecture.start_day);
                const endDay: string = formatDate(lecture.end_day);
                const lectureDay = startDay + " ~ " + endDay;
                tmpLecture.start_day = startDay;
                tmpLecture.end_day = endDay;
                tmpLecture.lecture_day = lectureDay;

                // 강의 시간
                let lectureTime: string = "";
                lecture.dayList.forEach((day: LectureDay, index: number) => {
                    const dayString = dayInKorean(day.week);
                    const startTime = formatTime(day.start_time);
                    const endTime = formatTime(day.end_time);
                    if (index === 0) {
                        lectureTime = lectureTime + (dayString + "  " + startTime + " ~ " + endTime);
                    }
                    else {
                        lectureTime = lectureTime + " / " + (dayString + "  " + startTime + " ~ " + endTime);
                    }
                });
                tmpLecture.lecture_time = lectureTime;

                return tmpLecture;
            });
            setRowData(tmpLectureList);
        }
    }, [lectureResponse]);

    const [columnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1),
        },
        {
            headerName: '강의명',
            field: 'sbj_nm',
            filter: true,
        },
        {
            headerName: '강의 기간',
            field: 'lecture_day',
            //filter: 'agDateColumnFilter',
            filter: 'dateRangeFilter',
            filterParams: { buttons: ['clear'] },
            /*children: [
                {
                    field: 'start_day',
                    headerName: '시작',
                    filter: 'agDateColumnFilter',
                },
                {
                    field: 'end_day',
                    headerName: '종료',
                    filter: 'agDateColumnFilter',
                },
            ],*/
            //filterParams: agDateColumnFilter(),
        },
        {
            headerName: '강의 시간',
            field: 'lecture_time',
            filter: false,
        },
        {
            headerName: '학과',
            field: 'dep_nm',
            filter: true,
        },
        {
            headerName: '강의차수',
            field: 'sbj_seq',
            filter: false,
        },
        {
            headerName: '개설인원',
            field: 'capacity',
            filter: false,
            cellRenderer: function (params: any) {
                const capacity = params.data.capacity;
                const student_count = params.data.subject_useq_count;
                return (student_count + "/" + capacity);
            },
        },
        {
            headerName: '상태',
            field: 'stat_cd_str_subject',
            filter: true,
        },
    ]);

    const handleOpen = () => {
        //setOpen(true);
        callOpenCreateDialog();
    };
    const handleClose = () => {
        //setOpen(false);
        callCloseCreateDialog();
    };

    const onRowSelected = useCallback((data: any) => {
        callSetSelectedLecture(data[0]);
        callGetDetail(data[0].sseq);
    }, [callSetSelectedLecture]);

    return (
        <PanelBox>
            <CommActionBar isSearch={true}>
                <CCreateButton onClick={handleOpen}>
                    강의 등록
                </CCreateButton>
                <LectureCreateDialog
                    open={createDialogOpen}
                    onClose={handleClose}
                />
            </CommActionBar>
            <div className="grid-height_10">
                <SelectableAgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={onRowSelected}
                    showPagination={true}
                />
            </div>
        </PanelBox>
    );
}

export default LectureList;