import axios from 'axios';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type StackList = {
    maxServerGroupMembers : number,
    maxServerGroups : number,
    totalVolumeGigabytesUsed : number,
    maxTotalVolumeGigabytes : number,
    totalFloatingIpsUsed : number,
    maxTotalFloatingIps : number,
    totalSecurityGroupsUsed : number,
    maxSecurityGroups : number,
    totalVolumesUsed : number,
    maxTotalVolumes : number,
    totalKeyPairsUsed : number,
    maxTotalKeypairs : number,
    securityGroupRulesUsed : number,
    maxSecurityGroupRules : number,
    totalRAMUsed : number,
    maxTotalRAMSize : number,
    totalInstancesUsed : number,
    maxTotalInstances : number,
    totalCoresUsed : number,
    maxTotalCores : number,
    personalitySizeUsed : number,
    maxPersonalitySize : number,
    imageMetaUsed : number,
    maxImageMeta : number,
    personalityUsed : number,
    maxPersonality : number,
    serverMetaUsed : number,
    maxServerMeta : number,
};

export type VMsatus = {
    running_cnt : number,
    max_cnt: number
}

export type BoardData = {
    memoryMbUsed: number,
    memoryMb: number,
    localGbUsed: number,
    localGb: number,
    vcpusUsed: number,
    vcpus: number,
    runningVms: number,
    totalVms: number,
    runningUser: number,
    totalUser: number,
    wait_registerUser: number,
    wait_subjectSignUpUser: number
}

export const getStackList = async () => {
    try {
        const requestUrl = "/admin/stack/limits"
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getVmStatus = async () => {
    try {
        const requestUrl = "/admin/stack/possible/create/vm"
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getDashboardItem = async() => {
    try {
        const requestUrl = "/admin/stack/chart";

        const res = await axios.get(requestUrl);
        console.log(res);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};