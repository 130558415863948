import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import styled from 'styled-components';
import { swalConfirm, swal } from "utils/swal-utils";
import { useTemplate } from 'hooks/useTemplate';

import FormControl from '@material-ui/core/FormControl';
import {CTextField} from "components/textfields";
import { modifyTemplate } from 'api/template';
import { coreList, gpuFlavorList, gpu_prefix } from 'config';

type TemplateEditDialogProps = {
    open: boolean;
    onClose: () => void;
}

function TemplateEditDialog ({
    open,
    onClose
}: TemplateEditDialogProps) {
    //const {open} = props;
    const { networkList, imageList, flavorList, selectedTemplate, callSetSelectedTemplate } = useTemplate();

    const [value, setValue] = useState('');
    /*const [network, setNetwork] = useState(selectedTemplate.tnseq);
    const [image, setImage] = useState(selectedTemplate.tiseq);
    const [flavor, setFlavor] = useState(selectedTemplate.flavor_id);*/
    const [name, setName] = useState<string>("");
    const [network, setNetwork] = useState<number>(0);
    const [image, setImage] = useState<number>(0);
    const [flavor, setFlavor] = useState<string>("");
    const [core, setCore] = useState<string>('');

    useEffect(() => {
        if (selectedTemplate !== null) {
            setNetwork(selectedTemplate.tnseq);
            setImage(selectedTemplate.tiseq);
            setFlavor(selectedTemplate.flavor_id);
            setName(selectedTemplate.tp_nm);
        }
    }, [selectedTemplate]);

    if (selectedTemplate === null) {
        return (
            <></>
        );
    }

    const handleClose = () => {
        //props.onClose && props.onClose();
        onClose();
    };
    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const onChangeName = (event: any) => {
        setName(event.target.value);
    };

    const onChangeNetwork = (event: any) => {
        setNetwork(event.target.value);
    };

    const onChangeImage = (event: any) => {
        setImage(event.target.value);
    };

    const onChangeFlavor = (event: any) => {
        setFlavor(event.target.value);
    };

    const onChangeCore = (event: any) => {
        setCore(event.target.value);
    };

    const handleUpdateAlert = () => {
        swalConfirm("수정하시겠습니까?").then(function(res) {
            console.log(res);
            if (res.isConfirmed) {
                modifyTemplate(network, image, flavor, selectedTemplate.tseq, name).then(function(response) {
                    swal(response.message).then(function(res) {
                        handleClose();
                        callSetSelectedTemplate(null);
                    });
                });
                
            }
        });
    }


    return (
        <CDialog
            id="myEditDialog"
            open={open}
            maxWidth="sm"
            title={`템플릿 수정`}
            onClose={handleClose}
            onUpdate={handleUpdateAlert}
            modules={['update']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>템플릿명</th>
                    <td>
                        <FormControl className="">
                            <CTextField type="text" name="tp_nm" value={selectedTemplate.tp_nm} onChange={onChangeName} />
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>네트워크</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={network} onChange={onChangeNetwork}>
                                {
                                    networkList && networkList.map((option, index) => {
                                        //if(option.network_id === selectedTemplate.network_id)
                                        if(option.tnseq === selectedTemplate.tnseq)
                                            return <option value={option.tnseq} selected={true}>{option.network_nm}</option>
                                        else
                                            return <option value={option.tnseq}>{option.network_nm}</option>
                                    })
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>이미지</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={image} onChange={onChangeImage}>
                                {
                                    imageList && imageList.map((option, index) => {
                                        //if(option.image_id === selectedTemplate.image_id)
                                        if(option.tiseq === selectedTemplate.tiseq)
                                            return <option value={option.tiseq} selected={true}>{option.image_nm}</option>
                                        else
                                            return <option value={option.tiseq}>{option.image_nm}</option>
                                    })
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>코어 타입</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={core} onChange = {onChangeCore}>
                                <option value='' selected>선택</option>
                                {
                                    coreList && coreList.map((option, index) => {
                                        return <option value={option.toLowerCase()} key={index}>{option}</option>
                                    })
                                }

                    
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>사양</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={flavor} onChange={onChangeFlavor} 
                                    disabled = {core===''}>
                                <option value='' selected>선택</option>
                               {
                                    core === 'cpu'.toLowerCase() ?  
                                    flavorList && flavorList.map((option, index) => {
                                        //gpu 아닌것만 출력한다.

                                        var optionStr = "("+(option.vcpus)+"/"+(option.ram)+"/"+(option.disk)+")"
                                        if(!option.name.startsWith(gpu_prefix)){
                                           
                                            return <option value={option.id} key={index} >{option.name + " " +optionStr}</option>
                                        }
                                        
                                    })
                                    :
                                    core === 'gpu'.toLowerCase() ? 
                                    gpuFlavorList && gpuFlavorList.map((option, index) => {
                                        //gpu 아닌것만 출력한다.

                                        var optionStr = "("+(option.vcpus)+"/"+(option.ram)+"/"+(option.disk)+")"
                                        
                                        if(!option.name.startsWith(gpu_prefix)){
                                           
                                            return <option value={option.name} key={index} >{option.name + " " +optionStr}</option>
                                        }
                                        
                                    })
                                    : <></>
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default TemplateEditDialog;
