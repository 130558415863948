import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useCommunity } from "hooks/useCommunity";
import { noticeList } from "api/community";
import NoticeListTable from "./noticeListTable";
import NoticePagination from "./noticePagination";

function NoticeList({ match }: RouteComponentProps) {
  const { noticeResponse, callGetNoticeList } = useCommunity();
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [msg, setMsg] = useState("");
  //const [showPaging, setShowPaging] = React.useState(true);

  useEffect(() => {
    if (
      !noticeResponse.loading &&
      !noticeResponse.error &&
      noticeResponse.data
    ) {
      if (noticeResponse.data.length === 0) {
        setTotal(0);
        setMsg("공지사항 내역이 없습니다.");
      } else {
        const tmpNoticeList = noticeResponse.data.map((notice: noticeList) => {
          let tmpNotice = { ...notice };
          if (tmpNotice.total !== undefined) {
            setTotal(tmpNotice.total);
          } else if (tmpNotice.total === 0) {
            setTotal(0);
            setMsg("공지사항 내역이 없습니다.");
          }
          return tmpNotice;
        });
        setRowData(tmpNoticeList);
      }
    } else if (!noticeResponse.loading || noticeResponse.error) {
      setTotal(0);
      setMsg("공지사항 내역이 없습니다.");
    }
  }, [noticeResponse]);

  const updateNoticeList = (newList?: any) => {
    let List: any = {};
    if (searchKeyword === "" && newList === undefined) {
      List = {
        pageNumber: pageNumber,
      };
      callGetNoticeList(List);
    } else {
      callGetNoticeList(newList);
    }
  };

  const listNumber = total - (pageNumber - 1) * 10;

  useEffect(() => {
    const newList = {
      pageNumber: pageNumber,
      keyword: searchKeyword,
    };

    updateNoticeList(newList);
  }, [pageNumber]);

  const handleSearchData = (e: any) => {
    const value = e.target.value;
    setSearchKeyword(value);
  };

  const getSearchData = useCallback(() => {
    let keyword = searchKeyword;
    const newList = {
      pageNumber: pageNumber,
      keyword: keyword,
    };
    if (keyword !== null && keyword !== "") {
      updateNoticeList(newList);
      //setShowPaging(false);
    } else {
      alert("검색어를 입력해주세요.");
    }
  }, [searchKeyword, callGetNoticeList]);

  const onChangeNoticePage = (page: number) => {
    setPageNumber(page);
  };

  return (
    <React.Fragment>
      <div className="layout_inner">
        <div className="board_wrap">
          <h3 className="board_tit">공지사항</h3>

          <div className="board_caption">
            <p>
              현재 총 <span>{total}</span>개의 게시물이 있습니다.
            </p>
            <div className="select_wrap">
              <input
                type="text"
                placeholder="검색어를 입력해주세요."
                className="input_table_search"
                onChange={(e) => {
                  handleSearchData(e);
                }}
              />
              <button
                type="button"
                className="btn_table_search"
                onClick={getSearchData}
              ></button>
            </div>
          </div>
          <table className="board_list">
            <NoticeListTable
              listNumber={listNumber}
              rowData={rowData}
              total={total}
              msg={msg}
            />
          </table>
          <div className="pagenation_wrap" style={{ marginTop: "0" }}>

          <div className="pagenation_inner">
                <NoticePagination
                  totalCount={total}
                  itemPerPage={10}
                  onChangePage={onChangeNoticePage}
                />
              </div>
            {/*showPaging && (
              <div className="pagenation_inner">
                <NoticePagination
                  totalCount={total}
                  itemPerPage={10}
                  onChangePage={onChangeNoticePage}
                />
              </div>
            )*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NoticeList;
