import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

function ContestView({match}:RouteComponentProps) {
    return (
        <React.Fragment>
              <div className="layout_inner">
                <div className="contest_view_wrap">
                <div className="contest_view_img">
                    <img src="/lib/image/contest/contest_view_img.png" alt=""/>
                    <button type="button"></button>
                </div>

                <div className="contest_view_summary">
                    <h3>
                    2021 전기안전 콘텐츠 공모전
                    <span>date : 2021.04.05</span>
                    <span>view : 208</span>
                    </h3>

                    <dl>
                    <dt>주최</dt>
                    <dd>경남 테크노파크</dd>

                    <dt>주관</dt>
                    <dd>경남 테크노파크</dd>

                    <dt>응모분야</dt>
                    <dd>빅데이터</dd>

                    <dt>접수방법</dt>
                    <dd>홈페이지 접수</dd>

                    <dt>참가자격</dt>
                    <dd>제한없음</dd>

                    <dt>시상종류</dt>
                    <dd>상금 / 상품</dd>

                    <dt>접수기간</dt>
                    <dd>2021.04.02 ~ 2021.06.02</dd>

                    <dt>첨부파일</dt>
                    <dd>
                        <a href="#">
                        <img src="/lib/image/common/ico_file.png" alt=""/>
                        <p>지원신청서.hwp (18.5KB)</p>
                        <img src="/lib/image/common/ico_download.png" alt=""/>
                        </a>
                    </dd>
                    </dl>
                </div>
                </div>

                <div className="contest_view_contents">
                <ul>
                    <li>
                    <div className="view_contents_tit">공모전 개요</div>
                    <div className="view_contents_txt">
                        <p className="tit">2021 전기안전 콘텐츠 공모전</p>
                        모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다.<br/>
                        공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들
                        공모전 전체 개요등이 들
                    </div>
                    </li>
                    <li>
                    <div className="view_contents_tit">지원자격</div>
                    <div className="view_contents_txt">
                        모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다.<br/>
                        공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들
                        공모전 전체 개요등이 들
                    </div>
                    </li>
                    <li>
                    <div className="view_contents_tit">시상</div>
                    <div className="view_contents_txt">
                        모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다.<br/>
                        공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들
                        공모전 전체 개요등이 들
                    </div>
                    </li>
                    <li>
                    <div className="view_contents_tit">참고사항</div>
                    <div className="view_contents_txt">
                        모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다.<br/>
                        공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들어갑니다. 공모전 전체 개요등이 들
                        공모전 전체 개요등이 들
                    </div>
                    </li>
                </ul>
                </div>

                <div className="btn_view_req_wrap">
                <button className="btn_view_req">신청하기</button>
                </div>

                <div className="board_btn_wrap">
                <button type="button">목록보기</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ContestView;