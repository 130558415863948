import React, {useEffect} from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import $ from 'jquery';

function FAQList( {match} : RouteComponentProps) {
        useEffect(() => {
            $('.qna_action').on('click', function(){
                $('.qna_open').hide();
                $(this).parents().parents().next().slideDown();
            });
        }, []);

    return (
        <React.Fragment>
              <div className="layout_inner">
                <div className="board_wrap">
                <h3 className="board_tit">FAQ</h3>
                <div className="board_caption">
                    <p>현재 총 <span>28</span>개의 게시물이 있습니다.</p>
                    <div className="select_wrap">
                    <input type="text" placeholder="검색어를 입력해주세요." className="input_table_search"/>
                    <button type="button" className="btn_table_search"></button>
                    </div>
                </div> 

                <table className="board_list board_qna">
                    <tr className="qna_fold">
                    <td className="td_num">28</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="new qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply waiting"></td>
                    <td className="td_name"></td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans" style={{fontWeight:'lighter'}}>
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">27</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="new qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply waiting"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans" style={{fontWeight:'normal'}}>
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">26</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans" style={{fontWeight:'normal'}}>
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">25</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans">
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">24</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans">
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">23</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name">  </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans">
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">22</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans" style={{fontWeight:'normal'}}>
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">21</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans">
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">20</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">
                        <div className="ans">
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr className="qna_fold">
                    <td className="td_num">19</td>
                    <td className="td_subject"><a href="javascript:void(0)" className="qna_action">빅데이터 셰어 플랫폼 VM 생성화면에 대해 문의드립니다.</a></td>
                    <td className="td_reply finish"> </td>
                    <td className="td_name"> </td>
                    <td className="td_date">2021.01.01</td>
                    </tr>

                    <tr className="qna_open">
                    <td colSpan={5} className="td_open">
                        <div className="qna_wrap">

                        <div className="ans">
                            안녕하세요. 관리자입니다.<br/>
                            빅데이터 셰어 플랫폼 VM 생성화면에 대해 답변드립니다.<br/>
                            답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용. 답변내용.
                            <div className="ans_name">관리자</div>
                            <div className="ans_date">2021.01.01</div>
                        </div>
                        </div>
                    </td>
                    </tr>
                </table>

                <div className="pagenation_wrap">
                    <div className="pagenation_inner">
                    <a href="#" className="pagenation_btn first"><span>First</span></a>
                    <a href="#" className="pagenation_btn prev"><span>Prev</span></a>
                    <div className="pagenation_current">
                        <span className="current">1- 5</span>
                        <span>of 40</span>
                    </div>
                    <a href="#" className="pagenation_btn next"><span>Next</span></a>
                    <a href="#" className="pagenation_btn last"><span>Last</span></a>
                    </div>
                </div>
                </div>
                </div>
        </React.Fragment>
    );
}

export default FAQList;