import React from "react";
import { RootState } from "../store";
import { useSelector, useDispatch } from "react-redux";
import {
  getNoticeList,
  getNoticeContent,
  getImportantNoticeList,
} from "../store/communitySlice";

export function useCommunity() {
  const { noticeResponse, noticeContentResponse, noticeImportantResponse } =
    useSelector((state: RootState) => state.community);
  const dispatch = useDispatch();

  const callGetNoticeList = (pagesearchInfo: any) => {
    dispatch(getNoticeList(pagesearchInfo));
  };

  const callGetNoticeContent = (cseq: any) => {
    dispatch(getNoticeContent(cseq));
  };

  const callGetImportantNoticeList = () => {
    dispatch(getImportantNoticeList());
  };

  return {
    noticeResponse,
    callGetNoticeList,
    noticeContentResponse,
    callGetNoticeContent,
    noticeImportantResponse,
    callGetImportantNoticeList,
  };
}
