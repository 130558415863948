import React, { useState, useEffect, useCallback } from 'react';

import { useNotice } from 'hooks/useNotice';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { SelectableAgGrid } from 'components/datagrids';
import { agDateColumnFilter } from "utils/common-utils";
import { CCreateButton } from "components/buttons";
import NoticeCreateDialog from "./NoticeCreateDialog";


const NoticeList = () => {
    const { noticeResponse, createDialogOpen, editDialogOpen, callGetNoticeDetail, callGetNoticeList, callSetSelectedNotice} = useNotice();
    const [rowData, setRowData] = useState([]);
    const [open2, setOpen2] = useState(createDialogOpen);


    useEffect(() => {
        callGetNoticeList();
    }, [createDialogOpen,editDialogOpen]);

    useEffect(() => {
        if (!noticeResponse.loading && !noticeResponse.error && noticeResponse.data){
            setRowData(noticeResponse.data);
        }
    },[noticeResponse])


    const [columnDefs] = useState([
        {
            headerName: 'NO',
            field: 'cseq',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: '제목',
            field: 'title',
            filter: true,
            minWidth: 1000,
            maxWidth: 1000
        },
        {
            headerName: '등록자',
            field: 'name',
            filter: true,
        },
        {
            headerName: '조회수',
            field: 'views',
            filter: true,
        },
        {
            headerName: '등록일',
            field: 'reg_date',
            filter: 'agDateColumnFilter',
            filterParams: agDateColumnFilter(),
        },
    ]);

    const handleCreateOpen = () => {
        setOpen2(true);    
    };
    const handleCreateClose = () => {
        setOpen2(false);
    };

    const onNoticeSelected = useCallback((data: any) => {
        callSetSelectedNotice(data[0]);
        callGetNoticeDetail(data[0].cseq);
    },[callSetSelectedNotice]);

    return (
        <PanelBox>
            <CommActionBar isSearch={true}>
                <CCreateButton onClick={handleCreateOpen}>
                    등록
                </CCreateButton>
                <NoticeCreateDialog
                    open={open2}
                    onClose={handleCreateClose}
                />
            </CommActionBar>
            <div className="grid-height_10">
                <SelectableAgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={onNoticeSelected}
                    showPagination={true}
                />
            </div>
        </PanelBox>
    );
}

export default NoticeList;