import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { asyncState, createAsyncReducer, createAsyncSaga, reducerUtils } from 'utils/asyncUtils';
import { getAlarmList, getNotConfirmedAlarmCont, VmCreatePushDetail } from 'api/alarm';
import { takeLatest } from "@redux-saga/core/effects";

type alarmState = {
    alarmResponse: asyncState;
    notConfirmedResponse: asyncState;
    vmProgress: VmCreatePushDetail | null;
};

const initialState: alarmState = {
    alarmResponse: reducerUtils.initial(),
    notConfirmedResponse: reducerUtils.initial(),
    vmProgress: null,
};

const alarmSlice = createSlice({
    name: 'alarm',
    initialState: initialState,
    reducers: {
        setVmProgress(state, action: PayloadAction<VmCreatePushDetail | null>) {
            state.vmProgress = action.payload;
        },
    },
    extraReducers: {
        ...createAsyncReducer('alarm/getAlarmList', 'alarmResponse'),
        ...createAsyncReducer('alarm/getNotConfirmedAlarmCount', 'notConfirmedResponse'),
    }
});

export const {
    setVmProgress
} = alarmSlice.actions;
export default alarmSlice.reducer;

/* saga */

const getAlarmListSaga = createAsyncSaga('alarm/getAlarmList', getAlarmList);
const getNotConfirmedAlarmCountSaga = createAsyncSaga('alarm/getNotConfirmedAlarmCount', getNotConfirmedAlarmCont);

export function* alarmSaga() {
    yield takeLatest('alarm/getAlarmList', getAlarmListSaga);
    yield takeLatest('alarm/getNotConfirmedAlarmCount', getNotConfirmedAlarmCountSaga);
}