import { Notice, NoticeCreateInput, NoticeDetail, NoticeModifyInput } from 'api/notice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { getNoticeList, setSeletedNotice, openCreateDialog, closeCreateDialog, registerNotice, getNoticeDetail, openEditDialog, openDeleteDialog, closeDeleteDialog, closeEditDialog, modifyNotice } from 'store/noticeSlice';

export function useNotice(){
    const { noticeResponse, createDialogOpen, editDialogOpen, deleteDialogOpen, registerResponse, detailNoticeResponse, modifyResponse, selectedNotice } = useSelector((state: RootState) => (state.notice));
   
    const dispatch = useDispatch();

    const callGetNoticeList = () => {
        dispatch(getNoticeList());
    };

    const callRegisterNotice = (newNotice: any) => {
        dispatch(registerNotice(newNotice));
    };

    const callGetNoticeDetail = (cseq: number) => {
        dispatch(getNoticeDetail(cseq));
    };

    const callModifyNotice = (editNotice: NoticeModifyInput) => {
        dispatch(modifyNotice(editNotice));
    };

    const callOpenCreateDialog = () => {
        dispatch(openCreateDialog());
    };

    const callCloseCreateDialog = () => {
        dispatch(closeCreateDialog());
    };

    const callOpenEditDialog = () => {
        dispatch(openEditDialog());
    };

    const callCloseEditDialog = () => {
        dispatch(closeEditDialog());
    };

    const callOpenDeleteDialog = () => {
        dispatch(openDeleteDialog());
    };

    const callCloseDeleteDialog = () => {
        dispatch(closeDeleteDialog());
    };


    const callSetSelectedNotice = (Notices: NoticeDetail|null) => {
        dispatch(setSeletedNotice(Notices));
    };

    return{
        noticeResponse,
        createDialogOpen,
        editDialogOpen,
        deleteDialogOpen,
        registerResponse,
        detailNoticeResponse,
        modifyResponse,
        registerNotice,
        getNoticeDetail,
        selectedNotice,
        callSetSelectedNotice,
        callRegisterNotice,
        callGetNoticeDetail,
        callGetNoticeList,
        callModifyNotice,
        callOpenCreateDialog,
        callCloseCreateDialog,
        callOpenEditDialog,
        callCloseEditDialog,
        callOpenDeleteDialog,
        callCloseDeleteDialog
    }
}