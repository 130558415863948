import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

function GNTP({ match }: RouteComponentProps) {


    return (
        <React.Fragment>
            <div className="layout_inner">
                <div className="equipment_tit_wrap">
                    <h3 className="equipment_tit">경남 테크노파크</h3>

                    <p>경남 테크노파크의 연구/실습 장비 목록입니다.</p>
                </div>

                <h3 className="board_tit">장비 목록</h3>

                <div className="equipment_list_wrap type_top type_bottom">
                    <img src="/lib/image/equipment/gntp_01.png" alt=""/>
                    <div className="text_wrap">
                        <h4 className="equipment_list_tit">경남 테크노파크 보유 장비</h4>
                        <p>
                            경남 테크노파크는 경남지역 산·학·연·관의 유기적인<br/>
                            협력체계를 구축하여 지역 혁신거점기관으로서 지역 산업의<br/>
                            기술 고도화와 기술집약적 기업의 혁신성장을 촉진하고<br/>
                            지역 경제 활성화와 국가 경제발전에 기여합니다.
                        </p>
                        <button className="btn_view_more" onClick={()=>{window.open('https://gnjangbi.com/eq/list')}}>보유 장비 보러가기</button>
                    </div>
                </div>

                <h3 className="board_tit">인적 자원</h3>
                <div className="equipment_human_list">
                    <ul>
                        <li>
                            <div className="equip_human_box human_box_04">
                                <h5>경남 테크노파크<br/>장비 담당자</h5>
                                <p>
                                    자동 차로봇센터, 기계소재부품센터,<br/>
                                    항공 우주센터, 조선해양센터 등의<br/>
                                    장비 담당자를 확인하실 수 있습니다.
                                </p>
                                <a href="https://gnjangbi.com/eq/manager" target="_blank">자세히 보기</a>
                            </div>
                        </li>
                    </ul>
                </div>
           </div>
        </React.Fragment>
    );
}

export default GNTP;