import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import * as Fetch from '../../utils/fetchFunction.js';
import Loading from 'components/spinner/Loading';

function CheckId({ match, history, id, name, email }) {
    const [loading, setLoading] = useState(false);

    const onClickEmailIdBtn = () => {
        setLoading(true);
        Fetch.callApi("/user/idinqury/sendmail", {email: email, name: name}, "POST")
        .then((res) => {
            console.log(res);
            setLoading(false);
            history.push(`${match.url}/send_email`);
        });
    };

    return (
        <div className="find_wrap">
            <div className="find_tit">아이디 찾기</div>
            <p className="find_tit_txt">회원가입시 등록했던 이름과 이메일을 입력하면 아이디를 찾을 수 있습니다.</p>
            <div className="find_account_wrap">
                <div className="check_account">
                    {name} 님의 아이디는 <span>{id}</span> 입니다.
                </div>
                <div className="check_btn_wrap">
                    <Link to="/Portal/login">
                        <button type="button" className="cancel_btn">로그인 페이지로 이동</button>
                    </Link>
                    <button type="button" className="confirm_btn" onClick={onClickEmailIdBtn}>
                    {loading ? <Loading/>: <span className="ico next">전체 아이디 이메일로 확인하기</span>}
                    </button>
                    {/*
                    <Link to={`${match.url}/send_email`} onClick={onClickEmailIdBtn}>
                        <button type="button" className="confirm_btn">
                            전체 아이디 이메일로 확인하기
                        </button>
                    </Link>
                    */}
                </div>
            </div>
        </div>
    );
}

export default CheckId;