/* define state className */

/* 가상 실습실 예약 상태 */
export const reservationStateClass = {
    STANDBY: 'state_02',
    APPROVE: 'state_01',
    REJECT: 'state_04',
    POSTPONE: 'state_05',
    DELETE: 'state_06',
    STOP: 'state_03 ing',
    WITHDRAW: 'state_05',
    EXTEND: 'state_02 ing',
    ETC: 'state_06 ing'
};

/* 실습자 상태 */
export const practicerStateClass = {
    APPROVE_REQUEST: 'state_02',
    APPROVED: 'state_01',
    DENIED: 'state_04',
    STOP: 'state_05',
    WITHDRAW_REQUEST: 'state_03 ing',
    WITHDRAW: 'state_06'
};

/* 가상머신 상태 */
export const vmStateClass = {
    NOT_CREATED: 'state_05 ing',
    ALLOCATED: 'state_03',
    DEPLOYING: 'state_02',
    DEPLOYED: 'state_01',
    RUNNING: 'state_01 ing',
    TERMINATED: 'state_05',
    DELETED: 'state_06',
    FORCE_REBOOTING: 'state_04',
    FORCE_TERMINATING: 'state_03 ing',
    ETC: 'state_06 ing'
};

/* 가상머신 상태명 */
export const vmStateName = {
    //NOT_CREATED: '미생성',
    NOT_CREATED: '대기', // vm 생성이 안됐는데 예약이 취소됨....?
    ALLOCATED: '할당됨',
    DEPLOYING: '배포중',
    DEPLOYED: '배포 완료',
    RUNNING: '기동중',
    TERMINATED: '종료됨',
    DELETED: '삭제됨',
    FORCE_REBOOTING: '강제 리부팅중',
    FORCE_TERMINATING: '강제 종료중',
    ETC: '대기'
};

/* 가상머신 액션 코드 */
export const vmAction = {
    EXECUTE: 'START',
    STOP: 'STOP',
    RESTART: 'RESUME',
};