import axios from 'axios';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');


export type Paging = {
    startPage: number;
    endPage: number;
    currentPage: number;
    lastPage: number;
    pageCount: number;
    rowCount: number;
    displayRow: number;
    offset: number;
}

export type SimpleLecture = {
    sseq: number;
    sbj_nm: string;
    start_day: string;
    end_day: string;
    lecture_day?:string
};

export type ColInfo = {
    col_cd: string,
    col_nm: string,
}

export type DepInfo = {
    dep_cd: string,
    dep_nm: string,
}

export type LectureDay = {
    dseq: number;
    sseq: number;
    week: string;
    start_time: string;
    end_time: string;
};

export type SignupDay = {
    dseq: number;
    end_time: string;
    sseq: number;
    start_time: string;
    week: string;
    week_int: number;
}

export type BookList = {
    dday: string;
    end_day: string;
    nseq: string;
    pwd: string;
    reason_msg?: string; 
    reg_date: any;
    res_cd: string;
    sbj_nm: string;
    signup_msg: string;
    sseq: number;
    start_day: string;
    stat_cd_subject: number,
    stat_cd_str_subject: string,
    stat_cd: number,
    stat_str: string,
    col_nm: string
    dep_nm: string
    total: number;
    useq: number;
    dayList: SignupDay[];
    instance_id: any;

    app_date?: string;
    app_useq?: number;

    // 화면에 표시할 데이터
    
    lecture_day ?: string;
    lecture_time ?: string;
    reason ?: string;
    tseq: number;
    tp_nm: string;
    subject_days: LectureDay[];
}

export type LectureDetail = {
    capacity: number;
    dayList: LectureDay[];
    col_cd: string;
    col_nm: string;
    dep_cd: string;
    dep_nm: string;
    name: string;
    useq: number;
    start_day: string;
    end_day: string;
    sseq: number;
    sbj_nm: string;
    sbj_seq: number;
    days_str: string;
    usg_type: string;

    // 화면에 표시할 데이터
    lecture_day ?: string;
    lecture_time ?: string;
    tseq: number;
    tp_nm: string;
    subject_days: LectureDay[];
    stat_cd_str?:string;
    stat_cd:number;
}

export type TemplateInstance = {
    flavor_id: string;
    id: string;
    image_id: string;
    image_nm: string;
    is_del: boolean;
    network_id: string;
    network_nm: string;
    reg_date: string;
    tiseq: number;
    tnseq: number;
    tp_nm: string;
    tseq: number;
  };


export const getDepColList = async (category: string) => {
    try {
        const requestUrl = "/user/depcol-info/" + category;

        const res = await axios.get(requestUrl);
        console.log(res);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};


export const getLectureList = async (col_cd:string, dep_cd:string) => {
    try {
        const requestUrl = "/subject/list-simple";

        const data = {
            col_cd: col_cd,
            dep_cd:dep_cd
        };

        const res = await axios.get(requestUrl,  {
            params: data
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getSubjetDetail = async (sseq: number) => {
    try{
        const requestUrl = "/subject/detail";
        const data = {
            sseq: sseq
        };

        const res = await axios.get(requestUrl, {
            params: data
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};


export const getTerminalUrl = async (nseq: number) => {
    try{
        const requestUrl = "/stack/console";
        const data = {
            nseq: nseq
        };

        const res = await axios.get(requestUrl, {
            params: data
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getJupyter = async (nseq: number) => {
    try{
        const requestUrl = "/stack/get/jupyter-info";
        const data = {
            nseq: nseq
        };

        const res = await axios.get(requestUrl, {
            params: data
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};


// export const bookSignup = async (sseq:number, stat_cd:number, res_cd:string, pwd:string, vm_cnt:number, signup_msg:string) => {
    export const bookSignup = async (res_cd:string, pwd:string, tseq:number, signup_msg:string) => {
    console.log("LapApi bookSignup");
    try {
        const bookDataObject = {
            // 'sseq': sseq, //강의코드
            // 'stat_cd' : stat_cd,
            'res_cd': res_cd,
            'pwd': pwd, 
            'tseq': tseq,
            'signup_msg': signup_msg
        }
        const requestUrl = "/lab/book/signup";
        const res = await axios.post(requestUrl, bookDataObject);
        console.log(res.data)
        console.log(res.data.message);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};


export const getBookList = async (pageNumber:number, stat_cd?:string, start_day?:string, end_day?:string) => {
    try {
        const requestUrl = "/lab/book/signup/list";
        const res = await axios.get(requestUrl, {
            params: {
                start_day : start_day,
                end_day :end_day,
                stat_cd :stat_cd,
                page: pageNumber,
            } 
        });
        const result = {
            labBookList : res.data.data.labBookListDtoList,
            pagination : res.data.data.paginationDto
        }
        return result
    } catch (error) {
        handleError(error);
    }
};

export const cancelBook = async (nseq:number) => {
    try {
        const requestUrl = "/lab/book/lab/cancel";
        const res = await axios.get(requestUrl, {
            params: {
                nseq : nseq,
            } 
        });
        console.log(res)
        return res.data
    } catch (error) {
        handleError(error);
    }
};

export const extendBook = async (nseq:number) => {
    try {
        const requestUrl = "/lab/book/extend/signup";
        const res = await axios.get(requestUrl, {
            params: {
                nseq : nseq,
            } 
        });
        console.log(res)
        return res.data
    } catch (error) {
        handleError(error);
    }
};


export const shutDown = async (nseq:number) => {
    try {
        const requestUrl = "/lab/book/lab/quit";
        const res = await axios.get(requestUrl, {
            params: {
                nseq : nseq,
            } 
        });
        console.log(res)
        return res.data
    } catch (error) {
        handleError(error);
    }
};


export const shutDownVM = async (id: string) => {
    try {
        const requestUrl = "/admin/stack/instance/remove/" + id;
        const res = await axios.get(requestUrl);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};



export const getTemplateList = async () => {
    try {
        const requestUrl = "/template/list";
        const res = await axios.get(requestUrl);
        console.log("getTemplateList API")
        console.log(res)
        return res.data.data;
    } catch (error) {
        handleError(error);
    }
};


