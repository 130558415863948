import axios from 'axios';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type Template = {
    id: string;
    tseq: number;
    tp_nm: string;
    is_del: boolean;
    tiseq: number;
    image_id: string;
    image_nm: string;
    tnseq: number;
    network_id: string;
    network_nm: string;
    flavor_id: string;
    sbj_nm: string;
    reg_date: string;
};

export type SimpleTemplate = {
    tseq: string;
    tp_nm: string;
};

export type Network = {
    tnseq: number;
    network_nm: string;
    network_id: string;
    upd_date: string;
    reg_date: string;
};

export type Image = {
    tiseq: number;
    image_nm: string;
    image_id: string;
    upd_date: string;
    reg_date: string;
};

export type Flavor = {
    links: any[];
    swap: number;
    vcpus: number;
    ram: number;
    name: string;
    id: string;
    disk: number;
};

export type TemplateRegisterInfo = {
    tp_nm: string;
    tnseq: number;
    tiseq: number;
    core_type : string;
    flavor_id: string;
};

export const getTemplateList = async (type: string) => {
    try {
        const requestUrl = "/admin/template/list/" + type;

        const res = await axios.get(requestUrl);
        console.log(res);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const modifyTemplate = async (network_seq: number, image_seq: number, flavor_id: string, template_seq: number, template_name: string) => {
    try {
        const requestUrl = "/admin/template/modify";
        const data = {
            tnseq: network_seq,
            tiseq: image_seq,
            flavor_id: flavor_id,
            tseq: template_seq,
            tp_nm: template_name,
        };

        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getTemplateSimpleList = async () => {
    try {
        const requestUrl = "/admin/template/list-simple";
        
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};


export const registerTemplate = async (newTemplate: TemplateRegisterInfo) => {
    try {
        const requestUrl = "/admin/template/register";
        const data: TemplateRegisterInfo = {
            ...newTemplate
        };

        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const deleteTemplate = async (tseq: number) => {
    try {
        const requestUrl = "/admin/template/delete";
        const data = {
            tseq: tseq
        };
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};