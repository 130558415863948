import React, { useState, useEffect } from 'react';
import { useEnvironment } from 'hooks/useEnvironment';
import { vmAction, vmStateClass, vmStateName } from 'utils/state';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CIconButton, CSelectButton } from "components/buttons";

type EnvironmentDetailProps = {
    timing: string;
}

function EnvironmentDetail({
    timing
}: EnvironmentDetailProps) {
    const { 
        selectedVm, 
        vmActionResponse,
        callVmActionRequest, 
        callGetConsoleUrl, 
        callGetJupyterInfo, 
        callDeleteInstance, 
        callGetCompletedEnvironmentList, 
        callGetExpectedEnvironmentList, 
        callGetOngoingEnvironmentList, 
        callGetWholeEnvironmentList,
        callCreateIndividualVm
    } = useEnvironment();
    const [stateBarClass, setStateBarClass] = useState<string>('stateBar ');
    const [stateBarText, setStateBarText] = useState<string>("");

    useEffect(() => {
        if (selectedVm === null) {
            return;
        }
        switch (selectedVm.vm_stat) {
            case ('0'):
                setStateBarText(vmStateName.NOT_CREATED);
                return setStateBarClass('stateBar ' + vmStateClass.NOT_CREATED);
            case ('1'):
                setStateBarText(vmStateName.ALLOCATED);
                return setStateBarClass('stateBar ' + vmStateClass.ALLOCATED);
            case ('2'):
                setStateBarText(vmStateName.DEPLOYING);
                return setStateBarClass('stateBar ' + vmStateClass.DEPLOYING);
            case ('3'):
                setStateBarText(vmStateName.DEPLOYED);
                return setStateBarClass('stateBar ' + vmStateClass.DEPLOYED);
            case ('4'):
                setStateBarText(vmStateName.RUNNING);
                return setStateBarClass('stateBar ' + vmStateClass.RUNNING);
            case ('5'):
                setStateBarText(vmStateName.TERMINATED);
                return setStateBarClass('stateBar ' + vmStateClass.TERMINATED);
            case ('6'):
                setStateBarText(vmStateName.DELETED);
                return setStateBarClass('stateBar ' + vmStateClass.DELETED);
            case ('7'):
                setStateBarText(vmStateName.FORCE_REBOOTING);
                return setStateBarClass('stateBar ' + vmStateClass.FORCE_REBOOTING);
            case ('8'):
                setStateBarText(vmStateName.FORCE_TERMINATING);
                return setStateBarClass('stateBar ' + vmStateClass.FORCE_TERMINATING);
            case ('9'):
                setStateBarText(vmStateName.ETC);
                return setStateBarClass('stateBar ' + vmStateClass.ETC);
            default:
                setStateBarText(vmStateName.ETC);
                return setStateBarClass('stateBar ' + vmStateClass.ETC);
        }
    }, [selectedVm]);

    if (selectedVm === null) {
        return (
            <PanelBox>

            </PanelBox>
        );
    }

    const actionList = [
        {
            name: 'Jupyter Notebook',
            onClick: () => {
                callGetJupyterInfo(selectedVm.nseq);
            },
        },
        {
            name: '터미널',
            onClick: () => {
                callGetConsoleUrl(selectedVm.nseq);
            },
        },
    ]

    const updateList = (timing: string) => {
        switch (timing) {
            case ("EXPECT") :
                callGetExpectedEnvironmentList();
                return;
            case ("ING") :
                callGetOngoingEnvironmentList();
                return;
            case ("END") :
                callGetCompletedEnvironmentList();
                return;
            case ("ALL") :
                //callGetWholeEnvironmentList();
                return;
            default :
                return;
        }
    }

    const handleVmActions = (action: string) => {
        callVmActionRequest(selectedVm.instance_id, action);
        //updateList(timing);
    };

    const handleDeleteVm = (id: string) => {
        callDeleteInstance(id);
        //updateList(timing);
    };

    const handleIndividualCreation = (nseq: number) => {
        callCreateIndividualVm(nseq);
    }

    return (
        <PanelBox className="panel_detail">
            <div className="panelTitBar">
                <div className="tit"><strong>{selectedVm.id}</strong>의 예약 신청 상세</div>
            </div>
            <CommActionBar>
                <CSelectButton
                    items={actionList}
                >
                    액션
                </CSelectButton>
                <div className="iconBtnGrope">
                    <CIconButton
                        icon="play"
                        tooltip="실행"
                        onClick={() => handleVmActions(vmAction.EXECUTE)}
                    />
                    <CIconButton
                        icon="stop"
                        tooltip="중지"
                        onClick={() => handleVmActions(vmAction.STOP)}
                    />
                    <CIconButton
                        icon="delete"
                        tooltip="삭제"
                        onClick={() =>handleDeleteVm(selectedVm.instance_id)}
                    />
                    <CIconButton
                        icon="replay"
                        tooltip="개별생성"
                        onClick={() => handleIndividualCreation(selectedVm.nseq)}
                    />
                </div>
            </CommActionBar>
            <div className="panelCont">
                <div className={stateBarClass}>
                    <span className="state_ico">{stateBarText}</span>
                </div>
                <table className="tb_data">
                    <tbody>
                    <tr>
                        <th>사용자 아이디</th>
                        <td>{selectedVm.id}</td>
                        <th>PORT</th>
                        <td>{selectedVm.jupyter_info}</td>
                    </tr>
                    <tr>
                        <th>CPU / MEMORY / DISK</th>
                        <td>{selectedVm.cpu}/{selectedVm.ram}/{selectedVm.disk}</td>
                        <th>VM 구동 시간</th>
                        <td>{selectedVm.spend_time}시간</td>
                    </tr>
                    <tr>
                        <th>VM 실행 시작 일시</th>
                        <td>{selectedVm.start_time}</td>
                        <th>VM 실행 종료 일시</th>
                        <td>{selectedVm.end_time ?? '-'}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </PanelBox>
    );
}

type VmStateProps = {
    state: string | number;
};

function VmState({
    state
}: VmStateProps) {
    if (state === '0') {
        
    }
}

export default EnvironmentDetail;