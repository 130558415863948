import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CDialog } from "components/dialogs";
import { swalConfirm, swal } from "utils/swal-utils";
import { CTextField } from "components/textfields";
import CustomCheckbox from 'components/checkbox/CustomCheckbox';

import { registerNotice } from 'api/notice'; 
import { useNotice } from 'hooks/useNotice';

const FileRowWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

type NoticeCreateDialoProps = {
    open: boolean;
    //onClickButton: () => void;
    onClose: () => void;
};

const NoticeCreateDialog = ({
    open,
    onClose
}:NoticeCreateDialoProps) => {
    const { callGetNoticeList, callRegisterNotice } = useNotice(); 
    const [noticeTitle, setNoticeTitle] = useState<string>('');
    const [cont, setCont] = useState<string>('');
    const [importantChecked, setImportantchecked] = useState<boolean>(false);
    // const [content, setContent] = useState<EditorState>(EditorState.createEmpty());

    const form = new FormData();

    const handleClose = () => {
        onClose && onClose();
        callGetNoticeList();
    };


    
    const handleCreateAlert = () => {
        swalConfirm("등록하시겠습니까??").then(function(res) {
            if (res.isConfirmed) {
                const statCd = (importantChecked) ? '0' : '1';
                
                form.append('title', noticeTitle);
                form.append('cont', cont);
                form.append('stat_cd', statCd);
            
                registerNotice(form);
            }
            handleClose();
        });
    }

    // const handleCreateAlert = () => {
    //     swalConfirm("등록하시겠습니까??").then(function(res) {
    //         if (res.isConfirmed) {
    //             const statCd = (importantChecked) ? '0' : '1';
                
    //             form.append('title', noticeTitle);
    //             form.append('cont', cont);
    //             form.append('stat_cd', statCd);
            
    //             registerNotice(form).then(function(res) {
    //                 console.log(res);
    //                 swal(res.message).then(function(res) {
    //                     handleClose();
    //                     callGetNoticeList();
    //                 });
    //             });
    //         }
    //     });
    // }

    const onChangeTitle = (event: any) => {
        setNoticeTitle(event.target.value);
    };

    const onChangeCont = (event: any) => {
        setCont(event.target.value);
    };
    
    const addFile = (event: any): void => {
        event.preventDefault();

        for(let key of Object.keys(event.target.files)) {
            if (key !== 'length') {
                form.append('file', event.target.files[key]);
            }
        }
    }

    // const onEditorStateChange = (content:any) => {
    //     // editorState에 값 설정
    //     setContent(content);
    //   };

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="md"
            title={`공지사항 등록하기`}
            onClose={handleClose}
            onCreate={handleCreateAlert}
            modules={['create']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>제목</th>
                    <td colSpan={3}>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            placeholder="제목을 입력하세요."
                            className="form_fullWidth"
                            value={noticeTitle}
                            onChange={onChangeTitle}
                        />
                    </td>
                </tr>
                <tr>
                    <th>상세내용</th>
                    <td colSpan={3}>
                        <textarea
                            placeholder="내용을 입력하세요."
                            rows={20}
                            // ref={textRef}
                            value={cont}
                            onChange={onChangeCont}
                            wrap='hard'
                        />
                        
                    </td>
                </tr>
                <tr>
                    <th>첨부파일</th>
                    <td colSpan={3}>
                        <FileRowWrap>
                            <div>
                                <input type='file' id='fileUpload' onChange={addFile}/>
                            </div>
                            <CustomCheckbox checked={importantChecked} setChecked={setImportantchecked} text="중요" />
                        </FileRowWrap>
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}


export default NoticeCreateDialog;
