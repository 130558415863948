import React from "react";
import { RootState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { labSignup, getBooksList, getTemplateList } from "store/labSlice";
import {
  getSubjetDetail,
  getTerminalUrl,
  getJupyter,
  shutDownVM,
} from "api/lab";

export function useLab() {
  const { booksResponse, signResponse, templateResponse } = useSelector(
    (state: RootState) => state.lab
  );
  const { saidaUseStat } = useSelector((state: RootState) => state.loginUser);
  const dispatch = useDispatch();

  const callLabSignup = (
    // sseq : number,
    // stat_cd : number,
    res_cd: string,
    pwd: string,
    tseq: number,
    signup_msg: string
  ) => {
    dispatch(
      labSignup({
        // sseq: sseq,
        // stat_cd: stat_cd,
        res_cd: res_cd,
        pwd: pwd,
        tseq: tseq,
        signup_msg: signup_msg,
      })
    );
  };

  const callGetBooksList = (pagesearchInfo: any) => {
    dispatch(getBooksList(pagesearchInfo));
    dispatch({ type: 'loginUser/autoLogin' });
  };

  const getLectureDetail = (lectureSeq: number) => {
    try {
      const result = getSubjetDetail(lectureSeq);
      return result;
    } catch (error) {
      return null;
    }
  };

  const getTerminalConsoleUrl = (nseq: number) => {
    try {
      const result = getTerminalUrl(nseq);
      return result;
    } catch (error) {
      return null;
    }
  };

  const getJupyterUrl = (nseq: number) => {
    try {
      const result = getJupyter(nseq);
      return result;
    } catch (error) {
      return null;
    }
  };

  const callGetTemplateList = () => {
    dispatch(getTemplateList());
  };

  return {
    signResponse,
    booksResponse,
    saidaUseStat,
    templateResponse,
    callLabSignup,
    callGetBooksList,
    getLectureDetail,
    getTerminalConsoleUrl,
    getJupyterUrl,
    callGetTemplateList,
  };
}
