import React, { useState } from "react";
import { swal, swalConfirm } from "utils/swal-utils";
import { useLab } from "hooks/useLab";
import { cancelBook, extendBook, shutDown, shutDownVM } from "api/lab";
import LabDialog from "./LabDialog";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { reasonForKorean } from '../../utils/commonUtil';

type ReservationListTableProps = {
  rowData: any;
  total: number;
  msg: string;
  updateLabList: () => void;
};

function ReservationListTable({
  rowData,
  total,
  msg,
  updateLabList,
}: ReservationListTableProps) {
  const { getTerminalConsoleUrl, getJupyterUrl } = useLab();
  const protocal = window.location.protocol;

  if (total === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={12}>{msg}</td>
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody style={{ borderTop: "1px solid #0a2348" }}>
        {rowData.map((book: any, idx: number) => {
          const handleApplyExtensionAlert = () => {
            swalConfirm(
              "30일 자동 연장 신청됩니다. 연장 신청 하시겠습니까?"
            ).then(function (res) {
              if (res.isConfirmed) {
                extendBook(book.nseq).then((res) => {
                  console.log(res);
                  updateLabList();
                });
              }
            });
          };

          const handleCancelAlert = () => {
            swalConfirm("예약 취소 하시겠습니까?").then(function (res) {
              console.log(res);
              if (res.isConfirmed) {
                cancelBook(book.nseq).then((res) => {
                  console.log(res);
                  updateLabList();
                });
              }
            });
          };

          // const handleShutdownAlert = () => {
          //   swalConfirm("이용 종료 하시겠습니까?").then(function (res) {
          //     console.log(res);
          //     if (res.isConfirmed) {
          //       shutDown(book.nseq).then((res) => {
          //         console.log(res);
          //         updateLabList();
          //       });
          //     }
          //   });
          // };

          const handleShutdownAlert = () => {
            swalConfirm("이용 종료 하시겠습니까?").then(function (res) {
              console.log(res);
              if (res.isConfirmed) {
                shutDownVM(book.instance_id).then((res) => {
                  console.log(res);
                  updateLabList();
                });
              }
            });
          };

          const handleJupyter = () => {
            swalConfirm("실습실에 접속하시겠습니까?")
              .then(function (res) {
                console.log(res);
                if (res.isConfirmed) {
                  console.log("주피터접속");
                  getJupyterUrl(book.nseq)
                    ?.then((data) => {
                      //getJupyterUrl(99)?.then((data)=>{
                      let popUrl = "http://" + data.data.addr;
                      let popOption =
                        "top=100, left=300, width=735, height=435, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=yes";
                      window.open(popUrl, "target", popOption);
                    })
                    .catch((e) => {
                      swal("준비중입니다.");
                    });
                }
              })
              .catch((e) => {
                swal(e);
              });
          };

          const handleTerminal = () => {
            swalConfirm("터미널에 접속하시겠습니까?")
              .then(function (res) {
                if (res.isConfirmed) {
                  getTerminalConsoleUrl(book.nseq)
                    ?.then((data) => {
                      //getTerminalConsoleUrl(99)?.then((data)=>{
                      let popUrl = data.data;
                      let popOption =
                        "top=100, left=300, width=735, height=435, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=yes";
                      window.open(popUrl, "target", popOption);
                    })
                    .catch((e) => {
                      swal("준비중입니다.");
                    });
                }
              })
              .catch((e) => {
                swal(e);
              });
          };

          const getDday = (d: string) => {
            let strDay = d.split("일");
            let _dday = Number(strDay[0]);
            return _dday;
          };

          let dday: string = book.dday;
          let _dday = getDday(dday);
          let tooltipMsg = book.reason_msg;

          if (book.length === 0) {
            return <tr>예약 신청 내역이 없습니다.</tr>;
          } else if (book.stat_str === "대기") {
            return (
              <tr key={idx} className="tb_btn_ctrl">
                {/* <td>{idx+1}</td> */}
                <td>{book.reg_date}</td>
                <td
                  colSpan={2}
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {book.sbj_nm}
                </td>
                <td>{book.start_day}</td>
                <td>{book.end_day}</td>
                <td>{book.dday}</td>
                {/* <td>-</td> */}
                <td className="txt_left">
                  <span className="status g2">{book.stat_str}</span>
                </td>
                <td>-</td>
                <td>
                  <LabDialog book={book} />
                </td>
                <td>-</td>
                <td>-</td>
                {/* <td>
                  <button
                    type="button"
                    className="btn_rsv btn_rsv_cancel"
                    onClick={handleCancelAlert}
                  >
                    취소
                  </button>
                </td> */}
                <td>-</td>
              </tr>
            );
          } else if (book.stat_str === "승인") {
            return (
              <tr key={idx} className="tb_btn_ctrl">
                {/* <td>{idx+1}</td> */}
                <td>{book.reg_date}</td>
                <td colSpan={2} style={{ overflow: "hidden", textOverflow: "ellipsis", textAlign:"center"}}>
                {reasonForKorean(book.res_cd)}
                </td>
                <td>{book.start_day}</td>
                <td>{book.end_day}</td>
                <td style={{ borderRight: "1px dotted #dce1e6" }}>
                  {book.dday}
                </td>
                {/* {_dday <= 30 ? (
                  <td>
                    <button
                      type="button"
                      className="btn_rsv btn_extension"
                      onClick={handleApplyExtensionAlert}
                    >
                      연장신청
                    </button>
                  </td>
                ) : (
                  <td>-</td>
                )} */}
                <td className="txt_left">
                  <span className="status g1">
                    <span className="action">{book.stat_str}</span>
                  </span>
                </td>
                <td className="txt_left">
                  {
                     (book.instance_id !== null)
                     ? <span className="status g1"><span className="action">접속 가능</span></span>
                     : <span className="status g2"><span className="action">준비중</span></span>
                  }
                </td>
                <td>
                  <LabDialog book={book} />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn_rsv btn_connect"
                    onClick={handleTerminal}
                  >
                    Terminal
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn_rsv btn_jupyter"
                    onClick={handleJupyter}
                  >
                    Jupyter
                  </button>
                </td>
                <td>
                  {
                    (book.instance_id !== null) ?                   
                    <button
                    type="button"
                    className="btn_rsv btn_end"
                    onClick={handleShutdownAlert}
                  >
                    종료
                  </button>
                  : <button
                    type="button"
                    className="btn_rsv btn_rsv_cancel"
                    onClick={handleCancelAlert}
                  >
                  예약 취소
                </button>
                  }

                </td>
              </tr>
            );
          } else {
            return (
              <tr key={idx} className="tb_btn_ctrl">
                {/* <td>{idx+1}</td> */}
                <td>{book.reg_date}</td>
                <td colSpan={2} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {book.sbj_nm}
                </td>
                <td>{book.start_day}</td>
                <td>{book.end_day}</td>
                <td>-</td>
                {book.stat_str === "보류" ? (
                  <td style={{ textAlign: "inherit", paddingLeft: "20px" }}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit"> 사유</Typography>
                          {tooltipMsg !== null ? (
                            -{ tooltipMsg }
                          ) : (
                            <em>사유가 없습니다.</em>
                          )}
                        </React.Fragment>
                      }
                    >
                      <span className="status g3">{book.stat_str}</span>
                    </HtmlTooltip>
                  </td>
                ) : (
                  <td className="txt_left">
                    <span className="status">{book.stat_str}</span>
                  </td>
                )}
                <td>-</td>
                <td>
                  <LabDialog book={book} />
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            );
          }
        })}
      </tbody>
    );
  }
}

export default ReservationListTable;

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
