import axios from 'axios';
import { handleError } from './axiosUtils';

export type Admin = {
    useq: number;
    id: string;
    name: string
    col_nm: string;
    col_cd: string;
    dep_nm: string;
    dep_cd: string;
    email: string;
    birth_day: string | null;
    role_str: string;
    stat_str: string;
    mobile_no: string;
};

export const getAdminList = async () => {
    try {
        const requestUrl = "/user/su-admin/users-list";
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const updateAdminStatus = async (id: string, code: number, reason_msg: string) => {
    try {
        const requestUrl = "/user/su-admin/stat-management";
        const data = {
            id: id,
            code: code,
            reason: reason_msg
        };
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getPracticerActiveLogList = async (page: number) => {
    try {
        const requestUrl = "log/su-admin/active/list/CIVIL";
        const res = await axios.get(requestUrl, {
            params: {
                page: page
            }
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getAdminActiveLogList = async (page: number) => {
    try {
        const requestUrl = "log/su-admin/active/list/ADMIN";
        const res = await axios.get(requestUrl, {
            params: {
                page: page
            }
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};