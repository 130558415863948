import React, { useEffect, useState } from 'react';
import { useLocation, RouteComponentProps, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';

import { usePortalDataset } from 'hooks/usePortalDataset';
import { datasetContent } from 'api/portalDataset';
import { spliteDateTimeSeconds } from 'utils/dateUtils';
import { data } from 'jquery';

const AttachedFile = styled.div`
    display: flex;
    align-items: flex-start;

    p {
        padding: 0px 5px;
    }
`;


function DatasetView({match}:RouteComponentProps) {
    const history= useHistory();
    const { search } = useLocation();
    const { no } = queryString.parse(search);
    const { datasetContentResponse, callGetDatasetContent, handleDownloadDataset } = usePortalDataset();
    const [ content, setContent ] = useState<datasetContent|null>(null);
    const [ fileNm, setFileNm ] = useState('');

    const dsseq:number = Number(no);

    useEffect(() => {
        console.log(dsseq)
        callGetDatasetContent(dsseq);
    }, []);

    useEffect(() => {
        if (!datasetContentResponse.loading && !datasetContentResponse.error && datasetContentResponse.data){
            const data:datasetContent = datasetContentResponse.data.data;
            let tmpData = {...data}
            let regDate = spliteDateTimeSeconds(data.reg_date);
            tmpData.reg_date = regDate;
            setContent(tmpData)
            setFileNm(data.file_nm);
        }
    }, [datasetContentResponse, setContent]);

    const goBack = () => {
        history.push("/Portal/lab/datasetlist");
    }

    const handleDownload = (dsseq: string | number | undefined) => {
        dsseq && handleDownloadDataset(dsseq);
    }

    return (
        <React.Fragment>
              <div className="layout_inner">
                <div className="board_wrap">
                {/* <h3 className="board_tit">{content?.title} <button type="button" className="btn_modify">다운로드</button></h3> */}
                <table className="tb_style_02">
                    <colgroup>
                    <col style={{width:"15%"}}/>
                    <col style={{width:"35%"}}/>
                    <col style={{width:"15%"}}/>
                    <col style={{width:"35%"}}/>
                    </colgroup>
                    <tr>
                    <th>분류</th>
                    <td>
                        <span className="mypage_info">{content?.category_name}</span>
                    </td>
                    <th>확장자</th>
                    <td>
                        <span className="mypage_info">{content?.extension_cd}</span>
                    </td>
                    </tr>
                    <tr>
                    <th>제공기관</th>
                    <td>
                        <span className="mypage_info">{content?.provider}</span>
                    </td>
                    <th>다운로드 수</th>
                    <td>
                        <span className="mypage_info">{content?.file_down_cnt}</span>
                    </td>
                    </tr>
                    <tr>
                    <th>수정일</th>
                    <td>
                        <span className="mypage_info">{content?.update_date}</span>
                    </td>
                    <th>등록일</th>
                    <td>
                        <span className="mypage_info">{content?.reg_date}</span>
                    </td>
                    </tr>
                    <tr>
                    <th style={{borderRight:"1px solid #e4e8ec"}}>첨부파일</th>
                    <td colSpan={3}>
                        <span className="mypage_info">
                        {
                        (fileNm !== null)
                            ?
                            <>                    
                                <AttachedFile onClick={(e) => handleDownload(content?.dsseq)}>
                                    <img src="/lib/image/common/ico_file.png" alt=""/>
                                    <p>{content?.file_nm}</p>
                                    <img src="/lib/image/common/ico_download.png" alt=""/>
                                </AttachedFile>
                            </>
                            :<></>
                        }
                        </span>
                    </td>
                    </tr>
                </table>

                <div className="view_dataset">
                    <pre style={{fontFamily:'Noto,  sans-serif', fontSize:'14px'}}>{content?.content}</pre>
                </div>
                
                <div className="board_btn_wrap">
                    <button type="button" onClick={goBack}>목록</button>
                </div>
                </div>
                </div>
        </React.Fragment>
    );
}

export default DatasetView;