import React from 'react';
import Layout from "layout";
import { Title } from 'pages';
import LectureList from './LectureList';
import LectureDetail from './LectureDetail';

function Lecture()  {
    const currentPage = Title.Lecture;

    return (
        <Layout currentPage={currentPage}>
            <LectureList />
            <LectureDetail />
        </Layout>
    );
}
export default Lecture;
