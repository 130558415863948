import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { asyncState, reducerUtils } from 'utils/asyncUtils';
import { call, put, takeLatest, takeEvery } from '@redux-saga/core/effects';
import * as DashboardApi from 'api/dashboard';

type DashboardState = {
    dashboardItemResponse:asyncState;

};

const initialState: DashboardState = {
    dashboardItemResponse:reducerUtils.initial(),
    
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        getDashboardItem(state) {
            state.dashboardItemResponse = reducerUtils.loading();
        },
        getDashboardItemSuccess(state, action: PayloadAction<DashboardApi.BoardData[]>) {
            state.dashboardItemResponse = reducerUtils.success(action.payload);
        },
        getDashboardItemError(state, action: PayloadAction<AxiosError>) {
            state.dashboardItemResponse = reducerUtils.error(action.payload);
        }
    }
});

export const {
    getDashboardItem,
    getDashboardItemSuccess,
    getDashboardItemError
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

/* saga */

function* getDashboardSaga() {
    try {
        const items : DashboardApi.BoardData[] = yield call(DashboardApi.getDashboardItem);
        yield put(getDashboardItemSuccess(items));
    } catch (error) {
        yield put(getDashboardItemError(error));
    }
}

export function* dashboardSaga() {
    yield takeLatest(getDashboardItem, getDashboardSaga);
}