import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';

import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    root: {
        width: '220px',
        position: 'fixed',
        bottom: '50px',
        left: '10px',
        color: '#268eff',
    },
    text: {
        color: "#ffffff",
        padding: "10px",
    },
});

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        },
    }),
)(LinearProgress);

function CustomProgressBar(props: LinearProgressProps & { value: number }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.text}>진행률</div>
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <BorderLinearProgress variant="determinate" {...props} />
                </Box>
                <Box minWidth={35}>
                    {/*<Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                    )}%`}</Typography>*/}
                    {`${Math.round(
                    props.value,
                    )}%`}
                </Box>
            </Box>
        </div>
    );
}

export default CustomProgressBar;