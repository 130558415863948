import React, { ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useMaintainLogin } from 'hooks/useMaintainLogin';

type PrivateRouterProps = {
    component: ComponentType;
};

function PrivateRouter({
    component: Component,
    ...rest
}: PrivateRouterProps & any) {
    const token = localStorage.getItem('access-token');
    const isLogin = (token)? true : false;

    return (
        <Route 
            {...rest}
            render={props => (
                (isLogin)
                ? <Component {...props} />
                : <Redirect push to="/" />
            )}
        />
    );
}

export default PrivateRouter;
