import React from 'react';
import CustomPagination from 'pages/PortalMain/components/pagination/customPagination';

type labPaginationProps = {
    totalCount: number;
    itemPerPage: number;
    onChangePage: (page: number) => void;
};

function labpagination({
    totalCount,
    itemPerPage,
    onChangePage
}: labPaginationProps) {
    return (
        <CustomPagination totalCount={totalCount} itemPerPage={itemPerPage} onChangePage={onChangePage} />
    );
}

export default labpagination;