import React, { useState, useEffect } from 'react';
import * as Common from '../../utils/common.js';

function UserInfoTable({ user }) {
    const [role, setRole] = useState("");
    //const [birthday, setBirthday] = useState("");
    const [mobileNum, setMobileNum] = useState("");

    useEffect(() => {
        // set role name
        if (user.role_cd === "CIVIL") {
            setRole("실습자");
        }
        else if (user.role_cd === "ADMIN") {
            setRole("관리자");
        }
        else if (user.role_cd === "SUPER") {
            setRole("총괄관리자");
        }
        else {
            setRole("");
        }

        // set birthday
        /*if (user.birth_day !== null) {
            const birthdayData = Common.formatDate(user.birth_day);
            setBirthday(birthdayData);
        }*/

        // set mobile number
        const mobileData = Common.formatPhoneNumber(user.mobile_no);
        setMobileNum(mobileData);
    }, [user]);

    if (user.role_cd === "CIVIL") {
        return (
            <div className="join_wrap mypage_wrap">
                <div className="join_box_wrap">
                    <table className="tb_style_02">
                        <colgroup>
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                        </colgroup>
                        <tr>
                            <th>아이디</th>
                            <td>
                                <span className="mypage_info">{user.id}</span>
                            </td>
                            <th>권한</th>
                            <td>
                                <span className="mypage_info">{role}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>
                                <span className="mypage_info">{user.name}</span>
                            </td>
                            <th>학교(소속)</th>
                            <td>
                                <span className="mypage_info">{user.col_nm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>학과(부서)</th>
                            <td>
                                <span className="mypage_info">{user.dep_nm}</span>
                            </td>
                            <th>학번(사번)</th>
                            <td>
                                <span className="mypage_info">{user.class_no}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>
                                <span className="mypage_info">{user.email}</span>
                            </td>
                            {/*<th>생년월일</th>
                            <td>
                                <span className="mypage_info">{birthday}</span>
                            </td>*/}
                        </tr>
                        <tr>
                            <th style={{borderRight: "1px solid #e4e8ec"}}>연락처</th>
                            <td colSpan="3">
                                <span className="mypage_info">{mobileNum}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }
    else if (user.role_cd === "ADMIN" || user.role_cd === "SUPER") {
        return (
            <div className="join_wrap mypage_wrap">
                <div className="join_box_wrap">
                    <table className="tb_style_02">
                        <colgroup>
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                        </colgroup>
                        <tr>
                            <th>아이디</th>
                            <td>
                                <span className="mypage_info">{user.id}</span>
                            </td>
                            <th>권한</th>
                            <td>
                                <span className="mypage_info">{role}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>
                                <span className="mypage_info">{user.name}</span>
                            </td>
                            <th>학교(소속)</th>
                            <td>
                                <span className="mypage_info">{user.col_nm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>학과(부서)</th>
                            <td>
                                <span className="mypage_info">{user.dep_nm}</span>
                            </td>
                            <th>관리자 구분</th>
                            <td>
                                <span className="mypage_info">
                                    {
                                        (user.role_cd === "ADMIN") ? "관리자" : "총괄관리자"
                                    }
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>
                                <span className="mypage_info">{user.email}</span>
                            </td>
                            <th>연락처</th>
                            <td>
                                <span className="mypage_info">{mobileNum}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }
    else {
        return (
            <div></div>
        );
    }
}

export default UserInfoTable;