import React, { useState, useEffect } from 'react';
import { Title } from 'pages';
import { useReservation } from 'hooks/useReservation';

import Layout from "layout";
import { CTabs, CTab, CTabPanel } from 'components/tabs';
import ReservationList from './ReservationList';
import ReservationDetail from './ReservationDetail';

const Reservation = () => {
    const currentPage = Title.Reservation;
    const { expectedResponse, ongoingResponse, completedResponse, callGetReservationList } = useReservation();
    const [tabValue, setTabvalue] = useState(0);
    const [timing, setTiming] = useState<string>('');

    useEffect(() => {
        if (tabValue === 0) {
            setTiming('EXPECT');
        }
        else if (tabValue === 1) {
            setTiming('ING');
        }
        else if (tabValue === 2) {
            setTiming('END');
        }
        else {
            setTiming('');
        }
    }, [tabValue]);

    const handleTabChange = (event: any, newValue: number) => {
        setTabvalue(newValue);
    };

    return (
        <Layout currentPage={currentPage}>
            <CTabs
                type="tab1"
                value={tabValue}
                onChange={handleTabChange}
            >
                <CTab label="수강 예정" />
                <CTab label="수강 진행" />
                <CTab label="수강 종료" />
            </CTabs>
            <CTabPanel
                value={tabValue}
                index={0}
            >
                <ReservationList timing="EXPECT" listResponse={expectedResponse} fetchList={callGetReservationList} />
            </CTabPanel>
            <CTabPanel
                value={tabValue}
                index={1}
            >
                <ReservationList timing="ING" listResponse={ongoingResponse} fetchList={callGetReservationList} />
            </CTabPanel>
            <CTabPanel
                value={tabValue}
                index={2}
            >
                <ReservationList timing="END" listResponse={completedResponse} fetchList={callGetReservationList} />
            </CTabPanel>
            <ReservationDetail tab={timing} />
        </Layout>
    );
}
export default Reservation;
