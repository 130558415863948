import { useSelector, useDispatch } from "react-redux";
import { RootState } from 'store';
import { confirmAlarm, getAlarmList, VmCreatePushDetail } from 'api/alarm';
import { setVmProgress } from 'store/alarmSlice';

export function useAlarm() {
    const { alarmResponse, vmProgress, notConfirmedResponse } = useSelector((state: RootState) => (state.alarm));
    const dispatch = useDispatch();

    const callGetAlarmalist = async (page: number) => {
        /*dispatch({
            type: 'alarm/getAlarmList',
            payload: page
        });*/
        try {
            const result = await getAlarmList(page);
            return result;
        } catch (error) {
            //swal("목록이 없습니다.");
        }
    };

    const callConfirmAlarm = async (rseq: number) => {
        try {
            const result = await confirmAlarm(rseq);
            return result;
        } catch(error) {
            return error;
        }
    };

    const callSetVmProgress = (status: VmCreatePushDetail | null) => {
        dispatch(setVmProgress(status));
    };

    const callGetNotConfirmedAlarmCount = () => {
        dispatch({
            type: "alarm/getNotConfirmedAlarmCount"
        });
    }

    return {
        alarmResponse,
        vmProgress,
        notConfirmedResponse,
        callGetAlarmalist,
        callConfirmAlarm,
        callSetVmProgress,
        callGetNotConfirmedAlarmCount
    };
}