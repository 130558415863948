import React from 'react';
import { includes } from 'lodash';
import DialogActions from "@material-ui/core/DialogActions";
import { CButton } from 'components/buttons/CButton';

const CDialogAction = (props) => {
    const {
        modules,
        onCreate,
        onUpdate,
        onDelete,
        onClose,
        onCustom,
        ...other
    } = props;

    const createButton = includes(modules, 'create') && (
        <CButton
            type="btn1"
            onClick={onCreate}
            buttonEventType="submit"
        >
            확인
        </CButton>
    );

    const confirmButton = includes(modules, 'confirm') && (
        <CButton
            type="btn1"
            onClick={onClose}
        >
            확인
        </CButton>
    );

    const customButton = includes(modules, 'custom') && (
        <CButton
            type="btn1"
            onClick={onCustom}
            buttonEventType="submit"
        >
            저장
        </CButton>
    );

    const editButton = includes(modules, 'update') && (
        <CButton
            type="btn1"
            onClick={onUpdate}
            buttonEventType="submit"
        >
            변경
        </CButton>
    );

    const closeButton = includes(modules, 'close') && (
        <CButton
            type="btn1"
            onClick={onClose}
        >
            닫기
        </CButton>
    );

    const deleteButton = includes(modules, 'delete') && (
        <CButton
            type="btn1"
            onClick={onDelete}
            buttonEventType="submit"
        >
            삭제
        </CButton>
    );


    return (
        <DialogActions {...other}>
            {createButton}
            {customButton}
            {editButton}
            {deleteButton}
            {closeButton}
            {confirmButton}
        </DialogActions>
    );
};

export { CDialogAction };
