import React, { useState, useEffect } from 'react';
import { agDateColumnFilter } from "utils/common-utils";
import {swalConfirm} from "utils/swal-utils";
import { formatDate } from 'utils/dateUtils';

import AuthorityList from './AuthorityList';
import AuthorityDetail from './AuthorityDetail';

const cellRenderer = {
    renderBirthday: function (birthday: string) {
        //const birthday = params.data.birth_day;
        const formatted = formatDate(birthday);
        return `<span>${formatted}</span>`;
    },
    
} 

const AuthorityTab = () => {
    
    return (
        <>
            <AuthorityList cellRenderer={cellRenderer} />
            <AuthorityDetail />
        </>
    );
}
export default AuthorityTab;
