import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import { swalConfirm, swal } from "utils/swal-utils";
import FormControl from '@material-ui/core/FormControl';
import { CTextField } from "components/textfields";
import { useTemplate } from 'hooks/useTemplate';
import { TemplateRegisterInfo, registerTemplate } from 'api/template';
import { coreList, gpuFlavorList, gpu_prefix } from 'config';

type TemplateCreateDialogProps = {
    open: boolean;
    onClose: () => void;
};

function TemplateCreateDialog ({
    open,
    onClose
}: TemplateCreateDialogProps) {
    const { networkList, imageList, flavorList } = useTemplate();

    const [templateName, setTemplateName] = useState<string>('');
    const [network, setNetwork] = useState<number>(-1);
    const [image, setImage] = useState<number>(-1);
    const [flavor, setFlavor] = useState<string>('');
    const [core, setCore] = useState<string>('');

    const handleClose = () => {
        onClose();
    };

    const handleCreateAlert = () => {
        swalConfirm("등록하시겠습니까??").then(function(res) {
            if (res.isConfirmed) {
                const newTemplate: TemplateRegisterInfo = {
                    tp_nm: templateName,
                    tnseq: network,
                    tiseq: image,
                    core_type: core,
                    flavor_id: flavor,
                };

                registerTemplate(newTemplate).then(function(res) {
                    console.log(res);
                    swal(res.message).then(function(res) {
                        handleClose();
                    });
                    
                });
            }
        });
    }

    const onChangeName = (event: any) => {
        setTemplateName(event.target.value);
    };

    const onChangeNetwork = (event: any) => {
        setNetwork(event.target.value);
    };

    const onChangeImage = (event: any) => {
        setImage(event.target.value);
    };

    const onChangeFlavor = (event: any) => {
        setFlavor(event.target.value);
    };

    const onChangeCore = (event: any) => {
        setCore(event.target.value);
    };


    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="sm"
            title={`템플릿 등록`}
            onClose={handleClose}
            onCustom={handleCreateAlert}
            modules={['custom']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>템플릿명</th>
                    <td>
                        <CTextField
                            id="template-name"
                            type="text"
                            placeholder="템플릿명을 입력해주세요."
                            className="form_fullWidth"
                            value={templateName}
                            onChange={onChangeName}
                        />
                    </td>
                </tr>
                <tr>
                    <th>템플릿 네트워크</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={network} onChange={onChangeNetwork}>
                                <option value='' selected>선택</option>
                                {
                                    networkList && networkList.map((option, index) => {
                                        return <option value={option.tnseq} key={index}>{option.network_nm}</option>
                                    })
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>템플릿 이미지</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={image} onChange={onChangeImage}>
                                <option value='' selected>선택</option>
                                {
                                    imageList && imageList.map((option, index) => {
                                        return <option value={option.tiseq} key={index}>{option.image_nm}</option>
                                    })
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>코어 타입</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={core} onChange = {onChangeCore}>
                                <option value='' selected>선택</option>
                                {
                                    coreList && coreList.map((option, index) => {
                                        return <option value={option.toLowerCase()} key={index}>{option}</option>
                                    })
                                }

                    
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>사양</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select value={flavor} onChange={onChangeFlavor} 
                                    disabled = {core===''}>
                                <option value='' selected>선택</option>
                               {
                                    core === 'cpu'.toLowerCase() ?  
                                    flavorList && flavorList.map((option, index) => {
                                        //gpu 아닌것만 출력한다.

                                        var optionStr = "("+(option.vcpus)+"/"+(option.ram)+"/"+(option.disk)+")"
                                        if(!option.name.startsWith(gpu_prefix)){
                                           
                                            return <option value={option.id} key={index} >{option.name + " " +optionStr}</option>
                                        }
                                        
                                    })
                                    :
                                    core === 'gpu'.toLowerCase() ? 
                                    gpuFlavorList && gpuFlavorList.map((option, index) => {
                                        //gpu 아닌것만 출력한다.

                                        var optionStr = "("+(option.vcpus)+"/"+(option.ram)+"/"+(option.disk)+")"
                                        
                                        if(!option.name.startsWith(gpu_prefix)){
                                           
                                            return <option value={option.type_id} key={index} >{option.name + " " +optionStr}</option>
                                        }
                                        
                                    })
                                    : <></>
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default TemplateCreateDialog;
