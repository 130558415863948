import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import { swalConfirm, openSwal } from "utils/swal-utils";
import FormControl from "@material-ui/core/FormControl";
import { CTextField } from "components/textfields";
import { CFileField } from "components/textfields/CFilefield";

import { useDatasetCreate } from 'hooks/useDatasetCreate';
import { useInputs } from 'hooks/useInputs';
import { checkExtension } from 'api/dataset';

const DatasetCreateDialog = (props) => {
    const { categoryList, fileExtentionList, handleSubmitDataset } = useDatasetCreate();
    const { inputs, setInputs, onChangeInput, onChangeSelect, onChangeTextarea } = useInputs({
        title: '',
        provider: '',
        fcseq: '',
        feseq: '',
        content: '',
        file: null
    });

    const {open} = props;

    useEffect(() => {
        setInputs({
            title: '',
            provider: '',
            fcseq: '',
            feseq: '',
            content: '',
            file: null
        });
    }, [open]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };
    const handleCreateAlert = () => {
        openSwal("등록하시겠습니까?", () => { handleSubmitDataset(inputs); }).then(function (res) {
            if (res.isConfirmed) {
                handleClose();
            }
        });
    }

    const handleFileSelect = async (event) => {
        setInputs((prev) => ({
            ...prev,
            feseq: 0
        }));

        onChangeInput(event);
        /*
        try {
            const res = await checkExtension(event.target.files[0].name);
            setInputs((prev) => ({
                ...prev,
                feseq: res.feseq
            }));

            onChangeInput(event);
        } catch (error) {
            setInputs((prev) => ({
                ...prev,
                feseq: '',
                file: null
            }));
            swalConfirm('지원하지 않는 확장자 입니다.');
        }
        */

    };

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="md"
            title={`데이터셋 등록하기`}
            onClose={handleClose}
            onCreate={handleCreateAlert}
            modules={['create']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>제목</th>
                    <td colSpan={3}>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            name="title"
                            value={inputs.title}
                            onChange={onChangeInput}
                            placeholder="제목을 입력하세요."
                            className="form_fullWidth"
                        />
                    </td>
                </tr>
                <tr>
                    <th>제공기관</th>
                    <td colSpan={3}>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            name="provider"
                            value={inputs.provider}
                            onChange={onChangeInput}
                            placeholder="제공기관을 입력하세요."
                            className="form_fullWidth"
                        />
                    </td>
                </tr>
                <tr>
                    <th>분류체계</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select name="fcseq" value={inputs.fcseq} onChange={onChangeSelect}>
                                <option value="">분류체계 선택</option>
                                {
                                    categoryList.map((category, index) => (<option value={category.fcseq} key={category.fcseq}>{category.category_name}</option>))
                                }
                            </select>
                        </FormControl>
                    </td>
                    <th>파일 확장자</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select name="feseq" value={inputs.feseq} onChange={onChangeSelect} disabled>
                                <option value="">파일 확장자 선택</option>
                                {
                                    fileExtentionList.map((extension, index) => (<option value={extension.feseq} key={extension.feseq}>{extension.extension_cd}</option>))
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>내용</th>
                    <td colSpan={3}>
                        <textarea
                            placeholder="내용을 입력하세요."
                            rows="10"
                            name="content"
                            value={inputs.content}
                            onChange={onChangeTextarea}
                        />
                    </td>
                </tr>
                <tr>
                    <th>첨부파일</th>
                    <td colSpan={3}>
                        <CFileField
                            id="dataset-title"
                            name="file"
                            value={inputs.file}
                            onChange={handleFileSelect}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default DatasetCreateDialog;
