import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from 'api/axiosUtils';
import { asyncState, createAsyncReducer, createAsyncSaga, reducerUtils } from 'utils/asyncUtils';
import { takeLatest, takeEvery } from '@redux-saga/core/effects';
import { getInstanceList, getAllInstanceList, VmInstance, createVmInstance, sendActionToVm, getSuAdminInstanceList, getInstanceListPaging, getSuAdminInstanceListPaging } from 'api/environment';

type EnvironmentState = {
    expectedResponse: asyncState;
    ongoingResponse: asyncState;
    completedResponse: asyncState;
    wholeResponse: asyncState;
    practiecPurposeResponse: asyncState;
    researchPurposeResponse: asyncState;
    createVmResponse: asyncState;
    vmActionResponse: asyncState;
    selectedVm: VmInstance | null;
};

const initialState: EnvironmentState = {
    expectedResponse: reducerUtils.initial(),
    ongoingResponse: reducerUtils.initial(),
    completedResponse: reducerUtils.initial(),
    wholeResponse: reducerUtils.initial(),
    practiecPurposeResponse: reducerUtils.initial(),
    researchPurposeResponse: reducerUtils.initial(),
    createVmResponse: reducerUtils.initial(),
    vmActionResponse: reducerUtils.initial(),
    selectedVm: null,
};

const environmentSlice = createSlice({
    name: "environment",
    initialState: initialState,
    reducers: {
        setSelectedVm(state, action: PayloadAction<VmInstance | null>) {
            state.selectedVm = action.payload;
        },
    },
    extraReducers: {
        ...createAsyncReducer('environment/getExpectedList', 'expectedResponse'),
        ...createAsyncReducer('environment/getOngoingList', 'ongoingResponse'),
        ...createAsyncReducer('environment/getCompletedList', 'completedResponse'),
        ...createAsyncReducer('environment/createVmInstance', 'createVmResponse'),
        ...createAsyncReducer('environment/requestVmAction', 'vmActionResponse'),
        ...createAsyncReducer('environment/getWholeList','wholeResponse'),
        ...createAsyncReducer('environment/getPracticePurposeList', 'practiecPurposeResponse'),
        ...createAsyncReducer('environment/getResearchPurposeList', 'researchPurposeResponse'),
        ...createAsyncReducer('environment/getSuPracticePurposeList', 'practiecPurposeResponse'),
        ...createAsyncReducer('environment/getSuResearchPurposeList', 'researchPurposeResponse'),
    }
});

export const {
    setSelectedVm
} = environmentSlice.actions;

export default environmentSlice.reducer;

/* saga */

const getExpectedListSaga = createAsyncSaga<VmInstance[]>('environment/getExpectedList', getInstanceList);
const getOngoingListSaga = createAsyncSaga<VmInstance[]>('environment/getOngoingList', getInstanceList);
const getCompletedListSaga = createAsyncSaga<VmInstance[]>('environment/getCompletedList', getInstanceList);
const getWholeListSaga = createAsyncSaga<VmInstance[]>('environment/getWholeList', getAllInstanceList);
const createVmInstanceSaga = createAsyncSaga<ApiResponse>('environment/createVmInstance', createVmInstance);
const requestVmActionSaga = createAsyncSaga<ApiResponse>('environment/requestVmAction', sendActionToVm);
const getPracticePurposeListSaga = createAsyncSaga<VmInstance[]>('environment/getPracticePurposeList', getInstanceListPaging);
const getResearchPurposeListSaga = createAsyncSaga<VmInstance[]>('environment/getResearchPurposeList', getInstanceListPaging);
const getSuPracticePurposeListSaga = createAsyncSaga<VmInstance[]>('environment/getSuPracticePurposeList', getSuAdminInstanceListPaging);
const getSuResearchPurposeListSaga = createAsyncSaga<VmInstance[]>('environment/getSuResearchPurposeList', getSuAdminInstanceListPaging);


export function* environmentSaga() {
    yield takeLatest('environment/getExpectedList', getExpectedListSaga);
    yield takeLatest('environment/getOngoingList', getOngoingListSaga);
    yield takeLatest('environment/getCompletedList', getCompletedListSaga);
    yield takeLatest('environment/getWholeList', getWholeListSaga);
    yield takeEvery('environment/createVmInstance', createVmInstanceSaga);
    yield takeEvery('environment/requestVmAction', requestVmActionSaga);
    yield takeLatest('environment/getPracticePurposeList', getPracticePurposeListSaga);
    yield takeLatest('environment/getResearchPurposeList', getResearchPurposeListSaga);
    yield takeLatest('environment/getSuPracticePurposeList', getSuPracticePurposeListSaga);
    yield takeLatest('environment/getSuResearchPurposeList', getSuResearchPurposeListSaga);
}