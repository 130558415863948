import React, { useState, useEffect } from 'react';
import { useReservation } from 'hooks/useReservation';
import { swalConfirm } from 'utils/swal-utils';
import { useSwal } from 'hooks/useSwal';
import { reservationState, Reservation } from 'api/reservation';
import { reservationStateClass } from 'utils/state';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CSelectButton } from "components/buttons";
import { formatDateTimeString } from 'utils/dateUtils';

type ReservationDetailProps = {
    tab: string;
};

function ReservationDetail({
    tab
}: ReservationDetailProps) {
    const { selectedReservation, callPermitReservation } = useReservation();
    const { openSwal } = useSwal();

    const [stateBarClass, setStateBarClass] = useState<string>('stateBar ');

    useEffect(() => {
        if (selectedReservation === null) {
            return;
        }
        switch(selectedReservation.stat_cd) {
            case (0):
                return setStateBarClass('stateBar ' + reservationStateClass.STANDBY);
            case (1):
                return setStateBarClass('stateBar ' + reservationStateClass.APPROVE);
            case (2):
                return setStateBarClass('stateBar ' + reservationStateClass.REJECT);
            case (3):
                return setStateBarClass('stateBar ' + reservationStateClass.POSTPONE);
            case (4):
                return setStateBarClass('stateBar ' + reservationStateClass.DELETE);
            case (5):
                return setStateBarClass('stateBar ' + reservationStateClass.STOP);
            case (6):
                return setStateBarClass('stateBar ' + reservationStateClass.WITHDRAW);
            case (7):
                return setStateBarClass('stateBar ' + reservationStateClass.EXTEND);
            case (9):
                return setStateBarClass('stateBar ' + reservationStateClass.ETC);
            default: 
                return;
        }
    }, [selectedReservation]);

    if (selectedReservation === null) {
        return (
            <PanelBox></PanelBox>
        );
    }

    const actionList = [
        {
            name: '승인',
            onClick: () => {
                openSwal({ text: "승인하시겠습니까?", onClickConfirm: () => callPermitReservation(selectedReservation.nseq, reservationState.APPROVE, tab) });
            },
        },
        {
            name: '보류',
            onClick: () => {
                openSwal({ text: "보류하시겠습니까?", onClickConfirm: () => callPermitReservation(selectedReservation.nseq, reservationState.POSTPONE, tab) });
            },
        },
        {
            name: '정지',
            onClick: () => {
                openSwal({ text: "정지 하시겠습니까?", onClickConfirm: () => callPermitReservation(selectedReservation.nseq, reservationState.STOP, tab) });
            },
        },
    ];

    
    return (
        <PanelBox className="panel_detail">
            <div className="panelTitBar">
                <div className="tit"><strong>{selectedReservation.id}</strong>의 예약 신청 상세</div>
            </div>
            {
                (tab !== 'END') &&
                <CommActionBar>
                    <CSelectButton
                        items={actionList}
                    >
                        액션
                    </CSelectButton>
                </CommActionBar>
            }
            <div className="panelCont">
                <div className={stateBarClass}>
                    <span className="state_ico">{selectedReservation.stat_str}</span>
                </div>
                <table className="tb_data">
                    <tbody>
                    <tr>
                        <th>아이디</th>
                        <td>{selectedReservation.id}</td>
                        <th>이름</th>
                        <td>{selectedReservation.name}</td>
                    </tr>
                    <tr>
                        <th>이용시간</th>
                        <td>{formatDateTimeString(selectedReservation.start_day)}  ~  {formatDateTimeString(selectedReservation.end_day)} </td>
                        <th>수업</th>
                        <td>{selectedReservation.sbj_nm}</td>
                    </tr>
                    <tr>
                        <th>신청사유</th>
                        <ReservationReason selectedReservation={selectedReservation} />
                    </tr>
                    </tbody>
                </table>
            </div>
        </PanelBox>
    );
}

type ReservationReasonType = {
    selectedReservation: Reservation;
};

function ReservationReason({
    selectedReservation
}: ReservationReasonType) {
    if (selectedReservation.res_cd === "TRAIN") {
        return (
            <td colSpan={3}>
                <div>수업 실습용</div>
            </td>
        );
    }
    else if (selectedReservation.res_cd === "STUDY") {
        return (
            <td colSpan={3}>
                <div>자습용</div>
            </td>
        );
    }
    else if (selectedReservation.res_cd === "RESEARCH") {
        return (
            <td colSpan={3}>
                <div>연구용</div>
            </td>
        );
    }
    else if (selectedReservation.res_cd === "ETC") {
        return (
            <td colSpan={3}>
                <div>기타사유</div>
                <div className="added">{selectedReservation.signup_msg}</div>
            </td>
        );
    }
    else {
        return (
            <React.Fragment />
        );
    }
}

export default ReservationDetail;