import React, { useState, useEffect, useRef } from 'react';
import * as Common from '../../utils/common.js';

//import DatePicker from '../../components/datepicker/datepicker';

function ModifyUserInfo({ user, onSubmit, onCancel, depList }) {
    const [role, setRole] = useState("");
    //const [birthday, setBirthday] = useState(user.birth_day);
    const [mobileNum, setMobileNum] = useState(user.mobile_no);
    const [depCd, setDepCd] = useState(user.dept_cd);

    //const birthdayRef = useRef();
    const mobileRef = useRef();

    useEffect(() => {
        // set role name
        if (user.role_cd === "CIVIL") {
            setRole("실습자");
        }
        else if (user.role_cd === "ADMIN") {
            setRole("관리자");
        }
        else if (user.role_cd === "SUPER") {
            setRole("총괄관리자");
        }
        else {
            setRole("");
        }

        // set birthday
        //const birthdayData = Common.formatDate(user.birth_day);
        //setBirthday(user.birth_day);

        // set mobile number
        //const mobileData = Common.formatPhoneNumber(user.mobile_no);
        setMobileNum(user.mobile_no);
    }, [user]);

    /*const getSelectedDate = (date) => {
        console.log(date);
        setBirthday(date);
    };*/

    const onClickModifyBtn = () => {
        //const modifiedBirthday = birthdayRef.current.value;
        //const modifiedMobile = mobileRef.current.value;

        //onSubmit(birthday, mobileNum, depCd);
        onSubmit(mobileNum, depCd);
    };

    const onChangeValue = (e) => {
        setMobileNum(e.target.value);
    };

    const handleDepChange = (event) => {
        const newDep = event.target.value;
        setDepCd(newDep);
    };

    if (user.role_cd === "CIVIL") {
        return (
            <div className="join_wrap mypage_wrap">
                <div className="join_box_wrap">
                    <table className="tb_style_02">
                        <colgroup>
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                        </colgroup>
                        <tr>
                            <th>아이디</th>
                            <td>
                                <span className="mypage_info">{user.id}</span>
                            </td>
                            <th>권한</th>
                            <td>
                                <span className="mypage_info">{role}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>
                                <span className="mypage_info">{user.name}</span>
                            </td>
                            <th>학교(소속)</th>
                            <td>
                                <span className="mypage_info">{user.col_nm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>학과(부서)</th>
                            <td>
                                {/*<span className="mypage_info">{user.dep_nm}</span>*/}
                                <div class="select_wrap">
                                    <select name="dep_cd" onChange={handleDepChange}>
                                        {
                                            depList.map((dep, i) => {
                                                if (dep.dep_cd === user.dept_cd) {
                                                    return (<option key={i} value={dep.dep_cd} selected>{dep.dep_nm}</option>);
                                                }
                                                else {
                                                    return (<option key={i} value={dep.dep_cd}>{dep.dep_nm}</option>);
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                            </td>
                            <th>학번(사번)</th>
                            <td>
                                <span className="mypage_info">{user.class_no}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>
                                <span className="mypage_info">{user.email}</span>
                            </td>
                            {/*<th>생년월일</th>
                            <td>
                                <DatePicker returnDate={getSelectedDate} readyDateValue={user.birth_day} />
                            </td>*/}
                        </tr>
                        <tr>
                            <th style={{borderRight: "1px solid #e4e8ec"}}>연락처</th>
                            <td colSpan="3">
                                <input type="text" class="input_style_02" value={mobileNum} onChange={onChangeValue} />
                            </td>
                        </tr>
                    </table>
                    <div className="btn_wrap">
                        <button className="btn_cancel" style={{marginRight: "0.3rem"}} onClick={onCancel}>취소</button>
                        <button className="btn_summit" onClick={onClickModifyBtn}>저장</button>
                    </div>
                </div>
            </div>
        );
    }
    else if (user.role_cd === "ADMIN" || user.role_cd === "SUPER") {
        return (
            <div className="join_wrap mypage_wrap">
                <div className="join_box_wrap">
                    <table className="tb_style_02">
                        <colgroup>
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                            <col style={{width: "15%"}} />
                            <col style={{width: "35%"}} />
                        </colgroup>
                        <tr>
                            <th>아이디</th>
                            <td>
                                <span className="mypage_info">{user.id}</span>
                            </td>
                            <th>권한</th>
                            <td>
                                <span className="mypage_info">{role}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>
                                <span className="mypage_info">{user.name}</span>
                            </td>
                            <th>학교(소속)</th>
                            <td>
                                <span className="mypage_info">{user.col_nm}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>학과(부서)</th>
                            <td>
                                <span className="mypage_info">{user.dep_nm}</span>
                            </td>
                            <th>이메일</th>
                            <td>
                                <span className="mypage_info">{user.email}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style={{borderRight: "1px solid #e4e8ec"}}>연락처</th>
                            <td colSpan="3">
                                <input type="text" class="input_style_02" value={mobileNum} onChange={onChangeValue} />
                            </td>
                        </tr>
                    </table>
                    <div className="btn_wrap">
                        <button className="btn_cancel" style={{marginRight: "0.3rem"}} onClick={onCancel}>취소</button>
                        <button className="btn_summit" onClick={onClickModifyBtn}>저장</button>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div></div>
        );
    }
}

export default ModifyUserInfo;