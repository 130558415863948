import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import { swalConfirm } from "utils/swal-utils";
import { CTextField } from "components/textfields";
import { CFileField } from "components/textfields/CFilefield";

const DatasetEditDialog = (props) => {
    const {open} = props;
    const handleClose = () => {
        props.onClose && props.onClose();
    };
    const handleUpdateAlert = () => {
        swalConfirm("수정하시겠습니까?");
    }

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="md"
            title={`공모전 수정하기`}
            onClose={handleClose}
            onUpdate={handleUpdateAlert}
            modules={['update']}
            topBtn={true}
            topModules={['update','delete']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>제목</th>
                    <td colSpan={3}>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            placeholder="제목을 입력하세요."
                            className="form_fullWidth"
                        />
                    </td>
                </tr>
                <tr>
                    <th>주최</th>
                    <td>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            placeholder="주최기관을 입력하세요."
                            className="form_fullWidth"
                        />
                    </td>
                    <th>주관</th>
                    <td>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            placeholder="주관기관을 입력하세요."
                            className="form_fullWidth"
                        />
                    </td>
                </tr>
                <tr>
                    <th>응모분야</th>
                    <td>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            placeholder=""
                            className="form_fullWidth"
                        />
                    </td>
                    <th>참가자격</th>
                    <td>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            placeholder=""
                            className="form_fullWidth"
                        />
                    </td>
                </tr>
                <tr>
                    <th>시상종류</th>
                    <td colSpan={3}>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            placeholder=""
                            className="form_fullWidth"
                        />
                    </td>
                </tr>
                <tr>
                    <th>접수기간</th>
                    <td colSpan={3}>
                        <div className="inputWrap_dividers">
                            <div className="inputArea">
                                <CTextField
                                    id=""
                                    type="text"
                                    placeholder="접수일"
                                    className="form_fullWidth"
                                />
                            </div>
                            <span className="mdd">~</span>
                            <div className="inputArea">
                                <CTextField
                                    id=""
                                    type="text"
                                    placeholder="마감일"
                                    className="form_fullWidth"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>포스터 이미지</th>
                    <td>
                        <CFileField
                            id=""
                            buttonText="첨부"
                            className="small"
                        />
                    </td>
                    <th>배너 이미지</th>
                    <td>
                        <CFileField
                            id=""
                            buttonText="첨부"
                            className="small"
                        />
                    </td>
                </tr>
                <tr>
                    <th>첨부파일</th>
                    <td colSpan={3}>
                        <CFileField
                            id="dataset-title"
                        />
                        <span className="td-txt"><button type="button" className="link_file">첨부파일.pdf</button></span>
                    </td>
                </tr>
                <tr>
                    <th>상세내용</th>
                    <td colSpan={3}>
                        <textarea
                            placeholder="내용을 입력하세요."
                            rows="10"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default DatasetEditDialog;
