import { AxiosResponse } from 'axios';
import CallApi from 'utils/customAxiosUtils';
import { handleError } from './axiosUtils';

export const getDatasetList = async () => {
    try {
        const requestUrl = "/dataset/admin/list";
        const res = await CallApi.get(requestUrl);
        return res;
    } catch (error: any) {
        handleError(error);
        return [];
    }
};

export const getDatasetCategories = async () => {
    try {
        const requestUrl = "/dataset/category";
        const res = await CallApi.get(requestUrl);
        return res;
    } catch (error: any) {
        handleError(error);
        return [];
    }
};

export const getFileExtensions = async () => {
    try {
        const requestUrl = "/dataset/extension";
        const res = await CallApi.get(requestUrl);
        return res;
    } catch (error: any) {
        handleError(error);
        return [];
    }
};

export const addDataset = async (inputs: any) => {
    try {
        const requestUrl = "/dataset/admin/register";
        const res = await CallApi.post(requestUrl, inputs, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return true;
    } catch (error: any) {
        handleError(error);
        return false;
    }
};

export const getDatasetDetail = async (dsseq: number) => {
    try {
        const requestUrl = '/dataset/content';
        const res = await CallApi.get(requestUrl, {
            params: {
                dsseq: dsseq
            }
        });
        return res;
    } catch (error: any) {
        handleError(error);
    }
};

export const checkExtension = async (fileName: string) => {
    try {
        const requestUrl = '/dataset/extension-check';
        const res: { extension_cd: string, feseq: number } = await CallApi.get(requestUrl, {
            params: {
                file_nm: fileName
            }
        });
        return res;
    } catch (error: any) {
        handleError(error);
    }
};

export const modifyDataset = async (inputs: any) => {
    try {
        console.log(inputs);
        const requestUrl = '/dataset/admin/modify';
        const res = await CallApi.post(requestUrl, inputs, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        //return true;
        return { success: true, dsseq: inputs.get('dsseq') };
    } catch (error: any) {
        handleError(error);
    }
};

export const deleteDataset = async (dsseq: number | string) => {
    try {
        const requestUrl = '/dataset/admin/delete';
        const res = await CallApi.delete(requestUrl, {
            data: {
                dsseq: dsseq
            }
        });
        return res;
    } catch (error: any) {
        handleError(error);
    }
};