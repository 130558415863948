import React, { useState, useEffect, useCallback } from 'react';
import { useMypage } from 'hooks/useMypage';
import UsagePagination from './usagePagination';
import { Usage } from 'api/mypage';


function UserUsageDetail(){
    const { usageListResponse, callGetUsageList } = useMypage();
    const [ rowData, setRowData ] = useState([]);
    const [ pageNumber, setPageNumber] = useState<number>(1);
    const [ total, setTotal ] = useState(0);

    useEffect(() => {
        if (!usageListResponse.loading && !usageListResponse.error && usageListResponse.data){
            const tmpDetailList = usageListResponse.data.map((detail:Usage) => {
                let tmpDetail = {...detail};               
                if(tmpDetail.total !==undefined){
                    setTotal(tmpDetail.total)
                }else if(tmpDetail.total === 0){
                    setTotal(0);
                }
                return tmpDetail;
            });
            setRowData(tmpDetailList);
        }else if(!usageListResponse.loading && usageListResponse.error){
            console.log('no data')
        }
    }, [usageListResponse]);

    const updateUsageList = (newList?:any) => {
        let List : any = {};
        if( newList === undefined){
            List = {
                'page': pageNumber,
            }
            callGetUsageList(List);
        }else{
            callGetUsageList(newList);
        }
    };

    useEffect(() => {
        updateUsageList();
    }, [pageNumber]);


    const onChangeNoticePage = (page:number) => {
        setPageNumber(page);
    };
    
    let listNumber = total-((pageNumber-1)*5);

    return(
        <React.Fragment>
            <h3 className="board_tit">가상 실습실 이용 내역</h3>
            <div className="vm_use_list">
                <table className="tb_style_03">
                    <tr>
                        <th className="tb_num">NO</th>
                        {/* <th className="tb_name">학교</th>
                        <th className="tb_name">학과</th> */}
                        <th className="tb_name">실습실 용도 </th>
                        <th> 신청 기간</th>
                        <th className="tb_use">사용 일수</th>
                    </tr>
                    {
                        (total === 0 )
                        ? <tr><td colSpan={6}>이용내역이 없습니다.</td></tr>
                        : rowData.map((detail:any, idx:number) => {
                            return(
                            <tr key={idx}>
                            <td className="tb_num" key={idx}>{listNumber--}</td>
                            {/* <td className="tb_name">{detail.col_nm}</td>
                            <td className="tb_name">{detail.dep_nm}</td> */}
                            <td className="tb_name" style={{overflow: 'hidden',textOverflow: 'ellipsis'}}>{detail.sbj_nm}</td>
                            <td>{detail.start_day} ~ {detail.end_day}</td>
                            <td className="tb_use" style={{width:'130px'}}>{detail.date_diff}일</td>
                            </tr>
                        )})
                    }
                </table>
                <div className="pagenation_wrap" style={{marginTop: "0"}}>
                    {
                    
                       <div className="pagenation_inner"><UsagePagination totalCount={total} itemPerPage={5} onChangePage={onChangeNoticePage} /></div>
                    }
                </div> 
            </div>
        </React.Fragment>
    )

}

export default UserUsageDetail;