import React, { useState, useEffect } from 'react';
import { Title } from 'pages';
import { useEnvironment } from 'hooks/useEnvironment';
import { useLoginUser } from 'hooks/useLoginUser';

import Layout from 'layout';
import { CTabs, CTab, CTabPanel } from 'components/tabs';
import EnvironmentTab from './EnvironmentTab';
import EnvironmentDetail from './EnvironmentDetail';
import { infoToast } from 'utils/tostifyUtils';
import CreateProgressBar from './CreateProgressBar';

function Environment() {
    const currentPage = Title.Environment;

    const { 
        expectedResponse, 
        ongoingResponse, 
        completedResponse, 
        wholeResponse, 
        createVmResponse, 
        practiecPurposeResponse,
        researchPurposeResponse,
        callGetCompletedEnvironmentList, 
        callGetExpectedEnvironmentList, 
        callGetOngoingEnvironmentList, 
        callGetWholeEnvironmentList,
        callPracticePurposeList,
        callResearchPurposeList,
        callSuPracticePurposeList,
        callSuResearchPurposeList
    } = useEnvironment();
    const { userRole } = useLoginUser();

    const [tabValue, setTabvalue] = useState(0);
    const [timing, setTiming] = useState<string>('');

    useEffect(() => {
        if (tabValue === 0) {
            //setTiming('EXPECT');
            setTiming('PRACTICE');
        }
        else if (tabValue === 1) {
            //setTiming('ING');
            setTiming('RESEARCH');
        }
        else if (tabValue === 2) {
            //setTiming('END');
            setTiming('ALL');
        }
        else if (tabValue === 3) {
            setTiming('ALL');
        }
        else {
            setTiming('');
        }
    }, [tabValue]);

    /*useEffect(() => {
        if (!createVmResponse.loading && createVmResponse.data) {
            infoToast(createVmResponse.data.message);
        }
    }, [createVmResponse]);*/

    const handleTabChange = (event: any, newValue: number) => {
        setTabvalue(newValue);
    };

    return (
        <Layout currentPage={currentPage}>
            <div className="headerOption">
            </div>
            <CTabs
                type="tab1"
                value={tabValue}
                onChange={handleTabChange}
            >
                {/*<CTab label="시작 예정" />
                <CTab label="진행중" />
                <CTab label="종료" />
                <CTab label="전체" />*/}
                <CTab label="수업 실습용" />
                <CTab label="연구 목적용" />
                <CTab label="전체" />
            </CTabs>
            {/*<CTabPanel
                value={tabValue}
                index={0}
            >
                <EnvironmentTab timing="EXPECT" listResponse={expectedResponse} fetchList={callGetExpectedEnvironmentList} />
            </CTabPanel>
            <CTabPanel
                value={tabValue}
                index={1}
            >
                <EnvironmentTab timing="ING" listResponse={ongoingResponse} fetchList={callGetOngoingEnvironmentList} />
            </CTabPanel>
            <CTabPanel
                value={tabValue}
                index={2}
            >
                <EnvironmentTab timing="END" listResponse={completedResponse} fetchList={callGetCompletedEnvironmentList} />
            </CTabPanel>
            <CTabPanel
                value={tabValue}
                index={3}
            >
                <EnvironmentTab timing="ALL" listResponse={wholeResponse} fetchList={callGetWholeEnvironmentList} />
            </CTabPanel>*/}
            <CTabPanel
                value={tabValue}
                index={0}
            >
                {
                    (userRole === 'ADMIN') 
                    ? <EnvironmentTab timing="PRACTICE" listResponse={practiecPurposeResponse} fetchList={callPracticePurposeList} vmPurpose={'G'} />
                    : <EnvironmentTab timing="PRACTICE" listResponse={practiecPurposeResponse} fetchList={callSuPracticePurposeList} vmPurpose={'G'} />
                }
            </CTabPanel>
            <CTabPanel
                value={tabValue}
                index={1}
            >
                {
                    (userRole === 'ADMIN') 
                    ? <EnvironmentTab timing="RESEARCH" listResponse={researchPurposeResponse} fetchList={callResearchPurposeList} vmPurpose={'R'} />
                    : <EnvironmentTab timing="RESEARCH" listResponse={researchPurposeResponse} fetchList={callSuResearchPurposeList} vmPurpose={'R'} />
                }
            </CTabPanel>
            <CTabPanel
                value={tabValue}
                index={2}
            >
                <EnvironmentTab timing="ALL" listResponse={wholeResponse} fetchList={callGetWholeEnvironmentList} vmPurpose={'R'} />
            </CTabPanel>
            <EnvironmentDetail timing={timing} />
            <CreateProgressBar />
        </Layout>
    );
}

export default Environment;