import React, {useEffect, useState} from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import theme from 'styles/theme';

const useStyles = makeStyles(() =>
    createStyles({
        primary: {
            backgroundColor: `${theme.colors.primaryLight}`,
            color: '#fff',
            fontWeight: '300',
            '&:hover': {
                backgroundColor: `${theme.colors.primaryDark}`,
                color: '#fff',
            },
            '&:disabled': {
                backgroundColor: '#8a9aab',
                color: '#fff'
            },
        },
        secondary: {
            backgroundColor: '#ccc',
            color: `${theme.colors.defaultDark}`,
            '&:hover': {
                backgroundColor: '#b6b6b6',
            },
            '&:disabled': {
                opacity: 0.5
            },
        },
        tertiary: {
            border: `1px solid ${theme.colors.defaultDark}`,
            background: '#fff',
            color: `${theme.colors.defaultDark}`,
            '&:hover': {
                backgroundColor: '#fff',
                borderColor: `${theme.colors.primaryDark}`,
                color: `${theme.colors.primaryDark}`,
            },
            '&:disabled': {

            },
        },
        '@global': {
            '.btn_common': {
                minWidth: 112,
                height: 38,
                padding: '0 20px',
                fontFamily: 'inherit',
                fontSize: '13px',
                borderRadius: '3px',
                transition: '0.2s',
            }
        }
    }),
);

const CButton = (props) => {
    const {
        children,
        type,
        style,
        className,
        buttonEventType = "button",
        onClick,
        role = "ROLE_USER",
        ...other
    } = props;
    const classes = useStyles();

    const buttonType =
        (type === 'btn1' && classes.primary) ||
        (type === 'btn2' && classes.secondary) ||
        (type === 'btn3' && classes.tertiary) ||
        classes.tertiary;

    return (
        <Button
            type={buttonEventType}
            className={`btn_common ${buttonType} ${className}`}
            style={style}
            onClick={onClick}
            {...other}
        >
            {children}
        </Button>
    );
};

export { CButton };
