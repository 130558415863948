import React, { useState, useEffect } from 'react';
import { useTemplate } from 'hooks/useTemplate';
import { openSwal, swalConfirm } from "utils/swal-utils";

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { CIconButton } from "components/buttons";
import TemplateEditDialog from './TemplateEditDialog';
import TemplateDeleteDialog from './TemplateDeleteDialog';

function TemplateDetail() {
    const { selectedTemplate, callGetTemplateList, callDeleteTemplate } = useTemplate();
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => {
        callGetTemplateList();
    }, [editOpen]);

    if (selectedTemplate === null ) {
        return (
            <PanelBox className="panel_detail">

            </PanelBox>
        );
    }

    const handleEdit = () => {
        setEditOpen(true);
    }
    const handleEditClose = () => {
        setEditOpen(false);
    }

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    return (
        <PanelBox className="panel_detail">
            <div className="panelTitBar">
                <div className="tit"><strong>{selectedTemplate.tp_nm}</strong>의 상세보기</div>
            </div>
            <CommActionBar>
                <div className="iconBtnGrope">
                    <CIconButton
                        icon="edit"
                        tooltip="수정"
                        onClick={handleEdit}
                    />
                    <CIconButton
                        icon="delete2"
                        tooltip="삭제"
                        onClick={handleDeleteOpen}
                    />
                </div>
                <TemplateEditDialog
                    open={editOpen}
                    onClose={handleEditClose}
                />
                <TemplateDeleteDialog
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                />
            </CommActionBar>
            <div className="panelCont">
                <table className="tb_data">
                    <tbody>
                    <tr>
                        <th>템플릿명</th>
                        <td>{selectedTemplate.tp_nm}</td>
                        <th>관리자 ID</th>
                        <td>admin</td>
                    </tr>
                    <tr>
                        <th>네트워크</th>
                        <td>{selectedTemplate.network_nm}</td>
                        <th>이미지</th>
                        <td>{selectedTemplate.image_nm}</td>
                    </tr>
                    <tr>
                        <th>사양</th>
                        <td>{selectedTemplate.flavor_id}</td>
                        <th>생성일시</th>
                        <td>{selectedTemplate.reg_date}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </PanelBox>
    );
}

export default TemplateDetail;