import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import admin from 'images/portal/mypage/join_ico_admin.png';

const Text = styled.div`
    white-space: pre-wrap;
`;

function AdminJoinPolicy({ history, match }) {
    const [open, setOpen] = useState(false);

    const onClickRead = () => {
        setOpen((prev) => (!prev));
    };

    const onlineAgreeCheck = useRef();
    const personalAgreeCheck = useRef();

    const goBack = () => {
        history.goBack();
    };

    const onClickAgreeBtn = () => {
        const online = onlineAgreeCheck.current.checked;
        const personal = personalAgreeCheck.current.checked;

        console.log(online);
        console.log(personal);

        if (online && personal) {
            history.push(`${match.url}/admin_join`);
        }
        else {
            //open alert?
            window.alert("모두 동의하여야 합니다.");
        }
    };

    return (
        <div className="login_wrap">
            <div className="join_tit">
                <img src={admin} alt="admin" /> 관리자 회원가입
            </div>
            <div className="join_agree_wrap">
                <div className="join_agree">
                    <h3 className="board_tit">온라인 회원약관</h3>
                    <div className="text_box">
                        <Text>
                            <p className="clause_tit">제1장 총칙</p>
                            <p><strong>제1조 (목적) </strong></p>
                            <br/>
                            이 약관은 경남 빅데이터 공유플랫폼 센터(이하“센터”)가 온라인으로 제공하는 빅데이터 공유플랫폼(이하 “플랫폼”) 및
                            제반서비스의 이용과 관련하여 센터와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                            <br/>
                            <br/>
                            <strong>제2조(정의)</strong>
                            <br/>
                            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                            <br/>
                            1. "이용자"라 함은 "센터"의 사이트에 접속하여 이 약관에 따라 "센터"가 제공하는 "플랫폼" 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.<br/>
                            2. "회원"이라 함은 "센터"와 이용계약을 체결하고 "이용자" 아이디(ID)를 부여받은 "이용자"로서 "센터"의 정보를 지속적으로 제공받으며 "센터"가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.<br/>
                            3. "비회원"이라 함은 "회원"이 아니면서 "센터"가 제공하는 서비스를 이용하는 자를 말합니다.<br/>
                            4. "아이디(ID)"라 함은 "회원"의 식별과 서비스이용을 위하여 "회원"이 정하고 "센터"가 승인하는 문자 또는 숫자의 조합을 말합니다.<br/>
                            5. "비밀번호(PASSWORD)"라 함은 "회원"이 부여받은 "아이디"와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 말합니다.
                            <br/>
                            <br/>
                            <p className="clause_tit">제2장 회원가입</p>
                            <br/>
                            <strong>제3조(회원가입)</strong>
                            <br/>
                            ① 회원가입은 "이용자"가 약관의 내용에 대하여 동의를 하고 회원가입신청을 한 후 "센터"가 이러한 신청에 대하여 승낙함으로써 체결됩니다. <br/>
                            ② 회원가입신청서에는 다음 사항을 기재해야 합니다. 1호 내지 3호의 사항은 필수사항이며, 그 외의 사항은 선택사항입니다. <br/>
                            <span className="clause_indent">1. "회원"의 성명과 인터넷상 개인식별번호</span>
                            <span className="clause_indent">2. "아이디"와 "비밀번호"</span>
                            <span className="clause_indent">3. “학교(회사명)”와 “학과(부서)”와 “학번(사번)”과 “직업”</span>
                            <span className="clause_indent">4. 기타 "센터"가 필요하다고 인정하는 사항</span>
                            ③ "센터"는 상기 "이용자"의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, "센터"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.<br/>
                            <span className="clause_indent">1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</span>
                            <span className="clause_indent">2. 실명이 아니거나 타인의 명의를 이용한 경우</span>
                            <span className="clause_indent">3. 허위의 정보를 기재하거나, 센터가 제시하는 내용을 기재하지 않은 경우</span>
                            <span className="clause_indent">4. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</span>
                            <span className="clause_indent">5. 위법, 불법행위 등 부정한 용도로 서비스를 이용하고자 하는 경우</span>
                            <span className="clause_indent">6. 버그 및 악성 프로그램을 이용하거나 시스템 취약점을 악용하여 부정한 방법으로 회원가입 신청을 한 경우</span>
                            ④ "센터"는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br/>
                            ⑤ 제3항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "센터"는 이를 신청자에게 알려야 합니다. "센터"의 귀책사유 없이 신청자에게 통지할 수 없는 경우에는 예외로 합니다.<br/>
                            ⑥ 회원가입계약의 성립 시기는 "센터"의 승낙이 "이용자"에게 도달한 시점으로 합니다.
                            <br/>
                            <br/>
                            <strong>제4조(회원가입정보의 변경) </strong>
                            ① “회원”은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.<br/>
                            ② "회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 "센터"에 대하여 그 변경사항을 알려야 합니다.<br/>
                            ③ 제2항의 변경사항을 "센터"에 알리지 않아 발생한 불이익에 대하여 "센터"는 책임지지 않습니다.
                            <br/>
                            <br/>
                            <p className="clause_tit">제3장 서비스 이용</p>
                            <br/>
                            <strong>제5조(서비스의 이용개시)</strong>
                            <br/>
                            ① “센터”는 “회원”의 가입신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.<br/>
                            ② "센터"의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 홈페이지에 공시하거나 "회원"에게 이를 통지합니다.
                            <br/>
                            <br/>
                            <strong>제6조(서비스의 제공)</strong>
                            <br/>
                            ① "센터"는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이러한 경우에는 그 내용을 사전에 공지합니다.<br/>
                            ② "서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.<br/>
                            ③ "센터"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. <br/>
                            이 경우 "센터"는 제11조에서 정한 방법으로 "회원"에게 통지합니다.<br/>
                            다만, "센터"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br/>
                            ④ "센터"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공 화면에 공지한 바에 따릅니다.
                            <br/><br/>
                            <strong>제7조(서비스의 변경 및 중지)</strong>
                            <br/>
                            ① "센터"는 수시로 "서비스"의 향상을 위하여 기존 서비스의 전부 또는 일부 내용을 별도의 통지 없이 변경할 수 있습니다.<br/>
                            ② "센터"는 다음 각 호에 해당하는 경우 "서비스"의 전부 또는 일부를 제한하거나 중지할 수 있습니다.<br/>
                            <span className="clause_indent">1. 컴퓨터 등 정보통신설비의 보수점검 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우</span>
                            <span className="clause_indent">2. 서비스를 위한 설비의 보수 등 공사로 인해 부득이한 경우</span>
                            <span className="clause_indent">3. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</span>
                            <span className="clause_indent">4. "회원"이 "센터"의 업무 활동을 방해하는 경우</span>
                            <span className="clause_indent">5. 서비스 제공업자와의 계약종료 등 "센터"의 제반 사정으로 서비스를 유지할 수 없는 경우</span>
                            <span className="clause_indent">6. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</span>
                            ③ 본 조 제2항에 의한 서비스 중단의 경우에는 센터가 제11조에서 정한 방법으로 "회원"에게 통지합니다. 단, "센터"가 통제할 수 없는 사유로 인한 서비스의 중단(운영자의 고의, 과실이 없는 디스크 장애, 시스템 장애 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
                            <br/>
                            <br/>
                            <strong>제8조(센터의 의무)</strong>
                            <br/>
                            ① "센터"는 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.<br/>
                            ② "센터"는 "이용자"가 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 하며 개인정보보호정책을 공시하고 준수합니다.<br/>
                            ③ "센터"는 콘텐츠이용과 관련하여 "이용자"로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체없이 처리합니다.
                            <br/>
                            <br/>
                            <strong>제9조(이용자의 의무)</strong>
                            <br/>
                            ① "이용자"는 다음 행위를 하여서는 안 됩니다.<br/>
                            <span className="clause_indent">1. 신청 또는 변경 시 허위내용의 기재</span>
                            <span className="clause_indent">2. 타인의 정보도용</span>
                            <span className="clause_indent">3. "센터"에 게시된 정보의 변경</span>
                            <span className="clause_indent">4. "센터"가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</span>
                            <span className="clause_indent">5. "센터"와 기타 제3자의 저작권 등 지적재산권에 대한 침해</span>
                            <span className="clause_indent">6. "센터" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</span>
                            <span className="clause_indent">7. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 "센터"의 사이트에 공개 또는 게시하는 행위</span>
                            <span className="clause_indent">8. 기타 불법적이거나 부당한 행위</span>
                            ② "이용자"는 관계법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, "센터"가 통지하는 사항 등을 준수하여야 하며, 기타 "센터"의 업무에 방해되는 행위를 하여서는 안 됩니다.
                            <br/>
                            <br/>
                            <strong>제10조(아이디와 비밀번호 관리에 대한 의무와 책임)</strong>
                            <br/>
                            ① "회원"의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다.<br/>
                            ② "센터"는 "회원"의 "아이디"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "센터" 및 "센터"의 운영자로 오인 우려가 있는 경우, 해당 "아이디"의 이용을 제한할 수 있습니다.<br/>
                            ③ "회원"은 "아이디" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우 즉시 "센터"에 통지하고 "센터"의 안내에 따라야 합니다.<br/>
                            ④ 제3자의 불법 도용 사실을 "센터"에 통지하지 않거나, 통지한 경우에도 "센터"의 안내에 따르지 않아, 발생한 불이익에 대하여 "센터"는 책임지지 않습니다.
                            <br/>
                            <br/>
                            <strong>제11조(회원에 대한 통지)</strong>
                            <br/>
                            ① "센터"가 "회원"에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 "회원"이 지정한 전자우편주소, 서비스 내 전자메시지 등으로 할 수 있습니다.<br/>
                            ② "센터"는 "회원" 전체에 대한 통지의 경우 "센터"의 게시판 등에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
                            <br/>
                            <br></br>
                            <strong>제12조(개인정보보호)</strong>
                            <br/>
                            ①"센터"는 관련법령이 정하는 바에 따라서 "회원" 등록정보를 포함한 "회원"의 개인정보를 보호하기 위하여 노력합니다. "회원"의 개인정보보호에 관해서는 관련 법령 및 "센터"의 개인정보보호정책이 적용됩니다. 단, "센터"의 공식 사이트 이외의 웹에서 링크된 사이트에서는 "센터"의 개인정보 보호정책이 적용되지 않습니다. 또한 "센터"는 "회원"의 귀책사유로 인해 노출된 정보에 대해서 일체의 책임을 지지 않습니다.<br/>
                            ② "센터"는 약관 또는 관계 법령이 정한 경우 이외에는 "회원"의 개인정보를 본인의 동의 없이 제3자에게 제공하지 아니합니다.
                            <br/>
                            <br/>
                            <p className="clause_tit">제4장 계약해제 및 이용제한</p>
                            <br></br>
                            <strong>제13조(서비스 이용의 중지 또는 계약의 해지)</strong>
                            <br/>
                            ① "회원"은 다음 행위를 하여서는 안 됩니다.<br/>
                            <span className="clause_indent">1. 신청 또는 변경 시 허위내용 등록</span>
                            <span className="clause_indent">2. 타인 또는 타사의 정보 도용</span>
                            <span className="clause_indent">3. "센터"에 게시된 정보의 변경</span>
                            <span className="clause_indent">4. "센터"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</span>
                            <span className="clause_indent">5. "센터"와 기타 제3자의 저작권 등 지적재산권에 대한 침해</span>
                            <span className="clause_indent">6. "센터" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</span>
                            <span className="clause_indent">7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 동서양속에 반하는 정보를 "서비스"를 이용하여 유포하는 행위</span>
                            <span className="clause_indent">8. 기타 불법적이거나 부당한 행위</span>
                            ② "회원"은 관계법, 본 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, "센터"가 통지하는 사항 등을 준수하여야 하며, 기타 "센터"의 업무에 방해되는 행위를 하여서는 안 됩니다.<br/>
                            ③ "센터"는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "센터"에게 할 수 있습니다.
                            <br/>
                            <br></br>
                            <strong>제14조(계약해재, 해지)</strong>
                            <br/>
                            ① "회원"은 언제든지 자기 정보 관리 메뉴를 통하여 서비스 탈퇴 신청을 할 수 있으며, "센터"는 관련법 등이 정하는 바에 따라 즉시 처리하여야 합니다.<br/>
                            ② "회원"이 계약을 해지할 경우, 관련법령 및 개인정보보호정책에 따라 "센터"가 "회원"정보를 보유하는 경우를 제외하고 는 해지 즉시 회원의 모든 개인정보는 소멸됩니다.<br/>
                            ③ "회원"이 계약을 해지하는 경우, 본인 및 하위 관리 계정에 등록된 데이터는 일체 삭제됩니다.
                            <br/>
                            <br/>
                            <p className="clause_tit">제5장 피해보상</p>
                            <br></br>
                            <strong>제15조(콘텐츠하자 등에 의한 이용자피해보상)</strong>
                            <br/>
                            "센터"는 콘텐츠하자 등에 의한 이용자피해보상의 기준·범위·방법 및 절차에 관한 사항을 디지털콘텐츠이용자보호지침에 따라 처리합니다.
                            <br/><br/>
                            <strong>제16조(손해배상)</strong>
                            <br/>
                            ① "회원"이 본 약관의 규정을 위반함으로 인하여 "센터"에 손해가 발생하게 되는 경우, 이 약관을 위반한 "회원"은"센터"에 발생하는 모든 손해를 배상하여야 합니다.<br/>
                            ② "센터"는 서비스 요금이 무료인 서비스의 이용과 관련하여 "회원"에게 발생한 손해에 대해 어떠한 책임도 지지 않습니다.<br/>
                            ③ "회원"이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 "센터"가 당해 "회원" 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 "회원"은 자신의 책임과 비용으로 "센터"를 면책 시켜야 하며, "센터"가 면책되지 못한 경우 당해 "회원"은 그로 인하여 "센터"에 발생한 모든 손해를 배상하여야 합니다.
                            <br/><br/>
                            <strong>제17조(면책조항)</strong>
                            <br/>
                            ① "센터"는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br/>
                            ② "센터"는 "이용자"의 귀책사유로 인한 서비스이용의 장애에 대하여는 책임을 지지 않습니다.<br/>
                            ③ "센터"는 "회원"이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br/>
                            ④ "센터"는 "이용자" 상호간 또는 "이용자"와 제3자 간에 서비스를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.
                            <br></br>
                        </Text>
                    </div>
                    <input type="checkbox" id="online" className="input_chk" ref={onlineAgreeCheck} />
                    <label for="online">온라인 회원 약관에 동의합니다.</label>
                </div>
                <div className="join_agree">
                    <h3 className="board_tit">개인정보취급방침</h3>
                    <div className="text_box">
                        <Text>
                            <strong>경남대학교 개인정보 처리방침 (개정13차, 2020.12.07.)</strong>
                            <br/>
                            경남대학교 개인정보 처리방침 (개정13차, 2020.12.07.)
                            경남대학교는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                            <br/><br/>
                            <strong>제1조(개인정보의 처리 목적)</strong>
                            <br/>
                            1. 경남대학교는 다음의 목적을 위해 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용하지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행합니다.<br/>
                            <span className="clause_indent">01 입시 및 학사업무 처리</span>
                            <span className="clause_indent">&nbsp;&nbsp;입시전형 업무, 신입생 선발, 학사관리 및 학교행정 업무, 국제교류생관리, 수강생 학사관리(평생교육원, 과학영재교육원, 스포츠지도사연수원) 등</span>
                            <span className="clause_indent">02 서비스 제공 및 관리</span>
                            <span className="clause_indent">&nbsp;&nbsp;공지사항, 증명서 발급, 입시상담, 도서이용자 관리, 취업정보, 취업지원 관리, 생활관생 관리, 학자금 대출 등</span>
                            <span className="clause_indent">03 발전기금 관리</span>
                            <span className="clause_indent">&nbsp;&nbsp;발전기금 기탁자 관리 및 기부금 영수증 발급 등</span>
                            <span className="clause_indent">04 민원사무 처리</span>
                            <span className="clause_indent">&nbsp;&nbsp;개인정보 열람, 개인정보 정정·삭제, 개인정보 처리정지 요구, 개인정보 유출사고 신고, 개인정보 침해 사실 신고 접수·처리 등</span>
                            2. 경남대학교는 개인정보 보호법 제32조에 따라 등록·공개하는 개인정보파일은 행정자치부 개인정보보호 종합포털(www.privacy.go.kr) → 민원마당 → 개인정보 열람 등 요구 → 개인정보파일 목록검색 → 기관명에 ‘경남대학교’ 입력 후 조회하실 수 있습니다.
                            <br/><br></br>
                            <strong>제2조(개인정보의 처리 및 보유기간)</strong>
                            <br/>
                            경남대학교는 법령에 따른 개인정보 보유.이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유.이용기간 내에서 개인정보를 처리.보유합니다.
                            <br/><br></br>
                            <strong>제3조(개인정보의 제3자 제공)</strong>
                            <br/>
                            경남대학교는 수집.보유하고 있는 개인정보는 이용자의 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않으며 다음 각 호의 경우에는 개인정보를 제3자에게 제공할 수 있습니다.
                            <br/>
                            <span className="clause_indent">01 정보주체로부터 별도의 동의를 받은 경우</span>
                            <span className="clause_indent">02 다른 법률에 특별한 규정이 있는 경우</span>
                            <span className="clause_indent">03 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</span>
                            <span className="clause_indent">04 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우</span>
                            <span className="clause_indent">05 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 개인정보위원회의 심의의결을 거친 경우</span>
                            <span className="clause_indent">06 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우</span>
                            <span className="clause_indent">07 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</span>
                            <span className="clause_indent">08 법원의 재판업무 수행을 위하여 필요한 경우</span>
                            <span className="clause_indent">09 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</span>
                            <br/>
                            우리대학교는 다음과 같은 기관에게 개인정보를 제공하고 있으며 제공목적 및 범위는 다음과 같다.
                            <table class="tb_style_01">
                                <tr>
                                    <th>제3자 제공한 기관명</th>
                                    <th>제3자 제공 파일명</th>
                                    <th>제공파일의 항목</th>
                                    <th>법적근거</th>
                                    <th>관련부서</th>
                                </tr>
                                <tr>
                                    <td>부산울산경남권역이러닝센터(GeLC)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>서울디지털대학교(SDU)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>한국대학가상교육연합(KCU)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>한국지역대학연합(RUCK)</td>
                                    <td>사이버강좌 수강생 명단</td>
                                    <td>학번,성명,생년월일,학년 소속학과,휴대폰번호</td>
                                    <td>개인정보보호법 제17조 및 18조</td>
                                    <td>교수학습센터</td>
                                </tr>
                                <tr>
                                    <td>육군본부</td>
                                    <td>입시관리(군사학과)</td>
                                    <td>주민등록번호,이름,수험번호,성별,출신고교,졸업년도,고교성적</td>
                                    <td>육군본부와의 군사학 발전 협력 합의서</td>
                                    <td>입학관리팀</td>
                                </tr>
                                <tr>
                                    <td>한국대학교육협의회</td>
                                    <td>입시관리</td>
                                    <td>모집시기구분,이름,주민등록번호,수험번호,제출대학코드(표준분류 기준),접수구분,합격/예치금납부 구분,접수일시</td>
                                    <td>고등교육법 시행령 제42조(입학지원방법등) 제42조의2(입학지원방법 위반의 처리)</td>
                                    <td>입학관리팀</td>
                                </tr>
                                <tr>
                                    <td>각종교외장학재단</td>
                                    <td>교외장학대상자명단</td>
                                    <td>성명,학과,학번,학년,주민등록번호,성적,학적상태,장학금액</td>
                                    <td>개인정보법17조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>보훈청</td>
                                    <td>국가보훈자녀 수업료 국고보조금청구대상자</td>
                                    <td>보훈번호,주민등록번호,성명,학과,학번,학년,성적,신청학점,입학금,수업료,등록금액</td>
                                    <td>[보훈법령] 국가유공자예우 및 지원에 관한 법률 제42조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>한국TOEIC위원회</td>
                                    <td>토익,토익스피킹,JPT 성적조회대상자 명단</td>
                                    <td>성명,생년월일,수험번호,응시일,성적</td>
                                    <td>개인정보법17조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>한국산업인력공단</td>
                                    <td>정보처리기사,사무자동화산업기사등 자격증취득자명단</td>
                                    <td>성명,생년월일,수험번호,응시일,성적</td>
                                    <td>개인정보법17조</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>한국장학재단</td>
                                    <td>학사정보원장,수납정보원장</td>
                                    <td>주민등록번호,성명,학과,학번,학년,성적,신청학점,입학금,수업료,등록금액,장학금액,대학수납가상계좌,학적상태</td>
                                    <td>한국장학재단설립등에 관한법률제50조의2(자료제출의 요청)</td>
                                    <td>장학복지팀</td>
                                </tr>
                                <tr>
                                    <td>경남은행</td>
                                    <td>수입/지출 관련 명단 (급여포함)</td>
                                    <td>성명,은행명,계좌번호,예금주,지급금액</td>
                                    <td>개인정보보호법 제17조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>퇴직소득명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,입사일,퇴사일,근속월수,근속연수,퇴직급여액,퇴직소득공제,산출세액</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td></td>
                                    <td>사업자등록번호,주민등록번호,기부일자,기부금액</td>
                                    <td>상속증여세법제50조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>출연받은 재산의 공익목적 사용현황(출연재산사용명세)</td>
                                    <td>사업자등록번호,주민등록번호,기부일자,사용일자,사용금액</td>
                                    <td>상속증여세법제50조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 간소화 파일(대학 교육비)</td>
                                    <td>사업자등록번호,성명,주민등록번호,납입금액,교육비구분,공제대상 교육비 부담액</td>
                                    <td>소득세법제165조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 간소화 파일(기부금)</td>
                                    <td>사업자등록번호,성명,주민등록번호,기부일자,기부금액</td>
                                    <td>소득세법제165조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 근로소득 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,주소,근무처,근무기간,급여,결정세액,기납부세액,차감징수세액,공제대상자 주민등록번호,그외 공제내역 등</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 기부금 명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,근무지,주소,전화번호,기부내용,기부처,기부일자,기부금액,공제금액,소멸금액,이월금액</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>연말정산 의료비 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,근무지,주소,전화번호,기부내용,기부처,기부일자,기부금액,공제금액,소멸금액,이월금액</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>일용근로소득 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,지급월,근무월,근무일수,총지급액(과세소득),비과세소득,소득세,지방소득세</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>거주자 기타소득 지급명세서</td>
                                    <td>사업자등록번호,성명,주민등록번호,지급연월일,귀속연월일,지급총액,필요경비,소득금액,세율,소득세,지방소득세</td>
                                    <td>소득세법제164조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>국세청</td>
                                    <td>부가가치세신고납부자료</td>
                                    <td>사업자등록번호,성명,주민등록번호,의료비공제대상자 주민등록번호,건수,금액</td>
                                    <td>부가가치세법제48조,제49조</td>
                                    <td>재무회계팀</td>
                                </tr>
                                <tr>
                                    <td>골드스타</td>
                                    <td>경남조선과 3개교 체육대회 참가 교직원 및 학생 명단</td>
                                    <td>성명,주민등록번호,연락처</td>
                                    <td>개인정보보호법</td>
                                    <td>조선해양시스템공학과</td>
                                </tr>
                                <tr>
                                    <td>국민건강보험공단</td>
                                    <td>건강보험 직장가입자 자격취득 및 상실</td>
                                    <td>성명,주민등록번호,보수월액,취득일,상실일,보수월액,근무월수</td>
                                    <td>국민건강보험법 시행령 제81조</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>국민연금공단</td>
                                    <td>국밈연금 취득 및 상실</td>
                                    <td>성명,주민등록번호,취득일,소득월액,상실일,상실부호</td>
                                    <td>국민연금법 시령 113조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>근로복지공단</td>
                                    <td>고용산재보험 취득 및 상실</td>
                                    <td>성명,주민등록번호,취득일,월평균보수월액,근로시간,계약종료일</td>
                                    <td>고용보험 시행령 제145조의 2,산업재해보상보험 시행령 제127조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>동부화재</td>
                                    <td>경남대학교 교직원단체보험 가입현황</td>
                                    <td>성명,주민등록번호,남여구분</td>
                                    <td>신용정보의 이용 및 보호에 관한 법률 시행령 제37조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>사립학교교직원연금공단</td>
                                    <td>교직원 임용신고서 봉급지급 내역서,신분변동신고</td>
                                    <td>성명,주민번호,성별,직급,직위,임용일자,근무월수,소득총액</td>
                                    <td>사학연금법 시행령 제89조의 2</td>
                                    <td>총무인사팀</td>
                                </tr>
                                <tr>
                                    <td>한국교육개발원</td>
                                    <td>학생명단</td>
                                    <td>학생기본정보,입학정보,재적정보,학적변동정보,졸업정보,외국인 학생정보</td>
                                    <td>'교육통계조사에 관한 훈령‘ (교육부 훈령 제50호)/’통계청 지정 통계 승인번호 제33401호‘</td>
                                    <td>평가감사팀</td>
                                </tr>
                                <tr>
                                    <td>한국교육개발원</td>
                                    <td>교원명단</td>
                                    <td>교원기본정보,학위정보,임용정보,경력정보,본직정보,동태정보,강의정보</td>
                                    <td>'교육통계조사에 관한 훈령‘ (교육부 훈령 제50호)/’통계청 지정 통계 승인번호 제33401호‘</td>
                                    <td>평가감사팀</td>
                                </tr>
                                <tr>
                                    <td>한국교육개발원</td>
                                    <td>직원·조교 명단</td>
                                    <td>기본정보 및 채용정보,연수정보</td>
                                    <td>'교육통계조사에 관한 훈령‘ (교육부 훈령 제50호)/’통계청 지정 통계 승인번호 제33401호‘</td>
                                    <td>평가감사팀</td>
                                </tr>
                                <tr>
                                    <td>각 지방병무청</td>
                                    <td>학적보유자 변동사항 통보</td>
                                    <td>학번,성명,주민등록번호,주소,학과,학년,학적변동 연월일,학적변동 사유</td>
                                    <td>병역법 시행규칙 제85조</td>
                                    <td>학사관리팀</td>
                                </tr>
                                <tr>
                                    <td>경남지방병무청</td>
                                    <td>학적보유자 명부</td>
                                    <td>성명,주민등록번호,학번,학년,입학년도,졸업년도,편입여부,재외국민여부,전자우편주소,휴대전화번호</td>
                                    <td>•병역법 시행령 제125조(재학생 입영 등 연기)<br/>
                                    •병무청 훈령 제1132호『입영연기 관리규정』제5조(학적보유자 명부의 송부 및 관리)</td>
                                    <td>학사관리팀</td>
                                </tr>
                                <tr>
                                    <td>경남지방병무청</td>
                                    <td>졸업자 명부</td>
                                    <td>주민등록번호,성명,학적보유사항,최종 학적보유 또는 변동일</td>
                                    <td>병역법 제80 조</td>
                                    <td>학사관리팀</td>
                                </tr>
                                <tr>
                                    <td>경남지방병무청</td>
                                    <td>학적상태 통보</td>
                                    <td>전공학과,학년,학적상태,변동일자,휴학사유</td>
                                    <td>•병역법 시행령 제127조 학적변동자의 처리<br/>
                                    •입영연기 관리규정 제16조 각급학교 병무담당 교육</td>
                                    <td>학사관리팀</td>
                                </tr>
                            </table>
                            <br/><br/>
                            <strong>제4조(개인정보처리의 위탁)</strong>
                            <br/>
                            경남대학교는 원칙적으로 이용자의 동의 없이 해당 개인 정보의 처리를 타인에게 위탁하지 않습니다. 단, 경남대학교가 제3자에게 개인정보의 처리 업무를 위탁하는 경우에는 「개인정보보호법」제26조(업무위탁에 따른 개인정보의 처리 제한)에 따라 위탁하며 다음 각 호의 내용이 포함된 문서에 의하여 위탁 업무의 내용과 수탁자를 홈페이지에 게시합니다.
                            <br/>
                            01 위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항<br/>
                            02 개인정보의 기술적.관리적 보호조치에 관한 사항<br/>
                            03 그 밖에 개인정보의 안전한 관리를 위하여 다음과 같이 대통령령으로 정한 사항<br/>
                            <span className="clause_indent">가. 위탁업무의 목적 및 범위</span>
                            <span className="clause_indent">나. 재위탁 제한에 관한 사항</span>
                            <span className="clause_indent">다. 개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항</span>
                            <span className="clause_indent">라. 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항</span>
                            <span className="clause_indent">마. 법 제26조제2항에 따른 수탁자(이하 "수탁자"라 한다)가 준수하여야 할 의무를 위반한 경우의 손해배상 등 책임에 관한 사항</span>
                            <br></br>
                            우리 대학교는 다음과 같은 기관에게 개인정보를 위탁하고 있으며 위탁업무내용 및 수탁기관은 다음과 같습니다.
                            <table className="tb_style_01">
                                <colgroup>
                                    <col style={{width:"55%"}} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>계약서 명</th>
                                        <th>수탁업체</th>
                                        <th>관련부서</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>출납업무(일괄입금,급여입금 등)와 관련한 개인정보 수집 및 처리</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>재무회계팀</td>
                                    </tr>
                                    <tr>
                                        <td>대학 및 대학원(신입 편입생 포함)등록금 수납 관련 개인정보처리</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>재무회계팀</td>
                                    </tr>
                                    <tr>
                                        <td>등록금 및 제 납부금의 수납 업무와 관련한 개인정보 수집 및 처리</td>
                                        <td>NH농협은행 신마산지점</td>
                                        <td>재무회계팀</td>
                                    </tr>
                                    <tr>
                                        <td>시스템경비 및 시설 경비 위탁운영 관리</td>
                                        <td>㈜에스원</td>
                                        <td>총무인사팀</td>
                                    </tr>
                                    <tr>
                                        <td>주차장 및 주차시설의 위탁운영 관련</td>
                                        <td>㈜아마노코리아</td>
                                        <td>총무인사팀</td>
                                    </tr>
                                    <tr>
                                        <td>보안감시 및 도난 예방, 시설물관리 및 화재예방</td>
                                        <td>㈜에스티에스시스템</td>
                                        <td>총무인사팀</td>
                                    </tr>
                                    <tr>
                                        <td>생활관 관생 관리비 수납</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>생활관 관생 관리비 수납</td>
                                        <td>NH농협은행 신마산지점</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>단체결핵검사</td>
                                        <td>대한결핵협회울산경남지부회</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>식사신청 확인</td>
                                        <td>㈜동원홈푸드</td>
                                        <td>한마생활관</td>
                                    </tr>
                                    <tr>
                                        <td>항공권발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜경남투어렌트카</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>여행자보험 가입에 필요한 정보 수집</td>
                                        <td>㈜DB손해보험</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>여행자보험 가입에 필요한 정보 수집</td>
                                        <td>골드스타보험대리점</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜웰빙투어</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜투어이천</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜대상에듀</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>항공권 발권 및 여행보험 가입에 필요한 여권정보 정보 수집</td>
                                        <td>㈜비에스하이투어</td>
                                        <td>국제교류팀</td>
                                    </tr>
                                    <tr>
                                        <td>도서관 개인정보DB 유지관리업무</td>
                                        <td>㈜퓨쳐누리</td>
                                        <td>학술문헌팀</td>
                                    </tr>
                                    <tr>
                                        <td>월영소식 홈페이지 유지보수 (전산관리 위탁)</td>
                                        <td>엔디소프트㈜</td>
                                        <td>홍보실</td>
                                    </tr>
                                    <tr>
                                        <td>2020학년도 대학일자리센터 온라인 취업지원플랫폼 [성취감솔루션]</td>
                                        <td>주식회사잡담</td>
                                        <td>대학일자리센터</td>
                                    </tr>
                                    <tr>
                                        <td>종합정보시스템 재개편 용역</td>
                                        <td>주식회사 TIS</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>중앙전산기기실 서버시스템 유지관리 용역</td>
                                        <td>㈜우리아이티</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>정보시스템 통합유지보수</td>
                                        <td>㈜우리아이티</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>컴퓨터 및 프린터 외 유지보수</td>
                                        <td>㈜우리아이티</td>
                                        <td>정보전산팀</td>
                                    </tr>
                                    <tr>
                                        <td>경남타임즈 홈페이지 유지보수</td>
                                        <td>엔디소프트㈜</td>
                                        <td>언론출판원 행정지원팀</td>
                                    </tr>
                                    <tr>
                                        <td>교육방송국 홈페이지 유지보수</td>
                                        <td>엔디소프트㈜</td>
                                        <td>언론출판원 행정지원팀</td>
                                    </tr>
                                    <tr>
                                        <td>IPP일반학습병행사업 온라인 관리시스템의 유지보수</td>
                                        <td>㈜이데링크</td>
                                        <td>KU-IPP사업단행정팀</td>
                                    </tr>
                                    <tr>
                                        <td>경남대학교 LINC+ 사업단 홈페이지 유지보수</td>
                                        <td>바롬넷</td>
                                        <td>UOK LINC+사업단</td>
                                    </tr>
                                    <tr>
                                        <td>경남대학교 LINC+ 4차 산업혁명 혁신선도대학사업 학생역량관리 프로그램 운영</td>
                                        <td>㈜이데링크</td>
                                        <td>UOK LINC+사업단</td>
                                    </tr>
                                    <tr>
                                        <td>경남대학교 산학협력단 연구비종합관리시스템 운영</td>
                                        <td>중소기업은행</td>
                                        <td>산학협력단</td>
                                    </tr>
                                    <tr>
                                        <td>학생경력개발시스템 홈페이지 유지보수</td>
                                        <td>㈜헬로잡</td>
                                        <td>취업지원팀</td>
                                    </tr>
                                    <tr>
                                        <td>2021학년도 대입 인터넷 원서접수 대행</td>
                                        <td>㈜진학어플라이</td>
                                        <td>입학관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>2021학년도 합격자통합관리시스템</td>
                                        <td>㈜진학어플라이</td>
                                        <td>입학관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>재학생의 국제학생증 발급</td>
                                        <td>한국국제학생교류회</td>
                                        <td>학사관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>증명발급시스템 운영</td>
                                        <td>㈜디지털존</td>
                                        <td>학사관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>신입생,편입생 학생증 발급</td>
                                        <td>BNK경남은행 경남대지점</td>
                                        <td>학사관리팀</td>
                                    </tr>
                                    <tr>
                                        <td>LMS강좌운영 유지보수</td>
                                        <td>㈜아이맥스소프트</td>
                                        <td>교육혁신본부</td>
                                    </tr>
                                    <tr>
                                        <td>콘텐츠제작 시스템 유지보수</td>
                                        <td>㈜자이닉스</td>
                                        <td>교육혁신본부</td>
                                    </tr>
                                    <tr>
                                        <td>연구활동종사자상해보험 가입에 필요한 정보 수집</td>
                                        <td>㈜DB손해보험</td>
                                        <td>시설관리팀</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br/><br/>
                            <strong>제5조(정보주체의 권리.의무 및 그 행사방법)</strong>
                            <br/>
                            정보주체는 다음과 같은 권리를 행사할 수 있으며, 만14세 미만 아동의 법정대리인은 그 아동의 개인정보에 대한 열람, 정정.삭제, 처리정지를 요구할 수 있습니다.
                            <br/>
                            01 개인정보 열람 요구 : 경남대학교에서 보유하고 있는 개인정보파일은 「개인정보보호법」제35조(개인정보의 열람)에 따라 열람을 요구할 수 있으며 개인정보 열람 요구 시 법 제35조 5항에 의하여 열람을 제한할 수 있습니다.<br/>
                            <span className="clause_indent">가. 법률에 따라 열람이 금지되거나 제한되는 경우</span>
                            <span className="clause_indent">나. 다른 사람의 생명.신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</span>
                            <span className="clause_indent">다. 공공기관이 다음의 경우 어느 하나에 해당하는 업무를 수항할 때 중대한 지장을 초래하는 경우</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 조세의 부과.징수 또는 환급에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 「초.중등교육법」 및 「고등교육법」에 따른 각급 학교, 「평생교육법」에 따른 평생교육시설, 그 밖의 다른 법률에 따라 설치된 고등교육기관에서의 성적 평가 또는 입학자 선발에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 학력.기능 및 채용에 관한 시험, 자격 심사에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 보상금.급부금 산정 등에 대하여 진행 중인 평가 또는 판단에 관한 업무</span>
                            <span className="clause_indent">&nbsp;&nbsp;- 다른 법률에 따라 진행 중인 감사 및 조사에 관한 업무</span>
                            02 개인정보 정정.삭제 요구 : 경남대학교에서 보유하고 있는 개인정보파일에 대해서는 「개인정보보호법」 제36조(개인정보의 정정.삭제)에 따라 경남대학교에 개인정보의 정정.삭제를 요구할 수 있습니다. 단, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
                            03 개인정보 처리정지 요구 : 경남대학교에서 보유하고 있는 개인정보파일은 「개인정보 보호법」 제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수 있습니다. 단, 개인정보 처리정지 요구 시 법 제37조제2항에 의하여 처리정지 요구가 거절될 수 있습니다.<br/>
                            <span className="clause_indent">가. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</span>
                            <span className="clause_indent">나. 다른 사람의 생명.신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</span>
                            <span className="clause_indent">다. 공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우</span>
                            <span className="clause_indent">라. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우</span>
                            04 권리행사의 방법 및 절차 : 개인정보보호법 제38조(권리행사의 방법 및 절차)에 의해 경남대학교는 정보주체가 열람 등 요구에 대한 거절 등 조치에 대하여 불복이 있는 경우 이의를 제기할 수 있도록 필요한 절차를 다음과 같이 안내합니다.<br/>
                            <br/>
                            [불복사유]<br/>
                            가. 정보공개 청구에 대한 경남대학교의 열람거절<br/>
                            나. 정보공개 청구에 대한 경남대학교의 일부열람<br/>
                            다. 정보공개 청구일로부터 20일 이내에 경남대학교가 공개 여부를 통지하지 않은 경우
                            <br/><br/>
                            [처리절차]<br/>
                            가. 이의신청 : 경남대학교로부터 열람여부의 결정통지를 받은 날 또는 열람거절의 결정이 있는 것으로 보는 날부터 "30일"이내 제기<br/>
                            나. 행정심판 : 처분이 있음을 안 날부터 "90일" 이내 제기(처분이 있은 날로부터 180일을 경과하면 제기 불가)<br/>
                            다. 행정소송 : 처분 등이 있음을 안 날부터 "90일" 이내(처분 등이 있는 날로부터 1년을 경과하면 제기 불가)<br/>
                            청구방법 : 서면, 유선, 이메일<br/>
                            청구장소 : 경남대학교 정보전산팀 개인정보보호담당자<br/>
                            - 전화 : 055-249-2852, fax : 0505-999-2111<br/>
                            - 이메일 : privacy@kyungnam.ac.kr
                            <br/><br></br>
                            <strong>제6조(처리하는 개인정보 항목)</strong>
                            <br/>
                            경남대학교는 제1조 2항의 목록에서 검색되는 “개인정보파일에 기록되는 개인정보의 항목”을 처리하고 있습니다.
                            <br/><br></br>
                            <strong>제7조(개인정보의 파기)</strong>
                            <br/>
                            경남대학교는 원칙적으로 개인정보의 보유기간이 경과했거나 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 단, 다른 법률에 따라 보존하여야하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
                            <br/>
                            01 파기 절차<br/>
                            개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및 기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br/>
                            02 파기 기한 및 파기방법<br/>
                            보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등 그 개인정보가 불필요하게 되었을 때에는 지체없이 파기합니다. 전자적 파일형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                            <br/><br></br>
                            <strong>제8조(개인정보 보호책임자)</strong>
                            <br/>
                            경남대학교 개인정보보호책임자 : 교학부총장 박 재 윤<br/>
                            개인정보보호담당자 : 손 영 화<br/>
                            연락처 : 055)249-2852, privacy@kyungnam.ac.kr
                            <br/><br></br>
                            <strong>제9조(시행령 제30조제1항에 따른 개인정보의 안전성 확보조치)</strong>
                            <br/>
                            경남대학교는 「개인 정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 관리적, 기술적, 물리적 조치를 하고 있습니다.<br/>
                            개인정보의 안전성 확보를 위해 다음과 같은 관리적, 기술적 및 물리적 조치를 취하고 있습니다.<br/>
                            <br/>
                            01 관리적 조치 : 개인정보보호 추진계획 수립.시행, 정기적인 교육 등<br/>
                            02 기술적 조치 : 개인정보 유출 및 훼손을 막기 위한 보안프로그램 설치, 고유식별정보 등의 암호화, 외부로부터의 무단접근 통제, 실시간 보안관제 모니터링 및 이벤트 분석.대응 등<br/>
                            03 물리적 조치 : 개인정보처리시스템의 보관장소에 대한 출입통제 등
                            <br/><br></br>
                            <strong>제10조(개인정보파일의 열람 및 정정 청구)</strong>
                            <br/>
                            정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 경남대학교는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br/>
                            <a className="persnal-btn" onClick={onClickRead}>개인정보열람 접수처리 부서 보기(열람을 원하면 클릭)</a>
                            <div className="persnal-dep" style={{display: open? '' : 'none'}}>
                                <table className="tb_style_01">
                                    <thead>
                                        <tr>
                                            <th>개인정보파일명</th>
                                            <th>열람장소</th>
                                            <th>연락처</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> 과학영재교육원 원생현황 </td>
                                            <td> 과학영재교육원 행정지원팀 </td>
                                            <td>055-249-2796 </td>
                                        </tr>
                                        <tr>
                                            <td> 사이버강좌수강생명단 </td>
                                            <td> 교수학습센터 </td>
                                            <td>055-249-2816</td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={3}> 교육대학원 교학행정실 </td>
                                            <td rowSpan={3}>055-249-2810</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 서약서제출자명부 </td>
                                            <td rowSpan={6}> 극동문제연구소 </td>
                                            <td rowSpan={6}>02-3700-0702</td>
                                        </tr>
                                        <tr>
                                            <td> 유료회원관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 통일경제아카데미 </td>
                                        </tr>
                                        <tr>
                                            <td> 특수자료 디지털 콘텐츠 열람자관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 홈페이지 회원관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 특수자료열람/대출대장 </td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={5}> 대학원 교학행정실 </td>
                                            <td rowSpan={5}>055-249-2808</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 정부보증학자금융자 </td>
                                        </tr>
                                        <tr>
                                            <td> 취업통계관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 교수연수원관리 </td>
                                            <td> 사범대부설 교육연수원 </td>
                                            <td>055-249-2302</td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={3}> 산업경영대학원 교학행정실 </td>
                                            <td rowSpan={3}>055-249-2585</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 연구과제관리 </td>
                                            <td> 산학협력단 산학협력팀 </td>
                                            <td> 055-249-2298 </td>
                                        </tr>
                                        <tr>
                                            <td> 입시관리 </td>
                                            <td rowSpan={2}> 입학관리팀 </td>
                                            <td rowSpan={2}>055-249-2031</td>
                                        </tr>
                                        <tr>
                                            <td> 입시홈페이지 회원관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 외부장학 기부금 발급자 관리 </td>
                                            <td rowSpan={3}> 장학복지팀 </td>
                                            <td rowSpan={3}>055-249-2033</td>
                                        </tr>
                                        <tr>
                                            <td> 장학관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 정부보증학자금융자 및 농어촌출신대학생학자금융자, 국가장학금 </td>
                                        </tr>
                                        <tr>
                                            <td> 기부금 등 출연재산관리 </td>
                                            <td> 재무회계팀 </td>
                                            <td>055-249-6388</td>
                                        </tr>
                                        <tr>
                                            <td> 경남대학교홈페이지 회원관리 </td>
                                            <td> 정보전산팀 </td>
                                            <td>055-249-2858</td>
                                        </tr>
                                        <tr>
                                            <td> 체육영재사업육성 지원자 명부 </td>
                                            <td> 체육교육과 </td>
                                            <td>055-249-2366</td>
                                        </tr>
                                        <tr>
                                            <td> 취업통계관리 </td>
                                            <td> 취창업지원팀 </td>
                                            <td>055-249-2288</td>
                                        </tr>
                                        <tr>
                                            <td> 여성지도자 수료생 명부 </td>
                                            <td rowSpan={3}> 평생교육원 행정지원팀 </td>
                                            <td rowSpan={3}>055-249-2717</td>
                                        </tr>
                                        <tr>
                                            <td> 학점은행제 교육생 명부 </td>
                                        </tr>
                                        <tr>
                                            <td> 평생학습과정 교육생 명부 </td>
                                        </tr>
                                        <tr>
                                            <td> 졸업관리 </td>
                                            <td rowSpan={2}> 학사관리팀 </td>
                                            <td rowSpan={2}>055-249-2491</td>
                                        </tr>
                                        <tr>
                                            <td> 학적관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 장애학생 현황 </td>
                                            <td rowSpan={2}> 학생지원팀 </td>
                                            <td rowSpan={2}>055-249-2950</td>
                                        </tr>
                                        <tr>
                                            <td> 발전기금 기탁자관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 어학연수생 관리 </td>
                                            <td rowSpan={2}> 학술교류팀 </td>
                                            <td rowSpan={2}>055-249-2221</td>
                                        </tr>
                                        <tr>
                                            <td> 외국인 학생 현황 </td>
                                        </tr>
                                        <tr>
                                            <td> 중앙도서관 이용자 </td>
                                            <td> 학술문헌팀 </td>
                                            <td>055-249-2844</td>
                                        </tr>
                                        <tr>
                                            <td> 생활관 관생 현황관리 </td>
                                            <td> 한마생활관 행정지원팀 </td>
                                            <td>055-249-2260</td>
                                        </tr>
                                        <tr>
                                            <td> 입학생관리 </td>
                                            <td rowSpan={3} style={{borderBottom: "1px solid #3a4147"}}> 행정대학원 교학행정실 </td>
                                            <td rowSpan={3} style={{borderBottom: "1px solid #3a4147"}}>055-249-2594</td>
                                        </tr>
                                        <tr>
                                            <td> 장학생관리 </td>
                                        </tr>
                                        <tr>
                                            <td> 학사 및 학적관리 </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br/><br/>
                            <strong>제11조(정보주체의 권익침해에 대한 구제방법)</strong>
                            <br/>
                            1. 정보주체는 개인정보를 침해당했을 시에는 경남대학교에 아래와 같이 민원접수를 할 수 있습니다.<br/>
                            개인정보 침해관련 민원 접수·처리부서<br/>
                            <span className="clause_indent">- 부서명 : 경남대학교 정보전산원 정보전산팀</span>
                            <span className="clause_indent">- 담당자 : 손영화</span>
                            <span className="clause_indent">- 연락처 : 055-249-2852, privacy@kyungnam.ac.kr, fax) 0505-999-2111</span>
                            2. 정보주체는 경남대학교의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 아래의 기관에 대해 문의하실 수 있습니다.<br/>
                            경남대학교 상위 감독기관(교육부)<br/>
                            <span className="clause_indent">- 소관업무 : 개인정보 침해사실 신고, 상담 신청</span>
                            <span className="clause_indent">- 홈페이지 : www.moe.go.kr</span>
                            <span className="clause_indent">- 전화 : (국번없이) 110</span>
                            <span className="clause_indent">- 주소 : (30119) 세종특별자치시 갈매로 408, 14동(어진동)</span>
                            <br/>
                            개인정보 침해신고센터 (한국인터넷진흥원 운영)
                            <span className="clause_indent">- 소관업무 : 개인정보 침해사실 신고, 상담 신청</span>
                            <span className="clause_indent">- 홈페이지 : privacy.kisa.or.kr</span>
                            <span className="clause_indent">- 전화 : (국번없이) 118</span>
                            <span className="clause_indent">- 주소 : 전라남도 나주시 진흥길 9 한국인터넷진흥원</span>
                            <br/>
                            개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
                            <span className="clause_indent">- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</span>
                            <span className="clause_indent">- 홈페이지 : www.kopico.go.kr</span>
                            <span className="clause_indent">- 전화 : 1833-6972</span>
                            <span className="clause_indent">- 주소 : 서울특별시 종로구 세종대로 209 정부서울청사 4층</span>
                            <span className="clause_indent">- 대검찰청 사이버범죄수사단 : (국번없이) 1301, privacy@spo.go.kr(www.spo.go.kr)</span>
                            <span className="clause_indent">- 경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)</span>
                            3. 정보주체의 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 정보주체는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/>
                            ※ 행정심판에 대해 자세한 사항은 행정심판위원회 (http://www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
                            <br/><br></br>
                            <strong>제12조(영상정보처리기기 설치.운영)</strong>
                            <br/>
                            경남대학교는 아래와 같이 영상정보처리기기를 설치.운영하고 있습니다.<br/>
                            <Link to="https://www.kyungnam.ac.kr/sites/ko/download/cctv06.pdf" download="" target="_blank" title="다운로드">경남대학교 CCTV 설치 및 운영 지침 보기(열람을 원하면 클릭)</Link>
                            <br/><br/>
                            01 영상정보처리기기 설치근거 및 목적 : 시설안전, 화재예방<br/>
                            02 설치 대수, 설치 위치 및 촬영범위<br/>
                            <span className="clause_indent">- 설치대수 : 624</span>
                            <span className="clause_indent">- 설치위치 : 학내 각 건물(설치 건물 안내판 부착)</span>
                            <span className="clause_indent">- 촬영범위 : 건물 내 외부</span>
                            03 관리책임자, 담당부서, 연락처 : 전지만, 총무인사팀, 055-249-2053<br/>
                            04 영상정보 촬영시간, 보관기간, 보관장소 및 처리방법<br/>
                            <span className="clause_indent">- 촬영시간 : 24시간</span>
                            <span className="clause_indent">- 보관기간 : 촬영일로부터 1개월</span>
                            <span className="clause_indent">- 보관장소 및 처리방법 : 총무인사팀 상황실</span>
                            05 개인영상정보 확인 및 장소 : 경남대학교 총무인사팀<br/>
                            06 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보에 관하여 열람 또는 존재확인.삭제를 원하는 경우 언제든지 영상정보처리기기 운영자에게 요구하실 수 있습니다. 단, 귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체, 재산의 이익을 위하여 필요한 개인영상정보에 한정됩니다.<br/>
                            07 영상정보 안정성 확보 조치 : 관리적 보안대책으로는 통제구역 설정으로 비인가자의 출입 제한하며 녹화서버 및 중계장비 등은 별도의 분리된 공간에 잠금장치를 하여 운영하고 있으며, 기술적 보안대책으로는 CCTV시스템 망을 인터넷망과 분리된 별도의 단독망으로 구성하였고 원격지 건물은 VPN장비를 이용 암호화를 통한 자료의 송수신이 가능토록 하여 운영하고 있습니다.
                            <br/><br></br>
                            <strong>제13조(개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)</strong>
                            <br/>
                            우리대학교 홈페이지는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.
                            <br/><br/>
                            <strong>제14조(개인정보 처리방침의 변경)</strong>
                            <br/>
                            1. 이 개인정보 처리방침은 시행일로부터 적용됩니다.<br/>
                            <span className="clause_indent">시행일자 : 2020년 12월 07일</span>
                            2. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.<br/>
                            <ul className="con-list">
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/8738/subview.do">개인정보 처리방침 보기 (개정13차, 2020.12.07.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7760/subview.do">개인정보 처리방침 보기 (개정12차, 2020.03.23.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7761/subview.do">개인정보 처리방침 보기 (개정11차, 2019.11.14.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7762/subview.do">개인정보 처리방침 보기 (개정10차, 2019.03.19.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7763/subview.do">개인정보 처리방침 보기 (개정9차, 2018.04.27.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7764/subview.do">개인정보 처리방침 보기 (개정8차, 2017.03.15.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7765/subview.do">개인정보 처리방침 보기 (개정7차, 2017.01.18.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7766/subview.do">개인정보 처리방침 보기 (개정6차, 2016.07.22.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7767/subview.do">개인정보 처리방침 보기 (개정5차, 2016.06.01.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7768/subview.do">개인정보 처리방침 보기 (개정4차, 2013.07.19.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7769/subview.do">개인정보 처리방침 보기 (개정3차, 2012.05.15.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7773/subview.do">개인정보 처리방침 보기 (개정2차, 2011.08.03.)</a></li>
                                <li className="mb10"><a href="https://www.kyungnam.ac.kr//ko/7774/subview.do">개인정보 처리방침 보기 (개정1차, 2009.10.13.)</a></li>
                                <li><a href="https://www.kyungnam.ac.kr//ko/7771/subview.do">개인정보 처리방침 보기 (제정, 2008.11.14.)</a></li>
                            </ul>
                        </Text>
                    </div>
                    <input type="checkbox" id="person" className="input_chk" ref={personalAgreeCheck} />
                    <label for="person">개인정보취급방침에 동의합니다.</label>
                </div>
                <div className="btn_wrap">
                    <button className="btn_cancel" onClick={goBack} style={{marginRight: "0.3rem"}}>취소</button>
                    <button className="btn_summit" onClick={onClickAgreeBtn}>저장</button>
                </div>
            </div>
        </div>
    );
}

export default AdminJoinPolicy;