import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import styled from 'styled-components';
import theme from "styles/theme";
import { useEnvironment } from 'hooks/useEnvironment';
import { swal } from 'utils/swal-utils';
import { formatTime } from 'utils/dateUtils';

const EnvCont = styled.div`
  padding: 30px;
  .titArea {
    text-align: center;
    font-size: 14px;
    .tit {
      font-size: 32px;
      font-weight: 300;
      line-height: 1.5em;
      margin-bottom: 10px
    }
  }
  .vmArea {
    display: flex;
    justify-content: space-between;
    width: 390px;
    margin: 25px auto 10px;
    padding: 30px 5px 0;
    border-top: 1px dotted #dbe5ee;
    .btn_common, .btn_icon {
      height: 43px;
      min-width: 43px;
      & + button {
        margin-left: 2px
      }
    }
    .value {
      display: flex;
      align-items: center;
      font-size: 28px;
      color: #afb8c4;
      font-weight: 300;
      .current, .total {
        padding: 0 8px;
        font-weight: 700;
        font-size: 34px;
      }
      .current {
        color: ${theme.colors.primaryDark};
      }
      .total {
        color: ${theme.colors.defaultDark};
      }
    }
  }
`;

type EnvironmentDeleteVMDialogProps = {
    open: boolean;
    onClose: () => void;
};

function EnvironmentDeleteVMDialog({
    open,
    onClose,

}: EnvironmentDeleteVMDialogProps) {
    const { callGetBatchDeleteList, callDeleteInstance } = useEnvironment();
    const [update] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: '학과',
            field: 'sbj_nm',
            filter: true,
        },
        {
            headerName: '강의명',
            field: 'start_day',
            filter: true,
        },
        {
            headerName: '시작 시간',
            field: 'start_time',
            filter: false,
            /*cellRenderer: function (params: any) {
                const start = (params.data.start_time !== null) ? formatTime(params.data.start_time) : "";
                return start;
            }*/
        },
        {
            headerName: '종료 시간',
            field: 'end_time',
            filter: false,
            /*cellRenderer: function (params: any) {
                const start = (params.data.start_time !== null) ? formatTime(params.data.start_time) : "";
                return start;
            }*/
        }
    ]);

    useEffect(() => {
        if (open) {
            callGetBatchDeleteList().then(function (res) {
                setRowData(res);
                if (res === null) {
                    swal("삭제할 VM이 없습니다.");
                    onClose();
                }
                else if (res !== null && res.length === 0) {
                    swal("삭제할 VM이 없습니다.");
                    onClose();
                }
            });
        }
    }, [open]);

    const handleClose = () => {
        onClose && onClose();
    };

    const handleDeleteVm = () => {
        callDeleteInstance("all");
    };

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="sm"
            title={`VM 일괄 삭제`}
            onClose={handleClose}
            onCreate={handleDeleteVm}
            modules={['create']}
        >
            <EnvCont>
                <div className="titArea">
                    <div className="tit">아래 가상환경을 삭제하시겠습니까?</div>
                    <div>삭제하실 VM을 확인해주세요.</div>
                </div>
                <div className="vmArea">
                    <table className="tb_data">
                        <colgroup>
                            <col width="5%" />
                            <col width="35%" />
                            <col width="30%" />
                            <col width="30%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th scope="col">NO</th>
                                <th scope="col">강의명</th>
                                <th scope="col">시작시간</th>
                                <th scope="col">종료시간</th>
                            </tr>
                            {
                                rowData && rowData.map((data: any, index: number) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{data.sbj_nm}</td>
                                            <td>{formatTime(data.start_time)}</td>
                                            <td>{formatTime(data.end_time)}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    {/*<AgGrid
                        rowData={rowData}
                        columnDefs={columnDefs}
                        showPagination={false}
                    />*/}
                    {/*<div className="value">
                        <span className="current">aaa</span>
                        /
                        <span className="total">bbb</span>
                    </div>
                    <div>
                        <CIconButton
                            type="outlined"
                            icon="plus_l"
                        />
                        <CIconButton
                            type="outlined"
                            icon="minus_l"
                        />
                    </div>*/}
                </div>
            </EnvCont>
        </CDialog>
    );
}
export default EnvironmentDeleteVMDialog;
