import axios from 'axios';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type LectureDay = {
    dseq: number;
    sseq: number;
    week: string;
    start_time: string;
    end_time: string;
};

export type Lecture = {
    capacity: number;
    dayList: LectureDay[];
    col_cd: string;
    col_nm: string;
    dep_cd: string;
    dep_nm: string;
    name: string;
    useq: number;
    start_day: string;
    end_day: string;
    sseq: number;
    sbj_nm: string;
    sbj_seq: number;
    days_str: string;
    usg_type: string;
    subject_useq_count: number;
    stat_cd_subject: number;
    stat_cd_str_subject: string;

    // 화면에 표시할 데이터
    lecture_day ?: string;
    lecture_time ?: string;
};

export type LectureDetail = Lecture & {
    tseq: number;
    tp_nm: string;
    subject_days: LectureDay[];
};

export type UsageType = {
    usg_type_str: string;
    usg_type_code: string;
};

export type LectureDayCreateInput = {
    week: string;
    start_time: string;
    end_time: string;
};

export type LectureCreateInput = {
    sbj_nm: string;
    start_day: string;
    end_day: string;
    tseq: number; //템플릿 시퀀스
    capacity: number;
    usg_type: string;
    subject_days: LectureDayCreateInput[];
    sbj_seq: number;
};

export type LectureModifyInput = {
    sbj_nm: string;
    start_day: string;
    end_day: string;
    tseq: number; //템플릿 시퀀스
    capacity: number;
    subject_days: LectureDayCreateInput[];
    sbj_seq: number;
    sseq: number;
};

export type LectureDayModifyInput = LectureDayCreateInput & {
    sseq: number;
};

export type SimpleLecture = {
    sseq: number;
    sbj_nm: string;
};

export const getLectureList = async () => {
    try {
        const requestUrl = "/admin/subject/list";

        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getSimpleLectureList = async () => {
    try {
        const requestUrl = "/admin/subject/list-simple";
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
}

export const getUsageTypes = async () => {
    try {
        const requestUrl = "/admin/subject/usg-type";

        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const registerSubject = async (newSubject: LectureCreateInput) => {
    try {
        const requestUrl = "/admin/subject/register";

        const res = await axios.post(requestUrl, newSubject);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const deleteSubject = async (sseq: number) => {
    try {
        const requestUrl = "/admin/subject/delete";
        const data = {
            sseq: sseq
        }

        const res = await axios.post(requestUrl, data);

        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getSubjetDetail = async (sseq: number) => {
    try{
        const requestUrl = "/admin/subject/detail";
        const data = {
            sseq: sseq
        };

        const res = await axios.get(requestUrl, {
            params: data
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const modifySubject = async (modifyInput: LectureModifyInput) => {
    try {
        const requestUrl = "/admin/subject/modify";

        const res = await axios.post(requestUrl, modifyInput);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getTodaySubjectList = async () => {
    try {
        const requestUrl = "/admin/subject/list-today";

        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};