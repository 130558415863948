import React, { useState, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { resetRegisterResponse } from 'store/datasetSlice';
import { swalConfirm } from "utils/swal-utils";

export function useDatasetCreate() {
    const { categoryResponse, fileExtensionResponse } = useSelector((state: RootState) => (state.dataset));
    const dispatch = useDispatch();

    const [categoryList, setCategoryList] = useState([]);
    const [fileExtentionList, setFileExtensionList] = useState([]);

    useEffect(() => {
        dispatch({
            type: 'dataset/getCategoryList'
        });
        dispatch({
            type: 'dataset/getFileExtensionList'
        });
    }, []);

    useEffect(() => {
        if (!categoryResponse.loading && categoryResponse.data) {
            setCategoryList(categoryResponse.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (!fileExtensionResponse.loading && fileExtensionResponse.data) {
            setFileExtensionList(fileExtensionResponse.data);
        }
    }, [fileExtensionResponse]);

    const handleSubmitDataset = (inputs: any) => {
        console.log(inputs);
        const formData = new FormData();
        (inputs.file) && formData.append('file', inputs.file[0]);
        formData.append('title', inputs.title);
        formData.append('content', inputs.content);
        formData.append('provider', inputs.provider);
        formData.append('fcseq', inputs.fcseq);
        formData.append('feseq', inputs.feseq);
        formData.append('dsseq', '0');
        formData.append('file_down_cnt', '0');
        formData.append('file_del', 'false');

        dispatch({
            type: 'dataset/registerDataset',
            payload: formData
        });
    };

    return {
        categoryList,
        fileExtentionList,
        handleSubmitDataset
    }
}