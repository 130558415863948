import React, { useEffect, useState } from 'react';
import { useLoginUser } from 'hooks/useLoginUser';
import { useHistory } from 'react-router-dom';
import { swalConfirm, swal } from 'utils/swal-utils';

function ChangePassword() {
    const history = useHistory();
    const { changePwdResponse, callChangePassword } = useLoginUser();
    const [changeInput, setChangeInput] = useState({
        currentPwd: "",
        newPwd: "",
        newPwdConfirm: ""
    });

    /*useEffect(() => {
        if (!changePwdResponse.loading && changePwdResponse.data) {
            swal(changePwdResponse.data);
        }

        if (changePwdResponse.error) {
            swal(changePwdResponse.error.message);
        }
    }, [changePwdResponse]);*/

    const onChangeInput = (event) => {
        const key = event.target.name;
        const value = event.target.value;

        setChangeInput((prev) => {
            return {
                ...prev,
                [key]: value
            };
        });
    };

    const onClickSave = () => {
        if (changeInput.newPwd.trim() !== "") {
            if (changeInput.newPwd === changeInput.newPwdConfirm) {
                callChangePassword(changeInput.newPwd);
            }
            else {
                swal("비밀번호가 서로 다릅니다.");    
            }
        }
        else {
            swal("새로운 비밀번호를 입력해 주세요.");
        }
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <React.Fragment>
            <h3 className="board_tit">비밀번호 변경</h3>
            <div className="join_wrap mypage_wrap">
                <div className="join_box_wrap">
                    <table className="tb_style_02">
                        <colgroup>
                            <col style={{width: "20%"}} />
                            <col />
                        </colgroup>
                        <tr>
                            <th>현재 비밀번호</th>
                            <td>
                                <input type="password" name="currentPwd" className="input_style_02" placeholder="현재 비밀번호를 입력해주세요." value={changeInput.currentPwd} onChange={onChangeInput} />
                            </td>
                        </tr>
                        <tr>
                            <th>새 비밀번호</th>
                            <td>
                                <input type="password" name="newPwd" className="input_style_02" placeholder="새 비밀번호를 입력해주세요." value={changeInput.newPwd} onChange={onChangeInput} />
                            </td>
                        </tr>
                        <tr>
                            <th>새 비밀번호 확인</th>
                            <td>
                                <input type="password" name="newPwdConfirm" className="input_style_02" placeholder="새 비밀번호를 다시 한번 입력해주세요." value={changeInput.newPwdConfirm} onChange={onChangeInput} />
                            </td>
                        </tr>
                    </table>
                    <div className="btn_wrap">
                        <button className="btn_cancel" style={{marginRight: "0.3rem"}} onClick={handleCancel}>취소</button>
                        <button className="btn_summit" onClick={onClickSave}>저장</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ChangePassword;