import React, { useState, useEffect } from 'react';
import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { AgGrid, SelectableAgGrid } from 'components/datagrids'
import { agDateColumnFilter } from "utils/common-utils";
import { CCreateButton } from "components/buttons";
import RegistrationCreateDialog from "pages/Contest/RegistrationCreateDialog";
import RegistrationDialog from "pages/Contest/RegistrationDialog";
import RegistrationEditDialog from "pages/Contest/RegistrationEditDialog";

const RegistrationTab = () => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [rowData] = useState([
        {
            no: "1",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "2",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "3",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "4",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "5",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "6",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "7",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "8",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "9",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "10",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "11",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "12",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "13",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "14",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "15",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "16",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "17",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "18",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "19",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
        {
            no: "20",
            title: "2021년도 신용보증기금 홍보영상 시나리오 공모전",
            date: "2021.03.01 ~ 2021.05.31",
            host: "경남테크노파크",
        },
    ]);
    const [columnDefs] = useState([
        {
            headerName: 'NO',
            field: 'no',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
        },
        {
            headerName: '제목',
            field: 'title',
            filter: true,
        },
        {
            headerName: '기간',
            field: 'date',
            filter: 'agDateColumnFilter',
            filterParams: agDateColumnFilter(),
            minWidth: 200,
            maxWidth: 300,
        },
        {
            headerName: '주최',
            field: 'host',
            filter: true,
            minWidth: 200,
            maxWidth: 300,
        },
    ]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCreateOpen = () => {
        setOpen2(true);
    };
    const handleCreateClose = () => {
        setOpen2(false);
    };
    const handleEditOpen = () => {
        setOpen3(true);
    };
    const handleEditClose = () => {
        setOpen3(false);
    };

    return (
        <PanelBox>
            <CommActionBar isSearch={true}>
                <CCreateButton
                    onClick={handleCreateOpen}
                >
                    등록
                </CCreateButton>
                <CCreateButton
                    onClick={handleOpen}
                >
                    상세보기
                </CCreateButton>
                <CCreateButton
                    onClick={handleEditOpen}
                >
                    수정
                </CCreateButton>
                <RegistrationCreateDialog
                    open={open2}
                    onClose={handleCreateClose}
                />
            </CommActionBar>
            <div className="grid-height_20">
                <AgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    rowPerPage={20}
                />
            </div>
            <RegistrationDialog
                open={open}
                onClose={handleClose}
            />
            <RegistrationEditDialog
                open={open3}
                onClose={handleEditClose}
            />
        </PanelBox>
    );
}
export default RegistrationTab;
