import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme: Theme) => ({
    alertWrap: {
        padding: "2px",
        border: "2px solid #6748c1",
        /*width: "300px",*/
        height: "180px",
    },
    alertContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "15px",
    },
    alertAction: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 10px",
        borderTop: "1px solid #dfe6e8",
        background: "#f0f4f6",
    },
}));

const AlertContentWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    div {
        font-size: 20px;
        color: #071e3f;
    }
`;

const ActionButton = styled.button`
    min-width: 90px;
    height: 35px;
    padding: 4px;
    border-radius: 4px;
    background: #fff;
    color: #071e3f;
    font-size: 13px;
    border: 1px solid #071e3f;
`;

type CustomAlertProps = {
    children: React.ReactNode;
    alertOpen: boolean;
    onConfirm: () => void;
};

function CustomAlert({
    children,
    alertOpen,
    onConfirm,
}: CustomAlertProps) {
    const classes= useStyles();

    const handleClose = () => {
        onConfirm();
    };

    return (
        <Dialog
            open={alertOpen}
            onClose={handleClose}
            classes={{paper: classes.alertWrap}}
        >
            <AlertContentWrap>
                { children }
            </AlertContentWrap>
            <DialogActions classes={{root: classes.alertAction}}>
                <ActionButton onClick={handleClose}>확인</ActionButton>
            </DialogActions>
        </Dialog>
    );
}

export default CustomAlert;