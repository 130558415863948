import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { swal } from "utils/swal-utils";

type ApiResponse = {
    success: boolean;
    data: any;
    message: string;
};

const customAxios: AxiosInstance = axios.create();

customAxios.interceptors.response.use(function (response: AxiosResponse<ApiResponse>) {
    // status가 2xx의 범위일 때
    if (response.data.success) {
        return response.data.data;
    }
    else {
        // 서버가 2xx 범위의 응답을 주었지만 api 호출 결과가 실패일 때
        //swal("요청이 실패하였습니다.");
        if (response.data.message) {
            throw new Error(response.data.message);
        }
        else {
            throw new Error("200 ok 응답이 왔지만 요청이 실패하였습니다.");
        }
    }
}, function (error) {
    // status가 2xx 이외의 범위일 때
    //swal("요청이 실패하였습니다.");
    //throw new Error("요청이 실패하였습니다.");
    //return error;
    console.log(">>> customAxiosUtils : caught error");
    //throw new Error(error);
    return Promise.reject(error);
});

// request interceptor
customAxios.interceptors.request.use(
    function (config) {
    // set authorization
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');
    return config;
    }
);

export default customAxios;