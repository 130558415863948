import React from 'react';

const fontSizes = {
  root: '12px',
  title: '14px',
  subtitle: '13px',
  paragraph: '13px',
}
const colors = {
  primary: '#8b8687',
  primaryDark: '#333030',
  primaryLight: '#e3dede',
  defaultDark: '#333030',
  defaultBorder: '#e0e2e5',
  panelTit: '#8b8687',
  defaultBg: '#333030',
}
const sizes = {
  sideNavWidth: '231px',
}
const mixins = {
  clearfix: `
    &::after {
      content: '';
      display: block;
      clear: both;
  `,
  flexCenter: `
    display: flex;
    justify-contents: center;
    align-items: center;
  `,
  ir: `
    position: absolute;
    top: auto;
    left: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
  `,
  ir_wa: `
    display: block;
    overflow: hidden;
    position: relative;
    z-index: -1;
  `,
  ir_btn: `
    display: block;
    height: 20px;
    color: transparent;
    white-space: nowrap;
    overflow: hidden;
  `
}

const superTheme = {
  fontSizes,
  colors,
  sizes,
  mixins,
}
export default superTheme;
