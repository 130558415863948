import React from 'react';
import {RootState} from '../store';
import {useSelector, useDispatch} from 'react-redux';
import { getUsageList } from '../store/mypageSlice';

export function useMypage() {
    const {usageListResponse} = useSelector((state : RootState) => state.mypage);
    const dispatch = useDispatch();

    const callGetUsageList = (pageInfo:any) => {
        dispatch(getUsageList(pageInfo));
    };

    return {usageListResponse, callGetUsageList};
}