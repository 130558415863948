import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Location from '../components/pageLocation/location';
import ReservationList from '../pages/Lab/reservationList';
import ReservationWrite from '../pages/Lab/reservationWrite';
import Dataset from '../pages/Lab/dataset';
import DatasetList from '../pages/Lab/datasetList';
import DatasetView from '../pages/Lab/datasetView';


function LabRouter({ match }) {
    const location = useLocation();

    const [locationText, setLocationText] = useState("데이터셋");

    useEffect(() => {
        const path = location.pathname;
        if(path === '/Portal/lab/reservationlist'){
            setLocationText("예약 신청 현황");
        }else if(path === '/Portal/lab/datasetlist'){
            setLocationText("데이터셋 목록");
        }else if(path === '/Portal/lab/reservationWrite'){
            setLocationText("예약 신청서 작성");
        }else {
            setLocationText("데이터셋");
        }
      }, [location]);

    return (
        <React.Fragment>
            <Location location={locationText} rootLocation="가상 실습실" />
            <section id="contetns">
                <div className="layout_inner">
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <Dataset match={match} />} />
                        <Route path={`${match.url}/reservationlist`} render={() => <ReservationList match={match}  />} />
                        <Route path={`${match.url}/reservationWrite`} render={() => <ReservationWrite match={match}  />} />
                        <Route path={`${match.url}/dataset`} render={() => <Dataset match={match}  />} />
                        <Route path={`${match.url}/datasetlist`} render={() => <DatasetList match={match}  />} />
                        <Route path={`${match.url}/datasetview`} render={() => <DatasetView match={match}  />} />
                    </Switch>
                </div>
            </section>
        </React.Fragment>   
    );
}

export default LabRouter;
