import { Practicer, updateUserStatus } from "api/practicer";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { setSelectedPracticer } from 'store/practicerSlice';
import { swal } from "utils/swal-utils";

export function usePracticer() {
    const { practicerResponse, selectedPracticer, activeLogResponse, logDetailResponse, saidaUseUpdateResponse } = useSelector((state: RootState) => state.priacticer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!saidaUseUpdateResponse.loading && !saidaUseUpdateResponse.error) {
            callGetPracticerList();
        }
    }, [saidaUseUpdateResponse]);

    const callGetPracticerList = () => {
        callSetSelectedPracticer(null);
        dispatch({
            type: 'practicer/getPracticerList'
        });
    };

    const callSetSelectedPracticer = (practicer: Practicer | null) => {
        dispatch(setSelectedPracticer(practicer));
    }

    const callUpdateUserStatus = (id: string, code: number, reason_msg: string) => {
        updateUserStatus(id, code, reason_msg).then(function (res) {
            if (res.success) {
                callGetPracticerList();
                swal("수정되었습니다.");
            }
            else {
                swal("요청이 실패하였습니다.");
            }
        })
        .catch(function (error) {
            swal("요청이 실패하였습니다.");
        });
    };

    const callGetUserActiveLogList = (page: number) => {
        dispatch({
            type: 'practicer/getActiveLogList',
            payload: page
        });
    };

    const callGetUserActiveLogDetail = (aseq: number) => {
        dispatch({
            type: 'practicer/getActiveLogDetail',
            payload: aseq
        });
    };

    const callUpdateSaidaUseStatus = (statCd: number) => {
        selectedPracticer && dispatch({
            type: 'practicer/updateSaidaUseStatus',
            payload: {id: selectedPracticer.id, stat_cd: statCd}
        });
    };

    
    const callUpdateInitUserPasswd = () => {
        selectedPracticer && dispatch({
            type: 'practicer/updateInitUserPasswd',
            payload: {id: selectedPracticer.id}
        });
    };

    return {
        practicerResponse,
        selectedPracticer,
        activeLogResponse,
        logDetailResponse,
        callGetPracticerList,
        callSetSelectedPracticer,
        callUpdateUserStatus,
        callGetUserActiveLogList,
        callGetUserActiveLogDetail,
        callUpdateSaidaUseStatus,
        callUpdateInitUserPasswd
    };
}