import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { reducerUtils, asyncState } from "utils/asyncUtils";
import * as TemplateApi from "api/template" ;
import { formatDateTime } from 'utils/dateUtils';
import { AxiosError } from "axios";

type RegisterInfos = {
    networkList: TemplateApi.Network[] | null;
    imageList: TemplateApi.Image[] | null;
    flavorList: TemplateApi.Flavor[] | null;
};

type TemplateState = {
    templateResponse: asyncState;
    networkList: TemplateApi.Network[] | null;
    imageList: TemplateApi.Image[] | null;
    flavorList: TemplateApi.Flavor[] | null;
    selectedTemplate: TemplateApi.Template | null;
};

const initialState: TemplateState = {
    templateResponse: reducerUtils.initial(),
    networkList: [],
    imageList: [],
    flavorList: [],
    selectedTemplate: null,
};

const templateSlice = createSlice({
    name: 'template',
    initialState: initialState,
    reducers: {
        getTemplateList(state) {
            state.templateResponse = reducerUtils.loading();
        },
        getTemplateListSuccess(state, action: PayloadAction<TemplateApi.Template[]>) {
            state.templateResponse = reducerUtils.success(action.payload);
        },
        getTemplateListError(state, action: PayloadAction<AxiosError>) {
            state.templateResponse = reducerUtils.error(action.payload);
        },
        setSeletedTemplate(state, action: PayloadAction<TemplateApi.Template | null>) {
            state.selectedTemplate = action.payload;
        },
        setRegisterInfo(state, action: PayloadAction<RegisterInfos>) {
            state.networkList = action.payload.networkList;
            state.imageList = action.payload.imageList;
            state.flavorList = action.payload.flavorList;
        },
    },
});

export const { getTemplateList, getTemplateListSuccess, getTemplateListError, setSeletedTemplate, setRegisterInfo } = templateSlice.actions;
export default templateSlice.reducer;

/* saga */

function* getTemplateListSaga() {
    try {
        const templateResult: TemplateApi.Template[] = yield call(TemplateApi.getTemplateList, "");
        const newTemplateResult: TemplateApi.Template[] = templateResult.map((template: TemplateApi.Template, index: number) => {
            const regDate = new Date(template.reg_date);
            const formattedDate = formatDateTime(regDate);
            template.reg_date = formattedDate;
            return template;
        });
        yield put(getTemplateListSuccess(newTemplateResult));
    } catch (error) {
        yield put(getTemplateListError(error));
    }
}

function* getRegisterInfoSaga() {
    try {
        const networkList: TemplateApi.Network[] = yield call(TemplateApi.getTemplateList, "network");
        const newNetworkList: TemplateApi.Network[] = networkList.map((net: TemplateApi.Network, index: number) => {
            const regDate = new Date(net.reg_date);
            const formattedRegDate = formatDateTime(regDate);

            const updDate = new Date(net.upd_date);
            const formattedUpdDate = formatDateTime(updDate);

            net.reg_date = formattedRegDate;
            net.upd_date = formattedUpdDate;

            return net;
        });

        const imageList: TemplateApi.Image[] = yield call(TemplateApi.getTemplateList, "image");
        const newImageList: TemplateApi.Image[] = imageList.map((image: TemplateApi.Image, index: number) => {
            const regDate = new Date(image.reg_date);
            const formattedRegDate = formatDateTime(regDate);

            const updDate = new Date(image.upd_date);
            const formattedUpdDate = formatDateTime(updDate);

            image.reg_date = formattedRegDate;
            image.upd_date = formattedUpdDate;

            return image;
        });

        const flavorList: TemplateApi.Flavor[] = yield call(TemplateApi.getTemplateList, "flavor");

        yield put(setRegisterInfo({networkList: newNetworkList, imageList: newImageList, flavorList: flavorList}));
    } catch (error) {
        yield put(setRegisterInfo({networkList: [], imageList: [], flavorList: []}));
    }
}

export function* templateSaga() {
    yield takeLatest(getTemplateList, getTemplateListSaga);
    yield takeLatest(getTemplateList, getRegisterInfoSaga);
}