import React, { useState, useCallback, useEffect, useRef } from 'react';
import * as Common from '../../utils/common.js';
import * as Fetch from '../../utils/fetchFunction.js';

import CustomAlert from '../../components/alert/customAlert';
//import Datepicker from '../../components/datepicker/datepicker';
import Loading from 'components/spinner/Loading';
import trainee from 'images/portal/mypage/join_ico_trainee.png';

function TraineeJoinAccount({ history, match }) {
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: '',
        id: '',
        pwd: '',
        pwd_re: '',
        col_cd: '',
        dept_cd: '',
        class_no: '',
        job_cd: '',
        email: '',
        //birth_day: '',
        purpose_cd: '',
        mobile_no: '',
        role_cd: 'CIVIL'
    });

    const [colList, setColList] = useState([]);
    const [deptList, setDeptList] = useState([]);
    const [alertPopOpen, setAlertPopOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const idRef = useRef();
    const emailRef = useRef();

    // call API to get col_cd, dept_cd info
    useEffect(() => {
        Fetch.callApi("/user/depcol-info", {}, "GET")
        .then((res) => {
            console.log(res);
            if (res.success) {
                setColList(res.data.col);
                setDeptList(res.data.dep);
            }
            else {
                console.log("조회 실패");
            }
        });
    }, []);

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const handleChange = useCallback((e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });
    }, [userInfo]);

    /*const verifyBirthday = useCallback((birthday) => {
        const length = birthday.length;
        console.log(length);
        if (length == 8) {
            return true;
        }
        else {
            return false;
        }
    }, []);*/

    const onClickSubmitBtn = () => {
        const userInfoObject = userInfo;

        if (Common.checkValidity(userInfoObject)) {
            console.log("fetch!");
            setLoading(true);
            Fetch.callApi("/user/signup", userInfoObject, "POST")
            .then((res) => {
                console.log(res);
                setLoading(false);
                if (res.success) {
                    history.push(`${match.url}/join_finish`);
                }
                else {
                    openAlert(res.message);
                }
            });
        }
        else {
            openAlert("정보를 모두 입력해주세요.");
        }

        // check password
        /*
        if (userInfoObject.pwd !== userInfoObject.pwd_check) {
            window.alert("비밀번호가 같지 않습니다.");
            return;
        }
        else if (!verifyBirthday(userInfoObject.birth_day)) {
            window.alert("생일을 입력해주세요.");
            return;
        }
        else {
            if (Common.checkValidity(userInfoObject) && Common.verifyEmail(userInfoObject.email)) {
                console.log("fetch!");
                Fetch.callApi("/user/signup", userInfoObject, "POST")
                .then((res) => {
                    console.log(res);
                    if (res.success) {
                        history.push(`${match.url}/join_finish`);
                    }
                    else {
                        window.alert(res.message);
                    }
                });
            }
        }
        */
    };

    /*const getBirthday = useCallback((result) => {
        setUserInfo({
            ...userInfo,
            birth_day: result
        })
    }, [userInfo]);*/

    const onClickIdCheckBtn = () => {
        const idValue = idRef.current.value;
        if (idValue.trim() === "") {
            //window.alert("아이디를 입력해 주세요.");
            openAlert("아이디를 입력해 주세요.");
        }
        else {
            Fetch.callApi("/user/signupcheck/id", {val: idValue}, "GET")
            .then((res) => {
                console.log(res);
                if (res.success) {
                    //window.alert(res.message);
                    openAlert(res.message);
                }
                else {
                    //window.alert(res.message);
                    openAlert(res.message);
                }
            });
        }
    };

    const onClickEmailCheckBtn = () => {
        const emailValue = emailRef.current.value;
        
        if (emailValue.trim() === "") {
            //window.alert("이메일을 입력해 주세요.");
            openAlert("이메일을 입력해 주세요.");
        }
        else {
            Fetch.callApi("/user/signupcheck/email", {val: emailValue}, "GET")
            .then((res) => {
                console.log(res);
                if (res.success) {
                    //window.alert(res.message);
                    openAlert(res.message);
                }
                else {
                    //window.alert(res.message);
                    openAlert(res.message);
                }
            });
        }
    };

    const openAlert = (message) => {
        setAlertText(message);
        setAlertPopOpen(true);
    }

    const onConfirmAlert = () => {
        setAlertPopOpen(false);
        setAlertText("");
    };

    return (
        <div className="join_wrap trainee_join_form">
            <CustomAlert alertOpen={alertPopOpen} onConfirm={onConfirmAlert}>
                <div>{ alertText }</div>
            </CustomAlert>
            <div className="join_tit">
                <img src={trainee} alt="trainee" /> 실습자 회원가입
            </div>
            <div className="join_box_wrap">
                <table className="tb_style_02">
                <colgroup>
                    <col style={{width: "15%"}} />
                    <col style={{width: "35%"}} />
                    <col style={{width: "15%"}} />
                    <col style={{width: "35%"}} />
                </colgroup>
                    <tr>
                        <th>이름</th>
                        <td>
                            <input type="text" name="name" className="input_style_02" placeholder="이름을 입력해주세요." onChange={handleChange} />
                        </td>
                        <th>아이디</th>
                        <td>
                            <input type="text" name="id" className="input_style_05" placeholder="아이디를 입력해주세요." onChange={handleChange} ref={idRef} />
                            <button type="button" className="btn_id_chk" id="id_chk" onClick={onClickIdCheckBtn}>중복확인</button>
                        </td>
                    </tr>
                    <tr>
                        <th>비밀번호</th>
                        <td>
                            <input type="password" name="pwd" className="input_style_02" placeholder="비밀번호를 입력해주세요." onChange={handleChange} />
                        </td>
                        <th>비밀번호 확인</th>
                        <td>
                            <input type="password" name="pwd_re" className="input_style_02" placeholder="비밀번호를 한번 더 입력해주세요." onChange={handleChange} />
                        </td>
                    </tr>
                    <tr>
                        <th>학교(회사명)</th>
                        <td>
                            <div className="select_wrap">
                            <select name="col_cd" id="" className="" onChange={handleChange}>
                                <option value="">학교(회사명) 선택</option>
                                {
                                    colList.map((collage, idx) => {
                                        return <option value={collage.col_cd} key={idx}>{collage.col_nm}</option>
                                    })
                                }
                            </select>
                            </div>
                        </td>
                        <th>학과(부서)</th>
                        <td>
                            <div className="select_wrap">
                            <select name="dept_cd" id="" onChange={handleChange}>
                                <option value="">학과(부서) 선택</option>
                                {
                                    deptList.map((dept, idx) => {
                                        return <option value={dept.dep_cd} key={idx}>{dept.dep_nm}</option>
                                    })
                                }
                            </select>
                            </div>
                        </td>
                    </tr>
                <tr>
                    <th>학번(사번)</th>
                    <td>
                        <input type="text" name="class_no" className="input_style_02" placeholder="학번을 입력해주세요." onChange={handleChange} />
                    </td>
                    <th>직업</th>
                    <td>
                        <div className="select_wrap">
                            <select name="job_cd" onChange={handleChange}>
                                <option value="">직업 선택</option>
                                <option value="PF">교수</option>
                                <option value="TA">조교</option>
                                <option value="RE">연구원</option>
                                <option value="TR">실습자</option>
                                <option value="UN">무직</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>이메일</th>
                    <td>
                        <input type="text" name="email" className="input_style_05" placeholder="이메일을 입력해주세요." onChange={handleChange} ref={emailRef} />
                        <button type="button" className="btn_id_chk" id="id_chk" onClick={onClickEmailCheckBtn}>중복확인</button>
                    </td>
                    <th>사용 목적</th>
                    <td>
                        {/*<Datepicker returnDate={getBirthday} readyDateValue="" />*/}
                        <div className="select_wrap">
                            <select name="purpose_cd" onChange={handleChange}>
                                <option value="">목적 선택</option>
                                <option value="R01">연구용</option>
                                <option value="S01">강의용</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th style={{borderRight: "1px solid #e4e8ec"}}>연락처</th>
                    <td colSpan="3">
                        <input type="text" name="mobile_no" className="input_style_02" placeholder="숫자만 입력해주세요. (ex.01012345678)" onChange={handleChange} />
                    </td>
                </tr>
                </table>

                <div className="btn_wrap">
                    <button className="btn_cancel" onClick={goBack} style={{marginRight: "0.3rem"}}>취소</button>
                    <button className="btn_summit" onClick={onClickSubmitBtn}>{loading ? <Loading/>: <span className="ico next">가입신청</span>}</button>
                </div>
            </div>
        </div>
    );
}

export default TraineeJoinAccount;