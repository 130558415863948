import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useLoginUser } from 'hooks/useLoginUser';

import DropDownMenu from './dropDownMenu';

type SidebarContentProps = {
    
};

function SidebarContent({ 
    
}: SidebarContentProps) {
    const civilMenuList = [
        {
            name: '마이페이지',
            link: '/Portal/mypage'
        },
    ];

    const adminMenuList = [
        {
            name: '마이페이지',
            link: '/Portal/mypage'
        },
    ];

    const { userName, userRole, callUserLogout } = useLoginUser();
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const token = localStorage.getItem('access-token');
    const isLogin = (token)? true : false;

    const onClickDropdownBtn = () => {
        setDropDownOpen(prev => !prev);
    };

    const onCloseMenu = () => {
        setDropDownOpen(false);
    };

    if (isLogin && userRole === "CIVIL") {
        return (
            <div className="sidebar_cont">
                <div className="sidebar_user">
                    <div className="sidebar_user_profile"></div>
                    <button type="button" className="sidebar_user_btn" onClick={onClickDropdownBtn}>{userName}</button>
                    <DropDownMenu menuList={civilMenuList} open={dropDownOpen} onCloseMenu={onCloseMenu} />
                </div>
            </div>
        );
    }
    else if (isLogin && ((userRole === "ADMIN") || (userRole === "SUPER"))) {
        return (
            <div className="sidebar_cont">
                <div className="sidebar_admin">
                    <Link className="btn_admin_console" to="/Dashboard">ADMIN CONSOLE</Link>
                    <div className="sidebar_user">
                        <div className="sidebar_admin_profile admin"></div>
                        <button type="button" className="sidebar_user_btn" onClick={onClickDropdownBtn}>{userName}</button>
                        <DropDownMenu menuList={adminMenuList} open={dropDownOpen} onCloseMenu={onCloseMenu} />
                    </div>
                    {/*<button type="button" className="btn_admin_console" onClick={onClickAdminConsole}>ADMIN CONSOLE</button>*/}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="sidebar_cont">
                <div className="login_box">
                    <ul>
                        <li><Link to="/Portal/login">로그인</Link></li>
                        <li><Link to="/Portal/join">회원가입</Link></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default SidebarContent;