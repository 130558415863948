import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import { swalConfirm, swal } from "utils/swal-utils";
import { CTextField } from "components/textfields";

import { useNotice } from 'hooks/useNotice';
import { deleteNotice } from 'api/notice';

type NoticeDeleteDialogProps = {
    open: boolean;
    onClose: () => void;
    detail:any;
    hostUrl:string;
};

const NoticeDeleteDialog = ({open,detail,onClose, hostUrl}:NoticeDeleteDialogProps) => {

    const { detailNoticeResponse, modifyResponse, selectedNotice, callGetNoticeList } = useNotice();

    useEffect(() => {
        if (!modifyResponse.loading && modifyResponse.error) {
            swal(modifyResponse.error.message);
            handleClose();
        }
        
        if (!modifyResponse.loading && modifyResponse.data) {
            swal(modifyResponse.data.message);
            handleClose();
        }
    }, [modifyResponse]);

    useEffect(() => {
        // setDeleteTitle(detail.title);
        // setDeleteCont(detail.cont);
    }, [open]);


    const handleDeleteAlert = () => {
        console.log('delete!!')
        swalConfirm("삭제하시겠습니까?").then(function(res) {
            if (res.isConfirmed) {
                if (selectedNotice !== null) {
                    deleteNotice(selectedNotice.cseq).then(function (response) {
                        swal(response.message).then(function(res) {
                            handleClose();
                            callGetNoticeList();
                        });
                    });
                }
            }
        });
    }

    const handleClose = () => {
        onClose && onClose();
    };

    if (detailNoticeResponse.loading && detailNoticeResponse.data) {
        // 로딩중
    }
    if (detailNoticeResponse.error) {
        // 에러
    }
    if (!detailNoticeResponse.data) {
        return null;
    }

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="md"
            title={`공지사항 삭제하기`}
            onClose={handleClose}
            onDelete={handleDeleteAlert}
            modules={['delete']}
            topBtn={true}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>제목</th>
                    <td colSpan={3}>
                    <span className="td-txt">{selectedNotice?.title}</span>
                    </td>
                </tr>
                <tr>
                    <th>내용</th>
                    
                    <td colSpan={3}>
                        {/* <td><span className="td-txt">{selectedNotice?.title}</span></td> */}
                        <textarea
                            rows={20}
                            name='cont'
                            defaultValue={detail.cont}
                            readOnly
                            style={{pointerEvents: 'none', border:'none', padding:'6px 12px'}}
                        ></textarea>
                    </td>
                </tr>
                <tr>
                    <th>첨부파일</th>
                    <td>
                        {
                                (detail.fileNm !== null)
                                ?  <span className="td-txt"><a style={{color:'black', marginRight:'5px'}} href={`${hostUrl}/Portal/community/notice/download-file?cseq=${detail.cseq}`}>{detail.fileNm}</a></span>
                                :  <span className="td-txt"/>
                        }
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default NoticeDeleteDialog;
