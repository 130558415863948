import React, { useState, useEffect } from 'react';
import { useAlarm } from 'hooks/useAlarm';

import CustomProgressBar from 'components/progress/CustomProgressBar';

const hideProgress = {
    display: "none",
};

function CreateProgressBar() {
    const { vmProgress } = useAlarm();
    const [progress, setProgress] = useState<number>(0);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        if (vmProgress !== null) {
            setShow(true);
            const value = parseInt(vmProgress.ratio.split("%")[0]);
            setProgress(value);
        }
        else {
            setTimeout(() => setShow(false), 5000);
        }
    }, [vmProgress]);

    return (
        <div style={show ? {} : hideProgress}>
            <CustomProgressBar value={progress} />
        </div>
    );
}

export default CreateProgressBar;