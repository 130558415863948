import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, getContext, takeEvery } from '@redux-saga/core/effects';
import * as LoginUserApi from 'api/loginUser';
import { reducerUtils, asyncState, createAsyncReducer, createAsyncSaga } from 'utils/asyncUtils';
import { History } from 'history';

type ApiResponse = {
    success: boolean;
    message: string;
};

type LoginInfo = {
    userId: string;
    userPwd: string;
    userRole: string;
};

type LoginResult = {
    token: string;
    name: string;
    role_cd: string;
    id: string;
    job_cd: string;
    saida_stat_cd: number;
}

type LoginUserState = {
    userRole: string;
    userName: string;
    userJob:string;
    loginResponse: asyncState;
    logoutResponse: asyncState;
    userId: string;
    accessToken: string;
    autoLoginResponse: asyncState;
    changePwdResponse: asyncState;
    isUserLogout: boolean;
    isWsConnected: boolean;
    saidaUseStat: number;
};

const initialState: LoginUserState = {
    userRole: "",
    userName: "",
    loginResponse: reducerUtils.initial(),
    logoutResponse:  reducerUtils.initial(),
    userId:"",
    userJob:"",
    accessToken: "",
    autoLoginResponse: reducerUtils.initial(),
    changePwdResponse: reducerUtils.initial(),
    isUserLogout: false,
    isWsConnected: false,
    saidaUseStat: 0,
}

const loginUserSlice = createSlice({
    name: 'loginUser',
    initialState: initialState,
    reducers: {
        userLogin(state, action: PayloadAction<LoginInfo>) {
            state.loginResponse = reducerUtils.loading();
        },
        userLoginSuccess(state, action: PayloadAction<LoginResult>) {
            state.loginResponse = reducerUtils.success(action.payload);
        },
        userLoginError(state, action: PayloadAction<LoginResult>) {
            state.loginResponse = reducerUtils.error(action.payload);
        },
        setLoginUser(state, action: PayloadAction<LoginResult>) {
            state.userName = action.payload.name;
            state.userRole = action.payload.role_cd;
            state.userId = action.payload.id;
            state.userJob = action.payload.job_cd;
            state.saidaUseStat = action.payload.saida_stat_cd;
            state.isUserLogout = false;
        },
        setLogoutUser(state){
            localStorage.removeItem("access-token");
            state.userName = '';
            state.userRole = '';
            state.loginResponse = reducerUtils.initial();
            state.userId = '';
            state.userJob = '';
            state.isUserLogout = true;
        },
        userLogout(state) {
            state.logoutResponse = reducerUtils.loading();
        },
        userLogoutSuccess(state, action: PayloadAction<ApiResponse>) {
            state.logoutResponse = reducerUtils.success(action.payload);
        },
        userLogoutError(state, action: PayloadAction<ApiResponse>) {
            state.logoutResponse = reducerUtils.error(action.payload);
        },
    },
    extraReducers: {
        ...createAsyncReducer('loginUser/autoLogin', 'autoLoginResponse'),
        ...createAsyncReducer('loginUser/changePassword', 'changePwdResponse'),
    }
});

export const { userLogin, userLoginSuccess, userLoginError, setLoginUser, userLogout, setLogoutUser, userLogoutSuccess,userLogoutError } = loginUserSlice.actions;
export default loginUserSlice.reducer;

/* saga */

const changePasswordSaga = createAsyncSaga('loginUser/changePassword', LoginUserApi.changePassword);

function* userLoginSaga(action: PayloadAction<LoginInfo>) {
    try {
        const loginResult: LoginResult = yield call(LoginUserApi.userLogin, action.payload.userId, action.payload.userPwd, action.payload.userRole, );
        yield put(userLoginSuccess(loginResult));
        yield localStorage.setItem('access-token', loginResult.token);
        yield put(setLoginUser(loginResult));
        const history: History = yield getContext('history');
        history.push("/Portal/main");
    } catch (error: any) {
        yield put(userLoginError(error));
    }
}

function* autoLoginSaga() {
    try {
        const loginResult: LoginUserApi.userSimpleInfo = yield call(LoginUserApi.getUserSimpleInfo);
        yield put({
            type: 'loginUser/autoLoginSuccess',
            payload: loginResult
        });
        const data = {
            token: '',
            name: loginResult.name,
            role_cd: loginResult.role_cd,
            id: loginResult.id,
            job_cd: loginResult.job_cd,
            saida_stat_cd: loginResult.saida_stat_cd
        };
        yield put(setLoginUser(data));
    } catch (error) {
        yield put({
            type: 'loginUser/autoLoginError',
            payload: error
        });
        yield localStorage.removeItem('access-token');
    }
}

function* userLogoutSaga() {
    try {
        const logoutResult: ApiResponse = yield call(LoginUserApi.userLogOut);
    
        yield put(setLogoutUser());
        yield put(userLogoutSuccess(logoutResult));
        const history: History = yield getContext('history');
        history.push("/Portal/main");
    }catch(error :any){
        yield put(userLogoutError(error));

    }
    
}


export function* loginUserSaga() {
    yield takeLatest(userLogin, userLoginSaga);
    yield takeEvery('loginUser/autoLogin', autoLoginSaga);
    yield takeEvery('loginUser/changePassword', changePasswordSaga);
    yield takeLatest(userLogout, userLogoutSaga);
}