import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const ContainerArea = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  left: ${({ theme }) => theme.sizes.sideNavWidth};
  //display: flex;
  //flex-direction: column;
  padding: 0 10px 10px;
  border-top-left-radius: 7px;
  background-color: #f0f1f4;
  overflow-y: auto
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 64px;
  padding-top: 10px;
`;

const BreadcrumbsArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 44px;
  position: relative;
  margin-left: 9px;
  padding-left: 17px;
  color: #000;
  font-size: 18px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 16px;
    margin-top: -8px;
    border-left: 3px solid #000;
  }
  //a, span {
  //  text-decoration: none;
  //  color: #919aa9;
  //  padding-right: 22px;
  //  margin-right: 15px;
  //  background: url(/images/bullet/breadcrumbs_arr.png) no-repeat right center
  //}
  span {
    font-weight: 400;
  }
`;
const ContArea = styled.div`
  //display: flex;
  //flex-direction: column;
`;

const MainContents = (props) => {
    return (
        <ContainerArea>
            <HeaderArea>
                <BreadcrumbsArea aria-label="breadcrumb">
                    <span>{props.currentPage}</span>
                </BreadcrumbsArea>
            </HeaderArea>
            <ContArea>
                {props.children}
            </ContArea>
        </ContainerArea>
    )
};

export { MainContents };
