import React from 'react';
import { PushAlarm } from 'api/alarm';
import { useAlarm } from 'hooks/useAlarm';
import { ToastProps } from 'react-toastify';

type CustomToastProps = {
    alarm: PushAlarm;
};

function CustomToast({
    alarm,
}: CustomToastProps) {
    const { callConfirmAlarm } = useAlarm();

    const handleAlarmConfirm = () => {
        callConfirmAlarm(alarm.rseq);
        //props.closeToast();
    };
    
    return (
        <div onClick={handleAlarmConfirm}>  
            {alarm.msg}
        </div>
    );
}

export default CustomToast;