const checkValidity = (object) => {
    for (const data in object) {
        if (object[data] === "") {
            //window.alert("정보를 모두 입력해주세요.");
            return false;
        }
    }
    return true;
};

const verifyEmail = (email) => {
    const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (email.match(regExp) != null) {
        return true;
    }
    else {
        return false;
    }
};

const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const date = dateString.substring(6, 8);

    const result = year + "." + month + "." + date;
    return result;
};

const formatPhoneNumber = (phone) => {
    const first = phone.substring(0, 3);
    const second = phone.substring(3, 7);
    const third = phone.substring(7, 11);

    const result = first + "-" + second + "-" + third;
    return result;
};

const logOut = () => {
    localStorage.removeItem("access-token");
};


export { checkValidity, verifyEmail, formatDate, formatPhoneNumber, logOut }