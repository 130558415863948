import { useRef, useEffect, useCallback } from 'react';

const baseOption = {
    threshold: 0.5
};

export function useIntersection(onIntersect: any) {
    const ref = useRef<HTMLDivElement>(null);
    const checkIntersect = useCallback(([entry], observer) => {
        if (entry.isIntersecting) {
            //observer.unobserve(entry.target);
            onIntersect(entry, observer);
            //observer.observe(entry.target);
        }
    }, [onIntersect]);
    
    useEffect(() => {
        const observer = new IntersectionObserver(checkIntersect, {...baseOption});
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () =>  observer.disconnect();
    }, [ref, checkIntersect]);
    return [ref];
};