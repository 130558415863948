import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

function USG({match}:RouteComponentProps) {
    return (
        <React.Fragment>
            <div className="layout_inner">

                <div className="equipment_tit_wrap">
                    <h3 className="equipment_tit">USG</h3>
                    <p>USG공유대학(University System of Gyeognam)의 연구/실습 장비 목록입니다.</p>
                </div>

                <h3 className="board_tit">장비 목록</h3>

                <div className="equipment_list_wrap type_top">
                    <img src="/lib/image/equipment/usg_01.png" alt=""/>
                    <div className="text_wrap">
                        <h4 className="equipment_list_tit">경남대학교 보유 장비</h4>
                        <p>
                        경남대학교는 연구시설 및 장비를 이용하여<br/>
                        경남지역의 산업체와 상호 호혜의 원칙하에 산학협동의<br/>
                        주도적인 역할을 수행하고 있습니다.
                        </p>
                        <button className="btn_view_more" onClick={()=>{window.open('https://gnjangbi.com/eqList?instId=200702080009,200709110067')}}>보유 장비 보러가기</button>
                    </div>
                </div>

            <div className="equipment_list_wrap">
                <img src="/lib/image/equipment/usg_02.png" alt=""/>
                <div className="text_wrap">
                    <h4 className="equipment_list_tit">경상대학교 보유 장비</h4>
                    <p>
                        경상대학교는 4차 산업혁명의 시대에 맞추어<br/>
                        첨단 융합 기술 분야의 교육과 연구에 매진하여 유용한<br/>
                        아이디어를 구현하여 다양한 자원의 사회적 가치를 높이는<br/>
                        가치 창조적인 기술인을 육성하고자 합니다.
                    </p>
                    <button className="btn_view_more"onClick={()=>{window.open('https://gnjangbi.com/eqList?instId=200702080017,201303136534')}}>보유 장비 보러가기</button>
                </div>
            </div>

            <div className="equipment_list_wrap type_bottom">
                <img src="/lib/image/equipment/usg_03.png" alt=""/>
                <div className="text_wrap">
                    <h4 className="equipment_list_tit">창원대학교 보유 장비</h4>
                    <p>
                        창원대학교 공동장비 활용센터는 지역 기업이<br/>
                        보유하기 어려운 고가의 연구시설 장비의 공동 활용<br/>
                        지원 체계를 마련하고 시험, 분석 등<br/>
                        기능별 일괄 지원 서비스를 제공하고 있습니다.
                    </p>
                    <button className="btn_view_more" onClick={()=>{window.open('https://gnjangbi.com/eqList?instId=201009075161,200702080150')}}>보유 장비 보러가기</button>
                </div>
            </div>

            <h3 className="board_tit">인적 자원</h3>
            <div className="equipment_human_list">
            <ul>
                <li>
                <div className="equip_human_box human_box_01">
                    <h5>USG 공유대학<br/>직원 안내 </h5>
                    <p>
                    USG 공유대학의 본부, 학사관리팀,<br/>
                    학생지원팀, 시스템운영팀<br/>
                    직원을 확인하실 수 있습니다.
                    </p>
                    <a href="http://usg.ac.kr/sub1_6" target="_blank">자세히 보기</a>
                </div>
                </li>
                <li>
                <div className="equip_human_box human_box_02">
                    <h5>스마트 제조 엔지니어링<br/>교수진</h5>
                    <p>
                    스마트 기계설계해석, E-Mobility,<br/>
                    지능로봇 전공 교수진을<br/>
                    확인하실 수 있습니다.
                    </p>
                    <a href="http://usg.ac.kr/sub2_1" target="_blank">자세히 보기</a>
                </div>
                </li>
            </ul>

            <ul>
                <li>
                <div className="equip_human_box human_box_03">
                    <h5>스마트 제조 ICT<br/>교수진</h5>
                    <p>
                    경남 제조업을 이끌어 갈 수 있는<br/>
                    ICT/SW 융합 역량을 가진 인재로<br/>
                    양성합니다.
                    </p>
                    <a href="http://usg.ac.kr/sub2_2" target="_blank">자세히 보기</a>
                </div>
                </li>
                <li>
                    <div className="equip_human_box human_box_04">
                        <h5>스마트 공동체<br/>교수진</h5>
                        <p>
                        현장 참여 실습을 활용해<br/>
                        지역의 다양한 문제를 창의적으로<br/>
                        해결할 수 있는 인재로 양성합니다.
                        </p>
                        <a href="http://usg.ac.kr/sub2_3" target="_blank">자세히 보기</a>
                    </div>
                </li>
            </ul>
            </div>
        </div>
    </React.Fragment>
    );
}

export default USG;