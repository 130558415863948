import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App';

/* import redux middlewares */
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

/* create redux store */
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer, { rootSaga } from 'store';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const customHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware({
  context: {
    history: customHistory
  }
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware, logger],
});

/* run root saga */
sagaMiddleware.run(rootSaga); // 스토어 생성이 완료된 다음 saga를 실행해야 한다.

ReactDOM.render(
  <Router history={customHistory}>
    <Provider store={store}>
      <App/>
    </Provider>
  </Router>
  ,
  document.getElementById('root')
);
