import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

export function useDatasetDetail() {
    const { detailResponse } = useSelector((state: RootState) => (state.dataset));
    const dispatch = useDispatch();

    const [detail, setDetail] = useState<any>(null);

    useEffect(() => {
        //if (!detailResponse.loading && detailResponse.data) {
        if (!detailResponse.loading) {
            setDetail(detailResponse.data);
        }
    }, [detailResponse]);

    const handleDelete = () => {
        dispatch({
            type: 'dataset/deleteDataset',
            payload: detail.dsseq
        });
    };

    return {
        detail,
        handleDelete
    }
}