import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import * as MypageApi from 'api/mypage';
import { reducerUtils, asyncState } from 'utils/asyncUtils';
import { AxiosError } from 'axios';
import { formatDate } from 'utils/dateUtils';

type pageInfo = {
    page : number
}

type mypageState = {
    usageListResponse: asyncState;
};

const initialState: mypageState = {
    usageListResponse: reducerUtils.initial(),
}

const mypageSlice = createSlice({
    name: 'mypage',
    initialState: initialState,
    reducers: {
        getUsageList(state, action:PayloadAction<pageInfo>) {
            state.usageListResponse = reducerUtils.loading();
        },
        getUsageListSuccess(state, action: PayloadAction<MypageApi.Usage[]>) {
            state.usageListResponse = reducerUtils.success(action.payload);
        },
        getUsageListError(state, action: PayloadAction<AxiosError>) {
            state.usageListResponse = reducerUtils.error(action.payload);
        },        
    }
})

export const {getUsageList, getUsageListSuccess, getUsageListError}= mypageSlice.actions;
export default mypageSlice.reducer;

/* saga */
function* getUsageListSaga(action:any) {
    try {
        const usageResult: any[] = yield call(MypageApi.getUsageDetails, action.payload.page);
        const result = Object.entries(usageResult);
        const usageList = result[0][1];
        const pageList = result[1][1];
        const total:number = pageList.rowCount;
        const newUsageResult: MypageApi.Usage[] = usageList.map((detail: MypageApi.Usage, index: number) => {
            // const formattedStart = formatDate(detail.start_day);
            // const formattedEnd = formatDate(detail.end_day);
            const formattedStart = detail.start_day;
            const formattedEnd = detail.end_day;
            detail.start_day = formattedStart;
            detail.end_day = formattedEnd;
            detail.total = total;
            return detail;
        });
        yield put(getUsageListSuccess(newUsageResult));
    } catch (error: any) {
        yield put(getUsageListError(error));
    }
}


export function* mypageSaga() {
    yield takeLatest(getUsageList, getUsageListSaga);
}
