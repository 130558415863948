import React, { useState, useEffect } from 'react';
import { useSwal } from 'hooks/useSwal';

import { CDialog } from "components/dialogs";
import styled from 'styled-components';
import { swalConfirm } from "utils/swal-utils";
import { PanelBox } from "components/styles/PanelBox";

type AuthorityDialogProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    onConfirm?: (text?: string) => void;
};

const Textarea = styled.textarea`
    width: 100%;
    height: 15em;
    border: 0;
    resize: vertical;
    padding-left: 10px
`;

function AuthorityDialog({
    open,
    onClose,
    title,
    onConfirm = () => {}
}: AuthorityDialogProps) {
    //const {open} = props;
    const [reason, setReason] = useState<string>("");
    const { openSwal  } = useSwal();
    const handleClose = () => {
        onClose && onClose();
    };

    const handleReasonInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value;
        setReason(text);
    };

    const handleCreateAlert = () => {
        openSwal({ text: "저장하시겠습니까?", onClickConfirm: () => {onConfirm(reason)} });
        handleClose();
    };

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="sm"
            title={title + ` 사유 입력`}
            onClose={handleClose}
            onCustom={handleCreateAlert}
            modules={['custom']}
        >
            <PanelBox>
                <Textarea
                    placeholder="사유를 100자 이내로 입력해 주세요."
                    value={reason}
                    onChange={handleReasonInput}
                />
            </PanelBox>
        </CDialog>
    );
}
export default AuthorityDialog;
