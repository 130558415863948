import React, { useState, useEffect, useCallback } from 'react';
import { Alarm, getAlarmList } from 'api/alarm';
import { useIntersection } from 'hooks/useIntersection';
import Swal from "sweetalert2";

import NotifyContent from './NotifyContent';

function WholeNotify() {
    const [alarmList, setAlarmList] = useState<Alarm[]>([]);
    const [page, setPage] = useState<number>(1);
    const [ref] = useIntersection(useCallback(async (entry: any, observer: any) => {
        try {
            observer.unobserve(entry.target);
            await handleIntersection(page);
            setPage((prev: number) => (prev + 1));
            observer.observe(entry.target);
        } catch (error) {
            let timerInterval: any = null;
            Swal.fire({
            html: '마지막 알림입니다.',
            width: 270,
            timer: 2000,
            timerProgressBar: false,
            didOpen: () => {
                timerInterval = setInterval(() => {
                
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
            });
        }
    }, [page]));

    const handleIntersection = async (page: number) => {
        const result = await getAlarmList(page);
        setAlarmList((prev: Alarm[]) => ([
            ...prev,
            ...result
        ]));
    };

    return (
        <React.Fragment>
            <ul>
                {
                    alarmList.map((alarm: Alarm, index: number) => (
                    <NotifyContent alarm={alarm} key={alarm.rseq} />
                    ))
                }
            </ul>
            <div ref={ref} style={{height: "100px"}}>
                
            </div>
        </React.Fragment>
    );
}

export default WholeNotify;