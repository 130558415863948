import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { asyncState, createAsyncReducer, createAsyncSaga, reducerUtils } from "utils/asyncUtils";
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { getAdminList, Admin, getPracticerActiveLogList, getAdminActiveLogList } from 'api/admin';

type adminState = {
    adminResponse: asyncState;
    practicerActiveResponse: asyncState;
    adminActiveResponse: asyncState;
    selectedAdmin: Admin | null;
};

const initialState: adminState = {
    adminResponse: reducerUtils.initial(),
    practicerActiveResponse: reducerUtils.initial(),
    adminActiveResponse: reducerUtils.initial(),
    selectedAdmin: null,
};

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        setSelectedAdmin(state, action: PayloadAction<Admin | null>) {
            state.selectedAdmin = action.payload;
        },
    },
    extraReducers: {
        ...createAsyncReducer('admin/getAdminList', 'adminResponse'),
        ...createAsyncReducer('admin/getPracticerActiveLogList', 'practicerActiveResponse'),
        ...createAsyncReducer('admin/getAdminActiveLogList', 'adminActiveResponse'),
    }
});

export const {
    setSelectedAdmin
} = adminSlice.actions;
export default adminSlice.reducer;

/* saga */

const getAdminListSaga = createAsyncSaga('admin/getAdminList', getAdminList);
const getPracticerActiveLogListSaga = createAsyncSaga('admin/getPracticerActiveLogList', getPracticerActiveLogList);
const getAdminActiveLogListSaga = createAsyncSaga('admin/getAdminActiveLogList', getAdminActiveLogList);

export function* adminSaga() {
    yield takeLatest('admin/getAdminList', getAdminListSaga);
    yield takeLatest('admin/getPracticerActiveLogList', getPracticerActiveLogListSaga);
    yield takeLatest('admin/getAdminActiveLogList', getAdminActiveLogListSaga);
}