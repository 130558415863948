import React from 'react';
import { Link } from 'react-router-dom';

import trainee from 'images/portal/mypage/login_ico_trainee.png';
import admin from 'images/portal/mypage/login_ico_admin.png';

function LoginChoose({ match }) {
    const traineeChooseText = "실습자로 로그인 하시는 경우\n해당 메뉴를 클릭해주세요.";
    const adminChooseText = "관리자로 로그인 하시는 경우\n해당 메뉴를 클릭해주세요.";

    return (
        <div className="login_wrap">
            <div className="login_tit">
                플랫폼 로그인
            </div>
            <div className="login_choose">
                <ul>
                    <li key="1">
                        <Link to={`${match.url}/trainee_login`}>
                            <div className="choose_img"><img src={trainee} alt="trainee" /></div>
                            <div className="choose_txt">
                                <p>실습자</p>
                                {
                                    traineeChooseText.split('\n').map((line, i) => {
                                        return (<span key={i}>{line}<br/></span>)
                                    })
                                }
                            </div>
                        </Link>
                    </li>
        
                    <li key="2">
                        <Link to={`${match.url}/admin_login`}>
                            <div className="choose_img"><img src={admin} alt="admin" /></div>
                            <div className="choose_txt">
                                <p>관리자</p>
                                {
                                    adminChooseText.split('\n').map(line => {
                                        return (<span>{line}<br/></span>)
                                    })
                                }
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default LoginChoose;