import React, { useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Fetch from '../../utils/fetchFunction.js';

function FindId({ match, history, onCheckId }) {
    const nameRef = useRef();
    const emailRef = useRef();
    const [ err , setErr ] = useState('');


    const goBack = () => {
        history.goBack();
    };

    const onClickConfirmBtn = () => {
        const nameValue = nameRef.current.value;
        const emailValue = emailRef.current.value;

        if ((nameValue.trim() === "") || (emailValue.trim() === "")) {
            window.alert("이름과 이메일을 입력해주세요.");
        }
        else {
            Fetch.callApi("/user/idinqury", {name: nameValue, email: emailValue}, "POST")
            .then((res) => {
                console.log(res);
                if (res.success) {
                    //onCheckId(res.data);
                    onCheckId(res.data.id, nameValue, emailValue);
                    history.push(`${match.url}/check_id`);
                }
                else {
                    window.alert(res.message);
                }
            });
        }
    };

    return (
        <div className="find_wrap">
            <div className="find_tit">아이디 찾기</div>
            <p className="find_tit_txt">회원가입시 등록했던 이름과 이메일을 입력하면 아이디를 찾을 수 있습니다.</p>

            <div className="find_account_wrap">
                <div className="find_account">
                    <input type="text" className="input_style_01" placeholder="이름을 입력해주세요." ref={nameRef} />
                    <input type="text" className="input_style_01" placeholder="이메일을 입력해주세요." ref={emailRef} />
                </div>
                <div className="find_btn_wrap">
                    <Link to="/login/trainee_login" style={{width: "100%", marginRight: "0.3rem"}}>
                        <button type="button" className="cancel_btn">취소</button>
                    </Link>
                    <button type="button" className="confirm_btn" onClick={onClickConfirmBtn}>확인</button>
                </div>
            </div>
        </div>
    );
}

export default FindId;