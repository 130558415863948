import React, { useEffect, useState } from 'react';
import { agDateColumnFilter } from "utils/common-utils";
import { useReservation } from 'hooks/useReservation';
import { Reservation } from 'api/reservation';
import { asyncState } from 'utils/asyncUtils';
import { reservationStateClass } from 'utils/state';
import { formatDate, formatDateTime, formatTime } from 'utils/dateUtils';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { SelectableAgGrid } from 'components/datagrids';

type ReservationListProps = {
    timing: string;
    listResponse: asyncState;
    fetchList: (timing: string) => void;
}

function ReservationList({
    timing,
    listResponse,
    fetchList
}: ReservationListProps) {
    //const { expectedResponse, callGetReservationList, callSetSelectedReservation } = useReservation();
    const { callSetSelectedReservation } = useReservation();
    const [update, setUpdate] = useState(false);
    const [rowData, setRowData] = useState<Reservation[]>([]);

    useEffect(() => {
        fetchList(timing);
    }, [update]);

    useEffect(() => {
        if (!listResponse.loading && listResponse.data) {
            setRowData(listResponse.data);
        }
    }, [listResponse]);

    const [columnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: '아이디',
            field: 'id',
            filter: true,
        },
        {
            headerName: '신청일',
            field: 'reg_date',
            filter: false,
            cellRenderer: function (params: any) {
                const reg_date = params.data.reg_date;
                const formattedDate = formatDateTime(new Date(reg_date));
                return `<span>${formattedDate}</span>`;
            }
        },
        {
            headerName: '강의명',
            field: 'sbj_nm',
            filter: true,
        },
        {
            headerName: '시작일자',
            field: 'start_day',
            filter: 'dateRangeFilter2',
            filterParams: { buttons: ['clear'] },
            cellRenderer: function (params: any) {
                const dateTime = params.data.start_day;
                if (dateTime !== "") {
                    const splitDateTime = dateTime.split(" ");
                    return formatDate(splitDateTime[0]) + " " + formatTime(splitDateTime[1]);
                }
                else {
                    return "";
                }
            }
        },
        {
            headerName: '종료일자',
            field: 'end_day',
            filter: false,
            cellRenderer: function (params: any) {
                const dateTime = params.data.end_day;
                if (dateTime !== "") {
                    const splitDateTime = dateTime.split(" ");
                    return formatDate(splitDateTime[0]) + " " + formatTime(splitDateTime[1]);
                }
                else {
                    return "";
                }
            }
        },
        {
            headerName: '상태',
            field: 'stat_cd',
            filter: true,
            cellRenderer: function (params: any) {
                switch(params.data.stat_cd) {
                    case (0):
                        return `<span class="state_ico ${reservationStateClass.STANDBY}">대기</span>`;
                    case (1):
                        return `<span class="state_ico ${reservationStateClass.APPROVE}">승인</span>`;
                    case (2):
                        return `<span class="state_ico ${reservationStateClass.REJECT}">반려 (승인거부)</span>`;
                    case (3):
                        return `<span class="state_ico ${reservationStateClass.POSTPONE}">보류</span>`;
                    case (4):
                        return `<span class="state_ico ${reservationStateClass.DELETE}">취소 (삭제)</span>`;
                    case (5):
                        return `<span class="state_ico ${reservationStateClass.STOP}">정지 (실습정지)</span>`;
                    case (6):
                        return `<span class="state_ico ${reservationStateClass.WITHDRAW}">탈퇴</span>`;
                    case (7):
                        return `<span class="state_ico ${reservationStateClass.EXTEND}">연장신청</span>`;
                    case (9):
                        return `<span class="state_ico ${reservationStateClass.ETC}">기타</span>`;
                    default: 
                        return `<span></span>`;
                }
            }
        },
    ]);

    const handleRowSelection = (reservation: Reservation[]) => {
        callSetSelectedReservation(reservation[0]);
        /*setRowData((prev: Reservation[]) => {
            // find selected row index
            let newArray = [...prev];
            newArray[0] = {
                ...newArray[0],
                stat_cd: 7,
            };
            console.log(newArray);
            return newArray;
        })
        console.log(reservation);*/
    };
    
    return (
        <PanelBox>
            <CommActionBar isSearch={true}>
            </CommActionBar>
            <div className="grid-height_10">
                <SelectableAgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={handleRowSelection}
                    showPagination={true}
                />
            </div>
        </PanelBox>
    );
}

export default ReservationList;