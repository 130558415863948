import React, { useState, useEffect } from 'react';
import { usePracticer } from 'hooks/usePracticer';
import { formatDate } from 'utils/dateUtils';
import { practicerStateClass } from 'utils/state';
import { useSwal } from 'hooks/useSwal';

import { CSelectButton } from "components/buttons";
import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import AuthorityDialog from "pages/Practicer/AuthorityDialog";

function AuthorityDetail() {
    const { selectedPracticer, callUpdateUserStatus, callUpdateSaidaUseStatus, callUpdateInitUserPasswd } = usePracticer();
    const { openSwal } = useSwal();
    const [open, setOpen] = useState<boolean>(false);
    const [stateBarClass, setStateBarClass] = useState<string>('stateBar ');
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [userState, setUserState] = useState<number>(-1);

    useEffect(() => {
        if (selectedPracticer === null) {
            return;
        }
        switch(selectedPracticer.stat_cd) {
            case (0):
                return setStateBarClass('stateBar ' + practicerStateClass.APPROVE_REQUEST);
            case (1):
                return setStateBarClass('stateBar ' + practicerStateClass.APPROVED);
            case (2):
                return setStateBarClass('stateBar ' + practicerStateClass.DENIED);
            case (3):
                return setStateBarClass('stateBar ' + practicerStateClass.STOP);
            case (4):
                return setStateBarClass('stateBar ' + practicerStateClass.WITHDRAW_REQUEST);
            case (5):
                return setStateBarClass('stateBar ' + practicerStateClass.WITHDRAW);
            default: 
                return;
        }
    }, [selectedPracticer]);

    if (selectedPracticer === null) {
        return (
            <PanelBox></PanelBox>
        );
    }

    const actionList = [
        {
            name: '승인',
            onClick: () => {
                setDialogTitle('승인');
                setUserState(1);
                handleOpen();
            },
        },
        {
            name: '승인거부',
            onClick: () => {
                setDialogTitle('승인거부');
                setUserState(2);
                handleOpen();
            },
        },
        {
            name: '실습정지',
            onClick: () => {
                setDialogTitle('실습정지');
                setUserState(3);
                handleOpen();
            },
        },
        {
            name: '탈퇴',
            onClick: () => {
                setDialogTitle('탈퇴');
                setUserState(5);
                handleOpen();
            },
        },
        {
            name: '비밀번호 초기화',
            onClick: () => {
                setDialogTitle('비밀번호 초기화');
                handleUserPwdInit("사용자의 비밀번호를 초기화 하시겠습니까?");
                //setUserState(5);
                //handleOpen();
            },
        },
    ];

    const saidaActionList = [
        {
            name: '사용',
            onClick: () => {
                handleSaidaStateUpdate("SAi-Da를 사용하시겠습니까?", 1);
            },
        },
        {
            name: '취소',
            onClick: () => {
                handleSaidaStateUpdate("SAi-Da 사용을 취소하시겠습니까?", 0);
            },
        },
    ]

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleUserStateUpdate = (text: string = "") => {
        callUpdateUserStatus(selectedPracticer.id, userState, text);
    };

    const handleSaidaStateUpdate = (text: string, status: number) => {
        openSwal({ text: text, onClickConfirm: () => {
            console.log(">>> SAi-DA 권한 업데이트 ");
            callUpdateSaidaUseStatus(status);
        } })
    };

    const handleUserPwdInit = (text: string) => {
        openSwal({ text: text, onClickConfirm: () => {
            console.log(">>> 유저 비밀번호 초기화 ");
            callUpdateInitUserPasswd();
        } })
    }

    return (
        <PanelBox className="panel_detail">
            <div className="panelTitBar">
                <div className="tit"><strong>{selectedPracticer.id}</strong>의 상세보기</div>
            </div>
            <CommActionBar>
                <CSelectButton
                    items={actionList}
                >
                    액션
                </CSelectButton>
                <CSelectButton
                    items={saidaActionList}
                >
                    SAi-Da
                </CSelectButton>
                <AuthorityDialog
                    open={open}
                    onClose={handleClose}
                    title={dialogTitle}
                    onConfirm={handleUserStateUpdate}
                />
            </CommActionBar>
            <div className="panelCont">
                <div className={stateBarClass}>
                    <span className="state_ico">{selectedPracticer.stat_str}</span>
                </div>
                <table className="tb_data">
                    <tbody>
                    <tr>
                        <th>id</th>
                        <td>{selectedPracticer.id}</td>
                        <th>이름</th>
                        <td>{selectedPracticer.name}</td>
                    </tr>
                    <tr>
                        <th>권한</th>
                        <td>{selectedPracticer.role_str}</td>
                        <th>할당받은 VM</th>
                        <td>{selectedPracticer.vm_count}</td>
                    </tr>
                    <tr>
                        <th>학교</th>
                        <td>{selectedPracticer.col_nm}</td>
                        <th>학과</th>
                        <td>{selectedPracticer.dep_nm}</td>
                    </tr>
                    <tr>
                        <th>이메일</th>
                        <td>{selectedPracticer.email}</td>
                        {/*<th>생년월일</th>
                        <td>
                            {
                                formatDate(selectedPracticer.birth_day)
                            }
                        </td>*/}
                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td colSpan={3}>{selectedPracticer.mobile_no}</td>
                    </tr>
                    {/*<tr>
                        <th>사유</th>
                        <td colSpan={3}>
                            <div>기타사유</div>
                            <div className="added"></div>
                        </td>
                    </tr>*/}
                    </tbody>
                </table>
            </div>
        </PanelBox>
    );
}

export default AuthorityDetail;