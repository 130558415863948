import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRouter from 'components/Router/PrivateRouter';
import { websocketConnectUrl } from 'config';

import { useMaintainLogin } from 'hooks/useMaintainLogin';

import SockJS from 'sockjs-client';
import StompJS from 'stompjs';
import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import CustomToast from 'components/toast/CustomToast';
import { toast } from 'react-toastify';
import { setVmProgress } from 'store/alarmSlice';

import {
  Dashboard,
  Reservation,
  Environment,
  Lecture,
  Template,
  Practicer,
  Dataset,
  Contest,
  Notice,
  Notify,
  PortalMain,
  Admin,
  EmailApprove,
  VmApprove
} from 'pages';

function App() {
  const { userRole, userId, isUserLogout } = useSelector((state: RootState) => state.loginUser);
  const dispatch = useDispatch();
  const [verifyUser, setVerifyUser] = useState(false);
  const [client, setClient] = useState<StompJS.Client | null>(null);

  useEffect(() => {
    if (userRole === "ADMIN" || userRole === "SUPER") {
      console.log("try connecting");
      if (client === null) {
        connectWs();
      }
    }
  }, [userRole, client]);

  useEffect(() => {
    if (isUserLogout) {
      console.log("try disconnecting");
      if (client !== null) {
        disconnectWs();
      }
    }
  }, [isUserLogout, client]);

  const connectWs = () => {
    const socket = new SockJS(websocketConnectUrl);
    const stompClient: StompJS.Client = StompJS.over(socket);
    if (userRole !== "CIVIL" && userRole !== "") {
      stompClient.connect({}, function (frame) {
        // connect callback
        stompClient.subscribe('/topic/alarm', function (data){
            // toast message
            console.log(data); 
            const message = JSON.parse(data.body);
            //const payload = JSON.parse(message.detail);
            console.log(message);
            if (message.id === userId) {
              console.log(message.detail);
              if (message.detail) {
                if (message.detail.isFinished) {
                  dispatch(setVmProgress(null));
                }
                else {
                  dispatch(setVmProgress(message.detail));
                }
              }
              else {
                // detail이 없을 때에만 toast 알림 표시
                switch (message.grade) {
                  case (0):
                    toast.success(<CustomToast alarm={message} />);
                    return;
                  case (1):
                    toast.warning(<CustomToast alarm={message} />);
                    return;
                  case (2):
                    toast.info(<CustomToast alarm={message} />);
                    return;
                  case (3):
                    toast.error(<CustomToast alarm={message} />);
                    return;
                  default:
                    toast(<CustomToast alarm={message} />);
                    return;
                }
              }
            }
        });
      }, function(error) {
        console.log(error);
      });
      setClient(stompClient);
    }
  };

  const disconnectWs = () => {
    if (client !== null && client.connected) {
      console.log("try disconnecting 2");
      client.disconnect(() => {
        // disconnect callback function
      })
      setClient(null);
    }
  };

  /*useEffect(() => {
    const currentUrl = window.location.pathname;
    console.log(currentUrl);
    if (currentUrl.includes('/Mail')) {
      setVerifyUser(false);
    }
    else {
      setVerifyUser(true);
    }
  }, []);*/

  useMaintainLogin();
  
  return (
    <>
      <Route exact path="/" component={PortalMain}/>
      <Route path="/Mail/auth/approval/request/:info" component={EmailApprove} />
      <Route path="/Mail/vm/approval/request/:info" component={VmApprove} />
      <Switch>
          <PrivateRouter path="/Dashboard" component={Dashboard} />
          <PrivateRouter path="/Reservation" component={Reservation} />
          <PrivateRouter path="/Environment" component={Environment} />
          <PrivateRouter path="/Lecture" component={Lecture} />
          <PrivateRouter path="/Template" component={Template} />
          <PrivateRouter path="/Practicer" component={Practicer} />
          <PrivateRouter path="/Admin" component={Admin} />
          <PrivateRouter path="/Dataset" component={Dataset} />
          <PrivateRouter path="/Contest" component={Contest} />
          <PrivateRouter path="/Board" component={Notice} />
          <PrivateRouter path="/Notify" component={Notify} />
          <Route path="/Portal" component={PortalMain} />
      </Switch>
    </>
  );
}

export default App;
