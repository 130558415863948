import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useInputs } from 'hooks/useInputs';

const FilterStyle = {
    boxShadow: "0 1px 4px 1px rgb(186 191 199 / 40%)",
    borderRadius: "3px",
    background: "#ffffff",
    minWidth: "145px",
    boxSizing: "border-box",
};

const InputStyle = {
    border: "none",
    width: "100%",
    height: "100%",
    padding: "8px 8px"
};


export default forwardRef((props, ref) => {
    const [active, setActive] = useState(false);
    const { inputs, setInputs, onChangeSelect } = useInputs({status: ""});

    useEffect(() => {
        props.filterChangedCallback(inputs.status);
    }, [inputs]);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params) {
                return true;
            },

            isFilterActive() {
                return active && inputs.value !== "";
            },

            // this example isn't using getModel() and setModel(),
            // so safe to just leave these empty. don't do this in your code!!!
            getModel() {
            },

            setModel() {
            }
        }
    });

    const onChangeStatus = (event) => {
        onChangeSelect(event);
        setActive(true);
    };

    const onBlurSelect = (event) => {
        //setInputs((prev) => ({...prev, status: ""}));
        setActive(false);
    };

    return (
        <div style={FilterStyle}>
            <select name="status" onChange={onChangeStatus} onBlur={onBlurSelect} style={InputStyle}>
                {
                    props.options.map((option) => {
                        return <option value={option.value}>{option.key}</option>;
                    })
                }
            </select>
        </div>
    )
});