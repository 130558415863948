import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import trainee from 'images/portal/mypage/join_ico_trainee.png';
import admin from 'images/portal/mypage/join_ico_admin.png';

function JoinSuccess({  }) {
    //const { userType } = useParams();

    /*if (userType === 't') {
        return (
            <div className="join_wrap">
                <div className="join_tit">
                    <img src={trainee} alt="userImg" /> 실습자 회원가입
                </div>
                <div className="join_box_wrap">
                    <div className="join_finish">
                        <h3>실습자 가입신청이 <span>승인</span>되었습니다.</h3>
                        <p>
                            빅데이터 셰어 플랫폼을 이용하실 수 있습니다.
                        </p>
                        <Link to="/Portal/main">
                            <button className="btn_move">메인 페이지로 이동</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    else if (userType === 'a') {
        return (
            <div className="join_wrap">
                <div className="join_tit">
                    <img src={admin} alt="userImg" /> 관리자 회원가입
                </div>
                <div className="join_box_wrap">
                    <div className="join_finish">
                        <h3>관리자 가입신청이 <span>승인</span>되었습니다.</h3>
                        <p>
                            빅데이터 셰어 플랫폼을 이용하실 수 있습니다.
                        </p>
                        <Link to="/Portal/main">
                            <button className="btn_move">메인 페이지로 이동</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div>
                wrong user type.
            </div>
        );
    }*/
    return (
        <div className="layout_inner" style={{marginTop: "200px"}}>
            <div className="join_wrap">
                <div className="join_tit">
                    <img src={trainee} alt="userImg" /> 회원가입 승인 안내
                </div>
                <div className="join_box_wrap">
                    <div className="join_finish">
                        <h3>가입신청이 <span>승인</span>되었습니다.</h3>
                        <p>
                            AI 가상실습실 플랫폼을 이용하실 수 있습니다.
                        </p>
                        <Link to="/Portal/main">
                            <button className="btn_move">AI 가상실습실 메인 페이지로 이동</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinSuccess;