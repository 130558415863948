import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import { openSwal } from "utils/swal-utils";
import { useTemplate } from 'hooks/useTemplate';

import { modifyTemplate } from 'api/template';

type TemplateEditDialogProps = {
    open: boolean;
    onClose: () => void;
}

function TemplateDeleteDialog ({
    open,
    onClose
}: TemplateEditDialogProps) {
    //const {open} = props;
    const { selectedTemplate, callDeleteTemplate } = useTemplate();

    if (selectedTemplate === null) {
        return (
            <></>
        );
    }

    const handleClose = () => {
        onClose();
    };

    const handleDeleteAlert = () => {
        openSwal("삭제하시겠습니까?", () => {
            callDeleteTemplate(selectedTemplate.tseq);
            onClose();
        });
    }


    return (
        <CDialog
            id="myEditDialog"
            open={open}
            maxWidth="sm"
            title={`템플릿 삭제`}
            onClose={handleClose}
            onDelete={handleDeleteAlert}
            modules={['delete']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>템플릿명</th>
                    <td>
                        <span className="td-txt">
                            {selectedTemplate.tp_nm}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>네트워크</th>
                    <td>
                        <span className="td-txt">
                            {selectedTemplate.network_nm}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>이미지</th>
                    <td>
                        <span className="td-txt">
                            {selectedTemplate.image_nm}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>사양</th>
                    <td>
                        <span className="td-txt">
                            {selectedTemplate.flavor_id}
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default TemplateDeleteDialog;
