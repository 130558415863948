import React, { useState, useEffect, useCallback } from 'react';
import * as Common from '../../utils/common.js';
import * as Fetch from '../../utils/fetchFunction.js';
import { useLoginUser } from 'hooks/useLoginUser';

import UserInfoTable from './userInfoTable';
import ModifyUserInfo from '../ModifyUserInfo/modifyUserInfo';
import UserUsageDetail from './userUsageDetail'

function UserInfo({ user, refreshUserInfo }) {
    const [changeInfo, setChangeInfo] = useState(false);
    const [userInfo, setUserInfo] = useState({...user});
    const [depList, setDepList] = useState();

    const { userName, userRole } = useLoginUser();

    // update userInfo if user changes.
    useEffect(() => {
        setUserInfo(user);
    }, [user]);

    const onClickModifyBtn = useCallback(() => {
        Fetch.callApi("/user/depcol-info/dep", {}, "GET")
        .then((res) => {
            if (res.success) {
                setDepList(res.data.dep);
                setChangeInfo(true);
            }
            else {
                console.log("조회 실패");
            }
        });
    }, []);

    //const handleInfoChange = (birthdayValue, mobileValue, depCd) => {
    const handleInfoChange = (mobileValue, depCd) => {
        //const modifiedUser = {dep_cd: depCd, birth_day: birthdayValue, mobile_no: mobileValue};
        const modifiedUser = {dep_cd: depCd, mobile_no: mobileValue};
        Fetch.callApi("/user/myinfo/modify", modifiedUser, "POST")
        .then((res) => {
            console.log(res);
            if (res.success) {
                //setUserInfo();
                window.alert("수정되었습니다.");
                setChangeInfo(false);
                refreshUserInfo();
            }
            else {
                window.alert("수정에 실패하였습니다.");
            }
        });
    };

    const handleCancelModify = useCallback(() => {
        setChangeInfo(false);
    }, []) ;

    return (
        <React.Fragment>
            <h3 className="board_tit">내 정보 <button type="button" className="btn_modify" onClick={onClickModifyBtn}>정보 수정</button></h3>
            {
                changeInfo ? <ModifyUserInfo user={userInfo} onSubmit={handleInfoChange} onCancel={handleCancelModify} depList={depList} /> : <UserInfoTable user={userInfo} />
            }
            {
                (userRole === 'CIVIL')
                ? <UserUsageDetail/>
                : ""
            }
        </React.Fragment>
    );
}

export default UserInfo;