import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Fetch from '../../utils/fetchFunction.js';

import CustomAlert from '../../components/alert/customAlert';

//import { HomeDispatchContext, SET_LOGIN_USER_TYPE, SET_LOGIN_USER_NAME } from 'main/store/context';
import { useLoginUser } from 'hooks/useLoginUser';

function AdminLogin({ match, history }) {
    //const {dispatch} = useContext(HomeDispatchContext);
    const { callUserLogin, callUserLogout, loginResponse } = useLoginUser();
    const [alertPopOpen, setAlertPopOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const idRef = useRef();
    const pwdRef = useRef();

    useEffect(() => {
        if (loginResponse.error) {
            if (!loginResponse.error.message) {
                openAlert("가입되지 않은 계정입니다.");
            }
            else {
                openAlert(loginResponse.error.message);
            }
        }
    }, [loginResponse]);

    const openAlert = (message) => {
        setAlertText(message);
        setAlertPopOpen(true);
    }

    const onConfirmAlert = () => {
        setAlertPopOpen(false);
        setAlertText("");
        callUserLogout();
    };

    const onClickLoginBtn = useCallback(() => {
        const id = idRef.current.value;
        const password = pwdRef.current.value;

        if ((id === "") || (password === "")) {
            window.alert("아이디와 비밀번호를 입력해주세요.");
        }
        else {
            console.log("admin login");
            callUserLogin(id, password, 'ADMIN');
            /*Fetch.callApi("/user/login/auth", {id: id, pwd: password}, "POST")
            .then((res) => {
                console.log(res);
                if (res.success) {
                    const tokenData = res.data.token;
                    const nameData = res.data.name;
                    const roleData = res.data.role_cd;
                    localStorage.setItem('access-token', tokenData);
                    //dispatch({ type: SET_LOGIN_USER_TYPE, userType: roleData });
                    //dispatch({ type: SET_LOGIN_USER_NAME, userName: nameData});
                    history.push("/main");
                }
                else {
                    window.alert(res.message);
                }
            });*/
        }
    }, [callUserLogin]);

    const onEnterPressed = (e) => {
        if (e.key === 'Enter'){
            onClickLoginBtn();
        }
    };

    return (
        <section id="contetns">
            <CustomAlert alertOpen={alertPopOpen} onConfirm={onConfirmAlert}>
                <div>{ alertText }</div>
            </CustomAlert>
            <div className="layout_inner">
                <div className="login_wrap">
                    <div className="login_tit">
                        관리자 로그인
                    </div>

                    <div className="login_account_wrap">
                        <div className="login_account admin">
                            <input type="text" className="input_style_01" placeholder="User ID" onKeyPress={onEnterPressed} ref={idRef} />
                            <input type="password" className="input_style_01" placeholder="Password" onKeyPress={onEnterPressed} ref={pwdRef} />
                        </div>

                        <div className="login_btn_wrap">
                            <button type="button" className="login_btn" onClick={onClickLoginBtn}>LOGIN</button>
                            <ul>
                                <li>
                                    <Link to="/Portal/join/admin_policy_agree">관리자 회원가입</Link>
                                </li>
                                <li>
                                    <Link to="/Portal/find_id">아이디 찾기</Link>
                                </li>
                                <li>
                                    <Link to="/Portal/find_password">비밀번호 찾기</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdminLogin;