import axios from 'axios';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type noticeList = {
        title: string;
        reg_date: string;
        cseq: number;
        total?: number;
        rowNum?:number;
}

export type Paging = {
    startPage: number;
    endPage: number;
    currentPage: number;
    lastPage: number;
    pageCount: number;
    rowCount: number;
    displayRow: number;
    offset: number;
}

export type noticeContent = {
    cseq: number,
    useq: number,
    title: string,
    reg_date: any,
    cont: string,
    file_nm: string,
}

export type NoticeCreateInput = {
    notice_title: string,
    notice_cont: string,
    notice_file?: string,
    file_binary?: string,
};


export const getNoticeList = async (pageNumber:number, keyword?:any) => {
    try {
        const requestUrl = "/notice/list";
        const res = await axios.get(requestUrl, {
            params: {
                page: pageNumber,
                keyword : keyword,
            } 
        });
        const result = {
            labBookList : res.data.data.noticeTitleDtoList,
            pagination : res.data.data.paginationDto
        }
        return result
    } catch (error) {
        handleError(error);
    }
};

export const getNoticeContent = async (cseq : number) => {
    try {
        const requestUrl = "/notice/content";
        const res = await axios.get(requestUrl, {
            params: {
                cseq : cseq,
            } 
        });
        const result = res.data
 
        return result
    } catch (error) {
        handleError(error);
    }
};



export const registerNotice = async (newNotice: NoticeCreateInput) => {
    try {
        const requestUrl = "/notice/admin/register";

        const res = await axios.post(requestUrl, newNotice);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};


export const getImportantNoticeList = async () => {
    try {
        const requestUrl = "/notice/important/list";
        const res = await axios.get(requestUrl);

        return res.data.data
    } catch (error) {
        handleError(error);
    }
};