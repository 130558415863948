import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Location from '../components/pageLocation/location';
import JoinChoose from '../pages/Join/joinChoose';
import TraineeJoinPolicy from '../pages/Join/traineeJoinPolicy';
import AdminJoinPolicy from '../pages/Join/adminJoinPolicy';
import TraineeJoinAccount from '../pages/Join/traineeJoinAccount';
import AdminJoinAccount from '../pages/Join/adminJoinAccount';
import JoinFinish from '../pages/Join/joinFinish';
import JoinSuccess from '../pages/Join/joinSuccess';

function JoinRouter({ match, history }) {
    const [userType, setUserType] = useState(null);

    const onClickJoinChoose = (type) => {
        console.log(type);
        setUserType(type);
    };

    return (
        <React.Fragment>
            <Location location="회원가입" />
            <section id="contetns">
                <div className="layout_inner">
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <JoinChoose match={match} onClickJoinChoose={onClickJoinChoose} />} />
                        <Route path={`${match.url}/trainee_policy_agree`} render={() => <TraineeJoinPolicy history={history} match={match} />} />
                        <Route path={`${match.url}/admin_policy_agree`} render={() => <AdminJoinPolicy history={history} match={match} />} />
                        <Route path={`${match.url}/trainee_join`} render={() => <TraineeJoinAccount history={history} match={match} />} />
                        <Route path={`${match.url}/admin_join`} render={() => <AdminJoinAccount history={history} match={match} />} />
                        <Route path={`${match.url}/join_finish`} render={() => <JoinFinish history={history} userType={userType} />} />
                        <Route path={`${match.url}/join_success/:userType`} render={() => <JoinSuccess history={history} />} />
                    </Switch>
                </div>
            </section>
            
        </React.Fragment>
    );
}

export default JoinRouter;