import axios from 'axios';
import { handleError } from './axiosUtils';

//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type VmInstance = {
    nseq: number;
    tseq: number;
    sseq: number;
    dseq: number;
    dep_cd: string;
    dep_nm: string;
    col_cd: string;
    col_nm: string;
    sbj_nm: string;
    usg_type: string;
    start_time: string;
    end_time: string;
    id: string;
    flavor_id: string;
    instance_id: string;
    ipAndPort: string | null;
    subject_stat: string;
    cpu: string;
    disk: string;
    ram: string;
    vm_stat: string | number;
    spend_time: string;
    jupyter_info: string;
}

export type VmCreateRequest = {
    cnt: number;
    usg_type: string;
    //sseq: number,
    //tseq: number,
    //desq: number,
}

export type VmActionRequest = {
    id: string;
    action: string;
};

export type SubjectState = {
    stat_cd: number;
    stat_cd_str: string;
    sseq: number;
    signUpUserCnt: number;
    capacity: number;
}

type listPagingParam = {
    page: number;
    timing?: string;
    category?: string;
    keyword?: string;
};

export const getInstanceList = async (timing: string) => {
    try {
        const requestUrl = "/admin/stack/vmmanage/list/" + timing;

        const res = await axios.get(requestUrl);
        console.log(res);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getSuAdminInstanceList = async (timing: string) => {
    try {
        const requestUrl = "/su-admin/stack/vmmanage/list/" + timing;

        const res = await axios.get(requestUrl);
        console.log(res);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getAllInstanceList = async ({page, timing, category, keyword}: listPagingParam) => {
    try {
        const requestUrl = "/admin/stack/vmmanage/list/ALL";

        const res = await axios.get(requestUrl, {
            params: {
                page: page,
                displayCnt: 10,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const getInstanceListPaging = async ({page, timing, category, keyword}: listPagingParam) => {
    try {
        const requestUrl = "/admin/stack/vmmanage/list/" + timing;

        const res = await axios.get(requestUrl, {
            params: {
                page: page,
                displayCnt: 10,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
}

export const getSuAdminInstanceListPaging = async ({page, timing, category, keyword}: listPagingParam) => {
    try {
        const requestUrl = "/su-admin/stack/vmmanage/list/" + timing;

        const res = await axios.get(requestUrl, {
            params: {
                page: page,
                displayCnt: 10,
                category: (category) ?? "",
                keyword: (keyword) ?? ""
            }
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
}

export const createIndividualVmInstance = async (nseq: number) => {
    try {
        const requestUrl = "/admin/stack/instance/create/per";

        const res = await axios.post(requestUrl, {
            nseq: nseq
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
}

export const createVmInstance = async (data: VmCreateRequest) => {
    try {
        const requestUrl = "/admin/stack/instance/create";
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const sendActionToVm = async (data: VmActionRequest) => {
    try {
        const requestUrl = "/admin/stack/instance/action";
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getConsoleUrl = async (nseq: number) => {
    try {
        const requestUrl = "/stack/console";
        const res = await axios.get(requestUrl, {
            params: {
                nseq: nseq
            }
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getJupyterInfo = async (nseq: number) => {
    try {
        const requestUrl = "/stack/get/jupyter-info";
        const res = await axios.get(requestUrl, {
            params: {
                nseq: nseq
            }
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const deleteInstance = async (id: string) => {
    try {
        const requestUrl = "/admin/stack/instance/remove/" + id;
        const res = await axios.get(requestUrl);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getBatchDeleteList = async () => {
    try {
        const requestUrl = "/admin/stack/instance/remove/list";
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

//export const getSubjectState = async (sseq: number) => {
export const getSubjectState = async (subjectType: string) => {
    try {
        const requestUrl = "/lab/book/get/subject-state";
        const res = await axios.get(requestUrl, {
            params: {
                usg_type: subjectType
            }
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};
