import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useLoginUser } from 'hooks/useLoginUser';

import SidebarContent from './sidebarContent';
import Navigation from './navigation';
import AllMenu from './allMenu';
import DropDownNav from './dropDownNav';

function Header() {
    const { userId } = useLoginUser();
    const [showMenu, setShowMenu] = useState(false);
    const [showMypage, setShowMypage] = useState(false);

    useEffect(() => {
        if (userId === "") {
            setShowMypage(false);
        }
        else {
            setShowMypage(true);
        }
    }, [userId]);

    const onClickAllMenuBtn = () => {
        //setNavState(state => !state);
        setShowMenu((prev) => (!prev));
    };

    const onClickNavMenu = () => {
        //$('.all_menu_wrap').fadeOut();
        setShowMenu(false);
    };

    const handleShowMenu = () => {
        setShowMenu(true);
    };

    const handleHideMenu = () => {
        setShowMenu(false);
    };

    return (
        <header id="header_wrap">
            <div class="header_top">
                <div class="layout_inner">
                    <h1>
                        <Link to="/">경남 빅데이터 공유플랫폼</Link>
                    </h1>
                    <SidebarContent />
                </div>
            </div>
            {/*<Navigation onShowMenu={handleShowMenu} onHideMenu={handleHideMenu} onClickAllMenuBtn={onClickAllMenuBtn} onClickNavMenu={onClickNavMenu} allMenu={showMenu} />*/}
            {/*<AllMenu onClickNavMenu={onClickNavMenu} showMenu={showMenu} />*/}
            <DropDownNav onClickNavMenu={onClickNavMenu} showMenu={showMenu} onShowMenu={handleShowMenu} onHideMenu={handleHideMenu} showMypage={showMypage} />
        </header>
    );
}

export default Header;