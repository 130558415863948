import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import styled from 'styled-components';
import { swalConfirm, swal } from "utils/swal-utils";
import FormControl from '@material-ui/core/FormControl';
import { CTextField } from 'components/textfields';
import LectureTimeInput, { lectureDay } from './LectureTimeInput';
import { useLecture } from 'hooks/useLecture';
import { UsageType, LectureCreateInput, registerSubject } from 'api/lecture';
import { SimpleTemplate } from 'api/template';

type LectureCreateDialogProps = {
    open: boolean;
    onClose: () => void;
};

function LectureCreateDialog({
    open,
    onClose
}: LectureCreateDialogProps) {
    //const {open} = props;
    const { adminInfo, usageList, templateList } = useLecture();

    const [daySeq, setDaySeq] = useState<number>(0);
    const [dayList, setDayList] = useState<lectureDay[]>([]);
    const [createInput, setCreateInput] = useState<LectureCreateInput>({
        sbj_nm: "",
        start_day: "",
        end_day: "",
        tseq: -1,
        sbj_seq: -1,
        capacity: -1,
        usg_type: "",
        subject_days: [],
    });

    useEffect(() => {
        setDayList([]);
        setCreateInput({
            sbj_nm: "",
            start_day: "",
            end_day: "",
            tseq: -1,
            sbj_seq: -1,
            capacity: -1,
            usg_type: "",
            subject_days: [],
        });
    }, [open]);

    const onChangeInput = (event: any) => {
        const key = event.target.name;
        const value = event.target.value;

        if (key === "start_day" || key === "end_day") {
            setCreateInput((prev: LectureCreateInput) => ({
                ...prev,
                [key]: value.replaceAll("-", ""),
            }))
        }
        else {
            setCreateInput((prev: LectureCreateInput) => ({
                ...prev,
                [key]: value,
            }))
        }
    };

    const handleClose = () => {
        onClose && onClose();
    };

    const combineInputs = (): LectureCreateInput => {
        let combined: LectureCreateInput = {...createInput};
        const lectureDayList = dayList.map((day: lectureDay, index: number) => {
            const tmpDayList = day.day;
            return tmpDayList;
        })
        console.log(lectureDayList);

        combined.subject_days = lectureDayList;
        return combined;
    };

    const inputValidation = (input: any): boolean => {
        for (const key in input) {
            console.log(key + ": " + input[key]);
            if (key === 'sbj_nm' || key === 'start_day' || key === 'end_day' || key ==='usg_type') {
                if (!input[key]) {
                    return false;
                }
            }
            else if (key === 'subject_days') {
                if (!input[key].length) {
                    return false;
                }
            }
            else {
                if (!input[key]) {
                    return false;
                }
            }
        }
        console.log("true");
        return true;
    };

    const handleCreateAlert = () => {
        const data: LectureCreateInput = combineInputs();
        if (!inputValidation(data)) {
            return swal("강의 정보를 입력해주세요.");
        }
        swalConfirm("등록하시겠습니까?").then(function(res) {
            if (res.isConfirmed) {
                registerSubject(data).then(function(response) {
                    swal(response.message).then(function(res) {
                        handleClose();
                    });
                });
            }
        });
    }

    const handleDayAdd = () => {
        setDayList((prev) => ([...prev, {index: daySeq + 1, day: {week: "", start_time: "", end_time: ""}}]));
        setDaySeq((prev: number) => (prev + 1));
    };

    const handleDayDelete = (index: number) => {
        const newDayList = dayList.filter((day) => (day.index !== index));
        setDayList(newDayList);
    };

    const handleDayUpdate = (newDay: lectureDay) => {
        const objIndex: number | null = dayList.findIndex((day: lectureDay, index: number) => {
            if (day.index === newDay.index)
                return true;
            else 
                return false;
        });

        console.log();
        if (objIndex  !== null) {
            setDayList((prev) => {
                let prevList = [...prev];
                const removed = prevList.splice(objIndex, 1, newDay);

                return prevList;
            })
        }
    };

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="sm"
            title={`강의 등록`}
            onClose={handleClose}
            onCustom={handleCreateAlert}
            modules={['custom']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>학교</th>
                    <td><span className="td-txt">{adminInfo?.col_nm}</span></td>
                </tr>
                <tr>
                    <th>학과</th>
                    <td><span className="td-txt">{adminInfo?.dep_nm}</span></td>
                </tr>
                <tr>
                    <th>담당자</th>
                    <td><span className="td-txt">{adminInfo && adminInfo.name}</span></td>
                </tr>
                <tr>
                    <th>강의용도</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select name="usg_type" onChange={onChangeInput}>
                                <option value="">선택</option>
                                {
                                    usageList && usageList.map((usage: UsageType) => {
                                        return <option value={usage.usg_type_code} key={usage.usg_type_code}>{usage.usg_type_str}</option>
                                    })
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>강의명</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <CTextField name="sbj_nm" onChange={onChangeInput} />
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>환경선택</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select name="tseq" onChange={onChangeInput}>
                                <option value="">선택</option>
                                {
                                    templateList && templateList.map((template: SimpleTemplate) => {
                                        return <option value={template.tseq} key={template.tseq}>{template.tp_nm}</option>
                                    })
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>강의기간</th>
                    <td>
                        <div className="inputWrap_dividers">
                            <div className="inputArea">
                                <FormControl className="">
                                    <CTextField type="date" name="start_day" onChange={onChangeInput} />
                                </FormControl>
                            </div>
                            <span className="mdd">~</span>
                            <div className="inputArea">
                                <FormControl className="">
                                    <CTextField type="date" name="end_day" onChange={onChangeInput} />
                                </FormControl>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>강의시간</th>
                    <td>
                        <LectureTimeInput dayList={dayList} onAddDay={handleDayAdd} onDeleteDay={handleDayDelete} onUpdateDay={handleDayUpdate} />
                    </td>
                </tr>
                <tr>
                    <th>강의차수</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <CTextField name="sbj_seq" type="number" onChange={onChangeInput} /*value={createInput.sbj_seq}*/ />
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>개설인원</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <CTextField name="capacity" type="number" onChange={onChangeInput} /*value={createInput.capacity}*/ />
                        </FormControl>
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default LectureCreateDialog;
