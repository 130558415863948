import React, { useState, useEffect } from 'react';
import { useAdmin } from 'hooks/useAdmin';
import { formatDate } from 'utils/dateUtils';

import { CSelectButton } from "components/buttons";
import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import AuthorityDialog from "./AuthorityDialog";
import { practicerStateClass } from 'utils/state';

function AuthorityDetail() {
    const { selectedAdmin, callUpdateAdminStatus } = useAdmin();
    const [open, setOpen] = useState<boolean>(false);
    const [stateBarClass, setStateBarClass] = useState<string>('stateBar ');
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [userState, setUserState] = useState<number>(-1);

    useEffect(() => {
        if (selectedAdmin === null) {
            return;
        }
        switch(selectedAdmin.stat_str) {
            case ("승인 대기"):
                return setStateBarClass('stateBar ' + practicerStateClass.APPROVE_REQUEST);
            case ("사용 중"):
                return setStateBarClass('stateBar ' + practicerStateClass.APPROVED);
            case ("승인 거절"):
                return setStateBarClass('stateBar ' + practicerStateClass.DENIED);
            case ("정지"):
                return setStateBarClass('stateBar ' + practicerStateClass.STOP);
            case ("탈퇴 요청"):
                return setStateBarClass('stateBar ' + practicerStateClass.WITHDRAW_REQUEST);
            case ("탈퇴"):
                return setStateBarClass('stateBar ' + practicerStateClass.WITHDRAW);
            default: 
                return;
        }
    }, [selectedAdmin]);

    if (selectedAdmin === null) {
        return (
            <PanelBox></PanelBox>
        );
    }

    const actionList = [
        {
            name: '승인',
            onClick: () => {
                setDialogTitle('승인');
                setUserState(1);
                handleOpen();
            },
        },
        {
            name: '승인거부',
            onClick: () => {
                setDialogTitle('승인거부');
                setUserState(2);
                handleOpen();
            },
        },
        {
            name: '실습정지',
            onClick: () => {
                setDialogTitle('실습정지');
                setUserState(3);
                handleOpen();
            },
        },
        {
            name: '탈퇴',
            onClick: () => {
                setDialogTitle('탈퇴');
                setUserState(5);
                handleOpen();
            },
        },
    ]

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleUserStateUpdate = (text: string = "") => {
        callUpdateAdminStatus(selectedAdmin.id, userState, text);
    };


    return (
        <PanelBox className="panel_detail">
            <div className="panelTitBar">
                <div className="tit"><strong>{selectedAdmin.id}</strong>의 상세보기</div>
            </div>
            <CommActionBar>
                <CSelectButton
                    items={actionList}
                >
                    액션
                </CSelectButton>
                <AuthorityDialog
                    open={open}
                    onClose={handleClose}
                    title={dialogTitle}
                    onConfirm={handleUserStateUpdate}
                />
            </CommActionBar>
            <div className="panelCont">
                <div className={stateBarClass}>
                    <span className="state_ico">{selectedAdmin.stat_str}</span>
                </div>
                <table className="tb_data">
                    <tbody>
                    <tr>
                        <th>id</th>
                        <td>{selectedAdmin.id}</td>
                        <th>이름</th>
                        <td>{selectedAdmin.name}</td>
                    </tr>
                    <tr>
                        <th>권한</th>
                        <td>{selectedAdmin.role_str}</td>
                    </tr>
                    <tr>
                        <th>학교</th>
                        <td>{selectedAdmin.col_nm}</td>
                        <th>학과</th>
                        <td>{selectedAdmin.dep_nm}</td>
                    </tr>
                    <tr>
                        <th>이메일</th>
                        <td>{selectedAdmin.email}</td>
                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td colSpan={3}>{selectedAdmin.mobile_no}</td>
                    </tr>
                    {/*<tr>
                        <th>사유</th>
                        <td colSpan={3}>
                            <div>기타사유</div>
                            <div className="added"></div>
                        </td>
                    </tr>*/}
                    </tbody>
                </table>
            </div>
        </PanelBox>
    );
}

export default AuthorityDetail;