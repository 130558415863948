import React, { useEffect, useState } from 'react';
import { useLocation, RouteComponentProps, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { useCommunity } from 'hooks/useCommunity';
import { noticeContent } from 'api/community';
import { dayInKorean, formatDateTime, toStringDate, spliteDate, spliteDateTimeSeconds } from 'utils/dateUtils';

function NoticeView( {location, match}:RouteComponentProps) {
    const history = useHistory();
    const { search } = useLocation();
    const { no } = queryString.parse(search);
    const { noticeContentResponse, callGetNoticeContent} = useCommunity();
    const [ content, setContent ] = useState<noticeContent|null>(null);
    const [ fileNm, setFileNm ] = useState('');

    const cseq:number = Number(no);

    useEffect(() => {
        callGetNoticeContent(cseq);
    }, []);
    
    useEffect(() => {
        if (!noticeContentResponse.loading && !noticeContentResponse.error && noticeContentResponse.data){
            const data:noticeContent = noticeContentResponse.data.data;
            let tmpData = {...data}
            let regDate = spliteDateTimeSeconds(data.reg_date);
            tmpData.reg_date = regDate;
            setContent(tmpData)
            setFileNm(data.file_nm);
        }
    }, [noticeContentResponse, setContent]);


    const goBack = () => {
        history.push("/Portal/community/notice");
    }


    return (
        <React.Fragment>
            <div className="layout_inner">
                <div className="board_wrap">
                <h3 className="board_tit">공지사항</h3>

                <table className="board_list board_view">
                    <tbody>
                        <tr>
                        <td className="td_subject">{content?.title}</td>
                        <td className="td_date" style={{width:'200px'}}>{content?.reg_date}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="view_contents">
                    <img src="/lib/image/board/board_test.png" alt=""/>

                    <pre style={{fontFamily:'Noto,  sans-serif', fontSize:'14px'}}>{content?.cont}</pre>


                </div>

                <div className="file_wrap">
                    <p className="file_tit">첨부파일</p>
                    {
                        (fileNm !== null)
                        ?
                        <>                    
                            <a href={`${match.url}/notice/download-file?cseq=${cseq}`}>
                            <img src="/lib/image/common/ico_file.png" alt=""/>
                            <p>{content?.file_nm}</p>
                            <img src="/lib/image/common/ico_download.png" alt=""/>
                            </a>
                        </>
                        :<></>
                    }
                </div>

                <div className="board_btn_wrap">
                    <button type="button" onClick={goBack}>목록으로</button>
                </div>
                </div>

                </div>
        </React.Fragment>
    );
}

export default NoticeView;