import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useApproveAuth } from 'hooks/useApproveAuth';

import Loading from 'components/spinner/Loading';

import HeaderImg from 'images/portal/main/main_visual.png';
import HeaderTextImg from 'images/portal/main/main_visual_txt.png';
import CheckIconImg from 'images/portal/mypage/check_ico_finish.png';
import AdminImg from 'images/portal/mypage/join_ico_admin.png';
import TraineeImg from 'images/portal/mypage/join_ico_trainee.png';

const ContentArea = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f6f8fa;
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ContentWrap = styled.div`
    width: 900px;
    height: 100%;
    border-radius: 10px;
    background-color: #ffffff;

    header {
        background-image: url(${HeaderImg});
        background-size: cover;
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 300px;
        margin-bottom: 15px;
        #email-visual-text {
            width: 400px;
            position: relative;
            margin: 30px;
        }
    }

    .email_join_finish {
        padding: 80px 100px 80px 315px;
        border-top: 1px solid #3a4147;
        background: url(${CheckIconImg}) no-repeat 100px center;
    }
`;

type MailProps = {
    info: string;
};

function EmailApprove({ match }: RouteComponentProps<MailProps>) {
    const { info } = match.params;
    const data = queryString.parse(info);
    const { authApprovalResponse } = useApproveAuth(data);
    const [roleText, setRoleText] = useState<string>("");
    const [roleImgSrc, setRoleImgSrc] = useState<string>("");

    useEffect(() => {
        if (data.role_cd === 'CIVIL') {
            setRoleImgSrc(TraineeImg);
            setRoleText('실습자');
        }
        else {
            setRoleImgSrc(AdminImg);
            setRoleText('관리자');
        }
    }, []);

    console.log(data.role_cd);

    if (authApprovalResponse.loading && !authApprovalResponse.data) {
        return (
            <ContentArea>
                <ContentWrap>
                    <Loading />
                </ContentWrap>
            </ContentArea>
        );
    }
    if (authApprovalResponse.error) {
        return (
            <ContentArea>
                <ContentWrap>
                    <header>
                        <img src={HeaderTextImg} id="email-visual-text" alt="visual text" />
                    </header>
                    <div className="join_wrap">
                        <div className="join_tit">
                            <img src={roleImgSrc} alt="userImg" /> {roleText} 회원가입
                        </div>
                        <div className="join_box_wrap">
                            <div className="join_finish email_join_finish">
                                <h3>이메일 인증이 <span>실패</span>하였습니다.</h3>
                                <p>
                                    오류가 발생하였습니다. 다시 시도해 주세요.
                                </p>
                                <Link to="/Portal/main">
                                    <button className="btn_move">메인 페이지로 이동</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </ContentWrap>
            </ContentArea>
        );
    }
    if (!authApprovalResponse.data) {
        return null;
    }

    return (
        <ContentArea>
            <ContentWrap>
                <header>
                    <img src={HeaderTextImg} id="email-visual-text" alt="visual text" />
                </header>
                <div className="join_wrap">
                    <div className="join_tit">
                        <img src={AdminImg} alt="userImg" /> {roleText} 회원가입
                    </div>
                    <div className="join_box_wrap">
                        <div className="join_finish email_join_finish">
                            <h3>이메일 인증이 <span>완료</span>되었습니다.</h3>
                            <p>
                                관리자가 가입 승인을 하면 빅데이터 셰어 플랫폼을 이용하실 수 있습니다.
                                승인 완료시 등록하신 이메일 주소로 안내 메일이 발송됩니다.
                            </p>
                            <Link to="/Portal/main">
                                <button className="btn_move">메인 페이지로 이동</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </ContentWrap>
        </ContentArea>
    );
}

export default EmailApprove;