import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';

export default forwardRef((props, ref) => {
    //const [year, setYear] = useState('All');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [active, setActive] = useState();
 
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params) {
                console.log(params);
                const cellStartDate = new Date(params.data.start_day);
                const cellEndDate = new Date(params.data.end_day);

                console.log(cellStartDate);
                console.log(startDate);

                if ((cellStartDate >= new Date(startDate)) && (cellEndDate <= new Date(endDate))) {
                    //setActive(true);
                    return true;
                }
                else {
                    //setActive(false);
                    return false;
                }
            },
 
            isFilterActive() {
                //return year === '2010'
                //console.log("isFilterActive");
                return active;
            },
 
            // this example isn't using getModel() and setModel(),
            // so safe to just leave these empty. don't do this in your code!!!
            getModel() {
            },
 
            setModel() {
            }
        }
    });
 
    const onStartDateChange = event => {
        setStartDate(event.target.value)
    };

    const onEndDateChange = event => {
        setEndDate(event.target.value);
        setActive(true);
    };
 
    useEffect(() => {
        props.filterChangedCallback()
    }, [startDate, endDate]);
 
    return (
        <div style={{display: "inline-block", width: "400px"}}>
            <div style={{padding: "10px", backgroundColor: "#d3d3d3", textAlign: "center"}}></div>
            <label style={{margin: "10px", padding: "50px", display: "inline-block", backgroundColor: "#999999"}}>
                <input type="date" name="startDate" value={startDate} onChange={onStartDateChange} /> 시작일
            </label>
            <label style={{margin: "10px", padding: "50px", display: "inline-block", backgroundColor: "#999999"}}>
                <input type="date" name="endDate" value={endDate} onChange={onEndDateChange} /> 종료일
            </label>
        </div>
    )
 });