import React, { useState, useEffect } from 'react';
import { vmStateClass, vmStateName } from 'utils/state';
import { formatTime } from 'utils/dateUtils';
import { useEnvironment } from 'hooks/useEnvironment';
import { useLoginUser } from 'hooks/useLoginUser';

import { SelectableAgGrid } from 'components/datagrids';
import { asyncState } from 'utils/asyncUtils';

type EnvironmentPagingListProps = {
    listResponse: asyncState;
    fetchList: (page: number, catetory?: string, keyword?: string) => void;
};

function EnvironmentPagingList({
    listResponse,
    fetchList, 
}: EnvironmentPagingListProps) {
    const { callSetSelectedVm } = useEnvironment();
    const { userRole } = useLoginUser();
    const [pageCount, setPageCount] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchCategory, setSearchCategory] = useState<string>("");
    const [searchKeyword, setSearchKeyword] = useState<string>("");

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: '학과',
            field: 'dep_nm',
            filter: 'customSearchFilter',
            filterParams: {
                filterChangedCallback: (keyword: string) => {
                    setSearchCategory("dep_nm");
                    setSearchKeyword(keyword);
                },
            },
        },
        /*{
            headerName: '용도',
            field: 'sbj_nm',
            filter: true,
        },*/
        {
            headerName: '템플릿 이름',
            field: 'tp_nm',
            filter: 'customSearchFilter',
            filterParams: {
                filterChangedCallback: (keyword: string) => {
                    setSearchCategory("tp_nm");
                    setSearchKeyword(keyword);
                },
            },
        },
        {
            headerName: '시작 일자',
            field: 'start_time',
            filter: false,
            cellRenderer: function (params: any) {
                //const start = (params.data.start_time !== null) ? formatTime(params.data.start_time) : "";
                const start = (params.data.start_time !== null) ? params.data.start_time.split(' ')[0] : "";
                return start;
            }
        },
        /*{
            headerName: '종료 일자',
            field: 'end_time',
            filter: false,
            cellRenderer: function (params: any) {
                const end = (params.data.end_time !== null) ? formatTime(params.data.end_time) : "";
                return end;
            },
        },*/
        {
            headerName: '구동 시간',
            field: 'spend_time',
            filter: false,
            cellRenderer: function (params: any) {
                const end = (params.data.spend_time !== null) ? params.data.spend_time + "시간" : "-";
                return end;
            },
        },
        {
            headerName: '사용자 ID',
            field: 'id',
            filter: 'customSearchFilter',
            filterParams: {
                filterChangedCallback: (keyword: string) => {
                    setSearchCategory("user_id");
                    setSearchKeyword(keyword);
                },
            },
        },
        {
            headerName: '사용자 이름',
            field: 'name',
            filter: 'customSearchFilter',
            filterParams: {
                filterChangedCallback: (keyword: string) => {
                    setSearchCategory("user_name");
                    setSearchKeyword(keyword);
                },
            },
        },
        /*{
            headerName: '강의 상태',
            field: 'subject_stat',
        },*/
        {
            headerName: 'CPU / MEMORY / DISK',
            filter: false,
            cellRenderer: function (params: any) {
                const cpu = params.data.cpu ?? "";
                const mem = params.data.ram ?? "";
                const disk = params.data.disk ?? "";

                return cpu + " / " + mem + " / " + disk;
            }
        },
        {
            headerName: 'VM 상태',
            //field: 'vm_stat',
            filter: 'customSelectFilter',
            filterParams: {
                filterChangedCallback: (keyword: string) => {
                    if (keyword === "") {
                        setSearchCategory("");
                        setSearchKeyword("");
                    }
                    else {
                        setSearchCategory("vm_stat");
                        setSearchKeyword(keyword);
                    }
                },
                options: [{key: "전체", value: ""}, {key: "대기", value: 0}, {key: "할당됨", value: 1}, {key: "삭제됨", value: 6}],
            },
            cellRenderer: function (params: any) {
                switch (params.data.vm_stat) {
                    case ('0'):
                        return `<span class="state_ico ${vmStateClass.NOT_CREATED}">${vmStateName.NOT_CREATED}</span>`;
                    case ('1'):
                        return `<span class="state_ico ${vmStateClass.ALLOCATED}">${vmStateName.ALLOCATED}</span>`;
                    case ('2'):
                        return `<span class="state_ico ${vmStateClass.DEPLOYING}">${vmStateName.DEPLOYING}</span>`;
                    case ('3'):
                        return `<span class="state_ico ${vmStateClass.DEPLOYED}">${vmStateName.DEPLOYED}</span>`;
                    case ('4'):
                        return `<span class="state_ico ${vmStateClass.RUNNING}">${vmStateName.RUNNING}</span>`;
                    case ('5'):
                        return `<span class="state_ico ${vmStateClass.TERMINATED}">${vmStateName.TERMINATED}</span>`;
                    case ('6'):
                        return `<span class="state_ico ${vmStateClass.DELETED}">${vmStateName.DELETED}</span>`;
                    case ('7'):
                        return `<span class="state_ico ${vmStateClass.FORCE_REBOOTING}">${vmStateName.FORCE_REBOOTING}</span>`;
                    case ('8'):
                        return `<span class="state_ico ${vmStateClass.FORCE_TERMINATING}">${vmStateName.FORCE_TERMINATING}</span>`;
                    case ('9'):
                        return `<span class="state_ico ${vmStateClass.ETC}">${vmStateName.ETC}</span>`;
                    default: 
                        return `<span class="state_ico ${vmStateClass.ETC}">${vmStateName.ETC}</span>`;
                }
            }
        },
    ]);

    useEffect(() => {
        fetchList(pageCount, searchCategory, searchKeyword);
    }, [pageCount, searchCategory, searchKeyword]);

    useEffect(() => {
        const reloadList = setTimeout(() => fetchList(pageCount, searchCategory, searchKeyword), 5000);
        return () => clearTimeout(reloadList);
    }, [rowData]);

    useEffect(() => {
        if (!listResponse.loading && listResponse.data) {
            setRowData(listResponse.data.vmManageListDtos);
            setTotalCount(listResponse.data.paginationDto.rowCount);
            setColumnDefs((prev: any) => {
                const newColumn = [...prev];
                newColumn[0] = {
                    ...newColumn[0],
                    valueGetter: (params: any) => {
                        return (params.node.rowIndex + 1) + ((pageCount - 1) * 10);
                    } 
                };
                return newColumn;
            })
        }
        else if (!listResponse.loading && listResponse.error) {
            setRowData([]);
            setTotalCount(0);
        }
    }, [listResponse]);

    const handleGetNextPage = () => {
        setPageCount((prev: number) => {
            const count: number = Math.floor(totalCount / 10) + (((totalCount % 10) > 0) ? 1 : 0);
            if (prev < count) {
                return (prev + 1);
            }
            else {
                return prev;
            }
        });
    };

    const handleGetPrevPage = () => {
        setPageCount((prev: number) => {
            if (prev > 1) {
                return (prev - 1);
            }
            else {
                return prev;
            }
        });
    };

    const handleRowSelect = (instance: any) => {
        callSetSelectedVm(instance[0]);
    };

    return (
        <div className="grid-height_10">
            <SelectableAgGrid
                rowData={rowData}
                columnDefs={columnDefs}
                onRowSelected={handleRowSelect}
                customPagination={true}
                itemPerPage={10}
                totalCount={totalCount}
                getNextPage={handleGetNextPage}
                getPrevPage={handleGetPrevPage}
            />
        </div>
    );
}

export default EnvironmentPagingList;