import React, { useState, useEffect } from 'react';
import Layout from "layout";
import { Title } from 'pages';
import { toast } from 'react-toastify';

import { CTabs, CTab, CTabPanel } from 'components/tabs';
import AuthorityTab from './AuthorityTab';
import CustomToast from 'components/toast/CustomToast';
import StateTab from './StateTab';

function Admin() {
    const currentPage = Title.Admin;
    const [tabvalue, setTabvalue] = useState(0);

    const handleTabChange = (event: any, newValue: number) => {
        setTabvalue(newValue);
    };

    const showToast = () => {
        const alarm = {
            id: "string",
            useq: 1,
            rseq: 2,
            msg: 'error',
            grade: 'string',
            class: 'string',
            detail: 'any'
        }
        toast.error(<CustomToast alarm={alarm} />);
        toast.info("info");
        toast.warning("warning");
        toast.success("success");
    }

    return (
        <Layout currentPage={currentPage}>
            <CTabs
                type="tab1"
                value={tabvalue}
                onChange={handleTabChange}
            >
                <CTab label="관리자 권한 관리" />
                <CTab label="관리자 상세 이용 현황" />
            </CTabs>
            <CTabPanel
                value={tabvalue}
                index={0}
            >
                <AuthorityTab />
            </CTabPanel>
            <CTabPanel
                value={tabvalue}
                index={1}
            >
                <StateTab />
            </CTabPanel>
        </Layout>
    );
}

export default Admin;