import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Location from '../components/pageLocation/location';
import FindId from '../pages/FindId/findId';
import AdminFindId from '../pages/FindId/adminFindId';
import CheckId from '../pages/FindId/checkId';
import EmailId from '../pages/FindId/emailId';

function FindIdRouter({ match, history }) {
    const [checkIdValue, setCheckIdValue] = useState("");
    const [checkNameValue, setCheckNameValue] = useState("");
    const [checkEmailValue, setCheckEmailValue] = useState("");

    const getCheckIdValue = (id, name, email) => {
        setCheckIdValue(id);
        setCheckNameValue(name);
        setCheckEmailValue(email);
    };
    
    return(
        <React.Fragment>
            <Location location="아이디 찾기" />
            <section id="contetns">
                <div className="layout_inner">
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <FindId match={match} history={history} onCheckId={getCheckIdValue} />} />
                        <Route exact path={`${match.url}/admin`} render={() => <AdminFindId match={match} history={history} />} />
                        <Route exact path={`${match.url}/check_id`} render={() => <CheckId match={match} history={history} id={checkIdValue} name={checkNameValue} email={checkEmailValue} />} />
                        <Route exact path={`${match.url}/send_email`} render={() => <EmailId match={match} history={history} />} />
                    </Switch>
                </div>
            </section>
        </React.Fragment>
    );
}

export default FindIdRouter;