import React, { useState, useEffect } from 'react';
import { Route, Switch, Link} from 'react-router-dom';
import $ from 'jquery';

import Location from '../components/pageLocation/location';
import NoticeList from '../pages/Community/noticeList';
import NoticeView from '../pages/Community/noticeView';
import FAQList from '../pages/Community/faqList';
import FAQview from '../pages/Community/faqView';

function CommunityRouter({ match }) {
    const [locationText, setLocationText] = useState("공지사항");

    // use jquery functions
    useEffect(() => {
        $('.js_tab > *').on('click',function(){
            $(this).addClass('on');
            $(this).siblings().removeClass('on');
        });
    }, []);

    const onClickTab = (text) => {
        setLocationText(text);
    };

    return (
        <React.Fragment>
            <Location location={locationText} rootLocation="커뮤니티" />
            <section id="contetns">
                <div className="layout_inner">
                    {/* <div className="top_tab js_tab">
                        <Link to={`${match.url}/notice`} className="on" onClick={() => onClickTab("공지사항")}><span>공지사항</span></Link>
                        <Link to={`${match.url}/FAQ`} onClick={() => onClickTab("FAQ")}><span>FAQ</span></Link>
                    </div> */}
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <NoticeList match={match} />} />
                        <Route exact path={`${match.url}/notice`} render={() => <NoticeList match={match} />} />
                        <Route exact path={`${match.url}/notice_view`} render={() => <NoticeView match={match}/>} />
                        <Route path={`${match.url}/FAQ`} render={() => <FAQList match={match} />} />
                        <Route path={`${match.url}/FAQ_view`} render={() => <FAQview match={match} />} />
                    </Switch>
                </div>
            </section>
        </React.Fragment>

    );
}

export default CommunityRouter;