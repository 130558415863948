import { combineReducers } from 'redux';
import { all } from '@redux-saga/core/effects';
import loginUserRecuder, { loginUserSaga } from './loginUserSlice';
import templateReducer, { templateSaga } from './templateSlice';
import labReducer, { labSaga } from './labSlice';
import lectureReducer, { lectureSaga } from './lectureSlice';
import reservationReducer, { reservationSaga } from './reservationSlice';
import communityReducer, { communitySaga } from './communitySlice';
import environmentReducer, { environmentSaga } from './environmentSlice';
import noticeReducer, { noticeSaga } from './noticeSlice';
import practicerReducer, { practicerSaga } from './practicerSlice';
import dashboardReducer, { dashboardSaga } from './dashboardSlice';
import alarmReducer, { alarmSaga } from './alarmSlice';
import mypageReducer, { mypageSaga } from './mypageSlice';
import adminReducer, { adminSaga } from './adminSlice';
import mailReducer, { mailSaga } from './mailSlice';
import portalDatasetReducer, { portalDatasetSaga } from './portalDatasetSlice';
import datasetReducer, { datasetSaga } from './datasetSlice';

const appReducer = combineReducers({
    loginUser: loginUserRecuder,
    template: templateReducer,
    lab: labReducer,
    lecture: lectureReducer,
    reservation: reservationReducer,
    community : communityReducer,
    environment: environmentReducer,
    notice : noticeReducer,
    priacticer: practicerReducer,
    dashboard : dashboardReducer,
    alarm: alarmReducer,
    mypage: mypageReducer,
    admin: adminReducer,
    mail: mailReducer,
    portalDataset: portalDatasetReducer,
    dataset: datasetReducer,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'loginUser/userLogout') {
        state = undefined;
    }

    return appReducer(state, action);
}

/* 프로젝트에서 만든 여러 개의 Saga를 모두 합친다. */
export function* rootSaga() {
    yield all([loginUserSaga(), dashboardSaga(), templateSaga(), lectureSaga(), labSaga(), reservationSaga(), communitySaga(), environmentSaga(), noticeSaga(), practicerSaga(), alarmSaga(), mypageSaga(), adminSaga(), mailSaga(), portalDatasetSaga(), datasetSaga()]); // all은 배열 안의 여러 saga를 동시에 실행시켜줍니다.
}

export default rootReducer;

// 루트 리듀서의 반환값을 유추해줍니다.
export type RootState = ReturnType<typeof rootReducer>;