import React, { useState, useEffect } from 'react';
import { usePracticer } from 'hooks/usePracticer';
import { useAdmin } from 'hooks/useAdmin';

import { PanelBox } from "components/styles/PanelBox";
import CommActionBar from 'components/common/CommActionBar';
import { SelectableAgGrid } from 'components/datagrids'
import { agDateColumnFilter } from "utils/common-utils";
import StateLogDialog from './StateLogDialog';
import { ActiveLog } from 'api/practicer';

function StateTab () {
    const { callGetUserActiveLogDetail } = usePracticer();
    const { practicerActiveResponse, callGetPracticerActiveLogList } = useAdmin();
    const [update, setUpdate] = useState(false);
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        callGetPracticerActiveLogList(pageCount);
    }, [update, pageCount]);

    useEffect(() => {
        if (!practicerActiveResponse.loading && practicerActiveResponse.data) {
            setRowData(practicerActiveResponse.data.logList);
            setTotalCount(practicerActiveResponse.data.paginationDto.rowCount);
            setColumnDefs((prev: any) => {
                const newColumn = [...prev];
                newColumn[0] = {
                    ...newColumn[0],
                    valueGetter: (params: any) => {
                        return (params.node.rowIndex + 1) + ((pageCount - 1) * 10);
                    } 
                };
                return newColumn;
            })
        }
    }, [practicerActiveResponse]);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'NO',
            filter: false,
            minWidth: 80,
            maxWidth: 80,
            valueGetter: (params: any) => (params.node.rowIndex + 1)
        },
        {
            headerName: 'ID',
            field: 'id',
            filter: true,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            headerName: '접속 IP',
            field: 'access_ip',
            filter: true,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            headerName: 'VM 명',
            field: 'instance_nm',
            filter: true,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            headerName: 'LOG 정보',
            field: 'activity_title',
            filter: true,
        },
        {
            headerName: '작업일시',
            field: 'reg_date',
            filter: false,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            headerName: '권한',
            field: 'role_str',
            filter: true,
            minWidth: 150,
            maxWidth: 200,
        },
    ]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleRowSelect = (log: ActiveLog[]) => {
        const logSeq: number = log[0].aseq;
        callGetUserActiveLogDetail(logSeq);
        handleOpen();
    };

    const handleGetNextPage = () => {
        setPageCount((prev: number) => {
            const count: number = Math.floor(totalCount / 20) + (((totalCount % 20) > 0) ? 1 : 0);
            if (prev < count) {
                return (prev + 1);
            }
            else {
                return prev;
            }
        });
    };

    const handleGetPrevPage = () => {
        setPageCount((prev: number) => {
            if (prev > 1) {
                return (prev - 1);
            }
            else {
                return prev;
            }
        });
    };

    return (
        <PanelBox>
            <CommActionBar isSearch={true}/>
            <div className="grid-height_20">
                <SelectableAgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onRowSelected={handleRowSelect}
                    customPagination={true}
                    itemPerPage={20}
                    totalCount={totalCount}
                    getNextPage={handleGetNextPage}
                    getPrevPage={handleGetPrevPage}
                />
            </div>
            <StateLogDialog
                open={open}
                onClose={handleClose}
            />
        </PanelBox>
    );
}
export default StateTab;
