import React from 'react';
import { CDialog } from "components/dialogs";
import { swalConfirm } from "utils/swal-utils";

const RegistrationDialog = (props) => {
    const {open} = props;
    const handleClose = () => {
        props.onClose && props.onClose();
    };
    const handleDeleteAlert = () => {
        swalConfirm("삭제하시겠습니까?");
    }
    const handleUpdate = () => {

    }

    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="md"
            title={`상세보기`}
            onClose={handleClose}
            modules={['confirm']}
            onDelete={handleDeleteAlert}
            onUpdate={handleUpdate}
            topBtn={true}
            topModules={['update','delete']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>제목</th>
                    <td colSpan={3}>
                        <span className="td-txt">공모전 제목</span>
                    </td>
                </tr>
                <tr>
                    <th>주최</th>
                    <td>
                        <span className="td-txt">주최기관</span>
                    </td>
                    <th>주관</th>
                    <td>
                        <span className="td-txt">주관기관</span>
                    </td>
                </tr>
                <tr>
                    <th>응모분야</th>
                    <td>
                        <span className="td-txt">응모분야</span>
                    </td>
                    <th>참가자격</th>
                    <td>
                        <span className="td-txt">참가자격</span>
                    </td>
                </tr>
                <tr>
                    <th>시상종류</th>
                    <td colSpan={3}>
                        <span className="td-txt">상금 / 상품</span>
                    </td>
                </tr>
                <tr>
                    <th>접수기간</th>
                    <td colSpan={3}>
                        <span className="td-txt">2021.04.01 ~ 2021.04.30</span>
                    </td>
                </tr>
                <tr>
                    <th>포스터 이미지</th>
                    <td>
                        <span className="td-txt">공모전 포스터.jpg</span>
                    </td>
                    <th>배너 이미지</th>
                    <td>
                        <span className="td-txt">공모전 포스터.jpg</span>
                    </td>
                </tr>
                <tr>
                    <th>첨부파일</th>
                    <td colSpan={3}>
                        <span className="td-txt"><button type="button" className="link_file">첨부파일.pdf</button></span>
                    </td>
                </tr>
                <tr>
                    <th>상세내용</th>
                    <td colSpan={3}>
                        <span className="td-txt">
                            공모전 상세 내용<br/>
                            공모전 상세 내용 공모전 상세 내용<br/>
                            공모전 상세 내용<br/>
                            공모전 상세 내용<br/>
                        </span>
                    </td>
                </tr>

                </tbody>
            </table>
        </CDialog>
    );
}
export default RegistrationDialog;
