import React, { useState, useEffect } from 'react';
import { CDialog } from "components/dialogs";
import styled from 'styled-components';
import { swalConfirm, swal } from "utils/swal-utils";
import { useLecture } from 'hooks/useLecture';
import { LectureModifyInput, LectureDayCreateInput, LectureDayModifyInput } from 'api/lecture';
import { SimpleTemplate } from 'api/template';
import { formatDate, formatTime } from 'utils/dateUtils';

import LectureTimeInput, { lectureDay } from './LectureTimeInput';
import FormControl from '@material-ui/core/FormControl';
import { CTextField } from 'components/textfields';

type LectureEditDialogProps = {
    open: boolean;
    onClose: () => void;
};

function LectureEditDialog({
    open,
    onClose
}: LectureEditDialogProps) {
    //const {open} = props;
    const { templateList, detailResponse, modifyResponse, callModifyLecture, callSetSelectedLecture } = useLecture();

    const [daySeq, setDaySeq] = useState<number>(0);
    const [dayList, setDayList] = useState<lectureDay[]>([]);
    const [modifyInput, setModifyInput] = useState<LectureModifyInput>({
        sbj_nm: "",
        sseq: -1,
        start_day: "",
        end_day: "",
        tseq: -1,
        sbj_seq: -1,
        capacity: -1,
        subject_days: [],
    });

    useEffect(() => {
        if (!detailResponse.loading && detailResponse.data && !detailResponse.error) {
            setModifyInput((prev: any) => {
                return ({
                    sbj_nm: detailResponse.data.sbj_nm,
                    sseq: detailResponse.data.sseq,
                    start_day: detailResponse.data.start_day,
                    end_day: detailResponse.data.end_day,
                    tseq: detailResponse.data.tseq,
                    sbj_seq: detailResponse.data.sbj_seq,
                    capacity: detailResponse.data.capacity,
                    subject_days: detailResponse.data.subject_days,
                });
            });

            const tmpDayList: lectureDay[] = detailResponse.data.subject_days.map((day: LectureDayCreateInput, index: number) => {
                const lectureDay = {
                    index: index,
                    day: {
                        week: day.week,
                        start_time: formatTime(day.start_time),
                        end_time: formatTime(day.end_time)
                    }
                };
                return lectureDay;
            });

            setDayList(tmpDayList);
            setDaySeq(tmpDayList.length);
        }
    }, [detailResponse]);

    useEffect(() => {
        if (!modifyResponse.loading && modifyResponse.error) {
            swal(modifyResponse.error.message);
            handleClose();
        }
        
        if (!modifyResponse.loading && modifyResponse.data) {
            swal(modifyResponse.data.message);
            handleClose();
        }
    }, [modifyResponse]);

    const handleClose = () => {
        onClose && onClose();
    };

    const combineInputs = (): LectureModifyInput => {
        const lectureDayList = dayList.map((day: lectureDay, index: number) => {
            const tmpDayList = {
                ...day.day,
                sseq: detailResponse.data.sseq
            };
            return tmpDayList;
        });
        let combined = {
            ...modifyInput,
            subject_days: lectureDayList
        };
        return combined;
    };

    const handleUpdateAlert = () => {
        const data: LectureModifyInput = combineInputs();
        swalConfirm("수정하시겠습니까??").then(function(res) {
            if (res.isConfirmed) {
                /*modifySubject(data).then(function(response) {
                    swal(response.message).then(function(res) {
                        handleClose();
                    });
                });*/
                callModifyLecture(data);
                //callSetSelectedLecture(null);
            }
        });
    };

    const onChangeInput = (event: any) => {
        const key = event.target.name;
        const value = event.target.value;

        if (key === "start_day" || key === "end_day") {
            setModifyInput((prev: any) => ({
                ...prev,
                [key]: value.replaceAll("-", ""),
            }))
        }
        else {
            setModifyInput((prev: any) => ({
                ...prev,
                [key]: value,
            }))
        }
    };

    const handleDayAdd = () => {
        setDayList((prev) => ([...prev, {index: daySeq + 1, day: {week: "", start_time: "", end_time: ""}}]));
        setDaySeq((prev: number) => (prev + 1));
    };

    const handleDayDelete = (index: number) => {
        const newDayList = dayList.filter((day) => (day.index !== index));
        setDayList(newDayList);
    };

    const handleDayUpdate = (newDay: lectureDay) => {
        const objIndex: number | null = dayList.findIndex((day: lectureDay, index: number) => {
            if (day.index === newDay.index)
                return true;
            else 
                return false;
        });

        console.log();
        if (objIndex  !== null) {
            setDayList((prev) => {
                let prevList = [...prev];
                const removed = prevList.splice(objIndex, 1, newDay);

                return prevList;
            })
        }
    };

    if (detailResponse.loading && detailResponse.data) {
        // 로딩중
    }
    if (detailResponse.error) {
        // 에러
    }
    if (!detailResponse.data) {
        return null;
    }

    return (
        <CDialog
            id="myEditDialog"
            open={open}
            maxWidth="sm"
            title={`강의 수정`}
            onClose={handleClose}
            onUpdate={handleUpdateAlert}
            modules={['update']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>강의명</th>
                    <td>
                        <FormControl className="">
                            <CTextField type="text" name="sbj_nm" value={detailResponse.data.sbj_nm} onChange={onChangeInput} />
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>환경선택</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select name="tseq" onChange={onChangeInput} value={modifyInput.tseq}>
                                <option value="">선택</option>
                                {
                                    templateList && templateList.map((template: SimpleTemplate) => {
                                        /*if (template.tseq === detailResponse.data.tseq) {
                                            return <option value={template.tseq} key={template.tseq} selected>{template.tp_nm}</option>
                                        }
                                        else {
                                            return <option value={template.tseq} key={template.tseq}>{template.tp_nm}</option>
                                        }*/
                                        return <option value={template.tseq} key={template.tseq}>{template.tp_nm}</option>
                                    })
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                <th>강의기간</th>
                    <td>
                        <div className="inputWrap_dividers">
                            <div className="inputArea">
                                <FormControl className="">
                                    <CTextField type="date" name="start_day" value={formatDate(detailResponse.data.start_day)} onChange={onChangeInput} />
                                </FormControl>
                            </div>
                            <span className="mdd">~</span>
                            <div className="inputArea">
                                <FormControl className="">
                                    <CTextField type="date" name="end_day" value={formatDate(detailResponse.data.end_day)} onChange={onChangeInput} />
                                </FormControl>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>강의시간</th>
                    <td>
                        <LectureTimeInput dayList={dayList} onAddDay={handleDayAdd} onDeleteDay={handleDayDelete} onUpdateDay={handleDayUpdate} />
                    </td>
                </tr>
                <tr>
                    <th>강의차수</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <CTextField name="sbj_seq" value={detailResponse.data.sbj_seq} onChange={onChangeInput} />
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>개설인원</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <CTextField name="capacity" value={detailResponse.data.capacity} onChange={onChangeInput} />
                        </FormControl>
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}
export default LectureEditDialog;
