import React from 'react';
import Layout from "layout";
import { Title } from 'pages';
import NoticeList from './NoticeList';
import NoticeDetails from './NoticeDetails';

function Notice()  {
    const currentPage = Title.Board;

    return (
        <Layout currentPage={currentPage}>
            <NoticeList />
            <NoticeDetails/>
        </Layout>
    );
}

export default Notice;

