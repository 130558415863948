import React, { useState, useEffect } from 'react';
import { Route, Switch, Link, useLocation } from 'react-router-dom';
import * as Fetch from '../utils/fetchFunction.js';
import $ from 'jquery';

import Location from '../components/pageLocation/location';
import UserInfo from '../pages/UserInfo/userInfo';
import ChangePassword from '../pages/ChangePassword/changePassword';
import Withdrawal from '../pages/Withdrawal/withdrawal';
import WithdrawalFinish from '../pages/Withdrawal/withdrawalFinish';
import ContestState from '../pages/Contest/contestState';

function MyPageRouter({ match, history }) {
    const location = useLocation();
    const [locationText, setLocationText] = useState("내 정보");
    const [userInfo, setUserInfo] = useState({
        id: "",
        role_cd: "",
        name: "",
        dep_nm: "",
        col_nm: "",
        class_no: "",
        email: "",
        birth_day: "",
        mobile_no: "",
        dept_cd: "",
        col_cd: ""
    });

    // use jquery functions
    useEffect(() => {
        $('.js_tab > *').on('click',function(){
            addClassEvent(this);
        });
    }, []);

    useEffect(() => {
        const path = location.pathname;
        // if(path === '/Portal/mypage/contest_state'){
        //     let val = $('.js_tab')[0].children[3]
        //     addClassEvent(val);
        //     onClickTab('공모전 신청현황');
        // }else 
        if(path === '/Portal/mypage/withdraw'){
            let val = $('.js_tab')[0].children[2]
            addClassEvent(val);
            onClickTab('회원탈퇴');
        }else if(path === '/Portal/mypage/change_password'){
            let val = $('.js_tab')[0].children[1]
            addClassEvent(val);
            onClickTab('비밀번호 변경');
        }else{
            let val = $('.js_tab')[0].children[0]
            addClassEvent(val);
            onClickTab('내 정보');
        }
      }, [location]);

    // get user information from server
    useEffect(() => {
        Fetch.callApi("/user/myinfo", {}, "POST")
        .then((res) => {
            console.log(res);
            if (res.success) {
                setUserInfo(res.data);
            }
            else {
                window.alert(res.message);
            }
        });
    }, []);

    const onClickTab = (text) => {
        setLocationText(text);
    };

    const addClassEvent = (val) => {
        $(val).addClass('on');
        $(val).siblings().removeClass('on');
    }

    const refreshUserInfo = () => {
        Fetch.callApi("/user/myinfo", {}, "POST")
        .then((res) => {
            console.log(res);
            if (res.success) {
                setUserInfo(res.data);
            }
            else {
                window.alert(res.message);
            }
        });
    }

    return (
        <React.Fragment>
            <Location location={locationText} rootLocation="마이페이지" />
            <section id="contetns">
                <div className="layout_inner">
                    <div class="top_tab js_tab">
                        <Link to={`${match.url}/user_info`} className="on" onClick={() => onClickTab("내 정보")}><span>내 정보</span></Link>
                        <Link to={`${match.url}/change_password`} onClick={() => onClickTab("비밀번호 변경")}><span>비밀번호 변경</span></Link>
                        <Link to={`${match.url}/withdraw`} onClick={() => onClickTab("회원탈퇴")}><span>회원탈퇴</span></Link>
                        {/* <Link to={`${match.url}/contest_state`} onClick={() => onClickTab("공모전 신청현황")}><span>공모전 신청현황</span></Link> */}
                    </div>
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <UserInfo match={match} user={userInfo} refreshUserInfo={refreshUserInfo} />} />
                        <Route path={`${match.url}/user_info`} render={() => <UserInfo match={match} user={userInfo} refreshUserInfo={refreshUserInfo} />} />
                        <Route path={`${match.url}/change_password`} render={() => <ChangePassword match={match} />} />
                        <Route exact path={`${match.url}/withdraw`} render={() => <Withdrawal match={match} history={history} />} />
                        <Route path={`${match.url}/withdraw/finish`} render={() => <WithdrawalFinish match={match} />} />
                        {/* <Route path={`${match.url}/contest_state`} render={() => <ContestState match={match} />} /> */}
                    </Switch>
                </div>
            </section>
        </React.Fragment>
    );
}

export default MyPageRouter;