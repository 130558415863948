import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useInputs } from './useInputs';
import { checkExtension } from 'api/dataset';
import { swalConfirm } from 'utils/swal-utils';
import { resetModifyResponse } from 'store/datasetSlice';

export function useDatasetEdit() {
    const { detailResponse, categoryResponse, fileExtensionResponse, modifyResponse } = useSelector((state: RootState) => (state.dataset));
    const dispatch = useDispatch();

    const { inputs, setInputs, onChangeInput, onChangeSelect, onChangeTextarea } = useInputs<any>({
        dsseq: -1,
        useq: -1,
        name: '',
        title: '',
        reg_date: '',
        update_date: '',
        content: '',
        file_nm: '',
        file_size: null,
        provider: '',
        fcseq: -1,
        category_cd: '',
        feseq: 0,
        extension_cd: '',
        file_download_cnt: -1,
        file: null
    });
    const [categoryList, setCategoryList] = useState([]);
    const [fileExtensionList, setFileExtensionList] = useState([]);
    const [fileUpdateYn, setFileUpdateYn] = useState<Boolean>(false);

    useEffect(() => {
        dispatch({
            type: 'dataset/getCategoryList'
        });
        dispatch({
            type: 'dataset/getFileExtensionList'
        });
    }, []);

    useEffect(() => {
        if (!categoryResponse.loading && categoryResponse.data) {
            setCategoryList(categoryResponse.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (!fileExtensionResponse.loading && fileExtensionResponse.data) {
            setFileExtensionList(fileExtensionResponse.data);
        }
    }, [fileExtensionResponse]);

    useEffect(() => {
        if (!detailResponse.loading && detailResponse.data) {
            setInputs({
                ...detailResponse.data,
                file: null
            });
        }
    }, [detailResponse]);

    const onDeleteOriginFile = () => {
        setInputs((prev: any) => ({
            ...prev,
            feseq: -1
        }));
    };

    const handleFileSelect = async (event: any) => {


        setInputs((prev: any) => ({
            ...prev,
            feseq: 0,
            file_nm: event.target.files[0].name
        }));

        onChangeInput(event);

        /*
        try {
            const res = await checkExtension(event.target.files[0].name);
            (res !== undefined) && setInputs((prev: any) => ({
                ...prev,
                feseq: res.feseq,
                file_nm: event.target.files[0].name
            }));

            onChangeInput(event);
        } catch (error: any) {
            setInputs((prev: any) => ({
                ...prev,
                feseq: 0,
                file: null,
                file_nm: '',
            }));
            swalConfirm('지원하지 않는 확장자 입니다.');
        }

        */
    };

    const handleDatasetEdit = (addNewFile: boolean, hasOriginFile: boolean) => {
        const formData = new FormData();

        if (addNewFile) {
            if (inputs.feseq === 0) {
                if (hasOriginFile) {
                    // 새로운 파일 선택 안함 (첨부파일 삭제)
                    console.log(">>> 1");
                    formData.append('dsseq', inputs.dsseq);
                    formData.append('title', inputs.title);
                    formData.append('content', inputs.content);
                    formData.append('provider', inputs.provider);
                    formData.append('fcseq', inputs.fcseq);
                    formData.append('feseq', '0');
                    formData.append('file_del', 'true');
                }
                else {
                    // 원래 첨부파일이 없었음. 내용만 변경
                    console.log(">>> 2");
                    formData.append('dsseq', inputs.dsseq);
                    formData.append('title', inputs.title);
                    formData.append('content', inputs.content);
                    formData.append('provider', inputs.provider);
                    formData.append('fcseq', inputs.fcseq);
                    formData.append('feseq', '0');
                    formData.append('file_del', 'false');
                }
            }
            else {
                // 새로운 파일 선택 완료
                console.log(">>> 3");
                formData.append('dsseq', inputs.dsseq);
                formData.append('file', inputs.file[0]);
                formData.append('title', inputs.title);
                formData.append('content', inputs.content);
                formData.append('provider', inputs.provider);
                formData.append('fcseq', inputs.fcseq);
                formData.append('feseq', inputs.feseq);
                formData.append('file_down_cnt', '0');
                formData.append('file_del', 'false');
            }
        }
        else {
            // 파일 변경 안함. 내용만 변경
            console.log(">>> 4");
            formData.append('dsseq', inputs.dsseq);
            formData.append('title', inputs.title);
            formData.append('content', inputs.content);
            formData.append('provider', inputs.provider);
            formData.append('fcseq', inputs.fcseq);
            formData.append('feseq', '0');
            formData.append('file_del', 'false');
        }

        dispatch({
            type: 'dataset/modifyDataset',
            payload: formData
        });
    };

    return {
        inputs,
        categoryList,
        fileExtensionList,
        onChangeInput,
        onChangeSelect,
        onChangeTextarea,
        handleFileSelect,
        onDeleteOriginFile,
        handleDatasetEdit
    };
}