import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import * as portalDatasetApi from "../api/portalDataset";
import { reducerUtils, asyncState } from "../utils/asyncUtils";
import { formatDateTime, spliteDateTime } from "../utils/dateUtils";
import { AxiosError } from "axios";

type searchInfo = {
  pageNumber: number;
  keyword: string;
  category: string;
};

type portalDatasetState = {
  datasetResponse: asyncState;
  datasetContentResponse: asyncState;
  categoriesResponse: asyncState;
};

const initialState: portalDatasetState = {
  datasetResponse: reducerUtils.initial(),
  datasetContentResponse: reducerUtils.initial(),
  categoriesResponse: reducerUtils.initial(),
};

const portalDatasetSlice = createSlice({
  name: "portalDataset",
  initialState: initialState,
  reducers: {
    getDatasetList(state, action: PayloadAction<searchInfo>) {
      state.datasetResponse = reducerUtils.loading();
    },
    getDatasetListSuccess(
      state,
      action: PayloadAction<portalDatasetApi.datasetList[]>
    ) {
      state.datasetResponse = reducerUtils.success(action.payload);
    },
    getDatasetListError(state, action: PayloadAction<AxiosError>) {
      state.datasetResponse = reducerUtils.error(action.payload);
    },
    getDatasetContent(state, action: PayloadAction<any>) {
      state.datasetContentResponse = reducerUtils.loading();
    },
    getDatasetcontentSuccess(
      state,
      action: PayloadAction<portalDatasetApi.datasetContent[]>
    ) {
      state.datasetContentResponse = reducerUtils.success(action.payload);
    },
    getDatasetContentError(state, action: PayloadAction<AxiosError>) {
      state.datasetContentResponse = reducerUtils.error(action.payload);
    },
    getCategoriesList(state) {
      state.categoriesResponse = reducerUtils.loading();
    },
    getCategoriesListSuccess(
      state,
      action: PayloadAction<portalDatasetApi.Categories[]>
    ) {
      state.categoriesResponse = reducerUtils.success(action.payload);
    },
    getCategoriesListError(state, action: PayloadAction<AxiosError>) {
      state.categoriesResponse = reducerUtils.error(action.payload);
    },
  },
});

export const {
  getDatasetList,
  getDatasetListSuccess,
  getDatasetListError,
  getDatasetContent,
  getDatasetcontentSuccess,
  getDatasetContentError,
  getCategoriesList,
  getCategoriesListSuccess,
  getCategoriesListError
} = portalDatasetSlice.actions;
export default portalDatasetSlice.reducer;

/* saga */

function* getDatasetListSaga(action: any) {
  try {
    const datasetResult: any[] = yield call(
      portalDatasetApi.getDatasetList,
      action.payload.pageNumber,
      action.payload.keyword,
      action.payload.category
    );
    const result = Object.entries(datasetResult);
    const datasetList = result[0][1];
    const pageList = result[1][1];
    const total: number = pageList.rowCount;
    const newDatasetResult: portalDatasetApi.datasetList[] = datasetList.map(
      (dataset: portalDatasetApi.datasetList, index: number) => {
        const regDate = new Date(dataset.reg_date);
        const formattedDate = formatDateTime(regDate);
        const real = spliteDateTime(formattedDate);
        dataset.reg_date = real;
        dataset.total = total;
        return dataset;
      }
    );
    yield put(getDatasetListSuccess(newDatasetResult));
  } catch (error) {
    yield put(getDatasetListError(error));
  }
}

function* getDatasetContentSaga(action: any) {
  try {
    const contentResult: portalDatasetApi.datasetContent[] = yield call(
      portalDatasetApi.getDatasetContent,
      action.payload
    );

    yield put(getDatasetcontentSuccess(contentResult));
  } catch (error) {
    yield put(getDatasetContentError(error));
  }
}

function* getCategoriesListSaga() {
    try {
      const CategoriesResult: portalDatasetApi.Categories[] = yield call(
        portalDatasetApi.getCategoriesList,
      );

      yield put(getCategoriesListSuccess(CategoriesResult));
    } catch (error) {
      yield put(getCategoriesListError(error));
    }
  }

export function* portalDatasetSaga() {
  yield takeLatest(getDatasetList, getDatasetListSaga);
  yield takeLatest(getDatasetContent, getDatasetContentSaga);
  yield takeLatest(getCategoriesList, getCategoriesListSaga);
  
}
