import { Lecture, LectureCreateInput, LectureModifyInput, deleteSubject } from 'api/lecture';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { getLectureList, setSelectedLecture, openCreateDialog, closeCreateDialog, registerLecture, getDetail, openEditDialog, closeEditDialog, modifyLecture } from 'store/lectureSlice';
import { swal } from 'utils/swal-utils';

export function useLecture() {
    const { lectureResponse, selectedLecture, createDialogOpen, editDialogOpen, adminInfo, usageList, templateList, registerResponse, detailResponse, modifyResponse } = useSelector((state: RootState) => (state.lecture));
    const dispatch = useDispatch();

    const callGetLectureList = () => {
        dispatch(getLectureList());
    };

    const callSetSelectedLecture = (lecture: Lecture | null) => {
        dispatch(setSelectedLecture(lecture));
    };

    const callOpenCreateDialog = () => {
        dispatch(openCreateDialog());
    };

    const callCloseCreateDialog = () => {
        dispatch(closeCreateDialog());
    };

    const callRegisterLecture = (newLecture: LectureCreateInput) => {
        dispatch(registerLecture(newLecture));
    };

    const callGetDetail = (sseq: number) => {
        dispatch(getDetail(sseq));
    };

    const callOpenEditDialog = () => {
        dispatch(openEditDialog());
    };

    const callCloseEditDialog = () => {
        dispatch(closeEditDialog());
    };

    const callModifyLecture = (lecture: LectureModifyInput) => {
        dispatch(modifyLecture(lecture));
    };

    const callDeleteLecture = async (sseq: number) => {
        try {
            const result = await deleteSubject(sseq);
            swal(result.message);
            callSetSelectedLecture(null);
            callGetLectureList();
        } catch (error) {
            swal(error.message);
        }
    };

    return {
        lectureResponse,
        selectedLecture,
        createDialogOpen,
        editDialogOpen,
        adminInfo,
        usageList,
        templateList,
        registerResponse,
        detailResponse,
        modifyResponse,
        callGetLectureList,
        callSetSelectedLecture,
        callOpenCreateDialog,
        callCloseCreateDialog,
        callRegisterLecture,
        callGetDetail,
        callOpenEditDialog,
        callCloseEditDialog,
        callModifyLecture,
        callDeleteLecture
    };
};