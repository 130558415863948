import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

function Dataset({match}:RouteComponentProps) {
    return (
        <React.Fragment>
                <div className="layout_inner">
                <p className="common_subtit"><span>DATASET</span></p>
                <h3 className="common_tit">데이터셋</h3>

                <div className="dataset_wrap">
                    <div className="dataset_main_text">
                        가치창출에 중요 기반이 되는 빅데이터 공유 플랫폼 센터
                    </div>

                    <div className="dataset_sub_text">
                        <p>
                            빅데이터 공유 플랫폼 센터는 사용자에게 개방할 데이터가 모여있는 공간으로 로그인 후에 누구나 이용할 수 있도록 통합 관리하는 창구 역할을 합니다.<br/>
                            가치 창출에 중요 기반이 되는 빅데이터를 지속적으로 확보하고 개방하겠습니다.
                        </p>
                    </div>

                    <div className="dataset_circle_list">
                        <ul>
                            <li className="social">
                                <h4>사회경제</h4>
                                카드 데이터, 유통/마케팅, 보건/건강, 문화/관광
                            </li>

                            <li className="industry">
                                <h4>산업</h4>
                                위성 데이터, 환경/에너지, 교통/물류, 부동산/지리
                            </li>

                            <li className="public">
                                <h4>공공</h4>
                                공공행정
                        </li>
                        </ul>
                    </div>

                    <div className="dataset_img">
                        <img src="/lib/image/practice/dataset_img_01.jpg" alt="데이터셋 이미지"/>
                    </div>

                    <div className="dataset_main_text">
                        데이터셋 이용절차
                    </div>

                    <div className="dataset_process_list">
                        <ul>
                            <li>
                                <img src="/lib/image/practice/dataset_process_01.png" alt=""/>
                                <div className="separation_line"></div>
                                빅데이터 공유 플랫폼 센터<br/>
                                홈페이지 로그인
                            </li>

                            <li>
                                <img src="/lib/image/practice/dataset_process_02.png" alt=""/>
                                <div className="separation_line"></div>
                                데이터셋 메뉴<br/>
                                클릭하여 이동
                            </li>

                            <li>
                                <img src="/lib/image/practice/dataset_process_03.png" alt=""/>
                                <div className="separation_line"></div>
                                데이터 목록<br/>
                                확인
                            </li>

                            <li>
                                <img src="/lib/image/practice/dataset_process_04.png" alt=""/>
                                <div className="separation_line"></div>
                                다운로드 버튼 클릭하여<br/>
                                해당 데이터 파일 다운로드
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Dataset;