import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import theme from 'styles/theme';
import SideUser from './SideUser';
import SideMenu from './SideMenu';
import sideLogo from 'images/layout/logo.png';

const SidebarArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${({ theme }) =>theme.sizes.sideNavWidth};
  display: flex;
  flex-direction: column;
  //background-color: #0a2348;
  background-color: ${({ theme }) => theme.colors.defaultBg};
  box-shadow: inset -1px 0 0 #04102d;
`;
const LogoArea = styled.div`
  height: 68px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SideNavbar = () => {
  return (
    <SidebarArea>
        <LogoArea><Link to="/"><img src={sideLogo} alt="경상남도 지역혁신플랫폼" /></Link></LogoArea>
        <SideUser />
        <SideMenu />
    </SidebarArea>
  );
}

export { SideNavbar };
