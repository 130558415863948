import axios from 'axios';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type Reservation = {
    nseq: number;
    app_date: string;
    start_day: string;
    end_day: string;
    id: string;
    name: string;
    sbj_nm: string;
    res_cd: string;
    signup_msg: string;
    stat_str: string;
    stat_cd: number;
    reg_date: string;
};

export const reservationState = {
    WAIT: 0,
    APPROVE: 1,
    REJECT: 2,
    POSTPONE: 3,
    CANCEL: 4,
    STOP: 5,
    WITHDRAW: 6,
    EXTEND: 7,
    ETC: 9
};

export const getAdminReservationList = async (status: string) => {
    try {
        const requestUrl = "/lab/book/admin/list/" + status;

        const res = await axios.get(requestUrl);
        if (res.data.success) {
            return (res.data.data);
        }
        else {
            throw new Error(res.data.message);
        }
    } catch (error) {
        handleError(error);
    }
};

export const permitReservation = async (
    nseq: number,
    stat_cd: number,
    reason_msg?: string
) => {
    try {
        const requestUrl = "/lab/book/admin/permit";
        const data = {
            nseq: nseq,
            stat_cd: stat_cd,
        };

        const res = await axios.post(requestUrl, data);
        console.log(res);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};