import React from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import theme from 'styles/theme';
import { Title } from 'pages';
import { CScrollbar  } from 'components/scrollbars';
import { useLoginUser } from 'hooks/useLoginUser';

const MenuNav = styled.nav`
  position: relative;
  color: #afbacb;
  font-size: 13px;
  flex-grow: 1;
  border-top: 1px solid #06193c;
  //ul {
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  overflow-y: auto;
  //  border-top: 1px solid #06193c;
  //}
  li {
    border-bottom: 1px solid #06193c;
    a {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 15px 27px 15px 42px;
      color: #afbacb;
      text-decoration: none;
      //transition: .2s;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 27px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        transform: translateY(-50%);
        //background-color: #485770;
        background-color: ${({ theme }) => theme.colors.primary};
        transition: background-color .2s;
      }
      &:hover, &.active {
        color: #fff;
        background-color: ${({ theme }) => theme.colors.primary};
        &::before {
          background-color: #fff
        }
      }
      &.active {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 4px;
          background-color: #ff6f61
          //background-color: ${({ theme }) => theme.colors.primary};
        }
      }

    }
  }
`;


export const SideMenu = () => {
  const { userRole } = useLoginUser();

  if (userRole === 'ADMIN') {
    return (
      <MenuNav>
          <CScrollbar>
              <ul>
                  <li><NavLink to="/Dashboard" activeClassName="active">{Title.Dashboard}</NavLink></li>
                  {/*<li><NavLink to="/Reservation" activeClassName="active">{Title.Reservation}</NavLink></li>*/}
                  <li><NavLink to="/Environment" activeClassName="active">{Title.Environment}</NavLink></li>
                  {/*<li><NavLink to="/Lecture" activeClassName="active">{Title.Lecture}</NavLink></li>*/}
                  <li><NavLink to="/Template" activeClassName="active">{Title.Template}</NavLink></li>
                  <li><NavLink to="/Practicer" activeClassName="active">{Title.Practicer}</NavLink></li>
                  <li><NavLink to="/Dataset" activeClassName="active">{Title.Dataset}</NavLink></li>
                  {/*<li><NavLink to="/Contest" activeClassName="active">{Title.Contest}</NavLink></li>*/}
                  <li><NavLink to="/Board" activeClassName="active">{Title.Board}</NavLink></li>
                  <li><NavLink to="/Notify" activeClassName="active">{Title.Notify}</NavLink></li>
              </ul>
          </CScrollbar>
      </MenuNav>
    );
  }
  else if (userRole === 'SUPER') {
    return (
      <MenuNav>
          <CScrollbar>
              <ul>
                  <li><NavLink to="/Dashboard" activeClassName="active">{Title.Dashboard}</NavLink></li>
                  {/*<li><NavLink to="/Reservation" activeClassName="active">{Title.Reservation}</NavLink></li>*/}
                  {/*<li><NavLink to="/Environment" activeClassName="active">{Title.Environment}</NavLink></li>*/}
                  {/*<li><NavLink to="/Lecture" activeClassName="active">{Title.Lecture}</NavLink></li>*/}
                  {/*<li><NavLink to="/Template" activeClassName="active">{Title.Template}</NavLink></li>*/}
                  <li><NavLink to="/Practicer" activeClassName="active">{Title.Practicer}</NavLink></li>
                  <li><NavLink to="/Admin" activeClassName="active">{Title.Admin}</NavLink></li>
                  {/*<li><NavLink to="/Dataset" activeClassName="active">{Title.Dataset}</NavLink></li>*/}
                  {/*<li><NavLink to="/Contest" activeClassName="active">{Title.Contest}</NavLink></li>*/}
                  <li><NavLink to="/Board" activeClassName="active">{Title.Board}</NavLink></li>
                  <li><NavLink to="/Environment" activeClassName="active">{Title.Environment}</NavLink></li>
                  {/*<li><NavLink to="/Notify" activeClassName="active">{Title.Notify}</NavLink></li>*/}
              </ul>
          </CScrollbar>
      </MenuNav>
    );
  }
  else {
    return (
      <MenuNav>
          <CScrollbar>
              <ul>
                  <li><NavLink to="/Dashboard" activeClassName="active">{Title.Dashboard}</NavLink></li>
                  <li><NavLink to="/Reservation" activeClassName="active">{Title.Reservation}</NavLink></li>
                  <li><NavLink to="/Environment" activeClassName="active">{Title.Environment}</NavLink></li>
                  <li><NavLink to="/Lecture" activeClassName="active">{Title.Lecture}</NavLink></li>
                  <li><NavLink to="/Template" activeClassName="active">{Title.Template}</NavLink></li>
                  <li><NavLink to="/Practicer" activeClassName="active">{Title.Practicer}</NavLink></li>
                  <li><NavLink to="/Admin" activeClassName="active">{Title.Admin}</NavLink></li>
                  <li><NavLink to="/Dataset" activeClassName="active">{Title.Dataset}</NavLink></li>
                  <li><NavLink to="/Contest" activeClassName="active">{Title.Contest}</NavLink></li>
                  <li><NavLink to="/Board" activeClassName="active">{Title.Board}</NavLink></li>
                  <li><NavLink to="/Notify" activeClassName="active">{Title.Notify}</NavLink></li>
              </ul>
          </CScrollbar>
      </MenuNav>
    );
  }
};

export default SideMenu;
