import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

function Organization({match}:RouteComponentProps) {
    return (
        <React.Fragment>
            <div className="layout_inner">
                <p className="common_subtit">organization</p>
                <h3 className="common_tit">최고의 역량을 보유한 빅데이터 공유 플랫폼</h3>
                <div className="organization_wrap">
                <img src="/lib/image/about/org_img.png" alt="조직도 이미지"/>
                <div className="organization_bg_01"></div>
                <div className="organization_bg_02"></div>
                <div className="table_wrap">
                    <h4 className="table_caption">담당업무</h4>
                    <table className="tb_style_01">
                        <colgroup>
                            <col style={{width:"25%"}}/>
                            <col style={{width:"25%"}}/>
                            <col style={{}}/>
                        </colgroup>
                        <thead>
                            <tr>
                            <th>소속</th>
                            <th>직위</th>
                            <th>담당업무</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>빅데이터 공유 플랫폼 <span>(경남테크노파크)</span></td>
                                <td>센터장</td>
                                <td>경남 빅데이터 공유 플랫폼 총괄</td>
                                </tr>
                                <tr>
                                <td>빅데이터 공유 플랫폼 <span>(경남테크노파크)</span></td>
                                <td>직원</td>
                                <td>경남 빅데이터 공유 플랫폼 운영 담당</td>
                                </tr>
                                <tr>
                                <td>빅데이터 공유 플랫폼 <span>(경남테크노파크)</span></td>
                                <td>직원</td>
                                <td>경남 빅데이터 공유 플랫폼 운영 지원</td>
                                </tr>
                                <tr>
                                <td>빅데이터 공유 플랫폼 <span>(경남대학교)</span></td>
                                <td>직원</td>
                                <td>경남 빅데이터 공유 플랫폼 운영 및 분석 지원</td>
                                </tr>
                                <tr>
                                <td>빅데이터 공유 플랫폼 <span>(경남대학교)</span></td>
                                <td>직원</td>
                                <td>경남 빅데이터 공유 플랫폼 운영 및 분석 지원</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
    </React.Fragment>
    );
}

export default Organization;