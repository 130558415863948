import React, { useState, useEffect } from 'react';
import { ThemeProvider } from "styled-components";
import GlobalStyles from 'styles/globalStyle';
import theme from "styles/theme";
import superTheme from 'styles/superTheme';
import { ToastContainer } from 'react-toastify';

import { SideNavbar } from './SideNavbar';
import { MainContents } from './MainContents';

import { useLoginUser } from 'hooks/useLoginUser';

export const Layout = (props) => {
    const currentPage = props.currentPage;
    const { userRole } = useLoginUser();

    const [appTheme, setAppTheme] = useState(theme);

    useEffect(() => {
        if (userRole === "SUPER") {
            setAppTheme(superTheme);
        }
        else {
            setAppTheme(theme);
        }
    }, [userRole]);


    return (
        <ThemeProvider theme={appTheme}>
            <SideNavbar/>
            <MainContents currentPage={currentPage}>
                {props.children}
                <ToastContainer position="top-right" autoClose={10000} />
            </MainContents>
            <GlobalStyles/>
        </ThemeProvider>
    );
}
export default Layout;
