import axios from 'axios';
import { handleError } from './axiosUtils';

export type authApproveData = {
    role_cd: string;
    token: string;
    useq: string;
}

export const approveAuth = async (data: authApproveData) => {
    try {
        const requestUrl = '/Mail/auth/approval/response';

        const res = await axios.get(requestUrl, {
            params: {
                ...data
            }
        });
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};