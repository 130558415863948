import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Location from '../components/pageLocation/location';
import FindPassword from '../pages/FindPassword/findPassword';
import EmailPassword from '../pages/FindPassword/emailPassword';

function FindPasswordRouter({ match, history }) {

    return(
        <React.Fragment>
            <Location location="비밀번호 찾기" />
            <section id="contetns">
                <div className="layout_inner">
                    <Switch>
                        <Route exact path={`${match.url}`} render={() => <FindPassword match={match} history={history} />} />
                        <Route exact path={`${match.url}/send_email`} render={() => <EmailPassword match={match} history={history} />} />
                    </Switch>
                </div>
            </section>
        </React.Fragment>
    );
}

export default FindPasswordRouter;