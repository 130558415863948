import axios from 'axios';
import { handleError } from './axiosUtils';
import ApiCall from 'utils/customAxiosUtils';

export type Practicer = {
    useq: number;
    id: string;
    name: string;
    role_str: string;
    col_cd: string;
    col_nm: string;
    dep_cd: string;
    dep_nm: string;
    email: string;
    birth_day: string;
    stat_cd: number;
    stat_str: string;
    vm_count: number;
    mobile_no: string;
};

export type ActiveLog = {
    aseq: number;
    useq: number;
    access_ip: string;
    instance_nm: string;
    activity_title: string;
    reg_date: string;
    role_cd: string;
    role_str: string;
}

export const getTraineeList = async () => {
    try {
        const requestUrl = "/user/admin/users-list"
        const res = await axios.get(requestUrl);
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
};

export const updateUserStatus = async (id: string, code: number, reason_msg: string) => {
    try {
        const requestUrl = "/user/admin/stat-management";
        const data = {
            id: id,
            code: code,
            reason: reason_msg
        }
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error) {
        handleError(error);
    }
};

export const getUserActiveLogList = async (page: number) => {
    try {
        const requestUrl = "/log/active/list";
        const res = await axios.get(requestUrl, {
            params: {
                page: page
            }
        });
        return (res.data.data);
    } catch (error) {
        handleError(error);
    }
}

export const getUserActiveLogDetail = async (aseq: number) => {
    try {
        const requestUrl = "/log/active/detail";
        const data = {
            aseq: aseq
        };
        const res = await axios.get(requestUrl, {
            params: data
        });
        return (res.data.data);
    } catch(error) {
        handleError(error);
    }
};

export const updateSaidaUseStatus = async (input: {id: string, status: number}) => {
    try {
        const requestUrl = "/user/admin/saida-grant";
        const data = {
            ...input
        };
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error: any) {
        handleError(error);
    }
};


export const updateInitUserPasswd = async (input: {id: string}) => {
    try {
        const requestUrl = "/user/admin/init-userPw";
        const data = {
            ...input
        };
        const res = await axios.post(requestUrl, data);
        return (res.data);
    } catch (error: any) {
        handleError(error);
    }
};