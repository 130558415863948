import axios from 'axios';
import CallApi from 'utils/fileDownloadAxiosUtils';
import { handleError } from './axiosUtils';

export type datasetList = {
    category_cd: string;
    category_name: string;
    title: string;
    reg_date: any;
    dsseq:number;
    total?: number;
}

export type Paging = {
    startPage: number;
    endPage: number;
    currentPage: number;
    lastPage: number;
    pageCount: number;
    rowCount: number;
    displayRow: number;
    offset: number;
}

export type datasetContent = {
    dsseq: number;
    useq: number;
    name:  string;
    title:  string;
    reg_date: any;
    update_date : any,
    content :  string;
    file_nm:  string;
    file_size: any;
    provider:  string;
    fcseq: number;
    category_cd: string;
    category_name: string;
    feseq: number;
    extension_cd: number;
    file_down_cnt: number;
}

export type Categories = {
    fcseq: number;
    category_cd: string;
    category_name: string;
    reg_date: any;
}

export const getDatasetList = async (pageNumber:number, keyword?:string, category?:string) => {
    try {
        const requestUrl = "/dataset/list";
        const res = await axios.get(requestUrl, {
            params: {
                page: pageNumber,
                keyword : keyword,
                category : category,
            } 
        });
        console.log(res)
        const result = {
            datasetBookList : res.data.data.dataSetTitleDto,
            pagination : res.data.data.paginationDto
        }
        console.log(result)
        return result
    } catch (error) {
        handleError(error);
    }
};

export const getDatasetContent = async (dsseq : number) => {
    try {
        const requestUrl = "/dataset/content";
        const res = await axios.get(requestUrl, {
            params: {
                dsseq : dsseq,
            } 
        });
        const result = res.data
        return result
    } catch (error) {
        handleError(error);
    }
};

export const getCategoriesList = async () => {
    try {
        const requestUrl = "/dataset/category"
        const res = await axios.get(requestUrl, {
        });
        const result = res.data
        return result
    } catch (error) {
        handleError(error);
    }
};

export const downloadDataset = async (dsseq: number | string) => {
    try {
        const requestUrl = "/Portal/lab/datasetlist/download-file";
        const res = await CallApi.get(requestUrl, {
            params: {
                dsseq: dsseq
            },
            responseType: "blob",
        });

        return res;
    } catch (error: any) {
        //handleError(error);
        //console.log(error);
        //return error;

        /*const reader = new FileReader();
        reader.addEventListener("loadend", () => {

        });
        reader.readAsArrayBuffer(error.response);
        console.log(reader.result);*/
        return Promise.reject(error);
    }
} 