/* custom hooks to use sweetAlert */

import Swal from "sweetalert2";

type openSwalParams = {
    text: string;
    onClickConfirm?: () => void;
};

export function useSwal() {

    const openSwal = ({
        text,
        onClickConfirm = () => {console.log("useSwal test");}
    }: openSwalParams) => {
        Swal.fire({
            text: text,
            width: 270,
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
        }).then(function(res) {
            if (res.isConfirmed) {
                onClickConfirm();
            }
        });
    };

    return {
        openSwal
    };
}