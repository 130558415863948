import axios from 'axios';
import React from 'react';
import { handleError } from './axiosUtils';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access-token');

export type Paging = {
    startPage: number;
    endPage: number;
    currentPage: number;
    lastPage: number;
    pageCount: number;
    rowCount: number;
    displayRow: number;
    offset: number;
}

export type Usage = {
    useq: number,
    sbj_nm: string,
    start_day: string,
    end_day: string,
    col_nm: string,
    dep_nm: string,
    hours: number,
    total?:number,
    index?:number
}

export const getUsageDetails= async (page:number) => {
    try {
        const requestUrl = "/subject/my-history";

        const res = await axios.get(requestUrl, {
            params: {
                page : page,
            } 
        });

        const result = {
            usageList : res.data.data.subjectHistoryDtoList,
            pagination : res.data.data.paginationDto
        }

        console.log(result)
        return result;
    } catch (error)  {
        handleError(error);
    }
};