import React from 'react';

import AuthorityList from './AuthorityList';
import AuthorityDetail from './AuthorityDetail';

function AuthorityTab() {
    return (
        <>
            <AuthorityList />
            <AuthorityDetail />
        </>
    );
}

export default AuthorityTab;