export function reasonForKorean(reason: string) {
    switch(reason) {
        case "TRAIN":
            return "수업 실습용";
        case "STUDY":
            return "자습용";
        case "RESEARCH":
            return "연구 목적용";
        case "ETC":
            return "기타 사유";
    }
}