import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Fetch from '../../utils/fetchFunction.js';
import Loading from 'components/spinner/Loading';

function FindPassword({ match, history }) {
    const [loading, setLoading] = useState(false);
    const idRef = useRef();
    const emailRef = useRef();

    const onClickConfirmBtn = () => {
        const idValue = idRef.current.value;
        const emailValue = emailRef.current.value;

        if ((idValue.trim() === "") || (emailValue.trim() === "")) {
            window.alert("이름과 이메일을 입력해주세요.");
        }
        else {
            setLoading(true);
            Fetch.callApi("/user/pwinqury/sendmail", {id: idValue, email: emailValue}, "POST")
            .then((res) => {
                console.log(res);
                setLoading(false);
                history.push(`${match.url}/send_email`);
            })
        }
    };
    return (
        <div className="find_wrap">
            <div className="find_tit">비밀번호 찾기</div>
            <p className="find_tit_txt">회원가입시 등록했던 아이디와 이메일을 입력하면 비밀번호를 찾을 수 있습니다.</p>

            <div className="find_account_wrap">
                <div className="find_account">
                    <input type="text" className="input_style_01" placeholder="아이디를 입력해주세요." ref={idRef} />
                    <input type="text" className="input_style_01" placeholder="이메일을 입력해주세요." ref={emailRef} />
                </div>
                <div className="find_btn_wrap">
                    <Link to="/login/trainee_login" style={{width: "100%", marginRight: "0.3rem"}}>
                        <button type="button" className="cancel_btn">취소</button>
                    </Link>
                    <button type="button" className="confirm_btn" onClick={onClickConfirmBtn}>
                    {loading ? <Loading/>: <span className="ico next">확인</span>}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FindPassword;