import React from 'react';

function AdminFindId({ match, history }) {

    const goBack = () => {
        history.goBack();
    };

    return (
        <div className="find_wrap">
            <div className="find_tit">관리자 아이디 찾기</div>
            <p className="find_tit_txt">회원가입시 등록했던 이름과 이메일을 입력하면 아이디를 찾을 수 있습니다.</p>

            <div className="find_account_wrap">
                <div className="find_account">
                    <input type="text" className="input_style_01" placeholder="이름을 입력해주세요." />
                    <input type="text" className="input_style_01" placeholder="이메일을 입력해주세요." />
                </div>
                <div className="find_btn_wrap">
                    <button type="button" className="cancel_btn" onClick={goBack}>취소</button>
                    <button type="button" className="confirm_btn">확인</button>
                </div>
            </div>
        </div>
    );
}

export default AdminFindId;