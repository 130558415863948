import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CDialog } from "components/dialogs";
import { swalConfirm, openSwal } from "utils/swal-utils";
import FormControl from "@material-ui/core/FormControl";
import { CTextField } from "components/textfields";
import { CFileField } from "components/textfields/CFilefield";
import { CIconButton } from "components/buttons";
import { useDatasetEdit } from 'hooks/useDatasetEdit';

const FileAttachmentWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const DatasetEditDialog = (props) => {
    const {open} = props;
    const {
        inputs,
        categoryList,
        fileExtensionList,
        onChangeInput,
        onChangeSelect,
        onChangeTextarea,
        handleFileSelect,
        onDeleteOriginFile,
        handleDatasetEdit
    } = useDatasetEdit();

    const [addNewFile, setAddNewFile] = useState(false);
    const [hasOriginFile, setHasOriginFile] = useState(false);

    useEffect(() => {
        if (inputs.file_nm === null) {
            setHasOriginFile(false);
            setAddNewFile(true);
        }
        else {
            setHasOriginFile(true);
        }
    }, [inputs]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };
    const handleUpdateAlert = () => {
        //swalConfirm("수정하시겠습니까?");
        openSwal("수정하시겠습니까?", () => { handleDatasetEdit(addNewFile, hasOriginFile); }).then(function (res) {
            if (res.isConfirmed) {
                handleClose();
            }
        });
    };

    const handleFileDelete = () => {
        setAddNewFile(true);
        onDeleteOriginFile();
    };
    
    return (
        <CDialog
            id="myDialog"
            open={open}
            maxWidth="md"
            title={`데이터셋 수정하기`}
            onClose={handleClose}
            onUpdate={handleUpdateAlert}
            modules={['update']}
            topBtn={false}
            topModules={['delete']}
        >
            <table className="tb_data tb_write">
                <tbody>
                <tr>
                    <th>제목</th>
                    <td colSpan={3}>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            name="title"
                            placeholder="제목을 입력하세요."
                            className="form_fullWidth"
                            value={inputs.title}
                            onChange={onChangeInput}
                        />
                    </td>
                </tr>
                <tr>
                    <th>제공기관</th>
                    <td colSpan={3}>
                        <CTextField
                            id="dataset-title"
                            type="text"
                            name="provider"
                            placeholder="제공기관을 입력하세요."
                            className="form_fullWidth"
                            value={inputs.provider}
                            onChange={onChangeInput}
                        />
                    </td>
                </tr>
                <tr>
                    <th>분류체계</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select name="fcseq" value={inputs.fcseq} onChange={onChangeSelect}>
                                <option value="">분류체계 선택</option>
                                {
                                    categoryList.map((category, index) => (<option value={category.fcseq} key={category.fcseq}>{category.category_name}</option>))
                                }
                            </select>
                        </FormControl>
                    </td>
                    <th>파일 확장자</th>
                    <td>
                        <FormControl className="form_fullWidth">
                            <select name="feseq" value={inputs.feseq} onChange={onChangeSelect} disabled>
                                <option value="">파일 확장자 선택</option>
                                {
                                    fileExtensionList.map((extension, index) => (<option value={extension.feseq} key={extension.feseq}>{extension.extension_cd}</option>))
                                }
                            </select>
                        </FormControl>
                    </td>
                </tr>
                <tr>
                    <th>내용</th>
                    <td colSpan={3}>
                        <textarea
                            placeholder="내용을 입력하세요."
                            rows="10"
                            name="content"
                            value={inputs.content}
                            onChange={onChangeTextarea}
                        />
                    </td>
                </tr>
                <tr>
                    <th>첨부파일</th>
                    <td colSpan={3}>
                        <FileAttachment inputs={inputs} handleFileSelect={handleFileSelect} handleFileDelete={handleFileDelete} hasOriginFile={hasOriginFile} addNewFile={addNewFile} />   
                    </td>
                </tr>
                </tbody>
            </table>
        </CDialog>
    );
}

function FileAttachment({
    inputs,
    handleFileSelect,
    handleFileDelete,
    hasOriginFile,
    addNewFile
}) {
    if (hasOriginFile) {
        if (addNewFile) {
            return (
                <CFileField
                    id="dataset-title"
                    name="file"
                    value={inputs.file}
                    onChange={handleFileSelect}
                />
            );
        }
        else {
            return (
                <FileAttachmentWrap>
                    <div>{inputs.file_nm}</div>
                    <CIconButton
                        icon="delete2"
                        tooltip="삭제"
                        onClick={handleFileDelete}
                    />
                </FileAttachmentWrap>
            );
        }
    }
    else {
        return (
            <CFileField
                id="dataset-title"
                name="file"
                value={inputs.file}
                onChange={handleFileSelect}
            />
        );
    }
}

export default DatasetEditDialog;
