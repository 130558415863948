import React, { useState, useEffect } from 'react';
import Layout from "layout";
import { Title } from 'pages';
import { CTabs, CTab, CTabPanel } from 'components/tabs';
import AuthorityTab from './AuthorityTab';
import StateTab from './StateTab';
import SuAdminStateTab from './SuAdminStateTab';
import { useLoginUser } from 'hooks/useLoginUser';

const Practicer = () => {
    const currentPage = Title.Practicer;
    const { userRole } = useLoginUser();
    const [tabvalue, setTabvalue] = useState(0);

    const handleTabChange = (event: any, newValue: number) => {
        setTabvalue(newValue);
    };

    return (
        <Layout currentPage={currentPage}>
            <CTabs
                type="tab1"
                value={tabvalue}
                onChange={handleTabChange}
            >
                <CTab label="실습자 권한 관리" />
                <CTab label="실습자 상세 이용 현황" />
            </CTabs>
            <CTabPanel
                value={tabvalue}
                index={0}
            >
                <AuthorityTab/>
            </CTabPanel>
            <CTabPanel
                value={tabvalue}
                index={1}
            >
                {
                    (userRole === "ADMIN")
                    ? <StateTab />
                    : <SuAdminStateTab />
                }
            </CTabPanel>
        </Layout>
    );
}
export default Practicer;
