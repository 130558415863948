import { Admin } from "api/admin";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from 'store';
import { setSelectedAdmin } from 'store/adminSlice';
import { updateAdminStatus } from 'api/admin';
import { swal } from "utils/swal-utils";

export function useAdmin() {
    const { adminResponse, selectedAdmin, practicerActiveResponse, adminActiveResponse } = useSelector((state: RootState) => (state.admin));
    const dispatch = useDispatch();

    const callGetAdminList = () => {
        dispatch({
            type: "admin/getAdminList"
        });
    };

    const callSetSelectedAdmin = (admin: Admin | null) => {
        dispatch(setSelectedAdmin(admin));
    };

    const callUpdateAdminStatus = async (id: string, code: number, reason_msg: string) => {
        try {
            const result = await updateAdminStatus(id, code, reason_msg);
            callSetSelectedAdmin(null);
            swal(result.message);
        } catch (error) {
            swal(error.message);
        }
    };

    const callGetPracticerActiveLogList = (page: number) => {
        dispatch({
            type: "admin/getPracticerActiveLogList",
            payload: page
        })
    };

    const callGetAdminActiveLogList = (page: number) => {
        dispatch({
            type: "admin/getAdminActiveLogList",
            payload: page
        })
    };

    return {
        adminResponse,
        selectedAdmin,
        practicerActiveResponse,
        adminActiveResponse,
        callGetAdminList,
        callSetSelectedAdmin,
        callGetPracticerActiveLogList,
        callGetAdminActiveLogList,
        callUpdateAdminStatus
    }
}