import React, { useState, useEffect } from 'react';

type GridPaginationProps = {
    totalCount: number;
    itemPerPage: number;
    onClickPrev: () => void;
    onClickNext: () => void;
    onChangeItemPerPage: (value: number) => void;
};

function GridPagination({
    totalCount,
    itemPerPage,
    onClickPrev,
    onClickNext,
    onChangeItemPerPage
}: GridPaginationProps) {
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        setPage(1);
    }, [itemPerPage]);

    const count: number = Math.floor(totalCount / itemPerPage) + (((totalCount % itemPerPage) > 0) ? 1 : 0);

    const handlePrev = () => {
        setPage((prev) => {
            if (prev > 1) {
                return (prev - 1);
            }
            else {
                return prev;
            }
        });
        onClickPrev();
    }

    const handleNext = () => {
        setPage((prev) => {
            if (prev < count) {
                return (prev + 1);
            }
            else {
                return prev
            }
        });
        onClickNext();
    };

    const handleChangeRowPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected: number = Number.parseInt(event.target.value);
        onChangeItemPerPage(selected);
    };

    return (
        <div id="pagination">
            <div className="paging-wrap">
                <div>
                    <select className="btn_comm" value={itemPerPage} onChange={handleChangeRowPerPage}>
                        <option value="10">10건</option>
                        <option value="20">20건</option>
                        <option value="50">50건</option>
                    </select>
                    <span>총 {totalCount}건</span>
                </div>
                <div>
                    <button type="button" className="btn_comm" onClick={handlePrev}>
                        <span className="btnLabel_icon hover prev">Prev</span>
                    </button>
                    <span className="page-num">{page} of {count}</span>
                    <button type="button" className="btn_comm" onClick={handleNext}>
                        <span className="btnLabel_icon hover next">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default GridPagination;