import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { asyncState, reducerUtils } from "utils/asyncUtils";
import { call, put, takeLatest, takeEvery } from "@redux-saga/core/effects";
import * as LectureApi from 'api/lecture';
import { getUserSimpleInfo, userSimpleInfo }  from 'api/loginUser';
import { getTemplateSimpleList, SimpleTemplate } from "api/template";
import { AxiosError } from 'axios';

type ApiResponse = {
    success: boolean;
    message: string;
}

type LectureState = {
    lectureResponse: asyncState;
    selectedLecture: LectureApi.Lecture | null;
    createDialogOpen: boolean;
    editDialogOpen: boolean;
    adminInfo: userSimpleInfo | null;
    usageList: LectureApi.UsageType[] | null;
    templateList: SimpleTemplate[] | null;
    registerResponse: asyncState;
    detailResponse: asyncState;
    modifyResponse: asyncState;
};

const initialState: LectureState =  {
    lectureResponse: reducerUtils.initial(),
    selectedLecture: null,
    createDialogOpen: false,
    editDialogOpen: false,
    adminInfo: null,
    usageList: null,
    templateList: null,
    registerResponse: reducerUtils.initial(),
    detailResponse: reducerUtils.initial(),
    modifyResponse: reducerUtils.initial(),
};

const lectureSlice = createSlice({
    name: 'lecture',
    initialState: initialState,
    reducers: {
        getLectureList(state) {
            state.lectureResponse = reducerUtils.loading();
        },
        getLectureListSuccess(state, action: PayloadAction<LectureApi.Lecture[]>) {
            state.lectureResponse = reducerUtils.success(action.payload);
        },
        getLectureListError(state, action: PayloadAction<AxiosError>) {
            state.lectureResponse = reducerUtils.error(action.payload);
        },
        setSelectedLecture(state, action: PayloadAction<LectureApi.Lecture | null>) {
            state.selectedLecture = action.payload;
        },
        openCreateDialog(state) {
            state.createDialogOpen = true;
        },
        closeCreateDialog(state) {
            state.createDialogOpen = false;
        },
        openEditDialog(state) {
            state.editDialogOpen = true;
        },
        closeEditDialog(state) {
            state.editDialogOpen = false;
            state.modifyResponse = reducerUtils.initial();
        },
        setAdminInfo(state, action: PayloadAction<userSimpleInfo | null>) {
            state.adminInfo = action.payload;
        },
        setTemplateList(state, action: PayloadAction<SimpleTemplate[]>) {
            state.templateList = action.payload;
        },
        setUsageList(state, action: PayloadAction<LectureApi.UsageType[]>) {
            state.usageList = action.payload;
        },
        registerLecture(state, action: PayloadAction<LectureApi.LectureCreateInput>) {
            state.registerResponse = reducerUtils.loading();
        },
        registerLectureSuccess(state, action: PayloadAction<ApiResponse>) {
            state.registerResponse = reducerUtils.success(action.payload);
        },
        registerLectureError(state, action: PayloadAction<AxiosError>) {
            state.registerResponse = reducerUtils.error(action.payload);
        },
        modifyLecture(state, action: PayloadAction<LectureApi.LectureModifyInput>) {
            state.modifyResponse = reducerUtils.loading();
        },
        modifyLectureSuccess(state, action: PayloadAction<ApiResponse>) {
            state.modifyResponse = reducerUtils.success(action.payload);
            //state.detailResponse = reducerUtils.initial();
        },
        modifyLectureError(state, action: PayloadAction<AxiosError>) {
            state.modifyResponse = reducerUtils.error(action.payload);
        },
        getDetail(state, action: PayloadAction<number>) {
            state.detailResponse = reducerUtils.loading();
        },
        getDetailSuccess(state, action: PayloadAction<LectureApi.LectureDetail>) {
            state.detailResponse = reducerUtils.success(action.payload);
        },
        getDetailError(state, action: PayloadAction<AxiosError>) {
            state.detailResponse = reducerUtils.error(action.payload);
        },
    },
});

export const { 
    getLectureList, 
    getLectureListSuccess, 
    getLectureListError, 
    setSelectedLecture, 
    openCreateDialog, 
    closeCreateDialog,
    openEditDialog,
    closeEditDialog,
    setAdminInfo, 
    setTemplateList, 
    setUsageList,
    registerLecture,
    registerLectureError,
    registerLectureSuccess,
    modifyLecture,
    modifyLectureError,
    modifyLectureSuccess,
    getDetail,
    getDetailError,
    getDetailSuccess
} = lectureSlice.actions;
export default lectureSlice.reducer;

/* saga */

function* getLectureListSaga() {
    try {
        const lectureList: LectureApi.Lecture[] = yield call(LectureApi.getLectureList);
        yield put(getLectureListSuccess(lectureList));
    } catch (error) {
        yield put(getLectureListError(error));
    }
}

function* getDetailSaga(action: PayloadAction<number>) {
    try {
        const lectureDetail: LectureApi.LectureDetail = yield call(LectureApi.getSubjetDetail, action.payload);
        yield put(getDetailSuccess(lectureDetail));
    } catch (error) {
        yield put(getDetailError(error));
    }
}

function* openCreateDialogSaga() {
    try {
        const userInfo: userSimpleInfo = yield call(getUserSimpleInfo);
        yield put(setAdminInfo(userInfo));
        
        const templateList: SimpleTemplate[] = yield call(getTemplateSimpleList);
        yield put(setTemplateList(templateList));

        const usageList: LectureApi.UsageType[] = yield call(LectureApi.getUsageTypes);
        yield put(setUsageList(usageList));
    } catch (error) {
        yield put(setAdminInfo(null));
        yield put(setTemplateList([]));
        yield put(setUsageList([]));
    }
}

function* openEditDialogSaga() {
    try {
        const templateList: SimpleTemplate[] = yield call(getTemplateSimpleList);
        yield put(setTemplateList(templateList));
    } catch (error) {
        yield put(setTemplateList([]));
    }
}

function* registerLectureSaga(action: PayloadAction<LectureApi.LectureCreateInput>) {
    try {
        const response: ApiResponse = yield call(LectureApi.registerSubject, action.payload);
        yield put(registerLectureSuccess(response));
    } catch (error) {
        yield put(registerLectureError(error));
    }
}

function* modifyLectureSaga(action: PayloadAction<LectureApi.LectureModifyInput>) {
    try {
        const response: ApiResponse = yield call(LectureApi.modifySubject, action.payload);
        yield put(modifyLectureSuccess(response));
        yield put(setSelectedLecture(null));
        yield put(getLectureList());
    } catch (error) {
        yield put(modifyLectureError(error));
    }
}

export function* lectureSaga() {
    yield takeLatest(getLectureList, getLectureListSaga);
    yield takeEvery(openCreateDialog, openCreateDialogSaga);
    yield takeEvery(registerLecture, registerLectureSaga);
    yield takeLatest(getDetail, getDetailSaga);
    yield takeEvery(openEditDialog, openEditDialogSaga);
    yield takeEvery(modifyLecture, modifyLectureSaga);
}